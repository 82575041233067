import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { appear } from "../../newUXPages/InformaAcademy/components/CompleteCourse/styles";

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #fff;
`;

export const TableContainer = styled.div`
  width: 100%;
  background-color: #fff;
  margin-top: 32px;
`;

export const FormContainer = styled.div`
  form {
    display: flex;
    flex-direction: column;
  }
`;

export const WrapperContainer = styled.div`
  display: grid;
  gap: 24px;
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
  line-height: 22px;
  color: #002244;
`;

const borderVariant = {
  default: {
    borderBottom: "1px solid rgba(0,0,0,0.16)",
  },
  noBorder: {
    borderBottom: "0px solid rgba(0,0,0,0)",
  },
};

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 21px;
  padding-left: 8px;
  border-bottom: ${({ border }) =>
    borderVariant[border]?.borderBottom || borderVariant.default.borderBottom};

  & > div {
    display: flex;
    gap: 16px;
  }
`;

export const FieldsContainer = styled.div`
  margin-top: 16px;
`;

export const Horizontal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > div {
    display: flex;
    flex: 1 0 100px;
    margin-left: 16px;
    @media (max-width: 500px) {
      margin-left: 0px;
      flex: 1 1 auto;
      width: 100%;
    }
  }

  & > div:first-child {
    margin-left: 0px;
  }
`;

export const FilterActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  flex-wrap: wrap;

  & > div:first-child {
    margin-left: 0px;
  }

  @media (max-width: 500px) {
    width: 100%;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 16px;
    @media (max-width: 500px) {
      margin-left: 0px;
    }
  }
`;

export const SubmitContainer = styled.div`
  width: 100%;
  max-width: 150px;

  @media (max-width: 500px) {
    margin-left: 0px;
    max-width: none;
    width: 100%;
  }
`;

export const TableTitleContainer = styled.div`
  padding: 16px 0px 16px 24px;
`;

export const TableTitle = styled.p``;

export const LinkButton = styled(Link)`
  text-decoration: none;
`;

export const GoBackContainer = styled.div``;
export const BadgeContainer = styled.div`
  cursor: pointer;
  @media (max-width: 500px) {
    margin-top: 16px;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 473px;
  background-color: #fff;
  border: 1px solid #dadada;
  border-radius: 5px;
  padding: 8px 16.8px;
`;
export const SearchIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #7d7d7d;
  margin-right: 8px;
`;
export const SearchInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
  background-color: transparent;
  color: #333;
  ::placeholder {
    color: #002244;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const ModalTitle = styled.h2`
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
`;

export const ModalSubtitle = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);

  ${(props) =>
    props.bold &&
    css`
      font-weight: 600;
    `}
`;

export const ModalButtonsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 16px;
  align-items: center;

  & > button {
    flex: 1;
  }
`;

export const CourseCardContainer = styled.div`
  min-width: 241px;
  max-width: 241px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;

  &:hover img,
  &:hover .failed {
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.4);
  }

  & > div {
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 21.79px;
      color: #3a3a3c;
      margin-bottom: 0px;
      width: 230px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      color: #6c6c6c;
      font-size: 14px;
      line-height: 22px;
      width: 230px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 152px;
  position: relative;
`;

export const SkeletonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 152px;
  border-radius: 4px;
`;

export const CourseImageCard = styled.img`
  min-width: 241px;
  max-width: 241px;
  height: 152px;
  max-height: 152px;
  object-fit: cover;
  border-radius: 4px;
  opacity: 0;
  transition: all 300ms;

  ${({ isLoading, error }) =>
    !isLoading &&
    !error &&
    css`
      animation: ${appear} 500ms forwards;
    `};
`;

export const FailedToLoad = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 152px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #dadada;
  flex-direction: column;
  gap: 4px;
  color: #002244 !important;
  z-index: 2;

  opacity: 0;
  transition: all 300ms;

  & > p {
    width: 60% !important;
    text-align: center;
    margin-bottom: 0;
    color: #002244 !important;
  }

  ${({ isLoading }) =>
    !isLoading &&
    css`
      animation: ${appear} 500ms forwards;
    `}
`;

export const CourseCardsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 16px;
  padding-bottom: 10px;
  overflow-x: auto;
  width: 90vw;
`;

export const Loader = styled.div`
  background: rgba(0, 0, 0, 0.1);
  height: 224px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
