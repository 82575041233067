import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

import { Container, Content } from "./styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Layout = ({ children }) => {
  const history = useHistory();
  const eventManual = history.location.pathname.includes("/event-manual/");
  const informaAcademyCourse =
    history.location.pathname.includes("/informa-academy/") || eventManual;
  const isLessonPage = history.location.pathname.includes("/lesson/");

  return (
    <Container>
      <Sidebar informaAcademyCourse={informaAcademyCourse || eventManual} />
      {!eventManual && (
        <Content informaAcademyCourse={informaAcademyCourse || eventManual}>
          {!isLessonPage && !eventManual && <Header />}
          {children}
        </Content>
      )}
      {eventManual && children}
      <Footer />
    </Container>
  );
};

export default Layout;
