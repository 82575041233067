import React, { useEffect, useState } from "react";
import {
  ManualSidebarContainer,
  ManualSidebarItem,
  ManualSidebarSubitem,
  ManualSidebarSubitemWrapper,
  ManualSidebarText,
  ManualSidebarWrapper,
  SidebarContainer,
  SidebarItensWrapper,
} from "./styles";
import { BsHouseDoor } from "react-icons/bs";
import { FiBookOpen } from "react-icons/fi";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { FormattedMessage } from "react-intl";
import { ArticleIcon } from "../EventManualHomepage/TopicList";

const ManualSidebar = ({ topics = [] }) => {
  const [menuOpen, setMenuOpen] = useState();
  const { articleId } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (articleId) {
      const chapterIndex = topics.findIndex((topic) =>
        topic.articles.some((article) => article.id === Number(articleId))
      );

      setMenuOpen(chapterIndex + 1);
    }
  }, [articleId, topics]);

  if (!topics) return null;

  return (
    <ManualSidebarContainer>
      <ManualSidebarItem onClick={() => history.push("/event-manual")}>
        <BsHouseDoor />
        <FormattedMessage id="home" />
      </ManualSidebarItem>
      <ManualSidebarItem>
        <FiBookOpen />
        <FormattedMessage id="exhibitor_manual" />
      </ManualSidebarItem>

      <SidebarContainer>
        {topics.map((topic, index) => {
          return (
            <ManualSidebarWrapper
              key={`sidebarChapter-${index}`}
              active={menuOpen === index + 1}
            >
              <ManualSidebarItem
                padding="4px 0 4px 12px"
                justify="space-between"
                active={menuOpen === index + 1}
                className={menuOpen === index + 1 ? "active" : ""}
                onClick={() => {
                  if (menuOpen && menuOpen === index + 1) {
                    setMenuOpen();
                  } else {
                    setMenuOpen(index + 1);
                  }
                }}
              >
                <ManualSidebarText
                  active={menuOpen === index + 1}
                  to={`/event-manual/chapter/${topic.chapterId}`}
                >
                  {topic.chapterTitle}
                </ManualSidebarText>
                {menuOpen && menuOpen === index + 1 ? (
                  <IoIosArrowUp />
                ) : (
                  <IoIosArrowDown />
                )}
              </ManualSidebarItem>

              {menuOpen && menuOpen === index + 1 ? (
                <SidebarItensWrapper>
                  {topic.articles.map((article) => (
                    <ManualSidebarSubitemWrapper>
                      <ManualSidebarSubitem
                        padding="4px 0 4px 12px"
                        className={
                          article.id === Number(articleId) ? "active" : ""
                        }
                        to={`/event-manual/chapter/${topic.chapterId}/article/${article.id}`}
                      >
                        <ArticleIcon />
                        {article.title}
                      </ManualSidebarSubitem>
                    </ManualSidebarSubitemWrapper>
                  ))}
                </SidebarItensWrapper>
              ) : null}
            </ManualSidebarWrapper>
          );
        })}
      </SidebarContainer>
    </ManualSidebarContainer>
  );
};

export default ManualSidebar;
