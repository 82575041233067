import * as React from "react";

const Recommend = (props) => {
  const { size = "13", fill = "#11A7D9", stroke = "#000" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 13 13"
    >
      <path
        fill={fill}
        stroke={stroke}
        d="M2.5 10a.48.48 0 0 0-.148-.352A.48.48 0 0 0 2 9.5q-.211 0-.355.148A.49.49 0 0 0 1.5 10q0 .211.145.355A.48.48 0 0 0 2 10.5a.49.49 0 0 0 .352-.145A.48.48 0 0 0 2.5 10m1.25-4v5a.48.48 0 0 1-.148.352.48.48 0 0 1-.352.148H1a.48.48 0 0 1-.352-.148A.48.48 0 0 1 .5 11V6a.48.48 0 0 1 .148-.352A.48.48 0 0 1 1 5.5h2.25a.48.48 0 0 1 .352.148A.48.48 0 0 1 3.75 6M13 6q0 .672-.43 1.164.117.344.117.594.024.593-.335 1.07.132.438 0 .914a1.5 1.5 0 0 1-.422.735q.07.874-.383 1.414-.5.594-1.54.609H9q-.516 0-1.125-.121a10 10 0 0 1-.95-.227q-.34-.105-.94-.308-.962-.336-1.235-.344a.52.52 0 0 1-.352-.152A.47.47 0 0 1 4.25 11V5.992q0-.195.14-.34a.5.5 0 0 1 .337-.16q.187-.015.593-.46.406-.447.79-.946.531-.68.788-.938.141-.14.243-.375.101-.234.136-.378.036-.145.106-.473a8 8 0 0 1 .097-.477q.044-.171.153-.406.11-.234.265-.39A.48.48 0 0 1 8.25.5q.36 0 .645.082t.468.203q.184.12.313.317.129.195.187.351.059.156.094.39A3.4 3.4 0 0 1 10 2.5q0 .297-.074.594a3 3 0 0 1-.149.469 6 6 0 0 1-.293.578 1.3 1.3 0 0 0-.148.359H11.5q.61 0 1.055.445Q13 5.391 13 6"
      ></path>
    </svg>
  );
};

export default Recommend;
