import styled, { css } from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  ${(props) =>
    props.modal &&
    css`
      border-color: #cbd5e1;
    `}

  & h2 {
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);

    &.bold {
      font-weight: 700;
      text-transform: capitalize;
    }
  }

  .ant-modal-content {
    border-radius: 8px;
  }
`;

export const ButtonContainer = styled.div`
  width: fit-content;
  margin-left: auto;
  display: flex;
  gap: 1rem;

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
`;

export const ModalBody = styled.div``;

export const Text = styled.p`
  font-size: 14px;
  line-height: 18.86px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
`;
