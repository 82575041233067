import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsClock } from "react-icons/bs";
import { useIntl } from "react-intl";
import { AiFillPlayCircle } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import { Modal } from "antd";

import {
  Container,
  Description,
  FadeContainer,
  Header,
  HeaderContainer,
  HeaderImage,
  HeaderWrapper,
  Info,
  InfoContainer,
  Lessons,
  LessonsContainer,
  LessonsHeader,
  RestartCourseButtons,
  RestartCourseContent,
  RestartCourseTitle,
  Title,
  TransitionWrapper,
} from "./styles";
import useZoom from "../../../hooks/zoom";
import Button, { BackButton } from "../components/Button";
import Category from "../../../newUXAssets/icons/Category";
import Calendar from "../../../newUXAssets/icons/Calendar";
import { LessonCard } from "../../../newUXComponents/LessonCard";
import CourseProgression from "../components/CourseProgression";
import Restart from "../../../newUXAssets/icons/Restart";
import {
  courseImpression,
  getInformaAcademyCourseInfo,
  restartCourse,
  setCourses,
} from "../../../redux/modules/informaAcademy/actions";
import moment from "moment";
import useNotification from "../../../hooks/newUXNotification";
import { CourseLoaderSkelleton } from "./skeleton";

export default function InformaAcademyCourse() {
  const intl = useIntl();
  const { courseId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { course, isLoadingCourse, error, courses, coursesLoaded } =
    useSelector((state) => state.informaAcademy);
  const { event } = useSelector((state) => state.initialSettings);
  const notification = useNotification();

  useEffect(() => {
    if (!courseId) return;

    dispatch(
      getInformaAcademyCourseInfo({
        courseId,
      })
    );

    dispatch(
      courseImpression({
        courseId,
      })
    );
  }, [dispatch, courseId, history]);

  useEffect(() => {
    if (coursesLoaded) return;
    dispatch(setCourses({}));
  }, [dispatch, coursesLoaded]);

  useEffect(() => {
    if (isNaN(event?.value)) return;
    if (
      coursesLoaded &&
      !courses.some((course) => course.courseId === +courseId)
    ) {
      notification.displayInfo(
        intl.formatMessage({ id: "error.course_not_allowed" }),
        "error.course_not_allowed"
      );
      history.push("/checklist");
    }
  }, [
    courseId,
    courses,
    coursesLoaded,
    event?.value,
    history,
    intl,
    notification,
  ]);

  useEffect(() => {
    if (error) {
      history.push("/not-found");
    }

    if (isNaN(event?.value)) {
      history.push("/checklist");
    }
  }, [error, event?.value, history]);

  return (
    <TransitionWrapper>
      <FadeContainer isVisible={isLoadingCourse || !course} absolute>
        <CourseLoaderSkelleton />
      </FadeContainer>

      <FadeContainer isVisible={!isLoadingCourse && course && course.lessons.length > 0}>
        <Container>
          <CourseHeader
            title={course.name}
            desc={course.description}
            workload={course.workload}
            category={course.category}
            updatedAt={course.lastUpdatedDate}
            courseId={courseId}
            bannerSrc={course.coverImage}
            lessons={course.lessons}
            courseStatus={course.courseStatus}
          />
          <CourseLessons
            lessons={course.lessons}
            courseId={courseId}
            courseStatus={course.courseStatus}
          />
        </Container>
      </FadeContainer>
    </TransitionWrapper>
  );
}

function CourseHeader({
  title,
  desc,
  workload,
  category,
  updatedAt,
  lessons,
  courseId,
  bannerSrc,
  courseStatus,
}) {
  const { scale } = useZoom();

  const nextLesson = useCallback(() => {
    return getNextLessonInfo({ lessons });
  }, [lessons]);

  const nextLessonInfo = nextLesson();

  return (
    <HeaderWrapper>
      <Header>
        <section>
          <BackButton href={"/informa-academy"} />
          <HeaderContainer scale={scale}>
            <Title scale={scale}>{title}</Title>
            <Description
              scale={scale}
              dangerouslySetInnerHTML={{ __html: desc }}
            />
          </HeaderContainer>
          <InfoContainer>
            <CourseInfo infoType={"workload"} info={workload} />
            <CourseInfo infoType={"category"} info={category} />
            <CourseInfo infoType={"updatedAt"} info={updatedAt} />
          </InfoContainer>
          <CourseButton
            userCourseStatus={courseStatus}
            courseTitle={title}
            courseId={courseId}
            nextLesson={nextLessonInfo}
          />
        </section>

        <HeaderImage scale={scale} src={bannerSrc} alt="Checklist Step Cover" />
      </Header>
    </HeaderWrapper>
  );
}

function CourseInfo({ infoType, info }) {
  const intl = useIntl();

  const categoryString = intl.formatMessage({
    id: "category",
  });
  const workloadString = intl.formatMessage({
    id: "workload",
  });
  const updatedAtString = intl.formatMessage({
    id: "updatedAt",
  });
  const hoursString = intl.formatMessage({
    id: "hours",
  });

  const color = "#095F83";
  const size = 15;

  switch (infoType) {
    case "category":
      return (
        <CourseInfoItem
          Icon={<Category width={size} height={size} color={color} />}
          label={categoryString}
          info={info}
        />
      );
    case "workload":
      return (
        <CourseInfoItem
          Icon={<BsClock width={size} height={size} color={color} />}
          label={workloadString}
          info={`${info} ${hoursString}`}
        />
      );
    case "updatedAt":
      const date = moment(info).format("DD/MM/YYYY");
      return (
        <CourseInfoItem
          Icon={<Calendar width={size} height={size} color={color} />}
          label={updatedAtString}
          info={date}
        />
      );

    default:
      return null;
  }
}

function CourseInfoItem({ Icon, label, info }) {
  return (
    <Info>
      {Icon}
      <p>{label}</p>
      <p>{info}</p>
    </Info>
  );
}

function CourseButton({ userCourseStatus, courseTitle, courseId, nextLesson }) {
  const history = useHistory();
  const intl = useIntl();

  const buttonStart = intl.formatMessage({ id: "course_start" });
  const buttonResume = intl.formatMessage({ id: "course_resume" });

  const redirectToLesson = () => {
    if (nextLesson.allCompleted && userCourseStatus === "IN_PROGRESS")
      return history.push(`/informa-academy/course/${courseId}/complete`);

    history.push(`/informa-academy/course/${courseId}/lesson/${nextLesson.lessonId}`);
  };

  const handleStartCourse = () => {
    redirectToLesson();
  };

  switch (userCourseStatus) {
    case "FINISHED": {
      return <RestartCourse courseTitle={courseTitle} courseId={courseId} />;
    }
    case "IN_PROGRESS": {
      return (
        <Button
          Icon={<AiFillPlayCircle size={20} />}
          label={buttonResume}
          variant={"academy"}
          size={"academy"}
          onClick={redirectToLesson}
        />
      );
    }
    case "NOT_STARTED": {
      return (
        <Button
          Icon={<AiFillPlayCircle size={20} />}
          label={buttonStart}
          variant={"academy"}
          size={"academy"}
          onClick={handleStartCourse}
        />
      );
    }
    default:
      return null;
  }
}

function CourseLessons({ lessons, courseId, courseStatus }) {
  const intl = useIntl();

  const lessonsString = intl.formatMessage({ id: "course_lessons" });

  const total = lessons.length;

  const concluded = lessons.filter((l) => l.completed === true).length;

  return (
    <LessonsContainer>
      <LessonsHeader>
        <h2>
          {lessonsString} ({total})
        </h2>
        <CourseProgression concludedLessons={concluded} totalLessons={total} />
      </LessonsHeader>
      <Lessons>
        {lessons.map((lesson, index) => (
          <LessonCard
            lesson={lesson}
            key={lesson.lessonId}
            index={index}
            courseId={courseId}
            lessonId={lesson.lessonId}
            courseStatus={courseStatus}
          />
        ))}
      </Lessons>
    </LessonsContainer>
  );
}

function RestartCourse({ courseTitle, courseId }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();

  const title = intl.formatMessage({ id: "course_restart" });
  const restartWarning = intl.formatMessage({ id: "course_restart_warning" });
  const restartConfirm = intl.formatMessage({ id: "course_restart_confirm" });
  const buttonCancel = intl.formatMessage({ id: "cancel" });
  const buttonConfirm = intl.formatMessage({ id: "confirm" });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(restartCourse({ courseId }));
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        Icon={<Restart />}
        label={title}
        variant={"academyRestartCourse"}
        size={"academy"}
        onClick={showModal}
      />
      <Modal
        title={<RestartCourseTitle>{title}</RestartCourseTitle>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="modalStyle"
        closable={false}
        centered
        footer={null}
      >
        <RestartCourseContent>
          <p>{restartWarning}</p>
          <p className="highlight">
            <span>"{courseTitle}"</span>?
          </p>
          <p>{restartConfirm}</p>
        </RestartCourseContent>
        <RestartCourseButtons>
          <Button
            label={buttonCancel}
            onClick={handleCancel}
            variant={"academySecondary"}
            size={"academy"}
          />
          <Button
            label={buttonConfirm}
            onClick={handleOk}
            variant={"academy"}
            size={"academy"}
          />
        </RestartCourseButtons>
      </Modal>
    </>
  );
}

export const getNextLessonInfo = ({ lessons }) => {
  const nextLessonInfo = {
    lessonId: "",
    allCompleted: false,
  };
  const isLessonsCompleted = lessons.every(
    (lesson) => lesson.completed === true
  );
  const lastCompletedLessonIndex = lessons.findLastIndex(
    (l) => l.completed === true
  );

  if (isLessonsCompleted) {
    nextLessonInfo.allCompleted = true;
  }

  if (lastCompletedLessonIndex === -1) {
    nextLessonInfo.lessonId = lessons[0].lessonId;
    return nextLessonInfo;
  }

  if (lastCompletedLessonIndex + 1 === lessons.length) {
    const index = lessons.findIndex((l) => l.completed === false);

    nextLessonInfo.lessonId = lessons[index]?.lessonId;
    return nextLessonInfo;
  }

  nextLessonInfo.lessonId = lessons[lastCompletedLessonIndex + 1].lessonId;
  return nextLessonInfo;
};
