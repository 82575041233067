import styled, { css } from "styled-components";

export const Container = styled.div`
  flex: 1;
  width: 100%;
  padding: 16px;
  background-color: #fff;
`;

export const Horizontal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > div {
    display: flex;
    flex: 1 0 100px;
    margin-left: 16px;
    @media (max-width: 768px) {
      margin-left: 0px;
      flex: 1 1 auto;
      width: 100%;
    }
  }

  & > div:first-child {
    margin-left: 0px;
  }
`;

export const Footer = styled.div`
  flex: 1;
  width: 100%;
  padding: 16px;
  background-color: #fff;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 16px;
`;