import html2pdf from "html2pdf.js";
import React, { useState } from "react";
import ReactDOM from "react-dom";

// Componente Modal para exibir o progresso
const ProgressModal = ({ status, message, visible }) => {
  if (!visible) return null;

  return (
    <div className="pdf-progress-modal-overlay">
      <div className="pdf-progress-modal">
        <div className={`pdf-progress-status ${status}`}>
          {status === "loading" && <div className="pdf-progress-spinner"></div>}
          {status === "success" && <div className="pdf-progress-check">✓</div>}
          {status === "error" && <div className="pdf-progress-error">×</div>}
        </div>
        <div className="pdf-progress-message">{message}</div>
      </div>
      <style jsx="true">{`
        .pdf-progress-modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9999;
        }
        .pdf-progress-modal {
          background-color: white;
          border-radius: 8px;
          padding: 20px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          text-align: center;
          min-width: 300px;
        }
        .pdf-progress-status {
          margin-bottom: 15px;
        }
        .pdf-progress-spinner {
          border: 4px solid #f3f3f3;
          border-top: 4px solid #3498db;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          animation: spin 2s linear infinite;
          margin: 0 auto;
        }
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        .pdf-progress-check {
          color: #4caf50;
          font-size: 40px;
          font-weight: bold;
        }
        .pdf-progress-error {
          color: #f44336;
          font-size: 40px;
          font-weight: bold;
        }
        .pdf-progress-message {
          font-size: 16px;
          color: #333;
        }
      `}</style>
    </div>
  );
};

const sanitizeFilename = (name) => {
  return name
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^a-zA-Z0-9\s]/g, "")
    .replace(/\s+/g, "_")
    .toLowerCase();
};

// Renderiza um artigo como HTML
const renderArticleHtml = (article) => {
  return `
    <article class="article">
      <h2>${article.articleTitle}</h2>
      <div class="article-meta">Atualizado em: ${article.updatedAt}</div>
      <div class="article-content">${article.content}</div>
    </article>
  `;
};

// Gera o HTML completo
const generateHtml = (data) => {
  const isArray = Array.isArray(data);
  const title = isArray
    ? data[0]?.chapterName || "Capítulo"
    : data.chapterName || data.articleTitle;

  let content = "";

  if (isArray) {
    content = data.map((article) => renderArticleHtml(article)).join("");
  } else {
    content = renderArticleHtml(data);
  }

  return `
    <div class="pdf-content">
      <style>
        .pdf-content {
          padding: 20px;
          font-family: Arial, sans-serif;
          line-height: 1.6;
        }
        .pdf-content h1 {
          font-size: 24px;
          margin: 20px 0;
          text-align: center;
          color: #283e50;
        }
        .pdf-content h2 {
          font-size: 20px;
          margin: 16px 0;
          color: #1e2a3a;
        }
        .pdf-content p {
          margin: 12px 0;
          text-align: justify;
          font-size: 12px;
        }
        .pdf-content strong {
          font-weight: bold;
        }
        .pdf-content img {
          max-width: 100%;
          height: auto;
          display: block;
          margin: 15px auto;
          page-break-inside: avoid;
        }
        .pdf-content ul, .pdf-content ol {
          margin: 12px 0 12px 25px;
          padding-left: 20px;
          font-size: 12px;
        }
        .pdf-content li {
          margin: 6px 0;
          line-height: 1.4;
        }
        .pdf-content table {
          width: 100%;
          border-collapse: collapse;
          margin: 15px 0;
          page-break-inside: avoid;
        }
        .pdf-content th, .pdf-content td {
          border: 1px solid #ddd;
          padding: 8px;
          font-size: 11px;
        }
        .pdf-content th {
          background-color: #f5f5f5;
        }
        .pdf-content .article {
          margin-bottom: 30px;
          page-break-inside: avoid;
        }
        .pdf-content .article-meta {
          font-size: 10px;
          color: #666;
          margin: 5px 0 15px 0;
        }
        .pdf-content .article-content {
          margin-top: 15px;
        }
      </style>
      <h1>${title}</h1>
      ${content}
    </div>
  `;
};

// Função principal para gerar o PDF
const GeneratePdf = ({ data, waitingMessage, successMessage }) => {
  return new Promise((resolve, reject) => {
    let modalRoot = null;
    let tempEl = null;

    const showModal = (status, message) => {
      if (!modalRoot) {
        modalRoot = document.createElement("div");
        modalRoot.id = "pdf-progress-modal-root";
        document.body.appendChild(modalRoot);
      }
      ReactDOM.render(
        <ProgressModal status={status} message={message} visible={true} />,
        modalRoot
      );
    };

    const cleanup = () => {
      if (tempEl && document.body.contains(tempEl)) {
        document.body.removeChild(tempEl);
      }
      if (modalRoot) {
        ReactDOM.unmountComponentAtNode(modalRoot);
        if (document.body.contains(modalRoot)) {
          document.body.removeChild(modalRoot);
        }
      }
    };

    try {
      // Show initial loading modal
      showModal("loading", waitingMessage);

      // Create temporary element
      tempEl = document.createElement("div");
      tempEl.innerHTML = generateHtml(data);
      document.body.appendChild(tempEl);

      // Configure PDF options
      const fileName = Array.isArray(data)
        ? sanitizeFilename(data[0]?.chapterName || "documento")
        : sanitizeFilename(data.articleTitle);

      const opt = {
        margin: [10, 10],
        filename: `${fileName}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 2,
          useCORS: true,
          logging: false,
          letterRendering: true,
        },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        },
        pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      };

      // Generate PDF
      html2pdf()
        .from(tempEl)
        .set(opt)
        .save()
        .then(() => {
          showModal("success", successMessage);
          setTimeout(() => {
            cleanup();
            resolve();
          }, 3000);
        })
        .catch((err) => {
          cleanup();
          reject(err);
        });
    } catch (err) {
      cleanup();
      reject(err);
    }
  });
};

export default GeneratePdf;
