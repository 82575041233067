import { useIntl } from "react-intl";
import { BadgeContainer } from "./styles";

function StatusBadge({ label }) {
  const intl = useIntl();

  return (
    <BadgeContainer variant={label}>
      {intl.formatMessage({
        id: label,
      })}
    </BadgeContainer>
  );
}

export default StatusBadge;
