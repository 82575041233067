import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 94vh;
  background: #fcfcfc;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: #fcfcfc;
  }

  ::-webkit-scrollbar-thumb {
    background: #11a7d9;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  ::-webkit-scrollbar-corner {
    background: #11a7d9;
  }
`;

export const HeaderWrapper = styled.div`
  background-color: #f7f9fa;
`;

export const Header = styled.header`
  background-color: #f7f9fa;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  max-width: 1380px;
  margin: 0 auto;

  & > section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 40px 24px;
    padding-right: 0;
    position: relative;
    width: 794px;
  }
`;

export const HeaderContainer = styled.div`
  max-width: ${(props) => (props.scale >= 1.5 ? "550.5px" : "734px")};
  @media (max-width: 1235px) {
    max-width: 450.5px;
  }
`;

export const HeaderImage = styled.img`
  align-self: center;
  max-height: 400px;

  aspect-ratio: 16/9;
  @media (max-width: 1235px) {
    height: 75%;
    max-width: 75%;
  }
`;

export const HeaderImageSkeleton = styled.div`
  align-self: center;
  max-height: 400px;
  height: 400px;

  aspect-ratio: 16/9;
  @media (max-width: 1235px) {
    height: calc(400px * 0.75);
    max-width: 75%;
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  color: #3a3a3c;
  margin: 0px;

  font-size: ${(props) => (props.scale >= 1.5 ? "31.5px" : "42px")};
  line-height: ${(props) => (props.scale >= 1.5 ? "47.25px" : "63px")};
`;
export const Description = styled.p`
  font-family: "Open Sans", sans-serif;
  // font-weight: 600;
  color: #3a3a3c;
  margin: 0px;

  font-size: ${(props) => (props.scale >= 1.5 ? "13.5px" : "18px")};
  line-height: ${(props) => (props.scale >= 1.5 ? "19.2px" : "25.6px")};
`;

export const InfoContainer = styled.div`
  display: flex;
  gap: 64px;
  align-items: flex-start;
`;

export const Info = styled.div`
  & > p {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.07px;
    color: #3a3a3c;
  }

  & > p:first-of-type {
    margin-top: 4px;
  }

  & > p:last-of-type {
    font-weight: 600;
  }
`;

export const LessonsContainer = styled.div`
  max-width: 1380px;
  margin: 23px auto;
  background: #fff;
  border-radius: 8px;
  padding: 24px;
`;

export const LessonsHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1330px;

  & > h2 {
    margin: 0px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    line-height: 40px;
    color: #444444;
  }
`;

export const Lessons = styled.div`
  display: grid;
  place-content: center;
  grid-template-columns: repeat(auto-fill, minmax(253px, 253px));
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 26px;
`;

export const RestartCourseTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 0px;

  color: #11a7d9;
  text-align: center;
`;

export const RestartCourseContent = styled.div`
  max-width: 470px;
  font-weight: 400;
  font-size: 16px;
  color: #000;
  line-height: 32px;
  text-align: center;
  margin: 24px 0px;

  & > p {
    margin: 0px;
  }

  & > .highlight > span {
    font-weight: 600;
  }
`;

export const RestartCourseButtons = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  & > button {
    flex: 1;
    border: 1px solid #11a7d9;
  }
`;

export const LoadingComponent = styled.div`
  position: absolute;
  z-index: 10;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
`;

export const FadeContainer = styled.div`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  position: ${({ absolute }) => absolute ? 'absolute' : 'relative'};
  width: 100%;
`;

export const TransitionWrapper = styled.div`
  position: relative;
  min-height: 100vh;
`;
