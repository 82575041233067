import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 16px 88px 16px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  padding: 1rem 5rem;
  background-color: #fff;
  display: flex;
  justify-content: center;
`;

export const InProgressContainer = styled.div`
  width: 100%;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InProgressSection = styled.section`
  width: 100%;
  background: linear-gradient(93.65deg, #002244 1.29%, #051a6c 130.28%);
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
`;

export const InProgressContent = styled.div`
  font-family: "Poppins";
  color: #fff;
`;

export const InProgressText = styled.p`
  font-size: 1.25rem;
  margin: 0;
`;

export const InProgressTitle = styled.p`
  font-size: 1.625rem;
  font-weight: 700;
  margin: 0;
`;

export const InProgressLesson = styled.p`
  font-size: 1rem;
  margin: 0;
  border-radius: 8px;
`;

export const InProgressButton = styled(Link)`
  width: fit-content;
  padding: 1rem 1.5rem;
  background: #11a7d9;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 5px;
  text-transform: uppercase;

  &:hover {
    background: #11a7d9;
    color: #fff;
  }
`;

export const LoadingComponent = styled.div`
  position: absolute;
  z-index: 10;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
`;
