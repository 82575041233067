import {
  BOOKMARK_MANUAL_ARTICLE,
  GET_MANUAL_LIST,
  GET_MANUAL_LIST_SUCCESS,
  PRINT_MANUAL_ARTICLE,
  PRINT_MANUAL_ARTICLE_SUCCESS,
  PRINT_MANUAL_CHAPTER,
  PRINT_MANUAL_CHAPTER_SUCCESS,
  RESET_SEARCH_MANUAL_LIST,
  SEARCH_MANUAL_LIST,
  SEARCH_MANUAL_LIST_SUCCESS,
  SHOW_SELECTED_ARTICLE,
  SIDEBAR_CHAPTER_INFO,
  SIDEBAR_CHAPTER_INFO_SUCCESS,
  VIEWED_MANUAL_ARTICLE,
} from "./types";

export const getManualTopicList = () => ({
  type: GET_MANUAL_LIST,
});

export const getManualTopicListSuccess = (data) => ({
  type: GET_MANUAL_LIST_SUCCESS,
  payload: data,
});

export const getManualTopicListFailure = (error) => ({
  type: "GET_MANUAL_LIST_FAILURE",
  payload: error,
});

export const getManualFaq_Highlight = () => ({
  type: "GET_MANUAL_FAQ_HIGHLIGHT",
});

export const getManualFaq_HighlightSuccess = ({
  faqs,
  savedArticles,
  recentArticles
}) => ({
  type: "GET_MANUAL_FAQ_HIGHLIGHT_SUCCESS",
  payload: {
    faqs,
    savedArticles,
    recentArticles
  },
});

export const getManualFaq_HighlightFailure = (error) => ({
  type: "GET_MANUAL_FAQ_HIGHLIGHT_FAILURE",
  payload: error,
});

export const searchManualTopicList = ({ userSearch, category }) => ({
  type: SEARCH_MANUAL_LIST,
  payload: { userSearch, category },
});

export const searchManualTopicListSuccess = (data) => ({
  type: SEARCH_MANUAL_LIST_SUCCESS,
  payload: data,
});

export const resetSearchManualTopicLis = () => ({
  type: RESET_SEARCH_MANUAL_LIST,
});

export const printChapter = (params) => ({
  type: PRINT_MANUAL_CHAPTER,
  payload: params,
});

export const printChapterSuccess = (data) => ({
  type: PRINT_MANUAL_CHAPTER_SUCCESS,
  payload: data,
});

export const printArticle = (params) => ({
  type: PRINT_MANUAL_ARTICLE,
  payload: params,
});

export const printArticleSuccess = (data) => ({
  type: PRINT_MANUAL_ARTICLE_SUCCESS,
  payload: data,
});

export const showSelectedArticle = (data) => ({
  type: SHOW_SELECTED_ARTICLE,
  payload: data,
});

export const sidebarChapterInfo = (params) => ({
  type: SIDEBAR_CHAPTER_INFO,
  payload: params,
});

export const sidebarChapterInfoSuccess = (data) => ({
  type: SIDEBAR_CHAPTER_INFO_SUCCESS,
  payload: data,
});

export const bookmarkManualArticle = (params) => ({
  type: BOOKMARK_MANUAL_ARTICLE,
  payload: params,
});

export const viewedManualArticle = (params) => ({
  type: VIEWED_MANUAL_ARTICLE,
  payload: params,
});
