import { Link } from "react-router-dom";
import styled from "styled-components";

export const SearchResultsContainer = styled.div`
  max-width: 1320px;
  margin: 20px auto;
  padding: 0 16px;
  width: 100%;
`;

export const SearchHeader = styled.div`
  margin-bottom: 24px;
`;

export const SearchTitle = styled.h1`
  font-size: 20px;
  color: #333;
  margin-bottom: 16px;
`;

export const ResultCard = styled.div`
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eee;
`;

export const CategoryLabel = styled.div`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
  letter-spacing: 0%;
  color: #1e293b;
`;

export const ResultTitle = styled(Link)`
  color: #095f83;
  text-decoration: none;
  display: block;
  margin-bottom: 0;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 3px;

  font-family: "Poppins";
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0%;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 0%;

  &:hover {
    text-decoration: underline;
  }
`;

export const ResultDescription = styled.p`
  margin-bottom: 0;

  font-family: "Inter";
  font-weight: 300;
  font-size: 16px;
  line-height: 25.6px;
  letter-spacing: 0%;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MostAccessedTag = styled.span`
  background-color: #f1fcf5;
  color: #175034;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 12px;
  float: right;

  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 22.4px;
  letter-spacing: 0%;
`;
