import { SkeletonDiv } from "./styles";

export default function Skeleton({ width, height, borderRadius, mb, mt }) {
  return (
    <SkeletonDiv
      width={width}
      height={height}
      borderRadius={borderRadius}
      mb={mb}
      mt={mt}
    />
  );
}
