import styled, { css, keyframes } from "styled-components";

const floating = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
`;

export const ScrollTop = styled.button`
  position: fixed;
  bottom: 80px;
  right: 30px;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 999;

  background: #11a7d9;
  color: #fff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  border: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
      animation: ${floating} 2s ease-in-out infinite;
    `}
`;
