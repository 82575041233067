import generateHeaders from "../utils/generateHeaders.js";
import api from "./index.js";

export const getEventManualTopicList = async ({ token, eventId, language }) => {
  const options = generateHeaders(token);

  return await api.get(
    `/manual/home/${eventId}/all-topics?language=${language}`,
    options
  );
};

export const getFAQ_HighlightService = async ({ token, eventId, language }) => {
  const options = generateHeaders(token);

  return await api.get(
    `/manual/home/${eventId}?language=${language}`,
    options
  );
};


export const searchEventManualTopic = async ({
  token,
  eventId,
  userSearch,
  category,
  language,
}) => {
  const headers = generateHeaders(token);

  const params = {
    query: userSearch,
    chapterId: category,
    language,
  };

  const options = {
    ...headers,
    params,
  };

  return await api.get(`/manual/search/${eventId}`, options);
};

export const printEventManualChapterService = async (params) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${params.token}`,
    },
  };

  return await api.get(
    `/chapters/${params.chapterId}/chapter-to-print`,
    options
  );
};

export const printEventManualArticleService = async (params) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${params.token}`,
    },
  };

  return await api.get(`/articles/${params.articleId}/print`, options);
};

export const bookmarkManualArticleService = async (params) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${params.token}`,
    },
  };

  return await api.post(`/saved-articles/toggle/${params.articleId}`, options);
};

export const viewedManualArticleService = async (params) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${params.token}`,
    },
  };

  return await api.post(`/saved-articles/view/${params.articleId}`, options);
};
