import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getArticle } from "../../../../redux/modules/adminExhibitorManual/actions";
import AnimatedMainContainer from "../../../../components/AnimatedMainContainer";
import { Container } from "../../style";
import {
  Article,
  ArticleButtonContainer,
  ArticleContainer,
  ArticleHeader,
  Badge,
} from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import Button from "../../../InformaAcademy/components/button";
import { HeaderContainer } from "../../newManual/styles";
import { AiOutlineEdit as EditIcon } from "react-icons/ai";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ForwardIcon from "../../../../icons/Forward";
import api from "../../../../service";
import { useToast } from "../../../../hooks/toast";

export default function ArticleDetails() {
  const { manualId, articleId } = useParams();
  const dispatch = useDispatch();
  const { error, isLoading, article } = useSelector(
    (state) => state.adminManualExhibitor
  );

  useEffect(() => {
    dispatch(getArticle({ articleId }));
  }, [dispatch, articleId]);

  if (isLoading || error || !article)
    return <AnimatedMainContainer goBack={`/admin/manual/${manualId}`} />;

  return (
    <AnimatedMainContainer goBack={`/admin/manual/${manualId}`}>
      <Container>
        <Header article={article} manualId={manualId} />
        <ArticleContainer>
          <ArticleHeader>
            <div>
              <h2>{article.articleTitle}</h2>
              {article.articleStatus === "DRAFT" && (
                <Badge>
                  <FormattedMessage id={"draft"} />
                </Badge>
              )}
            </div>
            <div>
              <span>{article.chapterName}</span>
              <span style={{ fontSize: 32 }}>·</span>
              <span>
                <FormattedMessage id={"updatedAt"} />{" "}
                {moment(article?.updatedAt).format("DD/MM/YYYY")}
              </span>
            </div>
          </ArticleHeader>
          <Article
            dangerouslySetInnerHTML={{
              __html: article.content,
            }}
          />
        </ArticleContainer>
      </Container>
    </AnimatedMainContainer>
  );
}

function Header({ manualId, article }) {
  const history = useHistory();
  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const postArticle = async () => {
    try {
      const content = {
        title: article.articleTitle,
        content: article.content,
        chapterId: article.chapterId,
        status: "POSTED",
      };
      const body = JSON.stringify(content);

      await api.put(`/articles/${article.articleId}`, body, config);
      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "success",
        }),
        description: intl.formatMessage({
          id: "success.article_update_posted",
        }),
      });
      history.push(`/admin/manual/${manualId}`);
    } catch (error) {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error.article_update_posted",
        }),
      });
    }
  };

  return (
    <HeaderContainer>
      <h2>
        <FormattedMessage id={"see_details"} />
      </h2>
      <ArticleButtonContainer>
        <Button
          label={"edit"}
          Icon={<EditIcon size={16} />}
          onClick={() => {
            history.push(`/admin/manual/${manualId}/edit/${article.articleId}`);
          }}
          variant={article.articleStatus === "DRAFT" ? "secondary" : "primary"}
        />

        {article.articleStatus === "DRAFT" && (
          <Button
            label={"article_publish"}
            Icon={<ForwardIcon />}
            onClick={postArticle}
          />
        )}
      </ArticleButtonContainer>
    </HeaderContainer>
  );
}
