import styled, { css } from "styled-components";

export const Container = styled.div`
  flex: 1;
  width: 100%;
  padding: 16px;
  background-color: #fff;
`;

export const Horizontal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > div {
    display: flex;
    flex: 1 0 100px;
    margin-left: 16px;
    @media (max-width: 768px) {
      margin-left: 0px;
      flex: 1 1 auto;
      width: 100%;
    }
  }

  & > div:first-child {
    margin-left: 0px;
  }
`;

export const HorizontalUpload = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
`;

export const UploadAreaWithLabel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const LabelContainer = styled.div`
  margin-right: 8px;
  margin-bottom: 8px;
`;

export const Label = styled.span`
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 600;
`;

export const FileDropzoneContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  align-items: center;
  font-size: 14px;
  height: 248px;

  ul,
  p {
    margin: 0;
  }

  // & > div {
  //   display: flex;
  //   flex: 1 0 100px;
  //   padding: 8px;
  //   max-height: 32px;
  //   @media (max-width: 768px) {
  //     margin-left: 0px;
  //     flex: 1 1 auto;
  //   }
  // }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;
      background-color: #f2f2f2;
    `}
`;

export const PreviewImage = styled.img`
  width: 140px;
  height: 93px;
  object-fit: cover;
  aspect-ratio: 16/9;
  border-radius: 8px;
  border: 1px solid #ebebf0;
`;

export const PreviewImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e7e4e4;
  height: 248px;
  border-radius: 5px;
  width: 100%;
`;

export const PreviewImageContent = styled.div`
  display: flex;
  gap: 13px;
  padding: 24px;
  border-radius: 5px;
  border: 1px solid #dde5e9;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const PreviewImageContentRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PreviewImageName = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  color: #444444;
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
`;

export const PreviewImageSize = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  color: #6c6c6c;
`;

export const RemoveButtonPreview = styled.button`
  font-family: "Open Sans";
  background: none;
  border: none;
  color: #c53030;
  font-size: 16px;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
  align-self: flex-start;
  line-height: 21.79px;
`;


export const Form = styled.form`
   ${(props) =>
    props.step !== 0 &&
    css`
    display: none;
    `}
`