import * as React from "react";

const Save = (props) => {
  const { size = "16", stroke = "#FFF" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 17 17"
    >
      <path
        stroke={stroke}
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13.6 2.28H3.4a.9.9 0 0 0-.9.9v10.2a.9.9 0 0 0 .9.9h10.2a.9.9 0 0 0 .9-.9V3.18a.9.9 0 0 0-.9-.9Z"
      ></path>
      <path
        stroke={stroke}
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11.167 2.28v6H5.5v-6z"
      ></path>
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M9.168 4.613v1.334M4.164 2.28h8.334"
      ></path>
    </svg>
  );
};

export default Save;
