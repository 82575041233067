import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #fff;
`;

export const TableContainer = styled.div`
  width: 100%;
  background-color: #fff;
  margin-top: 32px;
`;

export const FormContainer = styled.div`
  form {
    display: flex;
    flex-direction: column;
  }
`;

export const FormTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FormTitle = styled.h2`
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0%;
  vertical-align: middle;
  margin-bottom: 24px;
  color: #002244;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RemoveButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  color: #c53030;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;

  border-radius: 5px;
  padding-top: 4px;
  padding-right: 16px;
  padding-bottom: 4px;
  padding-left: 16px;
  border: 1px solid transparent;

  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: #c53030;
  }
`;

export const Title = styled.h1`
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0%;
  vertical-align: middle;
  color: #002244;
  margin-bottom: 0;
`;

export const Subtitle = styled.p`
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0%;
  vertical-align: middle;

  color: #6c6c6c;
  margin: 0;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const FieldsContainer = styled.div`
  margin-top: 16px;
`;

export const AddBanner = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  color: #000;
  font-weight: 700;
  cursor: pointer;

  font-family: "Inter";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0%;
  text-decoration-style: solid;
  text-decoration-thickness: 0%;
  color: #002244;
  text-transform: capitalize;

  &:hover {
    text-decoration: underline;
  }

  svg {
    font-size: 1.125rem;
  }
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem 0;
`;

export const CancelButton = styled.button`
  background-color: #fecaca;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 700;
  color: #991b1b;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    color: #991b1b;
  }
`;

export const PreviewButton = styled.button`
  background-color: #002244;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    color: #ffffff;
  }
`;
