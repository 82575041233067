import React, { useEffect, useMemo, useState } from "react";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Card,
  CardContainer,
  CardHeader,
  CodeCard,
  Container,
  Divider,
  FooterCreatePage,
  FooterLink,
  Header,
  NameCard,
  Title,
  TitleContainer,
  YearCard,
} from "./style";
import { useDispatch, useSelector } from "react-redux";

import {
  setIsEdit,
  setPreviewBanner,
  setPreviewCourse,
} from "../../redux/modules/homepageBanner/actions";
import SelectFilter from "../InformaAcademy/Analytics/components/SelectFilter";
import { getEventList } from "../../redux/modules/analytics/actions";
import { debounce } from "lodash";
import SearchInput from "../InformaAcademy/components/SearchInput";

const ManageHomepageBanner = () => {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const previewData = useSelector(
    (state) => state.homepageBanner.previewBanner
  );

  const dispatch = useDispatch();
  const { events, years, eventsInfo } = useSelector((state) => state.analytics);

  useEffect(() => {
    if (!previewData) return;

    dispatch(setPreviewBanner([]));
    dispatch(setPreviewCourse([]));
    dispatch(setIsEdit(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getEventList());
  }, [dispatch]);

  const eventsFilterMap = useMemo(() => {
    if (inputValue) {
      const caseInsensitive = inputValue.toLowerCase();
      return eventsInfo.filter(
        (event) =>
          event.eventEditionCode.toLowerCase().includes(caseInsensitive) ||
          event.name.toLowerCase().includes(caseInsensitive)
      );
    }

    if (selectedEvent && selectedYear) {
      return eventsInfo.filter(
        (event) => event.year === selectedYear && +event.id === selectedEvent
      );
    }

    if (selectedYear) {
      return eventsInfo.filter((event) => event.year === selectedYear);
    }

    if (selectedEvent) {
      return eventsInfo.filter((event) => +event.id === selectedEvent);
    }

    return eventsInfo;
  }, [inputValue, selectedEvent, selectedYear, eventsInfo]);

  const resetFilters = () => {
    setSelectedEvent("");
    setSelectedYear("");
  };

  const debouncedSearch = debounce((value) => {
    setInputValue(value);
  }, 1);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    debouncedSearch(value);
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({
        id: "breadcrumb.manage-homepage-banner",
      })}
    >
      <Container>
        <Header>
          <TitleContainer>
            <Title>
              <FormattedMessage id="active_events" /> ({events.length})
            </Title>
            <SelectFilter
              label={"year"}
              options={years}
              setValue={setSelectedYear}
              value={selectedYear}
              fit
            />
            <SelectFilter
              label={"event"}
              options={events.filter((event) => event.year === selectedYear)}
              setValue={setSelectedEvent}
              value={selectedEvent}
              fit
            />
          </TitleContainer>
          <SearchInput value={inputValue} onChange={handleSearchChange} />
        </Header>

        <CardContainer>
          {eventsFilterMap.map((event) => {
            return (
              <Card key={event.id}>
                <CardHeader>
                  <CodeCard>{event.eventEditionCode}</CodeCard>
                  <NameCard title={event.name}>{event.name}</NameCard>
                  <YearCard>{event.year}</YearCard>
                </CardHeader>

                <Divider />

                {event.homePageId && (
                  <FooterLink
                    to={`/admin/informa-academy/manage-homepage-banner/${event.id}/${event.homePageId}/preview`}
                  >
                    <EyeIcon />
                    {intl.formatMessage({
                      id: "preview_homepage_event_list",
                    })}
                  </FooterLink>
                )}

                {!event.homePageId && (
                  <FooterCreatePage
                    to={`/admin/informa-academy/manage-homepage-banner/${event.id}/create`}
                  >
                    <PlusIcon />
                    {intl.formatMessage({
                      id: "create_homepage_event_list",
                    })}
                  </FooterCreatePage>
                )}
              </Card>
            );
          })}
        </CardContainer>
      </Container>
    </AnimatedMainContainer>
  );
};

export default ManageHomepageBanner;

function EyeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="11"
      fill="none"
      viewBox="0 0 17 11"
    >
      <path
        fill="#11A7D9"
        stroke="#11A7D9"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.5 10c4.142 0 7.5-4.5 7.5-4.5S12.642 1 8.5 1 1 5.5 1 5.5 4.358 10 8.5 10Z"
      />
      <path
        fill="#11A7D9"
        stroke="#FFF4F0"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.5 7.375a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
      />
    </svg>
  );
}

function PlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        stroke="#024"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m8.02 3.833-.012 9.333M3.332 8.5h9.333"
      ></path>
    </svg>
  );
}
