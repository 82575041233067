import { IoIosCheckmarkCircle } from "react-icons/io";
import { ConcludedContainer } from "./styles";
import { useIntl } from "react-intl";

export default function Concluded({
  labelId = "checklist.concluded",
  overlap = false,
}) {
  const intl = useIntl();

  const label = intl.formatMessage({
    id: labelId,
  });

  return (
    <ConcludedContainer overlap={overlap}>
      <IoIosCheckmarkCircle size={18} />
      <p>{label}</p>
    </ConcludedContainer>
  );
}
