import React from "react";

import {
  Container,
  Content,
  AnimatedContainer,
  RouteBreadcrumbs,
  Header,
  GoBack,
} from "./styles";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BsChevronLeft } from "react-icons/bs";
import { FormattedMessage } from "react-intl";

const AnimatedMainContainer = ({
  breadcrumb,
  children,
  email = true,
  goBack,
}) => {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();

  return (
    <Container>
      <Content>
        {goBack && (
          <Header>
            <GoBack onClick={()=>history.push(goBack)}>
              <BsChevronLeft />
              <p>
                <FormattedMessage id={"goback"} />
              </p>
            </GoBack>
            {email && user?.name}
          </Header>
        )}
        {breadcrumb && (
          <Header>
            <RouteBreadcrumbs>{breadcrumb}</RouteBreadcrumbs>
            {email && user?.name}
          </Header>
        )}

        <AnimatedContainer className="animated-container">
          {children}
        </AnimatedContainer>
      </Content>
    </Container>
  );
};

export default AnimatedMainContainer;
