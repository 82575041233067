import React, { useEffect } from "react";
import { Container, LoadingComponent } from "../../Course/styles";
import { useIntl } from "react-intl";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { LHeader } from "../../Lesson/styles";
import Button from "../Button";
import BackButton from "../BackButton";
import { Breadcrumb } from "antd";
import { FaChevronRight } from "react-icons/fa";
import Home from "../../../../newUXAssets/icons/Home";
import CourseProgression from "../CourseProgression";
import { useDispatch, useSelector } from "react-redux";
import {
  completeCourse,
  getInformaAcademyCourseInfo,
} from "../../../../redux/modules/informaAcademy/actions";
import { LoadingSpinnerLogo } from "../../../../components/LoadingOverlay/styles";
import LogoCircle from "../../../../assets/images/Informa_Logo_Circle.png";
import completeImage from "../../../../newUXAssets/images/complete_course.png";
import { ContentContainer } from "./styles";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

const CompleteCourse = () => {
  const { courseId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { course, isLoading, error } = useSelector(
    (state) => state.informaAcademy
  );

  useEffect(() => {
    if (!courseId) return;

    dispatch(
      getInformaAcademyCourseInfo({
        courseId,
      })
    );
  }, [dispatch, courseId, history]);

  useEffect(() => {
    if (error) {
      history.push("/not-found");
    }
  }, [error, history]);

  useEffect(() => {
    if (!course) return history.push("/informa-academy");
    const lessons = course.lessons;
    const notAllCompletedLessons = lessons.some(
      (lesson) => lesson.completed === false
    );

    if (notAllCompletedLessons) return history.push(`/informa-academy`);
  }, [course, history]);

  if (isLoading || !course)
    return (
      <LoadingComponent>
        <div>
          <LoadingSpinnerLogo src={LogoCircle} />
        </div>
      </LoadingComponent>
    );

  const total = course.lessons.length;

  const concluded = course.lessons.filter((l) => l.completed === true).length;

  return (
    <Container>
      <CompleteHeader
        courseTitle={course.name}
        courseId={courseId}
        total={total}
        concluded={concluded}
      />
      <CompleteContent courseId={courseId} courseTitle={course.name} />
    </Container>
  );
};

export default CompleteCourse;

function CompleteHeader({ courseTitle, courseId, concluded, total }) {
  const intl = useIntl();

  const playCourse = intl.formatMessage({
    id: "playCourse",
  });

  return (
    <LHeader>
      <div className="bread">
        <BackButton href={`/informa-academy/course/${courseId}`} />
        <Breadcrumb separator={<FaChevronRight color="#11A7D9" />}>
          <Breadcrumb.Item>
            <a href="/" className="center">
              <Home />
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="/informa-academy">Informa Academy</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href={`/informa-academy/course/${courseId}`}>{courseTitle}</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{playCourse}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <CourseProgression
        concludedLessons={concluded}
        totalLessons={total}
        showLabel={false}
      />
    </LHeader>
  );
}

function CompleteContent({ courseId, courseTitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleComplete = () => {
    dispatch(completeCourse({ courseId }));

    history.push(`/informa-academy/course/${courseId}`);
  };

  const congratulations = intl.formatMessage({
    id: "congratulations",
  });
  const completedCourse = intl.formatMessage({
    id: "completed_course",
  });

  const completedCourseMessage = intl.formatMessage({
    id: "completed_course_message",
  });

  return (
    <ContentContainer>
      <div>
        <h1>{congratulations}! 🎉</h1>
        <h2>{completedCourse}</h2>
        <h3>{courseTitle}!</h3>
        <p>{completedCourseMessage}</p>
        <Button
          variant={"academy"}
          size={"academy"}
          label={"complete"}
          onClick={handleComplete}
          Icon={<IoIosCheckmarkCircleOutline size={18} color="#FFF" />}
        />
      </div>
      <img src={completeImage} alt="Complete Course Greeting" />
    </ContentContainer>
  );
}
