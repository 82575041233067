import * as React from "react";

const InformaAcademyFile = (props) => {
  const { size = 18, color = "#475569" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 19 18"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.336 5.25h6M6.336 8.25h3M10.086 16.125v-.375c0-2.121 0-3.182.659-3.841s1.72-.659 3.84-.659h.376m.375-1.243V7.5c0-2.828 0-4.243-.879-5.121C13.58 1.5 12.164 1.5 9.336 1.5s-4.243 0-5.121.879c-.88.878-.88 2.293-.88 5.121v3.408c0 2.434 0 3.65.665 4.475q.202.25.453.452c.824.665 2.041.665 4.475.665.529 0 .794 0 1.036-.085q.075-.027.148-.062c.232-.11.419-.298.793-.672l3.552-3.552c.434-.434.65-.65.765-.926s.114-.583.114-1.196"
      ></path>
    </svg>
  );
};

export default InformaAcademyFile;
