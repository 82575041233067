import * as React from "react";

const Movie = (props) => {
  const { size = "26", color = "#444444" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 26 26"
    >
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M2.168 5.417c0-.599.485-1.084 1.083-1.084h19.5c.599 0 1.084.485 1.084 1.084v15.166c0 .599-.485 1.084-1.084 1.084h-19.5a1.083 1.083 0 0 1-1.083-1.084z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M19.5 4.333v17.334M6.5 4.333v17.334M20.582 9.75h3.25M20.582 16.25h3.25M2.168 9.75h3.25M2.168 8.667v2.166M4.875 4.333h3.25M4.875 21.667h3.25M17.875 4.333h3.25M17.875 21.667h3.25M2.168 16.25h3.25M2.168 15.167v2.166M23.832 15.167v2.166M23.832 8.667v2.166"
      />
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11.375 10.292 15.708 13l-4.333 2.708z"
      />
    </svg>
  );
};

export default Movie;
