import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { Container } from "../../style";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { FormRow } from "../../../../components/ControlledInputs/styles";
import ControlledInput from "../../../../components/ControlledInputs/input";
import ControlledSelect from "../../../../components/ControlledInputs/select";
import { useDispatch, useSelector } from "react-redux";
import { listChapters } from "../../../../redux/modules/adminExhibitorManual/actions";
import { BsChevronLeft, BsEye } from "react-icons/bs";
import Button from "../../../InformaAcademy/components/button";
import ControlledTextareaJodit from "../../../../components/ControlledInputs/jodit-textarea";
import Save from "../../../../icons/Save";
import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  Article,
  ArticleButtonContainer,
  ArticleButtons,
  ArticleContainer,
  ArticleHeader,
  GoBackContainer,
} from "./styles";
import { FormattedMessage, useIntl } from "react-intl";

import { GoBack } from "../../../../components/AnimatedMainContainer/styles";
import api from "../../../../service";
import { useToast } from "../../../../hooks/toast";
import moment from "moment";

import { FormHeader } from "./article-form-header";

const schema = z.object({
  title: z.string().min(1).max(50, {
    message: "error.max_length-150",
  }),
  chapter: z.object({
    value: z.coerce.number().min(1),
    label: z.string().min(1),
  }),
  content: z
    .string()
    .transform((value) => ({
      original: value,
      cleaned: value
        .replace(/<\/?[^>]+(>|$)/g, "")
        .replace(/\n/g, "")
        .trim(),
    }))
    .refine(({ cleaned }) => cleaned.length > 0, {
      message: "error.field_required",
    })
    .refine(({ cleaned }) => cleaned.length <= 10_000, {
      message: "error.max_length-10000",
    }),
});

export default function ArticleForm({ defaultValue }) {
  const intl = useIntl();
  const [step, setStep] = useState(0);
  const { manualId } = useParams();
  const dispatch = useDispatch();
  const { chapters } = useSelector((state) => state.adminManualExhibitor);
  const { user, token } = useSelector((state) => state.auth);
  const { addToast } = useToast();
  const history = useHistory();

  const form = useForm({
    resolver: zodResolver(schema),
  });
  useEffect(() => {
    dispatch(
      listChapters({
        manualId,
      })
    );
  }, [dispatch, manualId]);

  const { control, handleSubmit, formState, setValue } = form;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const find = useMemo(() => {
    return chapters.find(
      (item) => item.chapterTitle === defaultValue?.chapterName
    )?.id;
  }, [chapters, defaultValue?.chapterName]);

  useEffect(() => {
    if (!defaultValue) return;

    setValue("title", defaultValue.articleTitle);
    setValue("content", defaultValue.content);

    if (Boolean(find)) {
      setValue("chapter", {
        value: find,
        label: defaultValue.chapterName,
      });
    }
  }, [defaultValue, find, setValue]);

  const onSubmit = (data) => {
    setStep(1);
  };

  const createOnDB = async ({ data, status }) => {
    try {
      const content = {
        title: data.title,
        content: data.content.original,
        chapterId: data.chapter.value,
        status,
      };
      const body = JSON.stringify(content);

      await api.post("/articles", body, config);
      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "success",
        }),
        description: intl.formatMessage({
          id:
            status === "POSTED"
              ? "success.article_create_posted"
              : "success.article_create_draft",
        }),
      });
      history.push(`/admin/manual/${manualId}`);
    } catch (error) {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id:
            status === "POSTED"
              ? "error.article_create_posted"
              : "error.article_create_draft",
        }),
      });
    }
  };

  const updateInDB = async ({ data, status, articleId }) => {
    try {
      const content = {
        title: data.title,
        content: data.content.original,
        chapterId: data.chapter.value,
        status,
      };
      const body = JSON.stringify(content);

      await api.put(`/articles/${articleId}`, body, config);
      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "success",
        }),
        description: intl.formatMessage({
          id:
            status === "POSTED"
              ? "success.article_update_posted"
              : "success.article_update_draft",
        }),
      });
      history.push(`/admin/manual/${manualId}`);
    } catch (error) {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id:
            status === "POSTED"
              ? "error.article_update_posted"
              : "error.article_update_draft",
        }),
      });
    }
  };

  const deleteInDB = async () => {
    if (!defaultValue) return;
    try {
      await api.delete(`/articles/${defaultValue.articleId}`, config);
      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "success",
        }),
        description: intl.formatMessage({
          id: "success.article_delete",
        }),
      });
      history.push(`/admin/manual/${manualId}`);
    } catch (error) {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error.article_delete",
        }),
      });
    }
  };

  const onSavePosted = async (data) => {
    if (defaultValue) {
      return await updateInDB({
        data,
        status: "POSTED",
        articleId: defaultValue.articleId,
      });
    }
    return await createOnDB({
      data,
      status: "POSTED",
    });
  };

  const onSaveDraft = async (data) => {
    if (defaultValue) {
      return await updateInDB({
        data,
        status: "DRAFT",
        articleId: defaultValue.articleId,
      });
    }
    return await createOnDB({
      data,
      status: "DRAFT",
    });
  };

  const date = defaultValue
    ? moment(defaultValue.updatedAt).format("DD/MM/YYYY")
    : moment().format("DD/MM/YYYY");

  return (
    <>
      {!defaultValue && step === 1 && (
        <Back email={user?.name} goBack={() => setStep(0)} />
      )}
      {defaultValue && (
        <Back
          email={user?.name}
          goBack={
            step === 0
              ? () =>
                  history.push(
                    `/admin/manual/${manualId}/details/${defaultValue.articleId}`
                  )
              : () => setStep(0)
          }
        />
      )}
      <Container>
        <FormHeader
          step={step}
          postArticle={handleSubmit(onSavePosted)}
          saveAsDraft={handleSubmit(onSaveDraft)}
          defaultValue={Boolean(defaultValue)}
          articleTitle={defaultValue?.articleTitle}
          handleDelete={deleteInDB}
        />
        {step === 1 && (
          <ArticleContainer>
            <ArticleHeader>
              <h2>{form.watch("title")}</h2>
              <div>
                <span>{form.watch("chapter").label}</span>
                <span style={{ fontSize: 32 }}>·</span>
                <span>
                  <FormattedMessage id={"updatedAt"} /> {date}
                </span>
              </div>
            </ArticleHeader>
            <Article
              dangerouslySetInnerHTML={{
                __html: form.watch("content"),
              }}
            />
          </ArticleContainer>
        )}
        {step === 0 && (
          <form>
            <FormRow marginBottom={"24px"}>
              <ControlledInput
                name={"title"}
                control={control}
                formState={formState}
                label={"article_title"}
                required={true}
                max={150}
                description={"course_name_sub"}
              />
              <ControlledSelect
                name={"chapter"}
                placeholder={"Selecione"}
                control={control}
                label={"manual_chapter"}
                required={true}
                options={chapters.map((item) => ({
                  value: item.id,
                  label: item.chapterTitle,
                }))}
                errors={formState.errors}
                disabled={chapters.length === 0}
              />
            </FormRow>
            <FormRow marginBottom={"24px"}>
              <ControlledTextareaJodit
                name={"content"}
                control={control}
                formState={formState}
                label={"description"}
                required={true}
                description={"article_desc_sub"}
                sub={"article_desc_sub2"}
                max={10_000}
                minHeight={600}
              />
            </FormRow>
            <ArticleButtons>
              <Button
                label={"cancel"}
                Icon={<IoIosCloseCircleOutline size={20} />}
                variant={"destructive"}
                type={"button"}
                onClick={() => history.push(`/admin/manual/${manualId}`)}
              />

              <ArticleButtonContainer>
                <Button
                  label={"article_save_draft"}
                  Icon={<Save stroke={"#002244"} />}
                  variant={"secondary"}
                  type={"button"}
                  onClick={handleSubmit(onSaveDraft)}
                />
                <Button
                  label={"preview"}
                  Icon={<BsEye size={16} />}
                  type={"button"}
                  onClick={handleSubmit(onSubmit)}
                />
              </ArticleButtonContainer>
            </ArticleButtons>
          </form>
        )}
      </Container>
    </>
  );
}

export function Back({ goBack, email }) {
  return (
    <GoBackContainer>
      <GoBack onClick={goBack}>
        <BsChevronLeft />
        <p>
          <FormattedMessage id={"goback"} />
        </p>
      </GoBack>
      {email}
    </GoBackContainer>
  );
}
