import styled, { css, keyframes } from "styled-components";
const appear = keyframes`
  0% {
    opacity: 0.3;
    transform: translateX(-500px);
  }
  45% {
    opacity: 1;
    transform: translateX(0);
  }
  90%{
    opacity: 1;
    transform: translateX(1000px);
  }
  100%{
    transform: translateX(1000px);
  }
`;

const appear2 = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-500px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const LHeader = styled.header`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  max-width: 1440px;
  margin: 0 auto;

  ${(props) =>
    props.appear &&
    css`
      animation: ${appear} 500ms;
    `}

  & .center,
  & li,
  & .ant-breadcrumb-separator {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .ant-breadcrumb-separator {
    color: #11a7d9;
  }

  & > .bread {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }

  & > .buttons {
    display: flex;
    gap: 16px;
  }
`;

export const LContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  gap: 24px;
  padding-bottom: 80px;
  // background-color: #fff;
`;

export const AnimationContainer = styled.div`
  max-width: 991px;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

export const LInfoContainer = styled.div`
  max-width: 991px;
  width: 100%;
  overflow: hidden;
  ${(props) =>
    props.appear &&
    css`
      opacity: 0;
      animation: ${appear2} 450ms 650ms forwards;
    `}
`;

export const Animation = styled.div`
  position: absolute;
  inset: 0;
  background-image: url(https://www.informamarkets.com/content/markets/generic/informa-markets/en/_jcr_content/root/header/responsivegrid_583191228/responsivegrid/image.coreimg.100.256.png/1728487786859/im-logo.png);
  background-repeat: no-repeat;
  background-position: top 20% left 50%;
  /* background-size: 200px; */
  background-color: #fcfcfc;
  background-blend-mode: multiply;
  z-index: 50;
  animation: ${appear} 1000ms forwards;
`;

export const LSidebarContainer = styled.div`
  max-width: 377px;
  padding: 16px;
  width: 100%;

  & > section {
    margin-top: 18px;
  }
`;

export const Info = styled.div`
  background-color: #fff;
  margin-top: 24px;
  border-radius: 4px;
  padding: 24px;
`;

export const H1 = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 32.4px;
  color: #3a3a3c;
  margin: 0;
  margin-bottom: 4px;
`;

export const H2 = styled.h2`
  line-height: 21.79px;
  font-size: 16px;
  font-weight: 400;
  color: #3a3a3c;
`;

export const P = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 24.51px;
  color: #3a3a3c;
  margin: 0;
  margin-top: 24px;
  margin-bottom: 8px;
`;

export const Text = styled.div`
  // font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
  color: #444444;
`;

export const DownloadCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(229px, 229px));
  gap: 8px;
`;

export const DownloadCard = styled.a`
  border-radius: 5px;
  background: #fafafc;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DownloadCardInfo = styled.div`
  & > p {
    color: #1e293b;
    font-family: "Inter";
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    margin: 0;
    text-overflow: ellipsis;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
  }

  & > span {
    /* font-family: "Inter"; */
    color: #475569;
    font-size: 12px;
    line-height: 100%;
  }
`;

export const SidebarCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: #fff;
`;

export const SidebarContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & > p {
    font-weight: 600;
    font-size: 14px;
    line-height: 19.07px;
    max-width: 150px;
    margin-bottom: 0;
  }
`;

export const SidebarCardImg = styled.img`
  border-radius: 8px;
  width: 94px;
  height: 63px;
  object-fit: cover;
`;

const loading = keyframes`
 to {
    background-position-x: -20%;
  }
`;

export const SkelletonVideo = styled.div`
  max-width: 991px;
  aspect-ratio: 16/9;
  border-radius: 4px;

  --loading-grey: #ededed;
  background-color: var(--loading-grey);
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    var(--loading-grey);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 2s ${loading} ease-in-out infinite;
`;

export const SkeletonDiv = styled.div`
  --loading-grey: #ededed;
  background-color: var(--loading-grey);
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    var(--loading-grey);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 2s ${loading} ease-in-out infinite;
  border-radius: 6px;

  ${(props) => {
    switch (props.type) {
      default:
      case "title":
        return css`
          height: 32px;
          width: 450px;
          margin-bottom: 4px;
        `;
      case "instructor":
        return css`
          height: 21px;
          width: 180px;
          margin-bottom: 24px;
        `;
      case "about":
        return css`
          height: 25px;
          width: 110px;
          margin-bottom: 8px;
        `;
      case "description":
        return css`
          height: 18px;
          width: 100%;
          margin-bottom: 3px;
        `;
      case "descriptionLastLine":
        return css`
          height: 18px;
          width: 75%;
          margin-bottom: 24px;
        `;

      case "files":
        return css`
          height: 25px;
          width: 200px;
          margin-bottom: 8px;
        `;

      case "file":
        return css`
          height: 55px;
          width: 196px;
        `;

      case "sidebarVideo":
        return css`
          border-radius: 8px;
          width: 94px;
          height: 63px;
        `;

      case "sidebarText":
        return css`
          border-radius: 8px;
          width: 100px;
          height: 15px;
        `;

      case "courseImg":
        return css`
          width: 100%;
          height: 152px;
          border-radius: 4px;
        `;
    }
  }}
`;

export const SkeletonFlex = styled.div`
  display: flex;
  gap: 8px;
`;

export const SkelletonSidebarCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: #fff;

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const Link = styled.span`
  font-size: 14px;
  line-height: 100%;
  font-weight: 600;
  color: #3a3a3c !important;
  font-family: "Open Sans";
  cursor: pointer;
`;

export const FalseLink = styled.span`
  font-size: 14px;
  line-height: 100%;
  font-weight: 600;
  color: #3a3a3c !important;
  font-family: "Open Sans";
`;
