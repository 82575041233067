import React, { useEffect, useState } from "react";
import { AiFillPrinter } from "react-icons/ai";
import {
  ArticleContainer,
  ArticleContent,
  ArticleSubtitle,
  ArticleText,
  ArticleTitle,
  ArticleViewWrapper,
  IconContainer,
  IconWrapper,
  StickyContainer,
  TopicRelatedArticle,
  TopicRelatedContainer,
  TopicRelatedTitle,
  TopicRelatedWrapper,
} from "../styles";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import {
  bookmarkManualArticle,
  getManualFaq_Highlight,
  getManualTopicList,
  printArticle,
  printChapter,
  viewedManualArticle,
} from "../../../../redux/modules/eventManual/action";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { ImFileText2 } from "react-icons/im";
import EventManualHeader from "../../EventManualHomepage/Header";
import ManualSidebar from "../../sidebar";
import GeneratePdf from "../../GeneratePDF";
import { Container } from "../../styles";
import ScrollTopButton from "../../../../newUXComponents/ScrollTopButton";
import { FormattedMessage, useIntl } from "react-intl";
import useNotification from "../../../../hooks/newUXNotification";
import { ArticleIcon } from "../../EventManualHomepage/TopicList";

const Article = () => {
  const [bookmarked, setBookmarked] = useState(false);
  const dispatch = useDispatch();
  const { chapterData, articleData, topics, savedArticles } = useSelector(
    (state) => state.eventManual
  );

  const [isPrinting, setIsPrinting] = useState(false);
  const { chapterId, articleId } = useParams();
  const notification = useNotification();
  const intl = useIntl();

  const formatedDate = (date) => moment(date).format("DD/MM/YYYY");

  useEffect(() => {
    if (!articleId) {
      return;
    }
    dispatch(getManualTopicList());
    dispatch(getManualFaq_Highlight());
    dispatch(printChapter({ chapterId }));
    dispatch(printArticle({ articleId }));
    dispatch(viewedManualArticle({ articleId }));
  }, [articleId, chapterId, dispatch]);

  useEffect(() => {
    const article = savedArticles.find(
      (article) => article.articleId === +articleId
    );

    if (article) {
      setBookmarked(article.favorite);
    }
  }, [articleId, savedArticles, topics]);

  const bookmarkArticle = (articleId) => {
    setBookmarked((prevState) => !prevState);
    dispatch(bookmarkManualArticle({ articleId }));
  };

  const handleGeneratePdf = async (chapter) => {
    setIsPrinting(true);
    try {
      await GeneratePdf({
        data: chapter,
        successMessage: intl.formatMessage({ id: "generating_pdf_success" }),
        waitingMessage: intl.formatMessage({ id: "generating_pdf" }),
      });
    } catch (error) {
      notification.displayInfo(
        intl.formatMessage({ id: "error.PDF_cannot_be_created" }),
        "error.PDF_cannot_be_created"
      );
    } finally {
      setIsPrinting(false);
    }
  };

  if (!articleData || !chapterData || !topics) return null;

  return (
    <Container>
      <div>
        <EventManualHeader topics={topics} />
      </div>
      <ArticleViewWrapper>
        <ManualSidebar topics={topics} />
        <ArticleContainer>
          <ArticleContent>
            <ArticleTitle>
              {articleData.articleTitle}{" "}
              <IconWrapper>
                <IconContainer
                  blueBackground={true}
                  onClick={() => bookmarkArticle(articleData.articleId)}
                >
                  {bookmarked ? <BsBookmarkFill /> : <BsBookmark />}
                </IconContainer>
                <IconContainer onClick={() => handleGeneratePdf(articleData)}>
                  <AiFillPrinter />
                </IconContainer>
              </IconWrapper>
            </ArticleTitle>
            <ArticleSubtitle>
              {articleData.chapterName} &#8226;{" "}
              <FormattedMessage id="updatedAt" />:{" "}
              {formatedDate(articleData.updatedAt)}
            </ArticleSubtitle>

            <ArticleText
              dangerouslySetInnerHTML={{ __html: articleData.content }}
            />
          </ArticleContent>

          <StickyContainer>
            {chapterData.filter(
              ({ articleTitle }) => articleTitle !== articleData.articleTitle
            ).length > 0 && (
              <TopicRelatedContainer>
                <TopicRelatedTitle>
                  <FormattedMessage id="related_topics" />
                </TopicRelatedTitle>
                {chapterData
                  .filter(
                    ({ articleTitle }) =>
                      articleTitle !== articleData.articleTitle
                  )
                  .map(({ articleTitle, articleId, chapterId }) => (
                    <TopicRelatedWrapper
                      to={`/event-manual/chapter/${chapterId}/article/${articleId}`}
                    >
                      <ArticleIcon />
                      <TopicRelatedArticle>{articleTitle}</TopicRelatedArticle>
                    </TopicRelatedWrapper>
                  ))}
              </TopicRelatedContainer>
            )}
          </StickyContainer>
        </ArticleContainer>
      </ArticleViewWrapper>
      <ScrollTopButton />
    </Container>
  );
};

export default Article;
