import * as React from "react";

const HeartIcon = (props) => {
  const { size = 18, color = "#5B93FF" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M11.888 1.875c.473 0 .945.067 1.395.218 2.768.9 3.765 3.937 2.932 6.592a9.55 9.55 0 0 1-2.257 3.607 29 29 0 0 1-4.748 3.72l-.187.113-.195-.12a28.6 28.6 0 0 1-4.777-3.72 9.7 9.7 0 0 1-2.258-3.6c-.848-2.655.15-5.692 2.948-6.608a3.4 3.4 0 0 1 .667-.157h.09q.315-.045.63-.045h.082q.709.021 1.373.248h.045q.045.022.067.044c.166.053.323.113.473.196l.285.127c.068.037.146.093.212.141q.065.048.11.077l.037.021c.065.038.132.077.188.12a4.7 4.7 0 0 1 2.887-.974m1.996 5.4a.617.617 0 0 0 .593-.571v-.09a2.475 2.475 0 0 0-1.582-2.37.6.6 0 0 0-.758.376c-.105.315.06.66.375.771.48.18.803.654.803 1.178v.023a.65.65 0 0 0 .142.465.63.63 0 0 0 .427.218"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default HeartIcon;
