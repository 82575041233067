import styled from "styled-components";

const green = {
  backgroundColor: "#33d27e",
  color: "#FFF",
};

const darkGrey = {
  backgroundColor: "#3D3D3D",
  color: "#FFF",
};

const backgroundBadgeVariants = {
  DRAFT: darkGrey,
  PRIVATE: darkGrey,
  PUBLISHED: green,
  POSTED: green,
  PUBLIC: green,
};

export const BadgeContainer = styled.div`
  border-radius: 20px;
  padding: 4px 8px;
  width: 120px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16.34px;
  font-weight: 700;
  text-align: center;

  background-color: ${({ variant }) =>
    backgroundBadgeVariants[variant]?.backgroundColor ||
    backgroundBadgeVariants.DRAFT.backgroundColor};
  color: ${({ variant }) =>
    backgroundBadgeVariants[variant]?.color ||
    backgroundBadgeVariants.DRAFT.color};
`;
