import styled from "styled-components";

export const ArticleButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ArticleButtonContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const GoBackContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ArticleContainer = styled.div`
  width: 1052px;
  padding: 32px 176.5px;
  margin: 0 auto;
  border: 1px solid #E2E8F0;
  border-radius: 8px;
`;

export const ArticleHeader = styled.div`
  margin-bottom: 24px;
  h2 {
    font-family: Poppins, sans-serif;
    color: #1e293b;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 0;
  }

  & > div {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  & span {
    color: #585f74;
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 14px;
  }
`;

export const Badge = styled.div`
  text-transform: capitalize;
  border-radius: 20px;
  background-color: #e1edf1;
  font-weight: 600;
  font-size: 11.58px;
  line-height: 14.16px;
  color: black;
  padding: 1.93px 14.16px;
`;

export const Article = styled.div`
  p {
    margin-bottom: 0;
  }

  img,
  iframe {
    max-width: 699px;
  }
`;
