import React from "react";
import {
  Info,
  LContainer,
  LHeader,
  LInfoContainer,
  LSidebarContainer,
  SkeletonDiv,
  SkeletonFlex,
  SkelletonSidebarCard,
  SkelletonVideo,
} from "./styles";
import CourseProgression from "../components/CourseProgression";
import { Divider } from "antd";
import { Container } from "../Course/styles";
import Button from "../components/Button";
import { FaArrowRight } from "react-icons/fa";
import { LessonBreadCrumb } from ".";
import Recommend from "../../../newUXAssets/icons/Recommend";

export function LessonLoaderSkelleton({ courseId, coursename }) {
  return (
    <Container>
      <LHeader>
        <LessonBreadCrumb courseId={courseId} courseName={coursename} />
        <div className="buttons">
          <Button
            label={"recommendLesson"}
            Icon={<Recommend size="16" stroke="none" fill="#093348" />}
            variant={"academyRecommend"}
            size={"academy"}
            iconEnd={true}
            disabled
          />
          <Button
            label={"finishLesson"}
            variant={"academy"}
            size={"academy"}
            iconEnd={true}
            Icon={<FaArrowRight />}
            disabled
          />
        </div>
      </LHeader>
      <LContainer>
        <LessonInfoSkelleton />
        <LessonSidebarSkelleton />
      </LContainer>
    </Container>
  );
}

export function LessonInfoSkelleton() {
  return (
    <LInfoContainer>
      <SkelletonVideo />
      <Info>
        <SkeletonDiv type={"title"} />
        <SkeletonDiv type={"instructor"} />

        <SkeletonDiv type={"about"} />
        <SkeletonDiv type={"description"} />
        <SkeletonDiv type={"description"} />
        <SkeletonDiv type={"description"} />
        <SkeletonDiv type={"descriptionLastLine"} />

        <SkeletonDiv type={"files"} />
        <SkeletonFlex>
          <SkeletonDiv type={"file"} />
          <SkeletonDiv type={"file"} />
          <SkeletonDiv type={"file"} />
        </SkeletonFlex>
      </Info>
    </LInfoContainer>
  );
}

export function LessonSidebarSkelleton() {
  return (
    <LSidebarContainer>
      <CourseProgression
        concludedLessons={0}
        totalLessons={99}
        skelleton
        fullsize={true}
      />
      <section>
        <SkelletonSidebarCard>
          <div>
            <SkeletonDiv type={"sidebarVideo"} />
            <SkeletonDiv type={"sidebarText"} />
          </div>
        </SkelletonSidebarCard>
        <Divider />
        <SkelletonSidebarCard>
          <div>
            <SkeletonDiv type={"sidebarVideo"} />
            <SkeletonDiv type={"sidebarText"} />
          </div>
        </SkelletonSidebarCard>
        <Divider />
        <SkelletonSidebarCard>
          <div>
            <SkeletonDiv type={"sidebarVideo"} />
            <SkeletonDiv type={"sidebarText"} />
          </div>
        </SkelletonSidebarCard>
        <Divider />
        <SkelletonSidebarCard>
          <div>
            <SkeletonDiv type={"sidebarVideo"} />
            <SkeletonDiv type={"sidebarText"} />
          </div>
        </SkelletonSidebarCard>
        <Divider />
        <SkelletonSidebarCard>
          <div>
            <SkeletonDiv type={"sidebarVideo"} />
            <SkeletonDiv type={"sidebarText"} />
          </div>
        </SkelletonSidebarCard>
        <Divider />
        <SkelletonSidebarCard>
          <div>
            <SkeletonDiv type={"sidebarVideo"} />
            <SkeletonDiv type={"sidebarText"} />
          </div>
        </SkelletonSidebarCard>
        <Divider />
      </section>
    </LSidebarContainer>
  );
}
