import React, { useCallback, useMemo } from "react";
import DataTable from "react-data-table-component";
import {
  AddButton,
  ArrowButton,
  ButtonContainer,
  ColumnName,
  Container,
  Divider,
  RemoveButton,
  TableContainer,
  TableOrderContainer,
  TableTitle,
} from "./style";
import { IoChevronUpOutline, IoChevronDownOutline } from "react-icons/io5";
import Empty from "../../pages/InformaAcademy/components/Empty";
import { useIntl } from "react-intl";
import { paginationOptions } from "../../pages/Manual/components/table-utils";
import { customStyles } from "../../pages/InformaAcademy/components/CoursesTable/styles";

const ReorderableTable = ({ title, data, pagination = true, setCourses }) => {
  const intl = useIntl();

  const handleReorder = useCallback(
    (row, direction) => {
      setCourses((prevCourses) => {
        // Encontrar o índice do curso atual
        const currentIndex = prevCourses.findIndex(
          (c) => c.courseId === row.courseId
        );
        const targetIndex =
          direction === "up" ? currentIndex - 1 : currentIndex + 1;

        // Verificar limites
        if (targetIndex < 0 || targetIndex >= prevCourses.length)
          return prevCourses;

        // Criar uma cópia do array para modificar
        const newCourses = [...prevCourses];

        // Remover o item da posição atual
        const [removedItem] = newCourses.splice(currentIndex, 1);

        // Inserir o item na nova posição
        newCourses.splice(targetIndex, 0, removedItem);

        // Atualizar courseOrder para todos os itens
        return newCourses.map((course, index) => ({
          ...course,
          courseOrder: index + 1,
        }));
      });
    },
    [setCourses]
  );

  const handleAdd = useCallback(
    (row) => {
      setCourses((prevCourses) => {
        const highestOrder = Math.max(
          ...prevCourses
            .filter((c) => c.courseOrder < 999)
            .map((c) => c.courseOrder),
          0
        );

        return prevCourses
          .map((course, index) => {
            if (course.courseId !== row.courseId) return course;
            return course.visible
              ? {
                  ...course,
                  visible: false,
                  courseOrder: 999 + index,
                }
              : {
                  ...course,
                  visible: true,
                  courseOrder: highestOrder + 1,
                };
          })
          .sort((a, b) => {
            if (a.courseOrder >= 999 && b.courseOrder >= 999) {
              return a.courseOrder - b.courseOrder;
            }
            if (a.courseOrder >= 999) {
              return 1;
            }
            if (b.courseOrder >= 999) {
              return -1;
            }
            return a.courseOrder - b.courseOrder;
          })
          .map((course, index, array) => {
            if (course.visible) {
              const visibleItemsBefore = array
                .slice(0, index)
                .filter((c) => c.visible);

              return {
                ...course,
                courseOrder: visibleItemsBefore.length + 1,
              };
            }

            return course;
          });
      });
    },
    [setCourses]
  );

  const visibles = useMemo(() => data.filter((item) => item.visible), [data]);

  const cols = useMemo(
    () => [
      {
        name: intl.formatMessage({
          id: "table_order",
        }),
        selector: (row) => row.courseOrder,
        cell: (row, index) => (
          <TableOrderContainer>
            <ColumnName>
              {row.courseOrder >= 999 ? 0 : row.courseOrder}
            </ColumnName>
            <ButtonContainer>
              <ArrowButton
                type="button"
                onClick={() => handleReorder(row, "up")}
                disabled={index === 0 || !row.visible}
              >
                <IoChevronUpOutline />
              </ArrowButton>
              <ArrowButton
                type="button"
                onClick={() => handleReorder(row, "down")}
                disabled={index === visibles.length - 1 || !row.visible}
              >
                <IoChevronDownOutline />
              </ArrowButton>
            </ButtonContainer>
          </TableOrderContainer>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
      {
        name: intl.formatMessage({
          id: "title",
        }),
        selector: "courseTitle",
        cell: (row) =>
          row.isBold ? <strong>{row.courseTitle}</strong> : row.courseTitle,
      },
      {
        cell: (row) => {
          return (
            <>
              {row.visible ? (
                <RemoveButton type="button" onClick={() => handleAdd(row)}>
                  <RemoveIcon />
                  {intl.formatMessage({ id: "table_button_remove" })}
                </RemoveButton>
              ) : (
                <AddButton type="button" onClick={() => handleAdd(row)}>
                  <AddIcon />
                  {intl.formatMessage({ id: "table_button_add" })}
                </AddButton>
              )}
            </>
          );
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        width: "160px",
      },
    ],
    [handleAdd, handleReorder, intl, visibles.length]
  );

  const conditionalRowStyles = [
    {
      when: (row) => !row.visible,
      style: {
        backgroundColor: "#EBEBF0",
        color: "#6C6C6C",
      },
    },
  ];

  return (
    <TableContainer>
      <TableTitle>
        {title} ({data.length})
      </TableTitle>
      <Divider />
      <Container>
        <DataTable
          conditionalRowStyles={conditionalRowStyles}
          customStyles={customStyles}
          noDataComponent={<Empty />}
          columns={cols}
          data={data}
          noHeader={!title}
          pagination={pagination}
          paginationComponentOptions={paginationOptions({
            locale: intl.locale,
          })}
          responsive
        />
      </Container>
    </TableContainer>
  );
};

export default ReorderableTable;

function RemoveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="13"
      fill="none"
      viewBox="0 0 14 13"
    >
      <rect
        width="11.72"
        height="11.72"
        x="1.141"
        y="0.779"
        stroke="#024"
        rx="5.86"
      />
      <path
        stroke="#024"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.242 6.64h5.72"
      />
    </svg>
  );
}

function AddIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      fill="none"
      viewBox="0 0 13 13"
    >
      <g stroke="#fff" strokeLinejoin="round" clipPath="url(#a)">
        <path d="M6.5 11.78a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"></path>
        <path strokeLinecap="round" d="M6.5 4.78v4M4.5 6.78h4"></path>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5.78h12v12H.5z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
