import React, { useEffect, useState } from "react";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import { FormattedMessage, useIntl } from "react-intl";
import { Container, TitleContainer, Title, SegmentLabel } from "./styles";

import { Segmented } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getBannersTotalizers,
  getCoursesTotalizers,
  getEventList,
} from "../../../redux/modules/analytics/actions";
import { LoadingComponent } from "../../../newUXPages/InformaAcademy/Course/styles";
import { LoadingSpinnerLogo } from "../../../components/LoadingOverlay/styles";
import LogoCircle from "../../../assets/images/Informa_Logo_Circle.png";
import CoursesAnalytics from "./Courses";
import BannerAnalytics from "./Banner";

const OPTIONS = [
  {
    label: <SegmentLabel>Banner</SegmentLabel>,
    value: "banner",
  },

  {
    label: (
      <SegmentLabel>
        <FormattedMessage id={"sidebar.courses"} />
      </SegmentLabel>
    ),
    value: "courses",
  },
];

export default function InformaAcademyAnalytics() {
  const [analytics, setAnalytics] = useState("banner");
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    bannersTotalizers,
    coursesTotalizers,

    isLoadingBanners,
    isLoading,

    events,
    years,
  } = useSelector((state) => state.analytics);

  useEffect(() => {
    dispatch(getCoursesTotalizers());
    dispatch(getBannersTotalizers());
    dispatch(getEventList());
  }, [dispatch]);

  const selectedSegment =
    analytics === "banner"
      ? "Banner"
      : intl.formatMessage({ id: "sidebar.courses" });

  if (isLoading || isLoadingBanners) {
    return <Loading />;
  }

  return (
    <AnimatedMainContainer breadcrumb={`Informa Academy | Analytics`}>
      <Container>
        <TitleContainer>
          <Title>Dashboard | {selectedSegment}</Title>
          <Segmented
            options={OPTIONS}
            value={analytics}
            onChange={setAnalytics}
            trackBg={"#000"}
          />
        </TitleContainer>

        {analytics === "courses" && (
          <CoursesAnalytics
            coursesTotalizers={coursesTotalizers}
            events={events}
            years={years}
          />
        )}
        {analytics === "banner" && (
          <BannerAnalytics
            bannersTotalizers={bannersTotalizers}
            events={events}
            years={years}
          />
        )}
      </Container>
    </AnimatedMainContainer>
  );
}

function Loading() {
  return (
    <LoadingComponent>
      <div>
        <LoadingSpinnerLogo src={LogoCircle} />
      </div>
    </LoadingComponent>
  );
}
