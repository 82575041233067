export const GET_EVENT_LIST = "GET_EVENT_LIST";
export const GET_EVENT_LIST_SUCCESS = "GET_EVENT_LIST_SUCCESS";
export const FILTER_EVENT_LIST_BY_NAME = "FILTER_EVENT_LIST_BY_NAME";
export const FILTER_EVENT_LIST_BY_NAME_SUCCESS =
  "FILTER_EVENT_LIST_BY_NAME_SUCCESS";
export const FILTER_EVENT_LIST_BY_YEAR = "FILTER_EVENT_LIST_BY_YEAR";
export const FILTER_EVENT_LIST_BY_YEAR_SUCCESS =
  "FILTER_EVENT_LIST_BY_YEAR_SUCCESS";
export const GET_BANNER_COURSE_LIST = "GET_BANNER_COURSE_LIST";
export const GET_BANNER_COURSE_LIST_SUCCESS = "GET_BANNER_COURSE_LIST_SUCCESS";
export const GET_PREVIEW_COURSE_LIST = "GET_PREVIEW_COURSE_LIST";
export const GET_PREVIEW_COURSE_LIST_SUCCESS =
  "GET_PREVIEW_COURSE_LIST_SUCCESS";
export const REORDER_BANNER_COURSE_LIST = "REORDER_BANNER_COURSE_LIST";
export const CREATE_BANNERS = "CREATE_BANNERS";
export const CREATE_BANNERS_SUCCESS = "CREATE_BANNERS_SUCCESS";
export const SET_BANNER = "SET_BANNER";
export const SET_BANNER_SUCCESS = "SET_BANNER_SUCCESS";
export const EDIT_BANNER = "EDIT_BANNER";
export const EDIT_BANNER_SUCCESS = "EDIT_BANNER_SUCCESS";
export const DELETE_BANNER = "DELETE_BANNER";
export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS";
export const SET_COURSES = "SET_COURSES";
export const SET_COURSES_SUCCESS = "SET_COURSES_SUCCESS";
export const SET_EMPTY_BANNER = "SET_EMPTY_BANNER";
export const SET_EMPTY_COURSE = "SET_EMPTY_COURSE";
export const CHANGE_VISIBILITY_COURSE = "CHANGE_VISIBILITY_COURSE";
export const ADMIN_GET_ALL_BANNERS = "ADMIN_GET_ALL_BANNERS";
export const ADMIN_GET_ALL_BANNERS_SUCCESS = "ADMIN_GET_ALL_BANNERS_SUCCESS";
