import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${({ $isTransparent }) => 
    $isTransparent ? 'transparent' : '#fff'};
  height: 8vh;
  justify-content: space-between;
  align-items: center;
  padding: 22px 52px 22px 72px;
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ContainerRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > div:nth-child(1) {
    padding: 0 15px;
  }

  > div:nth-child(2) {
    padding: 0 20px;
  }

  > div:nth-child(3) {
    display: flex;
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-left: 8px;
`;

export const Text = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "Open Sans";
  color: #444444;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  span:first-child {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`;
