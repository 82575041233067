import styled from "styled-components";

export const HeaderContainer = styled.div`
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  & p {
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 0;
  }
`;

export const Form = styled.form`
  margin-top: 24px;
  & p {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #1e293b;
    margin-bottom: 16px;
  }
`;
