import styled from "styled-components";

export const StepsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

export const StepsHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
  background-color: #fff;
  height: 50px;
`;

export const StepsContent = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  margin-bottom: 20px;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 16px;
  color: #64748b;
  font-size: 18px;
  line-height: 27px;
  font-family: "Poppins";

  &.active {
    font-weight: 600;
    color: #095f83;
  }
`;

export const StepLine = styled.div`
  width: 280px;
  height: 1px;
  background-color: #6c6c6c;
`;

export const StepCircle = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #e2e8f0;
  color: #475569;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  line-height: 21px;
  font-size: 14px;

  font-family: "Poppins";

  &.active,
  &.passed {
    background-color: #095f83;
    color: #fff;

    font-weight: 700;
    line-height: 24px;
    font-size: 16px;
  }
`;
