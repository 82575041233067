import styled, { css, keyframes } from "styled-components";

const variantStyles = {
  primary: {
    backgroundColor: "#002244",
    color: "#ffffff",
  },
  secondary: {
    backgroundColor: "#E2E8F0",
    color: "#002244",
  },
  destructive: {
    backgroundColor: "#FECACA",
    color: "#F91B1B",
  },
  academy: {
    backgroundColor: "#11A7D9",
    color: "#FFF",
    border: "1px solid #11A7D9",
  },
  academyRecommend: {
    backgroundColor: "#fff",
    color: "#093348",
    border: "1px solid #093348",
  },
  academyRecommended: {
    backgroundColor: "#093348",
    color: "#FFF",
    border: "1px solid #093348",
  },
  academySecondary: {
    backgroundColor: "#fff",
    color: "#11a7d9",
    border: "1px solid #11A7D9",
  },
  academyRestartCourse: {
    backgroundColor: "#e1f4fd",
    color: "#11a7d9",
  },
  backButton: {
    backgroundColor: "transparent",
    color: "#11a7d9",
  },
};

const textVariantStyles = {
  default: {
    fontWeight: "700",
    fontSize: "14px",
    padding: "4px 16px",
    lineHeight: "25.14px",
    borderRadius: "5px",
    textTransform: "uppercase",
  },
  sm: {
    fontWeight: "700",
    fontSize: "12px",
    padding: "2px 25px",
    lineHeight: "25.14px",
    borderRadius: "5px",
    textTransform: "uppercase",
  },
  academy: {
    fontWeight: "600",
    fontSize: "14px",
    padding: "8px 16px",
    lineHeight: "21px",
    borderRadius: "10px",
    textTransform: "capitalize",
  },
  backButton: {
    fontWeight: "600",
    fontSize: "14px",
    padding: "0px",
    lineHeight: "20px",
    borderRadius: "0px",
    textTransform: "capitalize",
    textDecorationLine: "underline",
    gap: "4px",
  },
};

const spin = keyframes`

  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }

`;

export const ButtonContainer = styled.button`
  font-family: "Open Sans", sans-serif;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  transition: all 300ms;
  background-color: ${({ variant }) =>
    variantStyles[variant]?.backgroundColor ||
    variantStyles.primary.backgroundColor};
  color: ${({ variant }) =>
    variantStyles[variant]?.color || variantStyles.primary.color};
  border: ${({ variant }) => variantStyles[variant]?.border || "none"};

  font-size: ${({ size }) =>
    textVariantStyles[size]?.fontSize || textVariantStyles.default.fontSize};
  padding: ${({ size }) =>
    textVariantStyles[size]?.padding || textVariantStyles.default.padding};
  border-radius: ${({ size }) =>
    textVariantStyles[size]?.borderRadius ||
    textVariantStyles.default.borderRadius};
  font-weight: ${({ size }) =>
    textVariantStyles[size]?.fontWeight ||
    textVariantStyles.default.fontWeight};
  line-height: ${({ size }) =>
    textVariantStyles[size]?.lineHeight ||
    textVariantStyles.default.lineHeight};
  text-transform: ${({ size }) =>
    textVariantStyles[size]?.textTransform ||
    textVariantStyles.default.textTransform};

  text-decoration-line: ${({ size }) =>
    textVariantStyles[size]?.textDecorationLine ||
    textVariantStyles[size]?.textDecorationLine};
  gap: ${({ size }) => textVariantStyles[size]?.gap || "8px"};

  & > svg {
    order: ${(props) => (props.iconEnd ? "999" : "-999")};

    ${({ loading }) =>
      loading &&
      css`
        animation: ${spin} 1s infinite;
      `};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const ReturnLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  p {
    color: #11a7d9;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-decoration-line: underline;
    margin: 0;
  }
`;
