import { all } from "redux-saga/effects";
import auth from "./auth/sagas";
import alerts from "./alerts/sagas";
import language from "./language/sagas";
import notification from "./notification/sagas";
import initialSettings from "./initialSettings/sagas";
import event from "./event/sagas";
import contract from "./contract/sagas";
import account from "./account/sagas";
import system from "./system/sagas";
import faq from "./faq/sagas";
import checklist from "./checklist/sagas";
import profile from "./profile/sagas";
import ecommerce from "./ecommerce/sagas";
import employee from "./employee/sagas";
import credentials from "./credentials/sagas";
import transferCredential from "./transferCredential/sagas";
import associateCompany from "./associateCompany/sagas";
import document from "./document/sagas";
import impersonate from "./impersonate/sagas";
import billing from "./billing/sagas";
import guests from "./guests/sagas";
import informaAcademy from "./informaAcademy/sagas";
import eventManual from "./eventManual/sagas";
import analytics from "./analytics/sagas";
import adminExhibitorManual from "./adminExhibitorManual/sagas";

export default function* rootSaga() {
  return yield all([
    auth,
    alerts,
    language,
    notification,
    event,
    initialSettings,
    contract,
    account,
    system,
    faq,
    checklist,
    profile,
    ecommerce,
    employee,
    credentials,
    transferCredential,
    associateCompany,
    document,
    impersonate,
    billing,
    guests,
    informaAcademy,
    eventManual,
    analytics,
    adminExhibitorManual,
  ]);
}
