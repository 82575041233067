import * as React from "react";

const Copy = (props) => {
  const { size = 16, fill = "#fff", stroke = "#002244" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g
        stroke={stroke}
        strokeLinejoin="round"
        strokeWidth="1.5"
        clipPath="url(#clip0_1924_151)"
      >
        <path
          strokeLinecap="round"
          d="M4.333 4.144v-1.54c0-.517.42-.937.938-.937h8.125c.518 0 .937.42.937.937v8.125c0 .518-.42.938-.937.938h-1.557"
        />
        <path d="M10.73 4.333H2.603a.937.937 0 0 0-.937.938v8.125c0 .517.42.937.937.937h8.125c.518 0 .938-.42.938-.937V5.27a.937.937 0 0 0-.938-.938Z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1924_151">
          <path fill={fill} d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Copy;
