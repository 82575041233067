import * as React from "react";

const Category = (props) => {
  const { size = "17", color = "#095F83" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke={color}
        strokeLinejoin="round"
        d="M6.75 2.25H3a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75h3.75a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75ZM6.75 10.5H3a.75.75 0 0 0-.75.75V15c0 .414.336.75.75.75h3.75A.75.75 0 0 0 7.5 15v-3.75a.75.75 0 0 0-.75-.75ZM15 2.25h-3.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75H15a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75ZM15 10.5h-3.75a.75.75 0 0 0-.75.75V15c0 .414.336.75.75.75H15a.75.75 0 0 0 .75-.75v-3.75a.75.75 0 0 0-.75-.75Z"
      />
    </svg>
  );
};

export default Category;
