import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { ButtonContainer, Container } from "./styles";
import Steps from "../../../components/Steps";
import api from "../../../service";
import { useToast } from "../../../hooks/toast";
import { useSelector } from "react-redux";
import CourseForm from "../components/course";
import Lesson from "../components/Lesson";
import Button from "../components/button";
import Restart from "../../../newUXAssets/icons/Restart";
import Save from "../../../icons/Save";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function NewCourseInformaAcademy() {
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const [course, setCourse] = useState(null);
  const [lessons, setLessons] = useState([]);
  const intl = useIntl();
  const history = useHistory();

  const { token } = useSelector((state) => state.auth);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const postCourse = async () => {
    try {
      if (lessons.length === 0)
        return addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error.lessons_empty",
          }),
        });

      const formDataCourse = convertCourseToFormData(course);

      const result = await api.post(
        "/informa-academy/courses/",
        formDataCourse,
        config
      );

      return result.data.description;
    } catch (err) {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error.course_create",
        }),
      });

      return "error";
    }
  };

  const postLessons = async (courseId) => {
    try {
      await Promise.all(
        lessons.map((lesson) =>
          api.post(
            "informa-academy/lesson",
            convertLessonToFormData(lesson, courseId),
            config
          )
        )
      );

      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "success.course_created",
        }),
      });
    } catch (err) {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error.lessons_create",
        }),
      });
    }
  };

  const handleSubmit = async () => {
    try {
      if (lessons.length < 1) {
        return addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error.lessons_create_min",
          }),
        });
      }
      const courseId = await postCourse();
      await postLessons(courseId);
      history.push("/admin/informa-academy");
    } catch (error) {}
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "course_register" })}
    >
      <Steps
        currentStep={currentStep}
        steps={["course_information", "course_lessons"]}
      />

      <Container>
        <CourseForm
          defaultValue={course}
          setCourse={setCourse}
          step={currentStep}
          goToLessons={() => setCurrentStep(1)}
        />
        {currentStep === 1 && (
          <Lesson lessons={lessons} setLessons={setLessons} />
        )}

        {currentStep === 1 && (
          <ButtonContainer>
            <Button
              label={"goback"}
              variant={"secondary"}
              Icon={<Restart stroke={"#002244"} />}
              onClick={() => setCurrentStep(0)}
            />

            <Button
              label={"save"}
              Icon={<Save />}
              // disabled={lessons.length === 0}
              onClick={handleSubmit}
            />
          </ButtonContainer>
        )}
      </Container>
    </AnimatedMainContainer>
  );
}

export default NewCourseInformaAcademy;

export function convertCourseToFormData(course) {
  const formData = new FormData();

  formData.append("name", course.name);
  formData.append("category", course.category);
  formData.append("responsible", course.responsible);
  formData.append("workload", course.workload);
  formData.append("language", course.language);
  formData.append("accessStartDate", course.accessStartDate);

  formData.append("description", course.description);
  formData.append("setupEvents", course.setupEvents.join(","));
  formData.append("coverImage", course.coverImage);

  if (course.accessStartDate) {
    formData.append("accessEndDate", course.accessEndDate);
  }

  if (course.id) {
    formData.append("id", course.id);
  }

  return formData;
}

export function convertLessonToFormData(lesson, courseId) {
  const formData = new FormData();
  formData.append("title", lesson.title);
  formData.append("orders", lesson.orders);
  formData.append("instructor", lesson.instructor);
  formData.append("description", lesson.description);
  formData.append("url", lesson.url);
  formData.append("language", "PORTUGUESE"); // TO BE REMOVED
  formData.append("course", courseId);

  if (lesson.coverImage) {
    formData.append("coverImage", lesson.coverImage);
  }

  if (lesson.files && lesson.files.length > 0) {
    lesson.files.map((file) => {
      return formData.append("files", file);
    });
  }

  if (lesson.id) {
    formData.append("id", lesson.id);
  }

  return formData;
}
