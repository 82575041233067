import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import moment from "moment";

import messages_pt from "../../translations/pt.json";
import messages_en from "../../translations/en.json";

import ptBR from "antd/lib/locale/pt_BR";
import enUS from "antd/lib/locale/en_US";

const messages = {
  pt: messages_pt,
  en: messages_en,
};

function LanguageProvider({ children }) {
  let language = useSelector((state) => state.language).code;

  if (language !== "pt" && language !== "en") {
    language = navigator.language.split(/[-_]/)[0];
  }
  moment.locale(language === "en" ? "en" : "us");

  return (
    <IntlProvider
      key={language}
      locale={language}
      messages={messages[language]}
      defaultLocale="en"
    >
      <ConfigProvider locale={language === "en" ? enUS : ptBR}>
        {children}
      </ConfigProvider>
    </IntlProvider>
  );
}

export default LanguageProvider;
