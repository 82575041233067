import React, { useState } from "react";
import {
  ImportantInfoContainer,
  InfoOptionsContainer,
  InfoOption,
  InfoOptionTitle,
  InfoDetailsContainer,
  InfoDetailsText,
  WrapperInfo,
} from "../../styles";

const EventManualFaq = ({ faqs }) => {
  const [indexActive, setIndexActive] = useState(null);
  const [selectedInfo, setSelectedInfo] = useState(null);

  return (
    <ImportantInfoContainer className={selectedInfo ? "" : "full-width"}>
      <InfoOptionsContainer $isActive={indexActive !== null}>
        {faqs.map((faq, index) => (
          <InfoOption
            key={index}
            $isActive={indexActive !== null && indexActive === index}
            onClick={() => {
              setIndexActive((prev) => (prev === index ? null : index));
              setSelectedInfo((prev) => (prev === faq ? null : faq));
            }}
          >
            <InfoOptionTitle
              $isActive={indexActive !== null && indexActive === index}
            >
              {faq.question}
            </InfoOptionTitle>

            <ArrowIcon
              stroke={
                indexActive !== null && indexActive === index
                  ? "#FFF"
                  : "#585F74"
              }
            />
          </InfoOption>
        ))}
      </InfoOptionsContainer>

      <WrapperInfo $isActive={indexActive !== null}>
        <InfoDetailsContainer>
          <InfoDetailsText>{selectedInfo?.answer}</InfoDetailsText>
        </InfoDetailsContainer>
      </WrapperInfo>
    </ImportantInfoContainer>
  );
};

export default EventManualFaq;

export function ArrowIcon({ stroke = "#fff", size = "26" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 26 26"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.672"
        d="M22.305 13H3.92M16.176 6.871 22.306 13l-6.13 6.129"
      />
    </svg>
  );
}
