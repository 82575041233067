import styled, { css } from "styled-components";

export const Container = styled.div`
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #e1e1e1;

  ${(props) =>
    props.mt &&
    css`
      margin-top: 16px;
    `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 12px;
  padding: 2px;

  @media (max-width: 1200px) {
    gap: 4px;
    & > button {
      width: 100%;
    }
  }
`;

export const Truncate = styled.div`
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const backgroundBadgeVariants = {
  waiting: {
    backgroundColor: "#ebebf0",
    color: "#3a3a3c",
  },
  aguardando: {
    backgroundColor: "#ebebf0",
    color: "#3a3a3c",
  },
  published: {
    backgroundColor: "#33d27e",
    color: "#FFF",
  },
  publicado: {
    backgroundColor: "#33d27e",
    color: "#FFF",
  },
  expired: {
    backgroundColor: "#3d3d3d",
    color: "#FFF",
  },
  expirado: {
    backgroundColor: "#3d3d3d",
    color: "#FFF",
  },
};

export const BadgeContainer = styled.div`
  border-radius: 20px;
  padding: 4px 8px;
  width: 120px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16.34px;
  font-weight: 700;
  text-align: center;

  background-color: ${({ variant }) =>
    backgroundBadgeVariants[variant]?.backgroundColor ||
    backgroundBadgeVariants.waiting.backgroundColor};
  color: ${({ variant }) =>
    backgroundBadgeVariants[variant]?.color ||
    backgroundBadgeVariants.waiting.color};
`;

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin: 90px 0px;
`;

export const customStyles = {
  headRow: {
    style: {
      backgroundColor: "#f6f6f6",
    },
  },
  headCells: {
    style: {
      color: "#533d59",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      textAlign: "left",
    },
  },
  rows: {
    style: {
      color: "#002244",
      fontSize: "14px",
      fontWeight: "600",
      fontFamily: "Open Sans",
      backgroundColor: "#fff",
      "&:hover": {
        backgroundColor: "rgba(17, 167, 217, 0.03)",
      },
    },
  },
};
