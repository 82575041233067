import { Link } from "react-router-dom";
import styled from "styled-components";

export const CourseContainer = styled.div`
  width: 100%;
  padding: 1.25rem;
  background-color: #fff;
  border-radius: 8px;
`;

export const CourseTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
`;

export const CourseTitle = styled.h2`
  font-family: "Poppins";
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const OpenCourseLink = styled(Link)`
  font-size: 0.875rem;
  color: #007bff;
  margin-left: auto;
  display: block;
  margin-top: 0.625rem;
  text-decoration: underline;
`;

export const LessonListWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 3rem;
  overflow: hidden;
`;

export const LessonList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;

  /* Remove scrollbars */
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
