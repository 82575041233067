import * as React from "react";

const Courses = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    fill="none"
    viewBox="0 0 18 19"

  >
    <path
      stroke="#002244"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M2.625 14.385v-9.75a2.25 2.25 0 0 1 2.25-2.25h8.25v9.75h-8.25a2.253 2.253 0 0 0-2.25 2.25Z"
    ></path>
    <path
      stroke="#002244"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M13.125 12.135h-8.25a2.25 2.25 0 1 0 0 4.5h10.5v-13.5M5.25 14.385h7.5"
    ></path>
  </svg>
);

export default Courses;
