import AnimatedMainContainer from "../../../../components/AnimatedMainContainer";
import { Container } from "../../style";
import ChapterForm from "./chapter-form";
import { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import ChapterTable from "./chapters-table";
import { HeaderContainer } from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { listChapters } from "../../../../redux/modules/adminExhibitorManual/actions";
import Button from "../../../InformaAcademy/components/button";
import Save from "../../../../icons/Save";
import { ButtonContainer } from "../../newManual/styles";
import api from "../../../../service";
import { useToast } from "../../../../hooks/toast";

export default function ManageChapters() {
  const { manualId } = useParams();

  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    chapters: chaptersRedux,
    error,
    isLoading,
  } = useSelector((state) => state.adminManualExhibitor);
  const { token } = useSelector((state) => state.auth);
  const [chapters, setChapters] = useState([]);
  const { addToast } = useToast();
  const history = useHistory();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    dispatch(
      listChapters({
        manualId,
      })
    );
  }, [dispatch, manualId]);

  useEffect(() => {
    if (chaptersRedux) {
      setChapters(chaptersRedux);
    }
  }, [chaptersRedux]);

  const createOnDb = async ({ chapterTitle, order }) => {
    const body = JSON.stringify({ chapterTitle, order });

    await api.post(`/chapters/manual/${manualId}`, body, config);
  };

  const updateInDb = async ({ chapterTitle, order, chapterId }) => {
    const body = JSON.stringify({ chapterTitle, order });

    await api.put(`/chapters/${chapterId}`, body, config);
  };

  const updateChaptersInDB = async (chaptersToUpdate) => {
    if (chaptersToUpdate.length === 0) return;

    const updatePromises = chaptersToUpdate.map((chapter) => {
      return updateInDb({
        order: chapter.order,
        chapterTitle: chapter.chapterTitle,
        chapterId: chapter.id,
      });
    });
    return Promise.all(updatePromises);
  };

  const createChaptersOnDB = async (chaptersToCreate) => {
    if (chaptersToCreate.length === 0) return;

    const createPromises = chaptersToCreate.map((chapter) => {
      return createOnDb({
        order: chapter.order,
        chapterTitle: chapter.chapterTitle,
      });
    });
    return Promise.all(createPromises);
  };

  const handleSave = async () => {
    try {
      const chaptersWithId = chapters.filter((i) => i.id);
      const chaptersWithoutId = chapters.filter((i) => i.id === undefined);

      await updateChaptersInDB(chaptersWithId);
      await createChaptersOnDB(chaptersWithoutId);

      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "success",
        }),
        description: intl.formatMessage({
          id: "success.chapters_update",
        }),
      });
      history.push(`/admin/manual/${manualId}`);
    } catch (error) {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error.chapters_update",
        }),
      });
    }
  };

  return (
    <AnimatedMainContainer goBack={`/admin/manual/${manualId}`}>
      <Container>
        <Header />

        <ChapterForm
          chapters={chapters}
          setChapters={setChapters}
          hideFormTitle
        />
        <ChapterTable
          key={chapters.length}
          chapters={chapters}
          setChapters={setChapters}
          articlesCol
        />
        <ButtonContainer margin="4px 0px 0px auto">
          <Button
            label={"save"}
            Icon={<Save />}
            onClick={handleSave}
            disabled={chapters.length === 0}
          />
        </ButtonContainer>
      </Container>
    </AnimatedMainContainer>
  );
}

function Header() {
  return (
    <HeaderContainer>
      <p>
        <FormattedMessage id={"manual_manage_chapters"} />
      </p>
    </HeaderContainer>
  );
}
