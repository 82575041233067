export function getCoursesTotalizers(params) {
  return {
    type: "GET_COURSES_TOTALIZERS",
    payload: {
      params,
    },
  };
}

export function getCoursesTotalizersSuccess(params) {
  return {
    type: "GET_COURSES_TOTALIZERS_SUCCESS",
    payload: {
      params,
    },
  };
}

export function getCoursesTotalizersFail(params) {
  return {
    type: "GET_COURSES_TOTALIZERS_FAIL",
    payload: {
      params,
    },
  };
}

export function getCourseAnalytics({
  idCourse = "",
  idEvent = "",
  idLesson = "",
  year = "",
  starterDate = "",
  finishingDate = "",
  page = "",
  perPage = "",
  sortBy = "",
  sortDirection = "",
}) {
  return {
    type: "GET_COURSE_ANALYTICS",
    payload: {
      idCourse,
      idEvent,
      idLesson,
      year,
      starterDate,
      finishingDate,
      page,
      perPage,
      sortBy,
      sortDirection,
    },
  };
}

export function getCourseAnalyticsSuccess({
  courseAnalytics,
  total,
  pageInfo,
}) {
  return {
    type: "GET_COURSE_ANALYTICS_SUCCESS",
    payload: { courseAnalytics, total, pageInfo },
  };
}

export function getCourseAnalyticsFail(params) {
  return {
    type: "GET_COURSE_ANALYTICS_FAIL",
    payload: {
      params,
    },
  };
}

export function getBannersTotalizers(params) {
  return {
    type: "GET_BANNERS_TOTALIZERS",
    payload: {
      params,
    },
  };
}

export function getBannersTotalizersSuccess(params) {
  return {
    type: "GET_BANNERS_TOTALIZERS_SUCCESS",
    payload: {
      params,
    },
  };
}

export function getBannersTotalizersFail(params) {
  return {
    type: "GET_BANNERS_TOTALIZERS_FAIL",
    payload: {
      params,
    },
  };
}

export function getBannerAnalytics({
  idEvent = "",
  idBanner = "",
  year = "",
  starterDate = "",
  finishingDate = "",
  page = "",
  perPage = "",
  sortBy = "",
  sortDirection = "",
}) {
  return {
    type: "GET_BANNER_ANALYTICS",
    payload: {
      idEvent,
      idBanner,
      year,
      starterDate,
      finishingDate,
      page,
      perPage,
      sortBy,
      sortDirection,
    },
  };
}

export function getBannerAnalyticsSuccess({
  bannerAnalytics,
  total,
  pageInfo,
}) {
  return {
    type: "GET_BANNER_ANALYTICS_SUCCESS",
    payload: {
      bannerAnalytics,
      total,
      pageInfo,
    },
  };
}

export function getBannerAnalyticsFail(params) {
  return {
    type: "GET_BANNER_ANALYTICS_FAIL",
    payload: {
      params,
    },
  };
}

export function bannerImpression({ bannerId }) {
  return {
    type: "BANNER_IMPRESSION",
    payload: { bannerId },
  };
}

export function bannerImpressionSuccess() {
  return {
    type: "BANNER_IMPRESSION_SUCCESS",
  };
}

export function bannerImpressionFailure(error) {
  return {
    type: "BANNER_IMPRESSION_FAILURE",
    payload: error,
  };
}

export function bannerViews({ bannerId }) {
  return {
    type: "BANNER_VIEWS",
    payload: { bannerId },
  };
}

export function bannerViewsSuccess() {
  return {
    type: "BANNER_VIEWS_SUCCESS",
  };
}

export function bannerViewsFailure(error) {
  return {
    type: "BANNER_VIEWS_FAILURE",
    payload: error,
  };
}

export function bannerInteraction({ bannerId }) {
  return {
    type: "BANNER_INTERACTION",
    payload: { bannerId },
  };
}

export function bannerInteractionSuccess() {
  return {
    type: "BANNER_INTERACTION_SUCCESS",
  };
}

export function bannerInteractionFailure(error) {
  return {
    type: "BANNER_INTERACTION_FAILURE",
    payload: error,
  };
}

export function getEventList() {
  return {
    type: "GET_EVENT_LIST_ANALYTICS",
  };
}

export function getEventListSuccess({ events, years, eventsInfo }) {
  return {
    type: "GET_EVENT_LIST_ANALYTICS_SUCCESS",
    payload: { events, years, eventsInfo},
  };
}

export function getEventListFailure(error) {
  return {
    type: "GET_EVENT_LIST_ANALYTICS_FAILURE",
    payload: error,
  };
}

export function getCoursesByEventId({ eventId }) {
  return {
    type: "GET_COURSES_ANALYTICS",
    payload: { eventId },
  };
}

export function getCoursesSuccessByEventId({ courses, lessons }) {
  return {
    type: "GET_COURSES_ANALYTICS_SUCCESS",
    payload: {
      courses,
      lessons,
    },
  };
}

export function getCoursesFailureByEventId(error) {
  return {
    type: "GET_COURSES_ANALYTICS_FAILURE",
    payload: error,
  };
}

export function getBannersByEventId({ eventId }) {
  return {
    type: "GET_BANNERS_LIST",
    payload: { eventId },
  };
}

export function getBannersSuccessByEventId({ banners }) {
  return {
    type: "GET_BANNERS_LIST_SUCCESS",
    payload: {
      banners,
    },
  };
}

export function getBannersFailureByEventId(error) {
  return {
    type: "GET_BANNERS_LIST_FAILURE",
    payload: error,
  };
}
