import { AiOutlineExclamationCircle } from "react-icons/ai";
import { FormattedMessage } from "react-intl";

import { Container, HighlightText, Text } from "./styles";

function Empty({
  intlId = "informa_academy_empty_text",
  highlightText = "informa_academy_empty_title",
}) {
  return (
    <Container>
      <AiOutlineExclamationCircle size={30} />
      <HighlightText>
        <FormattedMessage id={highlightText} />
      </HighlightText>
      <Text>
        <FormattedMessage id={intlId} />
      </Text>
    </Container>
  );
}

export default Empty;
