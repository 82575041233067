import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import StatusBadge from "../status-badge";
import { TableButtonContainer, TableContainer } from "../../style";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../InformaAcademy/components/CoursesTable/styles";
import { BiChevronUp } from "react-icons/bi";
import Button from "../../../InformaAcademy/components/button";
import { FiEye } from "react-icons/fi";
import Empty from "../../../InformaAcademy/components/Empty";
import { paginationOptions as options } from "../table-utils";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

export default function ArticleTable({
  articles,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  totalRows,
  setSortBy,
  setSortDirection,
}) {
  const intl = useIntl();
  const paginationOptions = {
    ...options({
      locale: intl.locale,
    }),
    disableNext: currentPage * rowsPerPage >= totalRows,
  };
  const columns = useMemo(() => {
    return [
      {
        id: "title",
        name: <FormattedMessage id={"article"} />,
        selector: (row) => row.articleTitle,
        sortable: true,
      },
      {
        maxWidth: "321px",
        name: <FormattedMessage id={"manual_chapter"} />,
        selector: (row) => row.chapterName,
      },
      {
        maxWidth: "172px",
        name: <FormattedMessage id={"updatedAt"} />,
        selector: (row) => moment(row.updatedAt).format("DD/MM/YYYY"),
      },
      {
        maxWidth: "163px",
        name: <FormattedMessage id={"Status"} />,
        selector: (row) => <StatusBadge label={row.status} />,
      },
      {
        maxWidth: "215px",
        center: true,
        selector: (row) => <ActionRow id={row.id} status={row.status} />,
      },
    ];
  }, []);

  return (
    <TableContainer>
      <DataTable
        data={articles}
        responsive
        columns={columns}
        noDataComponent={<Empty intlId="articles_empty" />}
        customStyles={customStyles}
        sortIcon={<BiChevronUp style={{ display: "flex" }} />}
        paginationComponentOptions={paginationOptions}
        persistTableHead
        pagination
        paginationServer
        paginationPerPage={rowsPerPage}
        onChangePage={(page) => setCurrentPage(page)}
        onChangeRowsPerPage={(size) => {
          setCurrentPage(1);
          setRowsPerPage(size);
        }}
        paginationTotalRows={totalRows}
        onSort={(column, sortDirection) => {
          setSortBy(column.id);
          setSortDirection(sortDirection);
        }}
      />
    </TableContainer>
  );
}

function ActionRow({ id }) {
  const { manualId } = useParams();
  return (
    <TableButtonContainer center>
      <Button
        label={"visualize"}
        variant={"secondary"}
        size={"sm2"}
        Icon={<FiEye size={13.33} />}
        href={`/admin/manual/${manualId}/details/${id}`}
      />
    </TableButtonContainer>
  );
}
