import api from "./index";
import generateHeaders from "../utils/generateHeaders";

export const getAnalyticsCourseTotalizersService = async ({ token }) => {
  const headers = generateHeaders(token);

  return await api.get("/academy/analytics/courses/totalizers", headers);
};

export const getAnalyticsCourseUsersService = async ({
  token,
  idCourse,
  idEvent,
  idLesson,
  year,
  starterDate,
  finishingDate,
  page,
  perPage,
  sortBy = "name",
  sortDirection = "ASC",
}) => {
  const headers = generateHeaders(token);

  const params = {
    idCourse,
    idEvent,
    idLesson,
    year,
    starterDate,
    finishingDate,
    page,
    per_page: perPage,
    sortBy,
    sortDirection,
  };
  const options = {
    ...headers,
    params,
  };

  return await api.get("/academy/analytics/courses/users", options);
};

export const getAnalyticsBannerTotalizersService = async ({ token }) => {
  const headers = generateHeaders(token);

  return await api.get("/academy/analytics/banners/totalizers", headers);
};

export const getAnalyticsBannerUsersService = async ({
  token,
  idEvent,
  idBanner,
  year,
  starterDate,
  finishingDate,
  page,
  perPage,
  sortBy = "name",
  sortDirection = "ASC",
}) => {
  const headers = generateHeaders(token);

  const params = {
    idEvent,
    idBanner,
    year,
    starterDate,
    finishingDate,
    page,
    size: perPage,
    sortBy,
    sortDirection,
  };

  const options = {
    ...headers,
    params,
  };

  return await api.get("/academy/analytics/banners/users", options);
};

export const bannerAnalyticsAction = async ({ token, bannerId, action }) => {
  const headers = generateHeaders(token);

  return await api.post(
    `/academy/analytics/banners/actions?action=${action}&bannerId=${bannerId}`,
    headers
  );
};
