import { printChapter } from "./action";
import {
  BOOKMARK_MANUAL_ARTICLE,
  GET_MANUAL_LIST,
  GET_MANUAL_LIST_SUCCESS,
  PRINT_MANUAL_ARTICLE,
  PRINT_MANUAL_ARTICLE_SUCCESS,
  PRINT_MANUAL_CHAPTER,
  PRINT_MANUAL_CHAPTER_SUCCESS,
  RESET_SEARCH_MANUAL_LIST,
  SEARCH_MANUAL_LIST,
  SEARCH_MANUAL_LIST_SUCCESS,
  SHOW_SELECTED_ARTICLE,
  SIDEBAR_CHAPTER_INFO,
  SIDEBAR_CHAPTER_INFO_SUCCESS,
  VIEWED_MANUAL_ARTICLE,
} from "./types";

const INITIAL_STATE = {
  topics: [],
  searchResult: [],
  loadedSearch: false,
  chapterData: null,
  articleData: null,
  selectedArticle: null,
  sidebarChapter: null,
  isLoading: false,
  error: null,
  loadedTopics: false,
  faqs: [],
  recentArticles: [],
  savedArticles: [],
  highlightError: false,
};

const eventManual = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_MANUAL_LIST:
      return {
        ...state,
        loading: true,
        error: null,
        loadedTopics: false,
      };

    case GET_MANUAL_LIST_SUCCESS:
      return {
        ...state,
        topics: action.payload,
        isLoading: false,
        error: null,
        loadedTopics: true,
      };

    case "GET_MANUAL_LIST_FAILURE":
      return {
        ...state,
        topics: [],
        isLoading: false,
        error: action.payload,
        loadedTopics: true,
      };

    case "GET_MANUAL_FAQ_HIGHLIGHT":
      return {
        ...state,
        faqs: [],
        recentArticles: [],
        savedArticles: [],
        highlightError: false,
      };

    case "GET_MANUAL_FAQ_HIGHLIGHT_SUCCESS":
      return {
        ...state,
        faqs: action.payload.faqs,
        recentArticles: action.payload.recentArticles,
        savedArticles: action.payload.savedArticles,
        highlightError: false,
      };

    case "GET_MANUAL_FAQ_HIGHLIGHT_FAILURE":
      return {
        ...state,
        faqs: [],
        recentArticles: [],
        savedArticles: [],
        highlightError: true,
      };

    case SEARCH_MANUAL_LIST:
      return {
        ...state,
        loading: true,
        error: null,
        loadedSearch: false,
      };

    case SEARCH_MANUAL_LIST_SUCCESS:
      return {
        ...state,
        searchResult: action.payload,
        isLoading: false,
        error: null,
        loadedSearch: true,
      };

    case RESET_SEARCH_MANUAL_LIST:
      return {
        ...state,
        searchResult: null,
        isLoading: false,
        error: null,
      };

    case PRINT_MANUAL_CHAPTER:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case PRINT_MANUAL_CHAPTER_SUCCESS:
      return {
        ...state,
        chapterData: action.payload,
        isLoading: false,
        error: null,
      };

    case PRINT_MANUAL_ARTICLE:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case PRINT_MANUAL_ARTICLE_SUCCESS:
      return {
        ...state,
        articleData: action.payload,
        isLoading: false,
        error: null,
      };

    case SHOW_SELECTED_ARTICLE:
      return {
        ...state,
        selectedArticle: action.payload,
        isLoading: false,
        error: null,
      };

    case SIDEBAR_CHAPTER_INFO:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case SIDEBAR_CHAPTER_INFO_SUCCESS:
      return {
        ...state,
        sidebarChapter: action.payload,
        isLoading: false,
        error: null,
      };

    case BOOKMARK_MANUAL_ARTICLE:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case VIEWED_MANUAL_ARTICLE:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    default:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
  }
};

export default eventManual;
