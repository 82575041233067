import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { FormRow } from "../../../../components/ControlledInputs/styles";
import ControlledInput from "../../../../components/ControlledInputs/input";
import { FormattedMessage } from "react-intl";
import Button from "../../../InformaAcademy/components/button";
import { BiPlus } from "react-icons/bi";
import { Form } from "./styles";

const schema = z.object({
  order: z.coerce.number().optional(),
  chapterTitle: z.string().min(1).max(150, {
    message: "error.max_length-150",
  }),
});

export default function ChapterForm({
  chapters,
  setChapters,
  hideFormTitle = false,
}) {
  const form = useForm({
    resolver: zodResolver(schema),
  });

  const { control, handleSubmit, formState, reset } = form;

  const onSubmit = async (data) => {
    const newChapter = {
      chapterTitle: data.chapterTitle,
      order: data?.order ?? 99_999,
    };

    const reorderedChapters = reorderChapters(chapters, newChapter);

    setChapters(reorderedChapters);
    return reset();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} key={Math.random()}>
      {!hideFormTitle && (
        <p>
          <FormattedMessage id={"manual_chapters"} />
        </p>
      )}
      <FormRow manual>
        <ControlledInput
          name={"chapterTitle"}
          max={150}
          control={control}
          formState={formState}
          label={"manual_chapter_title"}
          required={true}
          description={"course_name_sub"}
        />
        <ControlledInput
          name={"order"}
          control={control}
          formState={formState}
          label={"lesson_order"}
          // required={true}
          type="number"
          min={1}
          width={"200px"}
        />
        <Button
          label={"add"}
          Icon={<BiPlus size={16} />}
          variant={"secondary"}
        />
      </FormRow>
    </Form>
  );
}

export function reorderChapters(chapters, newChapter) {
  const newChapters = chapters.map((chapter) => ({ ...chapter }));
  const copiedChapter = {
    ...newChapter,
    order: +newChapter.order - 1,
  };
  newChapters.push(copiedChapter);

  newChapters.sort((a, b) => a.order - b.order);

  for (let i = 0; i < newChapters.length; i++) {
    if (i + 1 !== newChapters[i].order) {
      newChapters[i].order = i + 1;
    }
  }

  return newChapters;
}
