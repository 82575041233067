import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "../../../InformaAcademy/components/button";
import ControlledInput from "../../../../components/ControlledInputs/input";
import { FormRow } from "../../../../components/ControlledInputs/styles";
import Save from "../../../../icons/Save";
import BackIcon from "../../../../icons/Back";
import { useSelector } from "react-redux";
import api from "../../../../service";
import { useToast } from "../../../../hooks/toast";
import { reorderFAQ } from "./faq-form";
import ControlledTextareaInput from "../../../../components/ControlledInputs/input-textarea";
import { HeaderContainer } from "../chapter/styles";

const schema = z.object({
  order: z.coerce.number().optional(),
  question: z.string().min(1).max(50, {
    message: "error.max_length-50",
  }),
  answer: z.string().min(1).max(260, {
    message: "error.max_length-260",
  }),
});

export default function EditFAQForm({
  FAQ,
  setFAQ,
  defaultValue,
  resetModal,
  manualId,
}) {
  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: defaultValue,
  });
  const { control, handleSubmit, formState, reset } = form;
  const intl = useIntl();
  const { token } = useSelector((state) => state.auth);
  const { addToast } = useToast();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleReset = () => {
    reset();
    resetModal();
  };

  const updateInDb = async ({ question, answer, order }) => {
    try {
      const body = JSON.stringify({ question, answer, orders: order });
      await api.put(`/manual/${manualId}/faq/${defaultValue.id}`, body, config);

      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "success",
        }),
        description: intl.formatMessage({
          id: "success.FAQ_update.single",
        }),
      });
    } catch (error) {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error.FAQ_update.single",
        }),
      });
    }
  };

  const onSubmit = async (data) => {
    const updatedFAQ = {
      question: data.question,
      answer: data.answer,
      order: data?.order ?? 99_999,
      id: defaultValue.id ?? undefined
    };

    if (defaultValue) {
      const findIndex = FAQ.findIndex(
        (question) => question.id === defaultValue.id
      );
      if (findIndex !== -1) FAQ.splice(findIndex, 1);

      await updateInDb(updatedFAQ);
    }

    if (defaultValue.id) {
      await updateInDb(updatedFAQ);
    }

    const reorderedFAQ = reorderFAQ(FAQ, updatedFAQ);
    setFAQ(reorderedFAQ);
    handleReset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} key={Math.random()}>
      <HeaderContainer>
        <p>
          <FormattedMessage id={"manual_FAQ_edit"} />
        </p>
      </HeaderContainer>
      <FormRow marginBottom={"24px"}>
        <ControlledInput
          name={"question"}
          control={control}
          formState={formState}
          label={"question"}
          required={true}
          max={50}
          description={"FAQ_question_sub"}
        />
        <ControlledInput
          name={"order"}
          control={control}
          formState={formState}
          label={"lesson_order"}
          type="number"
          min={1}
        />
      </FormRow>
      <FormRow marginBottom={"24px"}>
        <ControlledTextareaInput
          name={"answer"}
          control={control}
          formState={formState}
          label={"answer"}
          description={"FAQ_answer_sub"}
          required={true}
          min={1}
          max={260}
        />
      </FormRow>
      <FormRow buttons>
        <Button
          label={"goback"}
          variant={"secondary"}
          Icon={<BackIcon />}
          type={"button"}
          onClick={() => {
            handleReset();
          }}
        />
        <Button label={"save"} Icon={<Save />} />
      </FormRow>
    </form>
  );
}
