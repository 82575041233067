import * as React from "react";

const Restart = (props) => {
  const { size = "16", stroke = "#11a7d9" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M2 12.667V14h12v-1.333M6 2 2.667 5.333 6 8.667"
      ></path>
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M2.667 5.333c6.666 0 11.333 1 11.333 5.334"
      ></path>
    </svg>
  );
};

export default Restart;
