import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { FormRow } from "../../../../components/ControlledInputs/styles";
import ControlledInput from "../../../../components/ControlledInputs/input";
import Button from "../../../InformaAcademy/components/button";
import { BiPlus } from "react-icons/bi";
import { ButtonContainer } from "../../manageManual/styles";
import { Form } from "../chapter/styles";
import ControlledTextareaInput from "../../../../components/ControlledInputs/input-textarea";

const schema = z.object({
  order: z.coerce.number().optional(),
  question: z.string().min(1).max(50, {
    message: "error.max_length-50",
  }),
  answer: z.string().min(1).max(260, {
    message: "error.max_length-260",
  }),
});

export default function FAQForm({ FAQ, setFAQ }) {
  const form = useForm({
    resolver: zodResolver(schema),
  });

  const { control, handleSubmit, formState, reset } = form;

  const onSubmit = async (data) => {
    const newFAQ = {
      question: data.question,
      answer: data.answer,
      order: data?.order ?? 99_999,
    };

    const reorderedFAQ = reorderFAQ(FAQ, newFAQ);

    setFAQ(reorderedFAQ);
    return reset();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} key={Math.random()}>
      <FormRow marginBottom={"24px"}>
        <ControlledInput
          name={"question"}
          control={control}
          formState={formState}
          label={"question"}
          required={true}
          max={50}
          description={"FAQ_question_sub"}
        />
        <ControlledInput
          name={"order"}
          control={control}
          formState={formState}
          label={"lesson_order"}
          type="number"
          min={1}
        />
      </FormRow>
      <FormRow marginBottom={"24px"}>
        <ControlledTextareaInput
          name={"answer"}
          control={control}
          formState={formState}
          label={"answer"}
          description={"FAQ_answer_sub"}
          required={true}
          min={1}
          width={"50%"}
          max={260}
        />
      </FormRow>
      <ButtonContainer end>
        <Button
          label={"clear_fields"}
          Icon={<Eraser />}
          variant={"tertiary"}
          border
          type={"button"}
          onClick={reset}
        />
        <Button label={"add"} Icon={<BiPlus size={20} />} border />
      </ButtonContainer>
    </Form>
  );
}

export function reorderFAQ(FAQs, newFAQ) {
  const newFAQs = FAQs.map((FAQ) => ({ ...FAQ }));
  const copiedFAQ = {
    ...newFAQ,
    order: +newFAQ.order - 1,
  };
  newFAQs.push(copiedFAQ);

  newFAQs.sort((a, b) => a.order - b.order);

  for (let i = 0; i < newFAQs.length; i++) {
    if (i + 1 !== newFAQs[i].order) {
      newFAQs[i].order = i + 1;
    }
  }

  return newFAQs;
}

const Eraser = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="#024"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M1.333 14h13.334M10.333 1.333l-8 8 2 2H7l6.667-6.667z"
    />
  </svg>
);
