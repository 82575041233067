import { useIntl } from "react-intl";
import {
  SearchContainer,
  SearchIcon,
  SearchInput as SearchInputContainer,
} from "../../styles";
import { RiSearchLine } from "react-icons/ri";

function SearchInput({ setSearch, ...rest }) {
  const intl = useIntl();
  return (
    <SearchContainer>
      <SearchIcon>
        <RiSearchLine />
      </SearchIcon>
      <SearchInputContainer
        type="text"
        placeholder={intl.formatMessage({ id: "search" })}
        onChange={(e) => setSearch(e.target.value)}
        {...rest}
      />
    </SearchContainer>
  );
}

export default SearchInput;
