import * as React from "react";

const InformaAcademyDownload = (props) => {
  const { size = 25, color = "#11A7D9" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 25"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5.839 10.636a5.002 5.002 0 1 0 2.527 9.675M18.027 10.636A5.002 5.002 0 1 1 15.5 20.31M18 10.5a6 6 0 0 0-12 0M8.531 15.56l3.467 3.478 3.567-3.538M12 10.5v6.77"
      ></path>
    </svg>
  );
};

export default InformaAcademyDownload;
