export function addMostAccessedFlag(array) {
  if (!array.length) return [];

  const maxAccessCount = Math.max(...array.map(obj => obj.accessCount || 0));
  if (maxAccessCount > 0) {
    return array.map(obj => {
      if (obj.accessCount === maxAccessCount) {
        return { ...obj, mostAccessed: true };
      }
      return obj;
    });
  }
  return array;
}