import { Link } from "react-router-dom";
import styled from "styled-components";

export const ArticleListWrapper = styled.div`
  display: grid;
  grid-template-columns: 324px 1fr;
  gap: 2rem;
  padding-top: 1rem;
  padding-bottom: 90px;
`;

export const ArticleViewWrapper = styled.div`
  display: grid;
  grid-template-columns: 324px 1fr;
  gap: 2rem;
  padding-top: 1rem;
  padding-bottom: 90px;
  box-sizing: border-box;
  position: relative;
`;

export const ArticleListContainer = styled.div`
  width: 100%;
  padding: 0 2.25rem;
  position: relative;
`;

export const ChapterTitle = styled.h1`
  font-family: "Poppins";
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

export const IconContainer = styled.span`
  background-color: ${({ blueBackground }) =>
    blueBackground ? "#E1F4FD" : "#e2e8f0"};
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;

  & > svg {
    color: ${({ blueBackground }) => (blueBackground ? "#068EC3" : "#1E293B")};
  }
`;

export const ArticleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ArticleItemList = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #cbd5e1;
  padding: 1rem 1.5rem;
  border-radius: 20px;
  font-family: "Inter";
  font-size: 1rem;
  font-weight: 500;
  color: #1e293b;
  cursor: pointer;

  & > svg {
    font-size: 24px;
    color: #11a7d9;
  }

  &:hover {
    color: #1e293b;
  }
`;

export const ArticleWrapper = styled.div`
  display: grid;
  grid-template-columns: 270px 1fr 350px;
  justify-content: space-between;
  align-items: flex-start;
  gap: 3.3rem;
`;

export const ArticleTitle = styled.h1`
  font-family: "Poppins";
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin: 0;
`;

export const ArticleSubtitle = styled.p`
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  color: #585f74;
`;

export const ArticleText = styled.div`
  & > p > img {
    max-width: 600px;
  }
`;
export const ArticleContent = styled.div`
  padding: 0 40px;
  /* margin: 0 auto; */
  max-width: 65vw;
  width: 100%;

  iframe {
    max-width: 100%;
  }
`;

export const StickyContainer = styled.div`
  position: relative;
  /* min-width: 300px; // Adjust based on your design */
  margin-right: 47px;
`;

export const TopicRelatedContainer = styled.aside`
  width: 277px;
  height: fit-content;
  padding: 1rem;
  border: 2px solid #cbd5e1;
  border-radius: 8px;
  box-shadow: 2px 2px 20px 0px rgba(168, 168, 168, 0.25);
  background: none;
  margin: 44px 0px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  top: 50px;
  position: sticky;
`;

export const TopicRelatedWrapper = styled(Link)`
  display: flex;
  align-items: start;
  gap: 8px;
  cursor: pointer;

  svg {
    margin-top: 4px;
    min-width: 14px;
    max-width: 14px;
  }

  span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const TopicRelatedTitle = styled.p`
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
  padding: 0;
`;

export const TopicRelatedArticle = styled(TopicRelatedTitle)`
  text-decoration: underline;
  color: #095f83;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ArticleContainer = styled.div`
  display: flex;
  gap: 12px;
`;
