import React from "react";
import {
  ArticleButton,
  SavedArticlesCard,
  SavedArticlesContainer,
  SavedArticlesTitle,
  SavedArticlesCategory,
  SavedArticlesDescription,
} from "../../styles";

import { ArrowIcon } from "../faq";
import sanitizeHtml from "sanitize-html";

const EventManualSavedArticles = ({ topics }) => {
  function cleanHtml(html) {
    return sanitizeHtml(html, {
      allowedTags: ["br"], // This removes all HTML tags
    }).replace(/<br\s*\/?>/g, "\n");
  }

  return (
    <SavedArticlesContainer>
      {topics.map((topic, index) => (
        <SavedArticlesCard key={`articleBookmarked-${index}`}>
          <div>
            <SavedArticlesTitle>{topic.articleTitle}</SavedArticlesTitle>
            <SavedArticlesCategory>{topic.chapterTitle}</SavedArticlesCategory>
          </div>
          <SavedArticlesDescription>
            {cleanHtml(topic.articleDescription)}
          </SavedArticlesDescription>
          <ArticleButton
            to={`/event-manual/chapter/${topic.chapterId}/article/${topic.articleId}`}
          >
            <ArrowIcon size="16" />
          </ArticleButton>
        </SavedArticlesCard>
      ))}
    </SavedArticlesContainer>
  );
};

export default EventManualSavedArticles;
