import React, { useEffect, useState } from "react";
import { ScrollTop } from "./styles";
import { BiArrowToTop } from "react-icons/bi";

export default function ScrollTopButton() {
  const [showScrollTop, setShowScrollTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;
      const clientHeight = document.documentElement.clientHeight;

      const scrolled = (scrollTop / (scrollHeight - clientHeight)) * 100;
      setShowScrollTop(scrolled > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    if (!showScrollTop) return;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <ScrollTop
      show={showScrollTop}
      onClick={scrollToTop}
      // disabled={!showScrollTop}
    >
      <BiArrowToTop size={20} />
    </ScrollTop>
  );
}
