export const GET_MANUAL_LIST = "GET_MANUAL_LIST";
export const GET_MANUAL_LIST_SUCCESS = "GET_MANUAL_LIST_SUCCESS";
export const SEARCH_MANUAL_LIST = "SEARCH_MANUAL_LIST";
export const SEARCH_MANUAL_LIST_SUCCESS = "SEARCH_MANUAL_LIST_SUCCESS";
export const RESET_SEARCH_MANUAL_LIST = "RESET_SEARCH_MANUAL_LIST";
export const PRINT_MANUAL_CHAPTER = "PRINT_CHAPTER";
export const PRINT_MANUAL_CHAPTER_SUCCESS = "PRINT_CHAPTER_SUCCESS";
export const PRINT_MANUAL_ARTICLE = "PRINT_ARTICLE";
export const PRINT_MANUAL_ARTICLE_SUCCESS = "PRINT_ARTICLE_SUCCESS";
export const SHOW_SELECTED_ARTICLE = "SHOW_SELECTED_ARTICLE";
export const SIDEBAR_CHAPTER_INFO = "SIDEBAR_CHAPTER_INFO";
export const SIDEBAR_CHAPTER_INFO_SUCCESS = "SIDEBAR_CHAPTER_INFO_SUCCESS";
export const BOOKMARK_MANUAL_ARTICLE = "BOOKMARK_MANUAL_ARTICLE";
export const VIEWED_MANUAL_ARTICLE = "VIEWED_MANUAL_ARTICLE";
