import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #002244;
  gap: 4px;
  margin: 90px 0px;
`;

export const HighlightText = styled.p`
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
`;

export const Text = styled.p`
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
`;