import * as React from "react";

const FileIcon = (props) => {
  const { size = 24, color = "#6C6C6C" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 25"
    >
      <path
        fill={color}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5 22.14h14a1 1 0 0 0 1-1v-14h-5v-5H5a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m15 2.14 5 5"
      />
      <path
        fill="#fff"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 10.64a2 2 0 1 0 0-4 2 2 0 0 0 0 4M7.5 14.14v4.5h9v-8l-4.755 5.25z"
      />
    </svg>
  );
};

export default FileIcon;
