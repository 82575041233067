import { FormattedMessage, useIntl } from "react-intl";
import React, { useMemo } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../components/CoursesTable/styles";
import { BiChevronUp } from "react-icons/bi";
import Empty from "../../../components/Empty";
import { Container } from "./styles";

import { paginationOptions as options } from "../../../../Manual/components/table-utils";

export default function AnalyticsCourseDataTable({
  courseAnalytics = [],
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  totalRows,
  setSortBy,
  setSortDirection,
  selectedLesson,
}) {
  const intl = useIntl();

  const columns = useMemo(() => {
    const defaultCols = [
      {
        id: "name",
        name: <FormattedMessage id={"enterprise"} />,
        selector: (row) => row.name,
        sortable: true,
        style: {
          flex: 1,
        },
      },
      {
        id: "email",
        name: <FormattedMessage id={"email"} />,
        selector: (row) => row.email,
        sortable: true,
        style: {
          flex: 1,
        },
      },
      {
        id: "language",
        name: <FormattedMessage id={"language"} />,
        selector: (row) => <FormattedMessage id={row.language} />,
        sortable: true,
        maxWidth: "199px",
      },
      {
        id: "profile",
        name: <FormattedMessage id={"profile"} />,
        selector: (row) => <FormattedMessage id={row.profile} />,
        sortable: true,
        maxWidth: "199px",
      },
    ];
    const withRecomend = [
      ...defaultCols,
      {
        id: "recommendation",
        name: <FormattedMessage id={"recommendation"} />,
        selector: (row) => <FormattedMessage id={row?.action} />,
        sortable: true,
        maxWidth: "199px",
      },
    ];
    return selectedLesson ? withRecomend : defaultCols;
  }, [selectedLesson]);

  const paginationOptions = {
    ...options({
      locale: intl.locale,
    }),
    disableNext: currentPage * rowsPerPage >= totalRows,
  };

  return (
    <Container>
      <DataTable
        data={courseAnalytics}
        responsive
        noDataComponent={<Empty intlId="analytics_empty_text" />}
        columns={columns}
        customStyles={customStyles}
        sortIcon={<BiChevronUp style={{ display: "flex" }} />}
        paginationComponentOptions={paginationOptions}
        persistTableHead
        pagination
        paginationServer
        paginationPerPage={rowsPerPage}
        onChangePage={(page) => setCurrentPage(page)}
        onChangeRowsPerPage={(size) => {
          setCurrentPage(1);
          setRowsPerPage(size);
        }}
        paginationTotalRows={totalRows}
        onSort={(column, sortDirection) => {
          setSortBy(column.id);
          setSortDirection(sortDirection);
        }}
      />
    </Container>
  );
}
