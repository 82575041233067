import {
  ADMIN_GET_ALL_BANNERS,
  ADMIN_GET_ALL_BANNERS_SUCCESS,
  CHANGE_VISIBILITY_COURSE,
  CREATE_BANNERS,
  CREATE_BANNERS_SUCCESS,
  DELETE_BANNER,
  DELETE_BANNER_SUCCESS,
  EDIT_BANNER,
  EDIT_BANNER_SUCCESS,
  FILTER_EVENT_LIST_BY_NAME,
  FILTER_EVENT_LIST_BY_NAME_SUCCESS,
  FILTER_EVENT_LIST_BY_YEAR,
  FILTER_EVENT_LIST_BY_YEAR_SUCCESS,
  GET_BANNER_COURSE_LIST,
  GET_BANNER_COURSE_LIST_SUCCESS,
  GET_EVENT_LIST,
  GET_EVENT_LIST_SUCCESS,
  GET_PREVIEW_COURSE_LIST,
  GET_PREVIEW_COURSE_LIST_SUCCESS,
  REORDER_BANNER_COURSE_LIST,
  SET_BANNER,
  SET_BANNER_SUCCESS,
  SET_COURSES,
  SET_COURSES_SUCCESS,
  SET_EMPTY_BANNER,
  SET_EMPTY_COURSE,
} from "./types";

export const getEventList = () => ({
  type: GET_EVENT_LIST,
});

export const getEventListSuccess = (data) => ({
  type: GET_EVENT_LIST_SUCCESS,
  payload: data,
});

export const getPreviewCourseList = (params) => ({
  type: GET_PREVIEW_COURSE_LIST,
  payload: params,
});

export const getPreviewCourseListSuccess = (data) => ({
  type: GET_PREVIEW_COURSE_LIST_SUCCESS,
  payload: data,
});

export const filterEventListByName = (params) => ({
  type: FILTER_EVENT_LIST_BY_NAME,
  payload: params,
});

export const filterEventListByNameSuccess = (data) => ({
  type: FILTER_EVENT_LIST_BY_NAME_SUCCESS,
  payload: data,
});

export const filterEventListByYear = (params) => ({
  type: FILTER_EVENT_LIST_BY_YEAR,
  payload: params,
});

export const filterEventListByYearSuccess = (data) => ({
  type: FILTER_EVENT_LIST_BY_YEAR_SUCCESS,
  payload: data,
});

export const getBannerCouseList = (params) => ({
  type: GET_BANNER_COURSE_LIST,
  payload: params,
});

export const getBannerCouseListSuccess = (data) => ({
  type: GET_BANNER_COURSE_LIST_SUCCESS,
  payload: data,
});

export const reorderBannerCourseList = (params) => ({
  type: REORDER_BANNER_COURSE_LIST,
  payload: params,
});

export const createBanners = (params) => ({
  type: CREATE_BANNERS,
  payload: params,
});

export const createBannersSuccess = (data) => ({
  type: CREATE_BANNERS_SUCCESS,
  payload: data,
});

export const setBanner = ({ eventId = null }) => ({
  type: SET_BANNER,
  payload: { eventId },
});

export const setBannerSuccess = (data) => ({
  type: SET_BANNER_SUCCESS,
  payload: data,
});

export const setBannerFailure = (error) => ({
  type: "SET_BANNER_FAILURE",
  payload: error,
});

export const editBanner = (params) => ({
  type: EDIT_BANNER,
  payload: params,
});

export const editBannerSuccess = (params) => ({
  type: EDIT_BANNER_SUCCESS,
  payload: params,
});

export const deleteBanner = (params) => ({
  type: DELETE_BANNER,
  payload: params,
});

export const deleteBannerSuccess = (data) => ({
  type: DELETE_BANNER_SUCCESS,
  payload: data,
});

export const setEmptyBanner = (isEmpty) => {
  return {
    type: SET_EMPTY_BANNER,
    payload: {
      isEmpty,
    },
  };
};

export const setCourses = ({ eventId = null }) => ({
  type: SET_COURSES,
  payload: { eventId },
});

export const setCoursesSuccess = (params) => ({
  type: SET_COURSES_SUCCESS,
  payload: params,
});

export const setCoursesFailure = () => ({
  type: "SET_COURSES_FAILURE",
});

export const setEmptyCourse = (isEmpty) => {
  return {
    type: SET_EMPTY_COURSE,
    payload: {
      isEmpty,
    },
  };
};

export const changeVisibilityCourse = (params) => ({
  type: CHANGE_VISIBILITY_COURSE,
  payload: params,
});

export function getInformaAcademyEventBanner(params) {
  return {
    type: "GET_INFORMA_ACADEMY_EVENT_BANNER",
    payload: {
      params,
    },
  };
}

export function getInformaAcademyCourseInfo(
  params,
  callbackSuccess,
  callbackError
) {
  return {
    type: "GET_INFORMA_ACADEMY_COURSE_INFO",
    payload: {
      params,
      callbackSuccess,
      callbackError,
    },
  };
}

export function getInformaAcademyLessonInfo(
  params,
  callbackSuccess,
  callbackError
) {
  return {
    type: "GET_INFORMA_ACADEMY_LESSON_INFO",
    payload: {
      params,
      callbackSuccess,
      callbackError,
    },
  };
}
export function getCourseInfoSuccess(data) {
  return {
    type: "GET_COURSE_INFO_SUCCESS",
    payload: data,
  };
}

export function getCourseInfoFailure(error) {
  return {
    type: "GET_COURSE_INFO_FAILURE",
    payload: error,
  };
}

export function getLessonInfoSuccess(data) {
  return {
    type: "GET_LESSON_INFO_SUCCESS",
    payload: data,
  };
}

export function getLessonInfoFailure(error) {
  return {
    type: "GET_LESSON_INFO_FAILURE",
    payload: error,
  };
}

export function markLessonCompleted(params) {
  return {
    type: "MARK_LESSON_COMPLETED",
    payload: params,
  };
}

export function markLessonCompletedSuccess(lessonId) {
  return {
    type: "MARK_LESSON_COMPLETED_SUCCESS",
    payload: lessonId,
  };
}

export function startCourse(params) {
  return {
    type: "START_COURSE",
    payload: params,
  };
}

export function startCourseSuccess() {
  return {
    type: "START_COURSE_SUCCESS",
  };
}

export function completeCourse(params) {
  return {
    type: "COMPLETE_COURSE",
    payload: params,
  };
}

export function completeCourseSuccess() {
  return {
    type: "COMPLETE_COURSE_SUCCESS",
  };
}

export function restartCourse(params) {
  return {
    type: "RESTART_COURSE",
    payload: params,
  };
}

export function restartCourseSuccess() {
  return {
    type: "RESTART_COURSE_SUCCESS",
  };
}

export function courseImpression(params) {
  return {
    type: "COURSE_IMPRESSION",
    payload: params,
  };
}

export function courseImpressionSuccess() {
  return {
    type: "COURSE_IMPRESSION_SUCCESS",
  };
}

export function lessonLike(params) {
  return {
    type: "LESSON_LIKE",
    payload: params,
  };
}

export function lessonLikeSuccess(params) {
  return {
    type: "LESSON_LIKE_SUCCESS",
    payload: params,
  };
}

export function lessonLikeFailure(params) {
  return {
    type: "LESSON_LIKE_FAILURE",
    payload: params,
  };
}

export function lessonUnlike(params) {
  return {
    type: "LESSON_UNLIKE",
    payload: params,
  };
}

export function lessonUnlikeSuccess() {
  return {
    type: "LESSON_UNLIKE_SUCCESS",
  };
}

export function lessonInteraction(params) {
  return {
    type: "LESSON_INTERACTION",
    payload: params,
  };
}

export function lessonInteractionSuccess() {
  return {
    type: "LESSON_INTERACTION_SUCCESS",
  };
}

export function getAdminCourseInfo(params) {
  return {
    type: "GET_ADMIN_COURSE_INFO",
    payload: params,
  };
}

export function getAdminCourseInfoSuccess(data) {
  return {
    type: "GET_ADMIN_COURSE_INFO_SUCCESS",
    payload: data,
  };
}

export function getAdminCourseInfoFailure() {
  return {
    type: "GET_ADMIN_COURSE_INFO_FAILURE",
  };
}

export function getAdminLessonsInfo(params) {
  return {
    type: "GET_ADMIN_LESSONS_INFO",
    payload: params,
  };
}

export function getAdminLessonsInfoSuccess(data) {
  return {
    type: "GET_ADMIN_LESSONS_INFO_SUCCESS",
    payload: data,
  };
}

export function getAdminLessonsInfoFailure(data) {
  return {
    type: "GET_ADMIN_LESSONS_INFO_FAILURE",
    payload: data,
  };
}

export function getAdminLatestCourses(params) {
  return {
    type: "GET_ADMIN_LASTEST_COURSES",
    payload: params,
  };
}

export function getAdminLatestCoursesSuccess(data) {
  return {
    type: "GET_ADMIN_LASTEST_COURSES_SUCCESS",
    payload: data,
  };
}

export function getAdminLatestCoursesFailure(data) {
  return {
    type: "GET_ADMIN_LASTEST_COURSES_FAILURE",
    payload: data,
  };
}

export const adminGetAllBannerEdit = (params) => ({
  type: ADMIN_GET_ALL_BANNERS,
  payload: params,
});

export const adminGetAllBannerEditSuccess = (data) => ({
  type: ADMIN_GET_ALL_BANNERS_SUCCESS,
  payload: data,
});

export const allowAccessInformaAcademy = () => ({
  type: "ALLOW_ACCESS",
});

export function dontAllowAcessInformaAcademy() {
  return {
    type: "DONT_ALLOW_ACCESS",
  };
}
export const resetAdminCourseState = () => ({
  type: "RESET_ADMIN_COURSE",
});
