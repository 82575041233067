import styled from "styled-components";

export const EventManualHomepageContainer = styled.section`
  width: 100%;
  margin: 0 auto;
  margin-top: 64px;
`;

export const ButtonContainer = styled.div`
  width: fit-content;
  margin: 0 auto;
  display: flex;
  gap: 1rem;
`;
