import { all, takeLatest, call, select, put } from "redux-saga/effects";
import {
  bookmarkManualArticleService,
  getEventManualTopicList,
  getFAQ_HighlightService,
  printEventManualArticleService,
  printEventManualChapterService,
  searchEventManualTopic,
  viewedManualArticleService,
} from "../../../service/eventManual.service";
import {
  getManualFaq_HighlightFailure,
  getManualFaq_HighlightSuccess,
  getManualTopicListFailure,
  getManualTopicListSuccess,
  printArticleSuccess,
  printChapterSuccess,
  searchManualTopicListSuccess,
  sidebarChapterInfoSuccess,
} from "./action";
import {
  BOOKMARK_MANUAL_ARTICLE,
  GET_MANUAL_LIST,
  PRINT_MANUAL_ARTICLE,
  PRINT_MANUAL_CHAPTER,
  SEARCH_MANUAL_LIST,
  SIDEBAR_CHAPTER_INFO,
  VIEWED_MANUAL_ARTICLE,
} from "./types";
import { addMostAccessedFlag } from "../../../utils/mostVisitedArticle";

function* getEventManualTopics() {
  const selectedToken = (state) => state.auth.token;
  const selectEvent = (state) => state.initialSettings.event?.value;
  const selectLanguage = (state) => state.language.code;

  try {
    const token = yield select(selectedToken);
    const eventId = yield select(selectEvent);
    const language = yield select(selectLanguage);

    if (!eventId) return;

    const params = {
      token,
      eventId,
      language,
    };

    const response = yield call(getEventManualTopicList, params);

    if (response.status === 200) {
      yield put(getManualTopicListSuccess(response.data));
    }
  } catch (error) {
    yield put(getManualTopicListFailure(error));
  }
}

function* getEventManualFAQ_highlighted() {
  const selectedToken = (state) => state.auth.token;
  const selectEvent = (state) => state.initialSettings.event?.value;
  const selectLanguage = (state) => state.language.code;

  try {
    const token = yield select(selectedToken);
    const eventId = yield select(selectEvent);
    const language = yield select(selectLanguage);

    if (!eventId) return;

    const params = {
      token,
      eventId,
      language,
    };

    const response = yield call(getFAQ_HighlightService, params);

    if (response.status !== 200) throw new Error("Failed to fetch data");

    const { data } = response;
    const { highlightedArticles, faqs } = data;

    yield put(
      getManualFaq_HighlightSuccess({
        faqs,
        recentArticles: highlightedArticles.recentArticles,
        savedArticles: highlightedArticles.savedArticles,
      })
    );
  } catch (error) {
    yield put(getManualFaq_HighlightFailure(error));
  }
}

function* searchEventManualTopics({ payload }) {
  const selectedToken = (state) => state.auth.token;
  const selectEvent = (state) => state.initialSettings.event?.value;
  const selectLanguage = (state) => state.language.code;

  try {
    const token = yield select(selectedToken);
    const eventId = yield select(selectEvent);
    const language = yield select(selectLanguage);
    const { userSearch, category } = payload;

    if (userSearch === "") {
      return searchManualTopicListSuccess(null);
    }

    const response = yield call(searchEventManualTopic, {
      token,
      eventId,
      userSearch,
      category,
      language,
    });

    if (response.status === 200) {
      const { data } = response;
      const array = addMostAccessedFlag(data);
      const arraySorted = array.sort((a, b) => b.accessCount - a.accessCount);

      yield put(searchManualTopicListSuccess(arraySorted));
    }
  } catch (error) {
    console.error("Erro ao buscar os artigos do manual: " + error);
  }
}

function* printEventManualChapter({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);
    const { chapterId } = payload;

    if (!chapterId) return;

    const params = {
      chapterId,
      token,
    };

    const response = yield call(printEventManualChapterService, params);

    if (response.status === 200) {
      yield put(printChapterSuccess(response.data.chapterWithArticlesToPrint));
    }
  } catch (error) {
    console.error("Erro ao tentar imprimir um capítulo: " + error);
  }
}

function* printEventManualArticle({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);
    const { articleId } = payload;

    if (!articleId) return;

    const params = {
      articleId,
      token,
    };

    const response = yield call(printEventManualArticleService, params);

    if (response.status === 200) {
      yield put(printArticleSuccess(response.data.articleAdmViewDetailsDTO));
    }
  } catch (error) {
    console.error("Erro ao tentar imprimir um artigo: " + error);
  }
}

function* sidebarChapterEventManual({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);
    const { chapterId } = payload;

    if (!chapterId) return;

    const params = {
      chapterId,
      token,
    };

    const response = yield call(printEventManualChapterService, params);

    if (response.status === 200) {
      yield put(
        sidebarChapterInfoSuccess(response.data.chapterWithArticlesToPrint)
      );
    }
  } catch (error) {
    console.error("Erro ao tentar carregar o capítulo: " + error);
  }
}

function* bookmarkManualArticle({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);
    const { articleId } = payload;

    if (!articleId) return;

    const params = {
      articleId,
      token,
    };

    const response = yield call(bookmarkManualArticleService, params);

    if (response.status !== 200) throw new Error("erro");
  } catch (error) {
    console.error("Erro ao tentar carregar o capítulo: " + error);
  }
}

function* viewedManualArticle({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);
    const { articleId } = payload;

    if (!articleId) return;

    const params = {
      articleId,
      token,
    };

    const response = yield call(viewedManualArticleService, params);

    if (response.status !== 200) throw new Error("erro");
  } catch (error) {
    console.error("Erro ao tentar carregar o capítulo: " + error);
  }
}

export default all([
  takeLatest(GET_MANUAL_LIST, getEventManualTopics),
  takeLatest("GET_MANUAL_FAQ_HIGHLIGHT", getEventManualFAQ_highlighted),
  takeLatest(SEARCH_MANUAL_LIST, searchEventManualTopics),
  takeLatest(PRINT_MANUAL_CHAPTER, printEventManualChapter),
  takeLatest(PRINT_MANUAL_ARTICLE, printEventManualArticle),
  takeLatest(SIDEBAR_CHAPTER_INFO, sidebarChapterEventManual),
  takeLatest(BOOKMARK_MANUAL_ARTICLE, bookmarkManualArticle),
  takeLatest(VIEWED_MANUAL_ARTICLE, viewedManualArticle),
]);
