import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: stretch;
  background-color: #dadada;
  padding: 88px 16px 88px 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimatedContainer = styled.div`
  display: flex;
  flex-direction: column;
  animation: ${appearFromLeft} 1s;
  width: 100%;
`;

export const RouteBreadcrumbs = styled.p`
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 600;
  color: #002244;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GoBack = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #002244;
  line-height: 22px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  transition: all 300ms;

  &:hover {
    border-color: #002244;
    & > svg {
      transform: translateX(-2px);
    }
  }
  & > p {
    margin: 0;
  }
  & > svg {
    transition: all 300ms;
  }
`;
