import { all, takeLatest, call, select, put } from "redux-saga/effects";

import {
  createInformaAcademyBannerService,
  getInformaAcademyBannerCourseListService,
  getInformaAcademyFilterEventByNameService,
  getInformaAcademyFilterEventByYearService,
  reorderInformaAcademyBannerCourseListService,
  completeCourseService,
  getInformaAcademyBannerService,
  getInformaAcademyCourseService,
  getInformaAcademyLessonService,
  markLessonCompletedService,
  getInformaAcademyCourseListService,
  changeVisibilityInformaAcademyBannerCourseListService,
  getInformaAcademyPreviewCourseListService,
  editInformaAcademyBannerService,
  restartCourseService,
  startCourseService,
  deleteInformaAcademyBannerService,
  getAdminCourseInfoService,
  getAdminLessonsInfoService,
  getAdminLastestCoursesService,
  getInformaAcademyEventService,
  impressionCourseService,
  interactionLessonService,
  likeLessonService,
  unlikeLessonService,
  getAdminAllBannerEditService,
} from "../../../service/informaAcademy.service";

import {
  ADMIN_GET_ALL_BANNERS,
  CHANGE_VISIBILITY_COURSE,
  CREATE_BANNERS,
  DELETE_BANNER,
  EDIT_BANNER,
  FILTER_EVENT_LIST_BY_NAME,
  FILTER_EVENT_LIST_BY_YEAR,
  GET_BANNER_COURSE_LIST,
  GET_EVENT_LIST,
  GET_PREVIEW_COURSE_LIST,
  REORDER_BANNER_COURSE_LIST,
  SET_BANNER,
  SET_COURSES,
} from "./types";

import {
  createBannersSuccess,
  filterEventListByNameSuccess,
  filterEventListByYearSuccess,
  getBannerCouseListSuccess,
  getEventListSuccess,
  completeCourseSuccess,
  getAdminCourseInfoFailure,
  getAdminCourseInfoSuccess,
  getAdminLessonsInfoFailure,
  getAdminLessonsInfoSuccess,
  courseImpressionSuccess,
  getCourseInfoFailure,
  getCourseInfoSuccess,
  getLessonInfoFailure,
  getLessonInfoSuccess,
  lessonInteractionSuccess,
  lessonLikeSuccess,
  lessonUnlikeSuccess,
  markLessonCompletedSuccess,
  setBannerSuccess,
  setCoursesSuccess,
  getPreviewCourseListSuccess,
  editBannerSuccess,
  restartCourseSuccess,
  startCourseSuccess,
  deleteBannerSuccess,
  getAdminLatestCoursesSuccess,
  getAdminLatestCoursesFailure,
  lessonLikeFailure,
  adminGetAllBannerEditSuccess,
  allowAccessInformaAcademy,
  dontAllowAcessInformaAcademy,
  setCoursesFailure,
  setBannerFailure,
} from "./actions";
import { MARLABS_DEFAULT } from "../../../components/ControlledInputs/dropzone";

function* getInformaAcademyEventList({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);

    const response = yield call(getInformaAcademyEventService, {
      token,
    });

    if (response.status === 200) {
      yield put(getEventListSuccess(response.data.events));
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* filterEventListByName({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);

    const params = {
      token,
      eventName: payload.eventName,
    };

    const response = yield call(
      getInformaAcademyFilterEventByNameService,
      params
    );

    if (response.status === 200) {
      yield put(filterEventListByNameSuccess(response.data.events));
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* filterEventListByYear({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);

    const params = {
      token,
      eventYear: payload.eventYear,
    };

    const response = yield call(
      getInformaAcademyFilterEventByYearService,
      params
    );

    if (response.status === 200) {
      yield put(filterEventListByYearSuccess(response.data.events));
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* getInformaAcademyBannerCouseList({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);

    const response = yield call(getInformaAcademyBannerCourseListService, {
      token,
      eventId: payload.eventId,
    });

    if (response.status === 200) {
      yield put(getBannerCouseListSuccess(response.data));
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* reorderBannerCourseList({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);

    const params = {
      token,
      reorderCourse: payload,
    };

    yield call(reorderInformaAcademyBannerCourseListService, params);

    const response = yield call(getInformaAcademyBannerCourseListService, {
      token,
      eventId: payload.eventId,
    });

    if (response.status === 200) {
      yield put(getBannerCouseListSuccess(response.data));
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* changeVisibilityCourse({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);

    const params = {
      token,
      courseId: payload.courseId,
    };

    yield call(changeVisibilityInformaAcademyBannerCourseListService, params);

    const response = yield call(getInformaAcademyBannerCourseListService, {
      token,
      eventId: payload.eventId,
    });

    if (response.status === 200) {
      yield put(getBannerCouseListSuccess(response.data));
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* createBanner({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);

    const params = {
      token,
      banners: payload,
    };

    const response = yield call(createInformaAcademyBannerService, params);

    if (response.status === 201) {
      yield put(createBannersSuccess(true));
    }
  } catch (error) {
    yield put(createBannersSuccess(false));
  }
}

function* editBanner({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);

    const params = {
      token,
      homePageId: payload.homePageId,
      banners: payload.banners,
    };

    const response = yield call(editInformaAcademyBannerService, params);

    if (response.status === 200) {
      yield put(editBannerSuccess(true));
    }
  } catch (error) {
    yield put(editBannerSuccess(false));
  }
}

function* deleteBanner({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);

    const params = {
      token,
      bannerId: payload.bannerId,
    };

    const response = yield call(deleteInformaAcademyBannerService, params);

    if (response.status === 200) {
      yield put(deleteBannerSuccess(true));
    }
  } catch (error) {
    yield put(deleteBannerSuccess(false));
  }
}

function* getInformaAcademyEventBanner({ payload }) {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);
    const responseBanner = yield call(getInformaAcademyBannerService, {
      eventId: payload.params.eventId,
      token,
    });

    const responseCourse = yield call(getInformaAcademyCourseListService, {
      eventId: payload.params.eventId,
      token,
    });

    const statusBanner = responseBanner.status;
    const statusCourse = responseCourse.status;

    if (statusBanner !== 200 || statusCourse !== 200) throw new Error("erro");

    yield put(allowAccessInformaAcademy(true));
  } catch (error) {
    yield put(dontAllowAcessInformaAcademy(false));
  }
}

function* getInformaAcademyListBanner({ payload }) {
  const selectToken = (state) => state.auth.token;
  const selectEvent = (state) => state.initialSettings.event?.value;

  try {
    const token = yield select(selectToken);
    const eventId = yield select(selectEvent);

    const params = {
      eventId: payload?.eventId ?? eventId,
      token,
    };

    const response = yield call(getInformaAcademyBannerService, params);

    const { banners } = response.data;

    yield put(setBannerSuccess(banners));
  } catch (error) {
    yield put(setBannerFailure(error));
  }
}

function* getInformaAcademyEventCourse({ payload }) {
  const selectToken = (state) => state.auth.token;
  const selectEvent = (state) => state.initialSettings.event?.value;

  try {
    const token = yield select(selectToken);
    const eventId = yield select(selectEvent);

    const params = {
      eventId: payload?.eventId ?? eventId,
      token,
    };

    const response = yield call(getInformaAcademyCourseListService, params);

    const { ongoingCourse, courses } = response.data;

    yield put(setCoursesSuccess({ ongoingCourse, courses }));
  } catch (error) {
    yield put(setCoursesFailure());
  }
}

function* getInformaAcademyPreviewCourse({ payload }) {
  const selectToken = (state) => state.auth.token;

  try {
    const { eventId } = payload;
    const token = yield select(selectToken);

    if (!eventId) return;

    const params = {
      eventId,
      token,
    };

    const response = yield call(
      getInformaAcademyPreviewCourseListService,
      params
    );

    const courses = response.data.courses.map(
      ({ courseOrder, visible, ...rest }, index) => ({
        courseOrder: visible && courseOrder ? courseOrder : 999 + index,
        visible,
        ...rest,
      })
    );

    yield put(getPreviewCourseListSuccess(courses));
  } catch (error) {
    console.error("Erro ao buscar cursos:", error);
  }
}

function* getInformaAcademyCourseInfo({ payload }) {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);
    const response = yield call(getInformaAcademyCourseService, {
      courseId: payload.params.courseId,
      token,
    });

    const { data: course, status } = response;

    if (status === 400) throw new Error("Failed to load courses details");

    yield put(getCourseInfoSuccess(course));
  } catch (error) {
    yield put(getCourseInfoFailure(error.response?.data?.code || true));
  }
}

function* getInformaAcademyLessonInfo({ payload }) {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);
    const response = yield call(getInformaAcademyLessonService, {
      lessonId: payload.params.lessonId,
      courseId: payload.params.courseId,
      token,
    });

    const { data } = response;

    if (response.status === 200) {
      const { lessonDetails: lesson } = data;
      yield put(getLessonInfoSuccess(lesson));
    }
  } catch (error) {
    yield put(getLessonInfoFailure(error.message));
  }
}

function* markLessonCompleted({ payload }) {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);

    const response = yield call(markLessonCompletedService, {
      courseId: payload.courseId,
      lessonId: payload.lessonId,
      token,
    });

    const { data } = response;
    if (!data.error) {
      yield put(markLessonCompletedSuccess(payload.lessonId));
    }
  } catch (error) {
    console.error("Erro ao completar a aula:", error);
  }
}

function* startCourse({ payload }) {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);

    const response = yield call(startCourseService, {
      courseId: payload.courseId,
      token,
    });

    const { data } = response;
    if (!data.error) {
      yield put(startCourseSuccess());
    }
  } catch (error) {
    console.error("Erro ao inciar curso:", error);
  }
}

function* completeCourse({ payload }) {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);

    const response = yield call(completeCourseService, {
      courseId: payload.courseId,
      token,
    });

    const { data } = response;
    if (!data.error) {
      yield put(completeCourseSuccess());
    }
  } catch (error) {
    console.error("Erro ao completar o curso:", error);
  }
}

function* restartCourse({ payload }) {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);

    const response = yield call(restartCourseService, {
      courseId: payload.courseId,
      token,
    });

    const { data } = response;
    if (!data.error) {
      yield put(restartCourseSuccess());
    }
  } catch (error) {
    console.error("Erro ao reiniciar curso:", error);
  }
}

function* likeLesson({ payload }) {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);

    const response = yield call(likeLessonService, {
      courseId: payload.courseId,
      lessonId: payload.lessonId,
      token,
    });

    yield put(lessonLikeSuccess(response));
  } catch (error) {
    yield put(lessonLikeFailure(error));
  }
}

function* unlikeLesson({ payload }) {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);

    const response = yield call(unlikeLessonService, {
      courseId: payload.courseId,
      lessonId: payload.lessonId,
      token,
    });

    yield put(lessonUnlikeSuccess(response));
  } catch (error) {
    console.error("Erro ao desrecomendar aula:", error);
  }
}

function* interactLesson({ payload }) {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);

    const response = yield call(interactionLessonService, {
      courseId: payload.courseId,
      lessonId: payload.lessonId,
      token,
    });

    const { data } = response;
    if (!data.error) {
      yield put(lessonInteractionSuccess());
    }
  } catch (error) {
    console.error("Erro ao desrecomendar aula:", error);
  }
}

function* impressionCourse({ payload }) {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);

    const response = yield call(impressionCourseService, {
      courseId: payload.courseId,
      token,
    });

    const { data } = response;
    if (!data.error) {
      yield put(courseImpressionSuccess());
    }
  } catch (error) {
    console.error("Erro ao desrecomendar aula:", error);
  }
}

function* getAdminCourseInfo({ payload }) {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);
    const response = yield call(getAdminCourseInfoService, {
      courseId: payload.courseId,
      token,
    });

    const { data } = response;

    let coverImageFile = data.coverImageUrl;
    if (data.coverImageUrl) {
      try {
        // Fetch the actual file data
        const coverImageResponse = yield call(
          fetch,
          process.env.NODE_ENV === `development`
            ? MARLABS_DEFAULT
            : data.coverImageUrl.url
        );
        const coverImageBlob = yield call([coverImageResponse, "blob"]);
        coverImageFile = new File(
          [coverImageBlob],
          data.coverImageUrl.name || "cover-image",
          { type: coverImageBlob.type }
        );
      } catch (fetchError) {
        console.error("Error fetching cover image:", fetchError);

        // Fallback to creating a mock File object
        const emptyBlob = new Blob([""], { type: "application/octet-stream" });
        coverImageFile = new File(
          [emptyBlob],
          data.coverImageUrl.name || "cover-image",
          {
            type: "application/octet-stream",
            lastModified: new Date().getTime(),
          }
        );
        // Add the original metadata
        coverImageFile.url = data.coverImageUrl.url;
        coverImageFile.size = data.coverImageUrl.size;
      }
    }

    const course = {
      ...data,
      coverImage: coverImageFile,
    };

    delete course.coverImageUrl;

    if (response.status === 200) {
      yield put(getAdminCourseInfoSuccess(course));
    }
  } catch (error) {
    yield put(getAdminCourseInfoFailure(error));
  }
}

function* getAdminLessonInfo({ payload }) {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);
    const response = yield call(getAdminLessonsInfoService, {
      courseId: payload.courseId,
      token,
    });

    const { data: lessons } = response;
    if (response.status !== 200) throw new Error();

    const parsedLessons = [];

    for (const lesson of lessons.lesson) {
      // Handle coverImage with improved error handling
      let coverImageFile = undefined;
      if (lesson?.coverImage?.url) {
        try {
          const coverImageResponse = yield call(
            fetch,
            process.env.NODE_ENV === `development`
              ? MARLABS_DEFAULT
              : lesson.coverImage.url
          );

          // Check if the response is ok (status 200-299)
          if (coverImageResponse.ok) {
            const coverImageBlob = yield call([coverImageResponse, "blob"]);
            coverImageFile = new File(
              [coverImageBlob],
              lesson.coverImage.name || "cover-image",
              { type: coverImageBlob.type }
            );
          } else {
            coverImageFile = undefined;
          }
        } catch (coverImageError) {
          console.error("Error fetching cover image:", coverImageError);
          // Log the specific error details
          if (coverImageError.message.includes("NoSuchKey")) {
            console.error(
              "S3 NoSuchKey Error: The specified key does not exist"
            );
          }
          coverImageFile = undefined;
        }
      }

      const filesArray = [];
      if (lesson.file && lesson.file.length > 0) {
        for (const file of lesson.file) {
          if (file.url) {
            try {
              const fileResponse = yield call(fetch, file.url);
              const fileBlob = yield call([fileResponse, "blob"]);
              filesArray.push(
                new File([fileBlob], file.name || "file", {
                  type: fileBlob.type,
                })
              );
            } catch (fileError) {
              console.error("Error fetching file:", fileError);
              // Optionally, you could choose to skip this file or handle it differently
            }
          } else {
            filesArray.push(file);
          }
        }
      }

      // Create modified lesson object
      const L = {
        ...lesson,
        test: lesson.coverImage.url,
        coverImage: coverImageFile, // Now set to undefined if fetch fails
        files: filesArray,
      };
      delete L.file;
      parsedLessons.push(L);
    }

    const sortedLessons = parsedLessons.sort((a, b) => a.orders - b.orders);
    yield put(getAdminLessonsInfoSuccess(sortedLessons));
  } catch (error) {
    yield put(getAdminLessonsInfoFailure(error));
  }
}
function* getAdminLatestCourses() {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);
    const response = yield call(getAdminLastestCoursesService, {
      token,
    });

    const { data } = response;
    const { courses } = data;
    if (response.status !== 200) throw new Error("error");

    yield put(getAdminLatestCoursesSuccess(courses));
  } catch (error) {
    if (error.response.data.description === "No courses found") {
      return yield put(getAdminLatestCoursesSuccess([]));
    }
    yield put(getAdminLatestCoursesFailure(error));
  }
}

function* getAdminAllBannerEdit({ payload }) {
  const selectToken = (state) => state.auth.token;

  try {
    const token = yield select(selectToken);

    const params = {
      eventId: payload.eventId,
      token,
    };

    const response = yield call(getAdminAllBannerEditService, {
      params,
    });

    const { banners } = response.data;

    const parsedBanners = [];
    for (const banner of banners) {
      let bannerImagefile = banner.bannerImage;
      if (banner?.bannerImage?.url) {
        const coverImageResponse = yield call(
          fetch,
          process.env.NODE_ENV === `development`
            ? MARLABS_DEFAULT
            : banner.bannerImage.url
        );
        const coverImageBlob = yield call([coverImageResponse, "blob"]);
        bannerImagefile = new File(
          [coverImageBlob],
          banner.bannerImage.name || "cover-image",
          { type: coverImageBlob.type }
        );
      }

      const B = {
        ...banner,
        bannerImage: bannerImagefile,
      };

      parsedBanners.push(B);
    }

    if (response.status !== 200) throw new Error();

    yield put(adminGetAllBannerEditSuccess(parsedBanners));
  } catch (error) {
    yield put(getAdminLatestCoursesFailure(true));
  }
}

export default all([
  takeLatest(GET_EVENT_LIST, getInformaAcademyEventList),
  takeLatest(FILTER_EVENT_LIST_BY_NAME, filterEventListByName),
  takeLatest(FILTER_EVENT_LIST_BY_YEAR, filterEventListByYear),
  takeLatest(GET_BANNER_COURSE_LIST, getInformaAcademyBannerCouseList),
  takeLatest(REORDER_BANNER_COURSE_LIST, reorderBannerCourseList),
  takeLatest(CREATE_BANNERS, createBanner),
  takeLatest(EDIT_BANNER, editBanner),
  takeLatest(DELETE_BANNER, deleteBanner),
  takeLatest(SET_COURSES, getInformaAcademyEventCourse),
  takeLatest(SET_BANNER, getInformaAcademyListBanner),
  takeLatest(CHANGE_VISIBILITY_COURSE, changeVisibilityCourse),
  takeLatest(GET_PREVIEW_COURSE_LIST, getInformaAcademyPreviewCourse),
  takeLatest("GET_INFORMA_ACADEMY_EVENT_BANNER", getInformaAcademyEventBanner),
  takeLatest("GET_INFORMA_ACADEMY_COURSE_INFO", getInformaAcademyCourseInfo),
  takeLatest("GET_INFORMA_ACADEMY_LESSON_INFO", getInformaAcademyLessonInfo),
  takeLatest("MARK_LESSON_COMPLETED", markLessonCompleted),
  takeLatest("START_COURSE", startCourse),
  takeLatest("COMPLETE_COURSE", completeCourse),
  takeLatest("RESTART_COURSE", restartCourse),
  takeLatest("GET_ADMIN_COURSE_INFO", getAdminCourseInfo),
  takeLatest("GET_ADMIN_LESSONS_INFO", getAdminLessonInfo),
  takeLatest("GET_ADMIN_LASTEST_COURSES", getAdminLatestCourses),
  takeLatest("LESSON_LIKE", likeLesson),
  takeLatest("LESSON_UNLIKE", unlikeLesson),
  takeLatest("LESSON_INTERACTION", interactLesson),
  takeLatest("COURSE_IMPRESSION", impressionCourse),
  takeLatest(ADMIN_GET_ALL_BANNERS, getAdminAllBannerEdit),
]);
