import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #fff;
`;

export const TableContainer = styled.div`
  width: 100%;
  background-color: #fff;
  margin-top: 32px;
`;

export const WrapperContainer = styled.div`
  display: grid;
  gap: 24px;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0px;
  line-height: 32.68px;
  color: #3a3a3c;
  font-family: "Open Sans";
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 21px;
  padding-left: 8px;

  & .ant-segmented {
    background-color: #dde5e9;
    border-radius: 4px;
    padding: 1px;
  }
  & .ant-segmented .ant-segmented-item {
    width: 112px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .ant-segmented .ant-segmented-item:last-child {
    border-radius: 0px 4px 4px 0px;
  }

  & .ant-segmented .ant-segmented-item:first-child {
    border-radius: 4px 0px 0px 4px;
  }

  & .ant-segmented .ant-segmented-item .ant-segmented-item-label {
    color: #6c6c6c;
  }

  &
    .ant-segmented
    .ant-segmented-item.ant-segmented-item-selected
    .ant-segmented-item-label {
    color: #3a3a3c;
  }
`;

export const SegmentLabel = styled.p`
  margin-bottom: 0px;
  size: 18px;
  line-height: 27px;
  font-weight: 600;
  transition: color 200ms;
`;

export const AContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const AMainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ACardsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const ACard = styled.div`
  flex: 1;
  padding: 16px;
  border: 1px solid #ebebf0;
  border-radius: 8px;

  & > p {
    margin-bottom: 0;
    font-weight: 800;
    font-family: "Nunito", serif;
    font-size: 26px;
    line-height: 35.46px;
  }

  & > h3 {
    color: #030229;
    margin-bottom: 0;
    font-weight: 400;
    font-family: "Nunito", serif;
    font-size: 16px;
    line-height: 21.82px;
  }
`;

export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${(props) => props.bg};
  margin-bottom: 8px;
`;

export const AsideContainer = styled.aside`
  width: 270.19px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > div {
    border: 1px solid #ebebf0;
    border-radius: 8px;
    padding: 16px;
  }
`;

export const ATableContainer = styled.div`
  border: 1px solid #ebebf0;
  border-radius: 8px;
  padding: 16px;
`;

export const ChartContainer = styled.section`
  display: grid;
  gap: 45px;
`;

export const LegendContainer = styled.div`
  display: grid;
  gap: 16px;
`;

export const ChartLegend = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  & > div {
    border-radius: 999px;
    width: 16px;
    height: 16px;
    background-color: ${(props) => {
      switch (props.variant) {
        case "exhibitor":
          return "#002244";
        case "serviceProvider":
          return "#06719e";
        case "advertiser":
          return "#7fd0eb";
        case "sponsor":
          return "#33d27e";
        default:
          return "transparent";
      }
    }};
  }

  & > p {
    margin: 0px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 16.94px;
    font-weight: 400;
  }

  & > span {
    display: block;
    margin-left: auto;
  }
`;

export const Label = styled.p`
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 23.36px;
  color: #002244;
  margin-bottom: ${(props) => props.marginBottom || "0px"};
`;

export const LanguagesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align=items: center;
  margin-bottom: 24px;
`;

export const Language = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  & > img {
    border-radius: 999px;
    width: 20px;
    height: 20px;
    object-fit: cover;
  }

  & > p {
    margin: 0px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 16.94px;
    font-weight: 400;
  }

  & > span {
    display: block;
    margin-left: auto;
  }
`;

export const DataHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  & > h2 {
    font-family: "Open Sans";
    font-size: 19.12px;
    line-height: 23.36px;
    font-weight: 600;
  }
`;
