import { Select as slct } from "antd";
import styled from "styled-components";

export const Select = styled(slct)`
  .ant-select-selection-search {
    padding-right: 18px;
    left: 6px !important;
  }

  .ant-select-selection-search,
  .ant-select-selection-item {
    width: 100px;
  }


  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }

  .ant-select-selector {
    border: none !important;
    padding-right: 20px !important;
    padding-left: 6px !important;
  }
`;
