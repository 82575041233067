import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import EventManualTopicList from "./TopicList";
import EventManualSavedArticles from "./savedArticles";
import EventManualFaq from "./faq";
import { Button, ContentWrapper, Subtitle, TopicTitle } from "../styles";
import { ButtonContainer, EventManualHomepageContainer } from "./style";
import ScrollTopButton from "../../../newUXComponents/ScrollTopButton";

const DEFAULT_CARDS_NUMBER = 6;

const EventManualHomepage = ({
  topics = [],
  faqs = [],
  recentArticles = [],
  savedArticles = [],
}) => {
  const [showSaved, setShowSaved] = useState(DEFAULT_CARDS_NUMBER);
  const [showRecentAccess, setShowRecentAccess] =
    useState(DEFAULT_CARDS_NUMBER);
  const intl = useIntl();

  // const savedArticles = [];
  // const notSaved = [];

  const handleShowMoreSaved = () => {
    if (savedArticles.length > showSaved) {
      setShowSaved((prev) => prev + 6);
    }
  };

  const handleShowMoreRecentAccess = () => {
    if (recentArticles.length > showRecentAccess) {
      setShowRecentAccess((prev) => prev + 6);
    }
  };

  const handleShowLessRecentAccess = () => {
    setShowRecentAccess(DEFAULT_CARDS_NUMBER);
  };

  const handleShowLessSaved = () => {
    setShowSaved(DEFAULT_CARDS_NUMBER);
  };

  return (
    <EventManualHomepageContainer>
      <ContentWrapper>
        <Subtitle>
          {intl.formatMessage({ id: "exhibitors_manual_title" })}
        </Subtitle>
        <TopicTitle>
          {intl.formatMessage({ id: "exhibitors_manual_topic_title" })}
        </TopicTitle>

        <EventManualTopicList topics={topics} />
      </ContentWrapper>

      {savedArticles.length > 0 && (
        <ContentWrapper>
          <TopicTitle>
            {intl.formatMessage({
              id: "exhibitors_manual_saved_articles",
            })}
          </TopicTitle>

          <EventManualSavedArticles
            topics={savedArticles.slice(0, showSaved)}
          />

          <ButtonContainer>
            {savedArticles.length > showSaved && (
              <Button onClick={handleShowMoreSaved}>
                <FormattedMessage id="exhibitors_manual_view_more" />
              </Button>
            )}
            {savedArticles.length > 6 && showSaved > savedArticles.length && (
              <Button onClick={handleShowLessSaved}>
                <FormattedMessage id="exhibitors_manual_view_less" />
              </Button>
            )}
          </ButtonContainer>
        </ContentWrapper>
      )}

      {recentArticles.length > 0 && (
        <ContentWrapper>
          <TopicTitle>
            {intl.formatMessage({
              id: "exhibitors_manual_recent_visited",
            })}
          </TopicTitle>

          <EventManualSavedArticles
            topics={recentArticles.slice(0, showRecentAccess)}
          />

          <ButtonContainer>
            {recentArticles.length > showRecentAccess && (
              <Button onClick={handleShowMoreRecentAccess}>
                <FormattedMessage id="exhibitors_manual_view_more" />
              </Button>
            )}
            {recentArticles.length > 6 &&
              showRecentAccess > recentArticles.length && (
                <Button onClick={handleShowLessRecentAccess}>
                  <FormattedMessage id="exhibitors_manual_view_less" />
                </Button>
              )}
          </ButtonContainer>
        </ContentWrapper>
      )}

      {faqs.length > 0 && (
        <ContentWrapper>
          <Subtitle>
            {intl.formatMessage({
              id: "exhibitors_manual_faq_subtitle",
            })}
          </Subtitle>
          <TopicTitle>
            {intl.formatMessage({
              id: "exhibitors_manual_important_info",
            })}
          </TopicTitle>

          <EventManualFaq faqs={faqs} />
        </ContentWrapper>
      )}

      <ScrollTopButton />
    </EventManualHomepageContainer>
  );
};

export default EventManualHomepage;
