import React, { useCallback, useEffect, useState } from "react";
import ArticleTable from "./articles-table";
import { ArticleHeaderContainer, ArticleHeaderTitle } from "../../style";
import SearchInput from "../../../InformaAcademy/components/SearchInput";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { listArticles } from "../../../../redux/modules/adminExhibitorManual/actions";
import { debounce } from "lodash";
import Empty from "../../../InformaAcademy/components/Empty";

const DEFAULT_PAGE = 1;
const DEFAULT_ROWS_PER_PAGE = 10;

export default function Articles({ manualId, total }) {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [totalRows, setTotalRows] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const dispatch = useDispatch();
  const { articles, errorArticles, pageInfo } = useSelector(
    (state) => state.adminManualExhibitor
  );

  const handleSearch = useCallback((searchTerm) => {
    setSearch(searchTerm);
    setCurrentPage(DEFAULT_PAGE);
    setRowsPerPage(DEFAULT_ROWS_PER_PAGE);
    setSortBy("");
    setSortDirection("");
  }, []);

  useEffect(() => {
    dispatch(
      listArticles({
        page: currentPage - 1,
        query: search,
        size: rowsPerPage,
        sortBy,
        sortDirection,
        manualId,
      })
    );
  }, [
    currentPage,
    dispatch,
    manualId,
    rowsPerPage,
    search,
    sortBy,
    sortDirection,
  ]);

  useEffect(() => {
    if (pageInfo) {
      setTotalRows(pageInfo.totalElements);
    }
  }, [pageInfo]);

  if (errorArticles) return <ArticleError />;

  return (
    <div>
      <ArticleHeader total={total} setSearch={handleSearch} />
      <ArticleTable
        articles={articles}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalRows={totalRows}
        setSortBy={setSortBy}
        setSortDirection={setSortDirection}
      />
    </div>
  );
}

const ArticleHeader = ({ total, setSearch, disabled }) => {
  const [inputValue, setInputValue] = useState("");

  const debouncedSearch = debounce((value) => {
    setSearch(value);
  }, 300);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    debouncedSearch(value);
  };

  return (
    <ArticleHeaderContainer>
      <ArticleHeaderTitle>
        <FormattedMessage id={"articles"} /> ({total})
      </ArticleHeaderTitle>
      <SearchInput
        value={inputValue}
        onChange={handleSearchChange}
        disabled={disabled}
      />
    </ArticleHeaderContainer>
  );
};

function ArticleError() {
  return (
    <div>
      <ArticleHeader total={0} disabled />
      <Empty intlId="admin_article_error" highlightText="db_error" />
    </div>
  );
}
