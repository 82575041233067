import api from "./index";

export const getEventService = async () =>
  await api.get("/exhibitor/events/by-user");

export const getAllEventsService = async ({ token }) => {
  return await api.patch(
    `/admin/setup-event`,
    { eventSetupName: null, eventsId: null },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getAllEventsWithVersionService = async ({ token }) => {
  return await api.get("/admin/events", {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${token}`,
    },
  });
};
