import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import {
  Container,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  SessionContainer,
  SessionIconContainer,
  SessionTitle,
  Session,
  SessionDivider,
  ClearButtonContainer,
  PeriodCategoryContainer,
  PeriodCategoryTitle,
  PeriodCategoryFieldsContainer,
  CredentialsContainer,
  CredentialsTitle,
  CredentialsFieldsContainer,
  InformationEditionContainer,
  DocumentsContainer,
  DocumentsUploadContainer,
  DocumentUpload,
  DocumentDragAndDropContainer,
  DocumentDragAndDropText,
  ActionContainer,
  BadgeContainer,
  VIPCategoryContainer,
  DocumentOpenText,
  DocumentOpenImage,
  DocumentVisualization,
  DocumentAction,
  LabelEndContainer,
  EditMetricsButtonContainer,
  ModalSaveMetricsContainer,
  FileActionsContainer,
  TableContainer
} from "./styles";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import api from "../../../service";
import { Form } from "@unform/web";
import InputWithLabel from "../../../components/InputWithLabel";
import SelectWithLabel from "../../../components/SelectWithLabel";
import InputWithHorizontalLabel from "../../../components/InputWithHorizontalLabel";
import Toggle from "../../../components/Toggle";
import Modal from "../../../components/Modal";
import {
  MdInfo,
  MdTimelapse,
  MdAssignmentInd,
  MdDescription,
  MdCloudUpload,
  MdClose,
  MdLabel,
  MdImage,
} from "react-icons/md";
import { FaShoppingBasket, FaInfinity } from "react-icons/fa";
import { useToast } from "../../../hooks/toast";
import DatePickerWithLabel from "../../../components/DatePickerWithLabel";
import { isBefore, parse, format, isToday, isSameDay } from "date-fns";
import Badge from "../../../components/Badge";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { fillTimepicker } from "../../../utils/fillTimepicker";
import { isTimeBefore, isTimeAfter } from "../../../utils/timeValidation";
import { getLogoSrcFromHtml } from "../../../utils/htmlManipulation";
import { RiVipDiamondFill } from "react-icons/ri";
import FormButton from "../../../components/FormButton";
import Dropzone from "../../../components/Dropzone";
import PDFModal from "../../../components/PDFModal";
import { base64ToBlob, downloadBlob } from "../../../utils/file";
import { useLoading } from "../../../hooks/loading";
import EventFileModal from "./EventFileModal";
import Table from "../../../components/Table";

const Detail = () => {
  const { token, user } = useSelector((state) => state.auth);
  const { id } = useParams();
  const [key, setKey] = useState();

  const [vipGuestMetrics, setVipGuestMetrics] = useState([]);
  const [loading, setLoading] = useState(false);

  const intervalTimes = [
    {
      label: "5m",
      value: 5,
    },
    {
      label: "10m",
      value: 10,
    },
    {
      label: "15m",
      value: 15,
    },
    {
      label: "20m",
      value: 20,
    },
    {
      label: "30m",
      value: 30,
    },
  ];

  const timeOptions5m = fillTimepicker(5);
  const [integrationOptions, setIntegrationOptions] = useState([])
  const [savedVipGuestMetrics, setSavedVipGuestMetrics] = useState({});

  const [isMetricsModalOpen, setIsMetricsModalOpen] = useState(false);
  const [isGuestSystemInformationRequired, setIsGuestSystemInformationRequired] =
    useState(false);
  const [logoSrc, setLogoSrc] = useState();
  const [isBannerUnmodified, setIsBannerUnmodified] = useState(false);
  const [hasRemovedOldBanner, setHasRemovedOldBanner] = useState(false);
  const { handleLoading } = useLoading();

  const formRef = useRef(null);
  const metricsFormRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();

  const { addToast } = useToast();
  const [dateModalIsOpen, setDateModalIsOpen] = useState(false)
  const [activateModalIsOpen, setActivateModalIsOpen] = useState(false);
  const [deactivateModalIsOpen, setDeactivateModalIsOpen] = useState(false);
  const [exhibitorsIntegrationModalIsOpen, setExhibitorsIntegrationModalIsOpen] = useState(false);
  const [manageGuestsEnableModalIsOpen, setManageGuestsEnableModalIsOpen] =
    useState(false);
  const [vipMetricsConfirmationModalOpen, setVipMetricsConfirmationModalOpen] =
    useState(false);
  const [removeBannerModalOpen, setRemoveBannerModalOpen] = useState(false);

  const [firstCredentialSchedulingDate, setFirstCredentialSchedulingDate] = useState();
  const [lastCredentialSchedulingDate, setLastCredentialSchedulingDate] = useState();
  const [firstCredentialSchedulingTime, setFirstCredentialSchedulingTime] = useState();
  const [lastCredentialSchedulingTime, setLastCredentialSchedulingTime] = useState();
  const [originalStartDate, setOriginalStartDate] = useState();

  const [deleteFileModalIsOpen, setDeleteFileModalIsOpen] = useState(false);
  const [editEventFileModalIsOpen, setEditEventFileModalIsOpen] = useState(false);
  const [fileIdToEventFileModal, setFileIdToEventFileModal] = useState();
  const [viewEventFileModalOpen, setViewEventFileModalOpen] = useState(false);
  const [viewEventFileLocation, setViewEventFileLocation] = useState();
  const [fileIdToDelete, setFileIdToDelete] = useState();
  const [eventFileList, setEventFileList] = useState([]);

  const [data, setData] = useState({
    feira: "",
    feira_ingles: "",
    edicaoDaFeira: "",
    year: "",
    eventEditionNumber: "",
    place: "",
    pavilion: "",
    address: "",
    fair_email: "",
    video_link: "",
    swapcard_group_id: "",
    swapcard_event_id: "",
    mounting_start: "",
    mounting_end: "",
    realization_start: "",
    realization_end: "",
    unmounting_start: "",
    unmounting_end: "",
    payments_start: "",
    payments_end: "",
    payments_limit_end: "",
    appointment_configuration_start: "",
    appointment_configuration_end: "",
    appointment_configuration_opening: "",
    appointment_configuration_closure: "",
    appointment_configuration_first_day_opening: "",
    appointment_configuration_first_day_closure: "",
    guestSystemIntegrationType: "",
    guestSystemActive: false,
    interval_between_appointments: "",
    credential_metrics_free_quota: "",
    credential_metrics_considered_area: "",
    vip_deadline: "",
    vip_guest_metrics_parameter: "",
    vip_guest_metrics_value: "",
    banner: [],
  });

  const schema = Yup.object().shape(
    {
      feira: Yup.string(),
      feira_ingles: Yup.string(),
      edicaoDaFeira: Yup.string(),
      year: Yup.string(),
      eventEditionNumber: Yup.string(),
      place: Yup.string(),
      pavilion: Yup.string(),
      address: Yup.string(),
      fair_email: Yup.string(),
      video_link: Yup.string().url(
        intl.formatMessage({ id: "error.valid_url" })
      ),
      ecommerce_opening_date: Yup.string().nullable(),
      ecommerce_opening_time: Yup.string()
        .nullable()
        .matches(
          /^$|^(2[0-3]|[01][0-9]):([0-5][0-9])$/g,
          intl.formatMessage({ id: "error.invalid_time_hh_mm" })
        )
        .when("ecommerce_opening_date", {
          is: (ecommerce_opening_date) => ecommerce_opening_date,
          then: Yup.string()
            .nullable()
            .required(
              intl.formatMessage({
                id: "error.field_required",
              })
            ),
        })
        .test(
          "is-ecommerce_opening_time-incorrect",
          intl.formatMessage({ id: "error.no_date_with_time" }),
          () => {
            const initial_date =
              formRef.current.getFieldValue("ecommerce_opening_date");
            const initial_time =
              formRef.current.getFieldValue("ecommerce_opening_time");

            if (!initial_date && initial_time) {
              return false;
            }

            return true;
          }
        ),
      ecommerce_closing_date: Yup.string()
        .nullable()
        .test(
          "is-ecommerce_closing_date-incorrect1",
          intl.formatMessage({
            id: "error.initial_date_later",
          }),
          () => {
            const initial_date =
              formRef.current.getFieldValue("ecommerce_opening_date");
            const final_date =
              formRef.current.getFieldValue("ecommerce_closing_date");

            if (initial_date && final_date) {
              return isBefore(new Date(initial_date), new Date(final_date));
            }
            return true;
          }
        )
        .when("ecommerce_opening_date", {
          is: (ecommerce_opening_date) => ecommerce_opening_date,
          then: Yup.string()
            .nullable()
            .required(
              intl.formatMessage({
                id: "error.field_required",
              })
            ),
        })
        .test(
          "is-ecommerce_closing_date-incorrect2",
          intl.formatMessage({ id: "error.no_initial_date" }),
          () => {
            const initial_date =
              formRef.current.getFieldValue("ecommerce_opening_date");
            const final_date =
              formRef.current.getFieldValue("ecommerce_closing_date");

            if (!initial_date && final_date) {
              return false;
            }

            return true;
          }
        ),
      ecommerce_closing_time: Yup.string()
        .nullable()
        .matches(
          /^$|^(2[0-3]|[01][0-9]):([0-5][0-9])$/g,
          intl.formatMessage({ id: "error.invalid_time_hh_mm" })
        )
        .when("ecommerce_closing_date", {
          is: (ecommerce_closing_date) => ecommerce_closing_date,
          then: Yup.string()
            .nullable()
            .required(
              intl.formatMessage({
                id: "error.field_required",
              })
            ),
        })
        .test(
          "is-ecommerce_closing_time-incorrect",
          intl.formatMessage({ id: "error.no_date_with_time" }),
          () => {
            const final_date =
              formRef.current.getFieldValue("ecommerce_closing_date");
            const final_time =
              formRef.current.getFieldValue("ecommerce_closing_time");

            if (!final_date && final_time) {
              return false;
            }

            return true;
          }
        ),
      swapcard_group_id: Yup.string(),
      swapcard_event_id: Yup.string(),
      vip_deadline: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        ),
      vip_guest_metrics_parameter: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        ),
      vip_guest_metrics_value: Yup.string()
        .nullable()
        .matches(
          /^(^$|([0-9.]*))$/g,
          intl.formatMessage({ id: "error.need_to_be_number" })
        )
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        ),
      mounting_start: Yup.string().nullable(),
      mounting_end: Yup.string()
        .nullable()
        .when("mounting_start", {
          is: (mounting_start) => mounting_start,
          then: Yup.string()
            .nullable()
            .required(
              intl.formatMessage({
                id: "error.field_required",
              })
            ),
        })
        .test(
          "is-mounting_end-incorrect1",
          intl.formatMessage({
            id: "error.initial_date_later",
          }),
          () => {
            const initial_date =
              formRef.current.getFieldValue("mounting_start");
            const final_date = formRef.current.getFieldValue("mounting_end");

            if (initial_date && final_date) {
              return isBefore(new Date(initial_date), new Date(final_date));
            }

            return true;
          }
        )
        .test(
          "is-mounting_start-incorrect2",
          intl.formatMessage({ id: "error.no_initial_date" }),
          () => {
            const initial_date =
              formRef.current.getFieldValue("mounting_start");
            const final_date = formRef.current.getFieldValue("mounting_end");

            if (!initial_date && final_date) {
              return false;
            }

            return true;
          }
        ),
      realization_start: Yup.string().nullable(),
      realization_end: Yup.string()
        .nullable()
        .test(
          "is-realization_end-incorrect1",
          intl.formatMessage({
            id: "error.initial_date_later",
          }),
          () => {
            const initial_date =
              formRef.current.getFieldValue("realization_start");
            const final_date = formRef.current.getFieldValue("realization_end");

            if (initial_date && final_date) {
              return isBefore(new Date(initial_date), new Date(final_date));
            }
            return true;
          }
        )
        .when("realization_start", {
          is: (realization_start) => realization_start,
          then: Yup.string()
            .nullable()
            .required(
              intl.formatMessage({
                id: "error.field_required",
              })
            ),
        })
        .test(
          "is-realization_end-incorrect2",
          intl.formatMessage({ id: "error.no_initial_date" }),
          () => {
            const initial_date =
              formRef.current.getFieldValue("realization_start");
            const final_date = formRef.current.getFieldValue("realization_end");

            if (!initial_date && final_date) {
              return false;
            }

            return true;
          }
        ),
      unmounting_start: Yup.string().nullable(),
      unmounting_end: Yup.string()
        .nullable()
        .test(
          "is-unmounting_end-incorrect1",
          intl.formatMessage({
            id: "error.initial_date_later",
          }),
          () => {
            const initial_date =
              formRef.current.getFieldValue("unmounting_start");
            const final_date = formRef.current.getFieldValue("unmounting_end");

            if (initial_date && final_date) {
              return isBefore(new Date(initial_date), new Date(final_date));
            }
            return true;
          }
        )
        .when("unmounting_start", {
          is: (unmounting_start) => unmounting_start,
          then: Yup.string()
            .nullable()
            .required(
              intl.formatMessage({
                id: "error.field_required",
              })
            ),
        })
        .test(
          "is-unmounting_end-incorrect2",
          intl.formatMessage({ id: "error.no_initial_date" }),
          () => {
            const initial_date =
              formRef.current.getFieldValue("unmounting_start");
            const final_date = formRef.current.getFieldValue("unmounting_end");

            if (!initial_date && final_date) {
              return false;
            }

            return true;
          }
        ),
      payments_start: Yup.string().nullable(),
      payments_end: Yup.string()
        .nullable()
        .test(
          "is-payments_end-incorrect1",
          intl.formatMessage({
            id: "error.initial_date_later",
          }),
          () => {
            const initial_date =
              formRef.current.getFieldValue("payments_start");
            const final_date = formRef.current.getFieldValue("payments_end");

            if (initial_date && final_date) {
              return isBefore(new Date(initial_date), new Date(final_date));
            }
            return true;
          }
        )
        .when("payments_start", {
          is: (payments_start) => payments_start,
          then: Yup.string()
            .nullable()
            .required(
              intl.formatMessage({
                id: "error.field_required",
              })
            ),
        })
        .test(
          "is-payments_end-incorrect2",
          intl.formatMessage({ id: "error.no_initial_date" }),
          () => {
            const initial_date =
              formRef.current.getFieldValue("payments_start");
            const final_date = formRef.current.getFieldValue("payments_end");

            if (!initial_date && final_date) {
              return false;
            }

            return true;
          }
        ),
      payments_limit_end: Yup.string().nullable(),
      appointment_configuration_start: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        )
        .test(
          "is-before_current_day",
          intl.formatMessage({
            id: "error.before_current_day",
          }),
          () => {
            const initial_date = formRef.current.getFieldValue(
              "appointment_configuration_start"
            );

            if(isSameDay(initial_date, originalStartDate)) return true

            const today_date = new Date()

            if (initial_date && isToday(new Date(initial_date))) {
              return true
            }

            if (initial_date && today_date) {
              return isBefore(today_date, new Date(initial_date));
            }
            return true;
          }
        ),
      guestSystemEventId: Yup.string()
      .test('is_guest_system_active',
        intl.formatMessage({
          id: "error.field_required",
        }),
        ()=>{
          if(!isGuestSystemInformationRequired) return true
          if(!formRef.current.getFieldValue("guestSystemEventId")) return false
          return true
        }
        )
      .max(50, intl.formatMessage({ id: "error.max_length" }, { length: 50 })),
      guestSystemEventEv: Yup.string()
      .test('is_guest_system_active',
        intl.formatMessage({
          id: "error.field_required",
        }),
        ()=>{
          if(!isGuestSystemInformationRequired) return true
          if(!formRef.current.getFieldValue("guestSystemEventEv")) return false
          return true
        }
        )
      .max(50, intl.formatMessage({ id: "error.max_length" }, { length: 50 }))
      ,
      guestSystemIntegrationType:Yup.string()
      .nullable()
      .test('is_guest_system_active',
        intl.formatMessage({
          id: "error.field_required",
        }),
        ()=>{
          if(!isGuestSystemInformationRequired) return true
          if(!formRef.current.getFieldValue("guestSystemIntegrationType")) return false
          return true
        }
      ),
      guestSystemActive: Yup.bool()
      .nullable()
      .required(
        intl.formatMessage({
          id: "error.field_required",
        })
      ),
      appointment_configuration_end: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        )
        .test(
          "is-payments_end-incorrect1",
          intl.formatMessage({
            id: "error.initial_date_later",
          }),
          () => {
            const initial_date = formRef.current.getFieldValue(
              "appointment_configuration_start"
            );
            const final_date = formRef.current.getFieldValue(
              "appointment_configuration_end"
            );

            if (initial_date && final_date) {
              return isBefore(new Date(initial_date), new Date(final_date));
            }
            return true;
          }
        )
        .test(
          "is-appointment_configuration_opening-incorrect2",
          intl.formatMessage({ id: "error.no_initial_date" }),
          () => {
            const appointment_configuration_start =
              formRef.current.getFieldValue("appointment_configuration_start");

            const appointment_configuration_end = formRef.current.getFieldValue(
              "appointment_configuration_end"
            );

            if (
              !appointment_configuration_start &&
              appointment_configuration_end
            ) {
              return false;
            }

            return true;
          }
        ),
      appointment_configuration_opening: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        ),
      appointment_configuration_closure: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        )
        .test(
          "is-appointment_configuration_closure-incorrect2",
          intl.formatMessage({ id: "error.no_initial_date" }),
          () => {
            const appointment_configuration_opening =
              formRef.current.getFieldValue(
                "appointment_configuration_opening"
              );

            const appointment_configuration_closure =
              formRef.current.getFieldValue(
                "appointment_configuration_closure"
              );

            if (
              !appointment_configuration_opening &&
              appointment_configuration_closure
            ) {
              return false;
            }

            return true;
          }
        )
        .test(
          "is-payments_end-incorrect1",
          intl.formatMessage({
            id: "error.initial_date_later",
          }),
          () => {
            var appointment_configuration_opening =
              formRef.current.getFieldValue(
                "appointment_configuration_opening"
              );
            var appointment_configuration_closure =
              formRef.current.getFieldValue(
                "appointment_configuration_closure"
              );

            if (
              appointment_configuration_opening &&
              appointment_configuration_closure
            ) {
              return isTimeBefore(
                appointment_configuration_opening,
                appointment_configuration_closure
              );
            }
            return true;
          }
        ),
        appointment_configuration_first_day_opening: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        )
        .test(
          "is-payments_first_day_opening-incorrect1",
          intl.formatMessage({
            id: "error.first_day_date_opening",
          }),
          () => {
            var appointment_configuration_first_day_opening =
              formRef.current.getFieldValue(
                "appointment_configuration_first_day_opening"
              );
            var appointment_configuration_opening =
              formRef.current.getFieldValue(
                "appointment_configuration_opening"
              );

            if (
              appointment_configuration_first_day_opening &&
              appointment_configuration_opening
            ) {
              return !isTimeAfter(
                appointment_configuration_opening,
                appointment_configuration_first_day_opening
              );
            }
            return true;
          }
        ),
      appointment_configuration_first_day_closure: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        )
        .test(
          "is-appointment_configuration_first_day_closure-incorrect2",
          intl.formatMessage({ id: "error.no_initial_date" }),
          () => {
            const appointment_configuration_first_day_opening =
              formRef.current.getFieldValue(
                "appointment_configuration_first_day_opening"
              );

            const appointment_configuration_first_day_closure =
              formRef.current.getFieldValue(
                "appointment_configuration_first_day_closure"
              );

            if (
              !appointment_configuration_first_day_opening &&
              appointment_configuration_first_day_closure
            ) {
              return false;
            }

            return true;
          }
        )
        .test(
          "is-payments_first_day_end-incorrect1",
          intl.formatMessage({
            id: "error.initial_date_later",
          }),
          () => {
            var appointment_configuration_first_day_opening =
              formRef.current.getFieldValue(
                "appointment_configuration_first_day_opening"
              );
            var appointment_configuration_first_day_closure =
              formRef.current.getFieldValue(
                "appointment_configuration_first_day_closure"
              );

            if (
              appointment_configuration_first_day_opening &&
              appointment_configuration_first_day_closure
            ) {
              return isTimeBefore(
                appointment_configuration_first_day_opening,
                appointment_configuration_first_day_closure
              );
            }
            return true;
          }
        )
        .test(
          "is-payments_first_day_end-incorrect3",
          intl.formatMessage({
            id: "error.first_day_date_closure",
          }),
          () => {
            var appointment_configuration_closure =
              formRef.current.getFieldValue(
                "appointment_configuration_closure"
              );
            var appointment_configuration_first_day_closure =
              formRef.current.getFieldValue(
                "appointment_configuration_first_day_closure"
              );

            if (
              appointment_configuration_closure &&
              appointment_configuration_first_day_closure
            ) {
              return !isTimeAfter(
                appointment_configuration_first_day_closure,
                appointment_configuration_closure
              );
            }
            return true;
          }
        ),
      interval_between_appointments: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        ),
      credential_metrics_free_quota: Yup.string()
        .matches(
          /^(^$|([0-9.]*))$/g,
          intl.formatMessage({ id: "error.need_to_be_number" })
        )
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        ),
      credential_metrics_considered_area: Yup.string()
        .matches(
          /^(^$|([0-9.]*))$/g,
          intl.formatMessage({ id: "error.need_to_be_number" })
        )
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        ),
      banner: Yup.object().nullable(),
    },
    [
      ["vip_guest_metrics_parameter", "vip_guest_metrics_value"],
      ["vip_deadline", "vip_guest_metrics_value"],
      ["vip_deadline", "vip_guest_metrics_parameter"],
    ]
  );

  const metricsSchema = Yup.object().shape({
    credential_metrics_free_quota_modal: Yup.string()
      .matches(
        /^(^$|([0-9.]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(
        intl.formatMessage({
          id: "error.field_required",
        })
      ),
    credential_metrics_considered_area_modal: Yup.string()
      .matches(
        /^(^$|([0-9.]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(
        intl.formatMessage({
          id: "error.field_required",
        })
      ),
  })

  const checkContainScheduledCredential = (
    newInitialDate,
    newEndDate,
    newInitialTime,
    newEndTime
  ) => {
    if (!firstCredentialSchedulingDate) return false
    if (!lastCredentialSchedulingDate) return false

    if (newInitialDate.getTime() > firstCredentialSchedulingDate.getTime()) return true
    if (newEndDate.getTime() < lastCredentialSchedulingDate.getTime()) return true

    if (newInitialTime > firstCredentialSchedulingTime) return true
    if (newEndTime < lastCredentialSchedulingTime) return true

    return false
  }

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) {
          return;
        }

        var formData = new FormData();

        if (data.ecommerce_opening_date)
          formData.set(
            "ecommerceOpeningDate",
            formatDate(data.ecommerce_opening_date, "yyyy-MM-dd"));
        formData.set("ecommerceOpeningTime", data.ecommerce_opening_time);
        if (data.ecommerce_closing_date)
          formData.set(
            "ecommerceClosingDate",
            formatDate(data.ecommerce_closing_date, "yyyy-MM-dd"));
        formData.set("ecommerceClosingTime", data.ecommerce_closing_time);
        formData.set("link", data.video_link);
        formData.set("swapcardGroupId", data.swapcard_group_id);
        formData.set("swapcardEventId", data.swapcard_event_id);
        if (data.mounting_start)
          formData.set(
            "initMounting",
            formatDate(data.mounting_start, "dd/MM/yyyy"));
        if (data.mounting_end)
          formData.set(
            "finishMounting",
            formatDate(data.mounting_end, "dd/MM/yyyy"));
        if (data.realization_start)
          formData.set(
            "initRealization",
            formatDate(data.realization_start, "dd/MM/yyyy"));
        if (data.realization_end)
          formData.set(
            "finishRealization",
            formatDate(data.realization_end, "dd/MM/yyyy"));
        if (data.unmounting_start)
          formData.set(
            "initUnmounting",
            formatDate(data.unmounting_start, "dd/MM/yyyy"));
        if (data.unmounting_end)
          formData.set(
            "finishUnmounting",
            formatDate(data.unmounting_end, "dd/MM/yyyy"));
        if (data.payments_start)
          formData.set(
            "initPayment",
            formatDate(data.payments_start, "dd/MM/yyyy"));
        if (data.payments_end)
          formData.set(
            "finishPayment",
            formatDate(data.payments_end, "dd/MM/yyyy"));
        if (data.payments_limit_end)
          formData.set(
            "limitPaymentDate",
            formatDate(data.payments_limit_end, "dd/MM/yyyy"));
        formData.set(
          "startDateForSchedulingCredentials",
          formatDate(data.appointment_configuration_start, "yyyy-MM-dd"));
        formData.set(
          "startTimeToScheduleCredentials",
          data.appointment_configuration_opening);
        formData.set(
          "endTimeToScheduleCredentials",
          data.appointment_configuration_closure);
        formData.set(
          "firstDayStartTimeToScheduleCredentials",
          data.appointment_configuration_first_day_opening);
        formData.set(
          "firstDayEndTimeToScheduleCredentials",
          data.appointment_configuration_first_day_closure);
        formData.set(
          "endDateForSchedulingCredentials",
          formatDate(data.appointment_configuration_end, "yyyy-MM-dd"));
        formData.set(
          "answeringTimeInterval",
          data.interval_between_appointments);
        formData.set(
          "consideredArea",
          data.credential_metrics_considered_area);
        formData.set(
          "freeQuotasOnCredentials",
          data.credential_metrics_free_quota);
        formData.set(
          "vipDeadline",
          formatDate(data.vip_deadline, "yyyy-MM-dd"));
        formData.set(
          "vipGuestMetricsParameter",
          data.vip_guest_metrics_parameter);
        formData.set("vipGuestMetricsValue", data.vip_guest_metrics_value);
        formData.set("active", data.status);
        formData.set("manageGuestsEnable", data.is_manage_guests_enable);
        formData.set("integrateExhibitorsOnSwapcard", data.is_exhibitors_integration_enable);
        formData.set("guestSystemEventId", data.guestSystemEventId);
        formData.set("guestSystemEventEv", data.guestSystemEventEv);
        formData.set("guestSystemIntegrationType", data.guestSystemIntegrationType);
        formData.set("guestSystemActive", data.guestSystemActive);
        if (data.banner && data.banner.length > 0)
          formData.set("banner", data.banner[0]);
        formData.set("hasRemovedOldBanner", hasRemovedOldBanner);

        api
          .post(`/admin/events/${id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((result) => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.event_updated",
              }),
            });
            history.push("/events");
          })
          .catch((err) => {
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          })
          .finally(() => {
            setLoading(false);
          }); 
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [
      loading,
      addToast,
      history,
      intl,
      id,
      schema,
      token,
      hasRemovedOldBanner
    ]
  );

  useEffect(() => {
    if (integrationOptions.length === 0) return
    handleLoading(true);
    api
      .get(`/admin/events/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (result) => {
        const payloadPeriods = result.data.output.periods;
        setSavedVipGuestMetrics({
          vipDeadline: result.data.output.vipDeadline,
          vipGuestMetricsParameter: result.data.output.vipGuestMetricsParameter,
          vipGuestMetricsValue: result.data.output.vipGuestMetricsValue,
        });

        setFirstCredentialSchedulingDate(new Date(result.data.output.firstCredentialSchedulingDate + 'T' + 
        '00:00'))
        setLastCredentialSchedulingDate(new Date(result.data.output.lastCredentialSchedulingDate + 'T' + 
        '00:00'))
        setFirstCredentialSchedulingTime(result.data.output.firstCredentialSchedulingTime)
        setLastCredentialSchedulingTime(result.data.output.lastCredentialSchedulingTime)


        setOriginalStartDate(parseStringToDate(
          result.data.output.startDateForSchedulingCredentials,
          "yyyy-MM-dd"))

        setIsGuestSystemInformationRequired(result.data.output.guestSystemActive)
        
        setData({
          ...result.data.output,
          ecommerce_opening_date: parseStringToDate(
            result.data.output.ecommerceOpeningDate,
            "yyyy-MM-dd"
          ),
          ecommerce_closing_date: parseStringToDate(
            result.data.output.ecommerceClosingDate,
            "yyyy-MM-dd"
          ),
          mounting_start: parseStringToDate(
            payloadPeriods?.initMounting,
            "dd/MM/yyyy"
          ),
          mounting_end: parseStringToDate(
            payloadPeriods?.finishMounting,
            "dd/MM/yyyy"
          ),
          realization_start: parseStringToDate(
            payloadPeriods?.initRealization,
            "dd/MM/yyyy"
          ),
          realization_end: parseStringToDate(
            payloadPeriods?.finishRealization,
            "dd/MM/yyyy"
          ),
          unmounting_start: parseStringToDate(
            payloadPeriods?.initUnmounting,
            "dd/MM/yyyy"
          ),
          unmounting_end: parseStringToDate(
            payloadPeriods?.finishUnmounting,
            "dd/MM/yyyy"
          ),
          payments_start: parseStringToDate(
            payloadPeriods?.initPayment,
            "dd/MM/yyyy"
          ),
          payments_end: parseStringToDate(
            payloadPeriods?.finishPayment,
            "dd/MM/yyyy"
          ),
          payments_limit_end: parseStringToDate(
            payloadPeriods?.limitPaymentDate,
            "dd/MM/yyyy"
          ),
          appointment_configuration_start: parseStringToDate(
            result.data.output.startDateForSchedulingCredentials,
            "yyyy-MM-dd"
          ),
          appointment_configuration_end: parseStringToDate(
            result.data.output.endDateForSchedulingCredentials,
            "yyyy-MM-dd"
          ),
          vip_deadline: parseStringToDate(
            result.data.output.vipDeadline,
            "yyyy-MM-dd"
          ),
        });

        setLogoSrc(getLogoSrcFromHtml(result.data.output.logo));
        if (result.data.output.banner) {
          setIsBannerUnmodified(true);
        }
        setEventFileList(result.data.output.eventFileList);
        setKey(new Date());
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
    setVipGuestMetrics([
      {
        label: intl.formatMessage({ id: "quota_by_area" }),
        value: "QUOTA_AREA",
      },
      {
        label: intl.formatMessage({ id: "single_quota" }),
        value: "QUOTA_SINGLE",
      },
    ]);
  }, [addToast, intl, id, token, handleLoading, integrationOptions]);

  useEffect(() => {
    handleLoading(true);
    api
      .get(`/admin/events/integration`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (result) => {
        setIntegrationOptions(result.data.output)
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  },[addToast, token, intl, handleLoading])

  const hasEditCredentialMetricsPermission = useMemo(
    () => {
      const dashboardCategory = user?.permissionCategories.find(
        (permission) => permission.code === 'EVENTS'
      );
      if (dashboardCategory?.permissions) {
        return dashboardCategory.permissions.some(
          (infoPermission) =>
            infoPermission.code === 'UPDATE_EVENT_CREDENTIAL_METRIC'
        );
      }
      return false;
    },
    [user]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const validationBlurMetrics = (name) => {
    let errors = metricsFormRef.current.getErrors();

    validateSingleFieldOnBlur(errors, metricsSchema, name, metricsFormRef);
  };

  const parseStringToDate = (dateString, formatString) => {
    if (!dateString) {
      return "";
    }
    return parse(dateString, formatString, new Date());
  };

  const formatDate = (date, formatString) => {
    if (!date || date === "") {
      return null;
    }

    return format(date, formatString);
  };

  const handleActivateEvent = useCallback((_) => {
    const statusValue = formRef.current.getFieldValue("status");
    if (statusValue) {
      setActivateModalIsOpen(true);
    } else {
      setDeactivateModalIsOpen(true);
    }
  }, []);

  const handleEnableManageGuests = useCallback((_) => {
    setManageGuestsEnableModalIsOpen(true);
  }, []);

  const handleExhibitorsIntegration = useCallback((_) => {
    setExhibitorsIntegrationModalIsOpen(true);
  }, []);

  const changeEventStatus = useCallback(() => {
    const statusValue = formRef.current.getFieldValue("status");

    if (statusValue) {
      setActivateModalIsOpen(false);
    } else {
      setDeactivateModalIsOpen(false);
    }

    api
      .put(
        `/admin/events/${id}`,
        { active: statusValue },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        if (statusValue) {
          addToast({
            type: "success",
            title: intl.formatMessage({
              id: "success",
            }),
            description: intl.formatMessage({
              id: "success.activate_event",
            }),
          });
        } else {
          addToast({
            type: "success",
            title: intl.formatMessage({
              id: "success",
            }),
            description: intl.formatMessage({
              id: "success.deactivate_event",
            }),
          });
        }
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      });
  }, [addToast, id, intl, token]);

  const changeManageGuestsStatus = useCallback(() => {
    const statusValue = formRef.current.getFieldValue("is_manage_guests_enable");

    setManageGuestsEnableModalIsOpen(false);

    api
      .put(
        `/admin/events/${id}/toggle-manage-guests`,
        { active: statusValue },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        addToast({
          type: "success",
          title: intl.formatMessage({
            id: "success",
          }),
          description: intl.formatMessage({
            id: "success.change_manage_guests_status",
          }),
        });
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      });
  }, [addToast, id, intl, token]);

  const changeExhibitorsIntegrationStatus = useCallback(() => {
    const statusValue = formRef.current.getFieldValue("is_exhibitors_integration_enable");

    setExhibitorsIntegrationModalIsOpen(false);

    api
      .put(
        `/admin/events/${id}/toggle-exhibitors-integration`,
        { active: statusValue },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        addToast({
          type: "success",
          title: intl.formatMessage({
            id: "success",
          }),
          description: intl.formatMessage({
            id: "success.change_exhibitors_integration_status",
          }),
        });
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      });
  }, [addToast, id, intl, token]);

  const cancelActivation = useCallback(() => {
    const previousValue = formRef.current.getFieldValue("status");
    formRef.current.setFieldValue("status", !previousValue);
  }, []);

  const cancelExhibitorsIntegration = useCallback(() => {
    const previousValue = formRef.current.getFieldValue("is_exhibitors_integration_enable");
    formRef.current.setFieldValue("is_exhibitors_integration_enable", !previousValue);
  }, []);

  const cancelManageGuestsStatusChange = useCallback(() => {
    const previousValue = formRef.current.getFieldValue("is_manage_guests_enable");
    formRef.current.setFieldValue("is_manage_guests_enable", !previousValue);
  }, []);

  const hasVipMetricsChanges = (
    newVipDeadline,
    newVipGuestMetricsParameter,
    newVipGuestMetricsValue
  ) => {
    const { vipDeadline, vipGuestMetricsParameter, vipGuestMetricsValue } =
      savedVipGuestMetrics;

    if (
      !vipDeadline ||
      !vipGuestMetricsParameter ||
      vipGuestMetricsValue === null ||
      vipGuestMetricsValue === undefined
    )
      return false;

    return (
      parseStringToDate(vipDeadline, "yyyy-MM-dd").getTime() !==
        newVipDeadline.getTime() ||
      vipGuestMetricsParameter !== newVipGuestMetricsParameter ||
      vipGuestMetricsValue !== parseFloat(newVipGuestMetricsValue)
    );
  };

  const confirmVipMetricsModal = () => {
    const formattedInitialDate = 
      new Date(formatDate(formRef?.current?.getFieldValue('appointment_configuration_start'), 'yyyy-MM-dd') + 'T' + 
      formRef?.current?.getFieldValue('appointment_configuration_opening'))
  
    const formattedFinalDate = 
      new Date(formatDate(formRef?.current?.getFieldValue('appointment_configuration_end'), 'yyyy-MM-dd') + 'T' +
      formRef?.current?.getFieldValue('appointment_configuration_closure'))

    setVipMetricsConfirmationModalOpen(false);

    if (checkContainScheduledCredential(
      formattedInitialDate,
      formattedFinalDate,
      formRef?.current?.getFieldValue('appointment_configuration_opening'),
      formRef?.current?.getFieldValue('appointment_configuration_closure')
    ))
    setDateModalIsOpen(true)

    else formRef.current.submitForm(); 
  };

  const confirmDateModal = () => {
    setDateModalIsOpen(false)

    formRef.current.submitForm(); 
  }

  const handleSubmitConditional = () => {
    const vip_deadline = formRef?.current?.getFieldValue("vip_deadline"),
      vip_guest_metrics_parameter = formRef?.current?.getFieldValue(
        "vip_guest_metrics_parameter"
      ),
      vip_guest_metrics_value = formRef?.current?.getFieldValue(
        "vip_guest_metrics_value"
      );

    const formattedInitialDate = 
      new Date(formatDate(formRef?.current?.getFieldValue('appointment_configuration_start'), 'yyyy-MM-dd') + 'T' + 
      '00:00')
  
    const formattedFinalDate = 
      new Date(formatDate(formRef?.current?.getFieldValue('appointment_configuration_end'), 'yyyy-MM-dd') + 'T' +
      '00:00')
    
    if (
      hasVipMetricsChanges(
        vip_deadline,
        vip_guest_metrics_parameter,
        vip_guest_metrics_value
      )
    ) {
      setVipMetricsConfirmationModalOpen(true);
    } 

    if (checkContainScheduledCredential(
      formattedInitialDate, 
      formattedFinalDate,
      formRef?.current?.getFieldValue('appointment_configuration_opening'),
      formRef?.current?.getFieldValue('appointment_configuration_closure')
      ))
    setDateModalIsOpen(true)
    
    else {
      formRef.current.submitForm();
    }
  };

  const handleRemoveLoadedBanner = () => {
    setRemoveBannerModalOpen(false);
    setIsBannerUnmodified(false);
    setHasRemovedOldBanner(true);
  }

  const handleExportGuests = () => {
    api
      .patch(
        `/admin/manage-guests/export`,
        {
          eventEditionCode: data.eventEditionCode,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        const { file, name } = result.data.manageGuestAdminExportOutput;
        var blob = base64ToBlob(file, "application/vnd.ms-excel");
        downloadBlob(blob, `${name}.xlsx`);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      });
  };

  const clearFieldsMointing = () => {
    formRef.current.setFieldValue("mounting_start").clear();
    formRef.current.setFieldValue("mounting_end").clear();
  };

  const clearFieldsRelization = () => {
    formRef.current.setFieldValue("realization_start").clear();
    formRef.current.setFieldValue("realization_end").clear();
  };

  const clearFieldsUnmounting = () => {
    formRef.current.setFieldValue("unmounting_start").clear();
    formRef.current.setFieldValue("unmounting_end").clear();
  };

  const clearFieldsPayments = () => {
    formRef.current.setFieldValue("payments_start").clear();
    formRef.current.setFieldValue("payments_end").clear();
  };

  const clearFieldsPaymentsLimitEnd = () => {
    formRef.current.setFieldValue("payments_limit_end").clear();
  };

  const validatePrevPath = () => {
    if (typeof history.location.state == "undefined") {
      return false;
    } else {
      if (history.location.state.prevPath.pathname.includes("/account/")) {
        return true;
      }
    }
  };

  const submitMetricsForm = useCallback(
    async (data) => {
      try {
        metricsFormRef.current?.setErrors({});

        await metricsSchema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) {
          return;
        }

        api
          .put(`/admin/events/credential-metric/${id}`, 
          {
            freeQuotasOnCredentials: data.credential_metrics_free_quota_modal,
            consideredArea: data.credential_metrics_considered_area_modal
          }, {
            headers: {
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((result) => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.event_updated",
              }),
            });
            history.push("/events");
          })
          .catch((err) => {
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          })
          .finally(() => {
            setLoading(false);
          }); 
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          metricsFormRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [loading, addToast, history, intl, id, metricsSchema, token]
  )

  const handleMetricsModalOpen = () => {
    metricsFormRef.current?.setErrors({})
    setIsMetricsModalOpen(true)
  }

  const handleGuestSystemToggleClick = () => {
    if(isGuestSystemInformationRequired){
      formRef.current.setFieldValue("guestSystemEventId", "")
      formRef.current.setFieldValue("guestSystemEventEv", "")
      formRef.current.setFieldValue("guestSystemIntegrationType", "")
      formRef.current?.setErrors({});
    }
    setIsGuestSystemInformationRequired(!isGuestSystemInformationRequired)
  }

  const handleDeleteFileModalIsOpen = (fileId) => {
    setDeleteFileModalIsOpen(true);
    setFileIdToDelete(fileId);
  };

  const handleOpenEventFileModal = (fileId) => {
    setEditEventFileModalIsOpen(true);
    setFileIdToEventFileModal(fileId);
  };

  const handleSearchForEventEditionFiles = useCallback(() => {
    try {
      setLoading(true);
      if (loading) {
        return;
      }

      api
        .get(`/event-file/by-event-edition/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          setEventFileList(result.data.output);
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => setLoading(false));
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }
    }
  }, [addToast, intl, token, loading]);

  const handleViewEventFile = useCallback(
    (id) => {
      handleLoading(true);
      api
        .get(`/event-file/${id}?action=VIEW`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          const sendMethod = result.data.output.sendMethod;

          if (sendMethod === "UPLOAD") {
            setViewEventFileLocation(result.data.output.document);
            setViewEventFileModalOpen(true);
          } else {
            window.open(
              result.data.output.link,
              "_blank"
            );
          }
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    },
    [addToast, intl, token, handleLoading]
  );

  const handleDeleteEventFile = useCallback(
    () => {
      setDeleteFileModalIsOpen(false);
      handleLoading(true);
      api
        .delete(`/event-file/${fileIdToDelete}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          addToast({
            type: "success",
            title: intl.formatMessage({
              id: "success",
            }),
            description: intl.formatMessage({
              id: "success.event_file_deleted",
            }),
          });
          handleSearchForEventEditionFiles();
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    },
    [addToast, intl, token, handleLoading, fileIdToDelete]
  );

  const eventFileColumns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "title" }),
        selector: (row) => row.title,
        sortable: true,
        allowOverflow: true,
        wrap: false,
        minWidth: "200px",
      },
      {
        name: intl.formatMessage({ id: "file_language" }),
        selector: (row) => row.fileLanguage,
        cell: (row) =>
          row.fileLanguage === "PT"
            ? intl.formatMessage({ id: "portuguese" })
            : intl.formatMessage({ id: "english" }),
      },
      {
        cell: (row) => (
          <Badge
            color="#002244"
            text={intl.formatMessage({ id: "visualize" })}
            onClick={() => handleViewEventFile(row.id)}
          />
        ),
        right: true,
        width: "150px",
      },
      {
        cell: (row) => (
          <Badge
            color="#002244"
            text={intl.formatMessage({ id: "edit" })}
            onClick={() => handleOpenEventFileModal(row.id)}
          />
        ),
        right: true,
        width: "150px",
      },
      {
        cell: (row) => (
          <Badge
            color="#002244"
            text={intl.formatMessage({ id: "delete" })}
            onClick={() => handleDeleteFileModalIsOpen(row.id)}
          />
        ),
        right: true,
        width: "150px",
      },
    ],
    [
      intl,
      handleViewEventFile,
      handleOpenEventFileModal,
      handleDeleteEventFile
    ]
  );

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "edition_information" })}
    >
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          key={key}
          initialData={{
            event: data.eventEditionName,
            edicaoDaFeira: data.eventEditionCode,
            year: "",
            eventEditionNumber: "",
            place: data.eventVenue,
            pavilion: data.pavilion,
            fair_email: "",
            prefix: data.eventCode,
            video_link: data.linkParaVideos,
            ecommerce_opening_date: data.ecommerce_opening_date,
            ecommerce_opening_time: data.ecommerceOpeningTime,
            ecommerce_closing_date: data.ecommerce_closing_date,
            ecommerce_closing_time: data.ecommerceClosingTime,
            swapcard_group_id: data.swapcardGroupId,
            swapcard_event_id: data.swapcardEventId,
            mounting_start: data.mounting_start,
            mounting_end: data.mounting_end,
            realization_start: data.realization_start,
            realization_end: data.realization_end,
            unmounting_start: data.unmounting_start,
            unmounting_end: data.unmounting_end,
            payments_start: data.payments_start,
            payments_end: data.payments_end,
            payments_limit_end: data.payments_limit_end,
            appointment_configuration_start:
              data?.appointment_configuration_start,
            appointment_configuration_end: data.appointment_configuration_end,
            appointment_configuration_opening: {
              label: timeOptions5m.filter(
                (i) => i.value === data?.startTimeToScheduleCredentials
              )[0]?.label,
              value: data?.startTimeToScheduleCredentials,
            },
            appointment_configuration_closure: {
              label: timeOptions5m.filter(
                (i) => i.value === data?.endTimeToScheduleCredentials
              )[0]?.label,
              value: data?.endTimeToScheduleCredentials,
            },
            appointment_configuration_first_day_opening: {
              label: timeOptions5m.filter(
                (i) => i.value === data?.firstDayStartTimeToScheduleCredentials
              )[0]?.label,
              value: data?.firstDayStartTimeToScheduleCredentials,
            },
            appointment_configuration_first_day_closure: {
              label: timeOptions5m.filter(
                (i) => i.value === data?.firstDayEndTimeToScheduleCredentials
              )[0]?.label,
              value: data?.firstDayEndTimeToScheduleCredentials,
            },
            interval_between_appointments: {
              label: intervalTimes.filter(
                (i) => i.value === data.answeringTimeInterval
              )[0]?.label,
              value: data.answeringTimeInterval,
            },
            credential_metrics_free_quota: data.freeQuotasOnCredentials,
            credential_metrics_considered_area: data.consideredArea,
            status: data.active,
            is_manage_guests_enable: data.manageGuestsEnable,
            is_exhibitors_integration_enable: data.integrateExhibitorsOnSwapcard,
            vip_deadline: data?.vip_deadline,
            vip_guest_metrics_parameter: {
              label: vipGuestMetrics.filter(
                (i) => i.value === data.vipGuestMetricsParameter
              )[0]?.label,
              value: data.vipGuestMetricsParameter,
            },
            vip_guest_metrics_value: data?.vipGuestMetricsValue,
            guestSystemEventId: data.guestSystemEventId,
            guestSystemEventEv: data.guestSystemEventEv,
            guestSystemIntegrationType: {
              label: integrationOptions.filter(
                (i) => i.value === data?.guestSystemIntegrationType
              )[0]?.label,
              value: data?.guestSystemIntegrationType
            },
            guestSystemActive: data.guestSystemActive
          }}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id="search_for_event_edition" />
            </Title>
            <ActionContainer>
              <BadgeContainer
                onClick={() =>
                  validatePrevPath()
                    ? history.goBack()
                    : history.push("/events", { prevPath: history.location })
                }
              >
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => handleSubmitConditional()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                  loading={loading}
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>

          <SessionDivider nr_items="2">
            <Session>
              <SessionContainer>
                <SessionIconContainer>
                  <MdInfo size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="informations" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <InputWithLabel
                  name="event"
                  validationBlur={validationBlur}
                  disabled
                  label={intl.formatMessage({ id: "event_edition" })}
                />
                <InformationEditionContainer>
                  <Horizontal>
                    <InputWithLabel
                      name="edicaoDaFeira"
                      validationBlur={validationBlur}
                      disabled
                      className="edition"
                      label={intl.formatMessage({ id: "edition" })}
                    />
                    <InputWithLabel
                      name="year"
                      validationBlur={validationBlur}
                      disabled
                      label={intl.formatMessage({ id: "year" })}
                    />
                    <InputWithLabel
                      name="prefix"
                      validationBlur={validationBlur}
                      disabled
                      label={intl.formatMessage({ id: "prefix" })}
                    />
                    <Toggle
                      label="Status"
                      name="status"
                      onClick={() => handleActivateEvent()}
                    />
                  </Horizontal>
                </InformationEditionContainer>

                <Horizontal>
                  <InputWithLabel
                    name="place"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "place" })}
                  />

                  <InputWithLabel
                    name="pavilion"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "pavilion" })}
                    value={data.pavilion}
                  />
                </Horizontal>

                <InputWithLabel
                  name="fair_email"
                  validationBlur={validationBlur}
                  disabled
                  label={intl.formatMessage({ id: "fair_email" })}
                />

                <Horizontal>
                  <InputWithLabel
                    name="video_link"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "video_link" })}
                  />
                </Horizontal>
              </FieldsContainer>
              <SessionContainer>
                <SessionIconContainer>
                  <FaShoppingBasket size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="ecommerce" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <Horizontal>
                  <DatePickerWithLabel
                    name="ecommerce_opening_date"
                    validationBlur={validationBlur}
                    validateEnd={() => validationBlur("ecommerce_closing_date")}
                    label={intl.formatMessage({ id: "opening_date" })}
                    labelOrientation="vertical"
                  />
                  <InputWithLabel
                    name="ecommerce_opening_time"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "opening_time" })}
                  />
                </Horizontal>
                <Horizontal>
                  <DatePickerWithLabel
                    name="ecommerce_closing_date"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "closing_date" })}
                    labelOrientation="vertical"
                  />
                  <InputWithLabel
                    name="ecommerce_closing_time"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "closing_time" })}
                  />
                </Horizontal>
              </FieldsContainer>
              <SessionContainer>
                <SessionIconContainer>
                  <FaInfinity size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="digital_platform" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <Horizontal>
                  <InputWithLabel
                    name="swapcard_group_id"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "swapcard_group_id" })}
                  />
                  <InputWithLabel
                    name="swapcard_event_id"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "swapcard_event_id" })}
                  />
                </Horizontal>
                <Horizontal>
                  <Toggle
                        label={intl.formatMessage({ id: "integrate_exhibitors" })}
                        name="is_exhibitors_integration_enable"
                        onClick={handleExhibitorsIntegration}
                      />
                </Horizontal>
              </FieldsContainer>
              <SessionContainer>
                <SessionIconContainer>
                  <RiVipDiamondFill size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="vip_guests" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <VIPCategoryContainer>
                  <Horizontal>
                    <DatePickerWithLabel
                      name="vip_deadline"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "vip_deadline" })}
                      labelWidth="100px"
                      disabled={data.vipDeadline}
                      required
                    />
                    <Toggle
                      label={intl.formatMessage({ id: "manage_guests" })}
                      name="is_manage_guests_enable"
                      onClick={() => handleEnableManageGuests()}
                    />
                  </Horizontal>
                </VIPCategoryContainer>
                <VIPCategoryContainer>
                  <SelectWithLabel
                    name="vip_guest_metrics_parameter"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({
                      id: "vip_guest_metrics",
                    })}
                    placeholder={intl.formatMessage({
                      id: "vip_guest_metrics",
                    })}
                    labelOrientation="horizontal"
                    labelWidth="100px"
                    options={vipGuestMetrics}
                    disabled={data.vipGuestMetricsParameter}
                    required
                  />
                  <InputWithHorizontalLabel
                    name="vip_guest_metrics_value"
                    label={intl.formatMessage({
                      id: "quotas",
                    })}
                    labelWidth="100px"
                    validationBlur={validationBlur}
                    disabled={data.vipGuestMetricsValue}
                    required
                  />
                </VIPCategoryContainer>
                <VIPCategoryContainer>
                  <FormButton
                    size="auto"
                    fontSize="12px"
                    type="button"
                    onClick={() => handleExportGuests()}
                  >
                    <FormattedMessage id="export_guests_to_xls" />
                  </FormButton>
                </VIPCategoryContainer>
              </FieldsContainer>
              <SessionContainer>
                <SessionIconContainer>
                  <MdLabel size="18px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="exhibitor_guest_system_configuration" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <Horizontal>
                  <InputWithLabel
                    name="guestSystemEventId"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "event_Id" })}
                    maxLength="50"
                    disabled={!isGuestSystemInformationRequired}
                    required={isGuestSystemInformationRequired}
                  />
                  <InputWithLabel
                    name="guestSystemEventEv"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "event_EV" })}
                    maxLength="50"
                    disabled={!isGuestSystemInformationRequired}
                    required={isGuestSystemInformationRequired}
                  />
                </Horizontal>
                <Horizontal>
                  <Toggle
                    name="guestSystemActive"
                    label={intl.formatMessage({ id: "active" })}
                    onClick={handleGuestSystemToggleClick}
                  />
                  <SelectWithLabel
                    name="guestSystemIntegrationType"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "integration_type_select" })}
                    placeholder={intl.formatMessage({ id: "integration_type" })}
                    validateEnd={() =>
                      validationBlur("guestSystemIntegrationType")
                    }
                    options={integrationOptions}
                    disabled={!isGuestSystemInformationRequired}
                    required={isGuestSystemInformationRequired}
                  />
                </Horizontal>
              </FieldsContainer>
            </Session>
            <Session>
              <SessionContainer>
                <SessionIconContainer>
                  <MdTimelapse size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="periods" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <PeriodCategoryContainer>
                  <PeriodCategoryTitle>
                    <FormattedMessage id="assemble" />
                  </PeriodCategoryTitle>

                  <PeriodCategoryFieldsContainer>
                    <DatePickerWithLabel
                      name="mounting_start"
                      validationBlur={validationBlur}
                      validateEnd={() => validationBlur("mounting_end")}
                      label={intl.formatMessage({ id: "start" })}
                      popperPlacement="bottom-start"
                      labelWidth="unset"
                      maxWidth="200px"
                    />
                    <LabelEndContainer>
                      <DatePickerWithLabel
                        name="mounting_end"
                        validationBlur={validationBlur}
                        label={intl.formatMessage({ id: "end" })}
                        labelWidth="unset"
                        popperPlacement="bottom-end"
                        maxWidth="200px"
                      />
                    </LabelEndContainer>

                    <BadgeContainer onClick={clearFieldsMointing}>
                      <ClearButtonContainer>
                        <Badge
                          color="#C9C9C9"
                          text={intl.formatMessage({ id: "clear_fields" })}
                          fontSize="12px"
                        />
                      </ClearButtonContainer>
                    </BadgeContainer>
                  </PeriodCategoryFieldsContainer>
                </PeriodCategoryContainer>

                <PeriodCategoryContainer>
                  <PeriodCategoryTitle>
                    <FormattedMessage id="realization" />
                  </PeriodCategoryTitle>
                  <PeriodCategoryFieldsContainer>
                    <DatePickerWithLabel
                      name="realization_start"
                      validationBlur={validationBlur}
                      validateEnd={() => validationBlur("realization_end")}
                      label={intl.formatMessage({ id: "start" })}
                      labelWidth="unset"
                      popperPlacement="bottom-start"
                      maxWidth="200px"
                    />
                    <LabelEndContainer>
                      <DatePickerWithLabel
                        name="realization_end"
                        validationBlur={validationBlur}
                        label={intl.formatMessage({ id: "end" })}
                        labelWidth="unset"
                        popperPlacement="bottom-end"
                        maxWidth="200px"
                      />
                    </LabelEndContainer>

                    <BadgeContainer onClick={clearFieldsRelization}>
                      <ClearButtonContainer>
                        <Badge
                          color="#C9C9C9"
                          text={intl.formatMessage({ id: "clear_fields" })}
                          fontSize="12px"
                        />
                      </ClearButtonContainer>
                    </BadgeContainer>
                  </PeriodCategoryFieldsContainer>
                </PeriodCategoryContainer>

                <PeriodCategoryContainer>
                  <PeriodCategoryTitle>
                    <FormattedMessage id="disassemble" />
                  </PeriodCategoryTitle>

                  <PeriodCategoryFieldsContainer>
                    <DatePickerWithLabel
                      name="unmounting_start"
                      validationBlur={validationBlur}
                      validateEnd={() => validationBlur("unmounting_end")}
                      label={intl.formatMessage({ id: "start" })}
                      labelWidth="unset"
                      popperPlacement="bottom-start"
                      maxWidth="200px"
                    />
                    <LabelEndContainer>
                      <DatePickerWithLabel
                        name="unmounting_end"
                        validationBlur={validationBlur}
                        label={intl.formatMessage({ id: "end" })}
                        labelWidth="unset"
                        popperPlacement="bottom-end"
                        maxWidth="200px"
                      />
                    </LabelEndContainer>

                    <BadgeContainer onClick={clearFieldsUnmounting}>
                      <ClearButtonContainer>
                        <Badge
                          color="#C9C9C9"
                          text={intl.formatMessage({ id: "clear_fields" })}
                          fontSize="12px"
                        />
                      </ClearButtonContainer>
                    </BadgeContainer>
                  </PeriodCategoryFieldsContainer>
                </PeriodCategoryContainer>

                <PeriodCategoryContainer>
                  <PeriodCategoryTitle>
                    <FormattedMessage id="payments" />
                  </PeriodCategoryTitle>
                  <PeriodCategoryFieldsContainer>
                    <DatePickerWithLabel
                      name="payments_start"
                      validationBlur={validationBlur}
                      validateEnd={() => validationBlur("payments_end")}
                      label={intl.formatMessage({ id: "start" })}
                      labelWidth="unset"
                      popperPlacement="bottom-start"
                      maxWidth="200px"
                    />
                    <LabelEndContainer>
                      <DatePickerWithLabel
                        name="payments_end"
                        validationBlur={validationBlur}
                        label={intl.formatMessage({ id: "end" })}
                        labelWidth="unset"
                        popperPlacement="bottom-end"
                        maxWidth="200px"
                      />
                    </LabelEndContainer>
                    <BadgeContainer onClick={clearFieldsPayments}>
                      <ClearButtonContainer>
                        <Badge
                          color="#C9C9C9"
                          text={intl.formatMessage({ id: "clear_fields" })}
                          fontSize="12px"
                        />
                      </ClearButtonContainer>
                    </BadgeContainer>
                  </PeriodCategoryFieldsContainer>
                </PeriodCategoryContainer>

                <PeriodCategoryContainer>
                  <PeriodCategoryTitle>
                    <FormattedMessage id="payments_limit" />
                  </PeriodCategoryTitle>
                  <PeriodCategoryFieldsContainer className="payment_limit">
                    <DatePickerWithLabel
                      name="payments_limit_end"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "end" })}
                      labelWidth="unset"
                      popperPlacement="bottom-start"
                      maxWidth="200px"
                    />

                    <BadgeContainer onClick={clearFieldsPaymentsLimitEnd}>
                      <ClearButtonContainer>
                        <Badge
                          color="#C9C9C9"
                          text={intl.formatMessage({ id: "clear_fields" })}
                          fontSize="12px"
                        />
                      </ClearButtonContainer>
                    </BadgeContainer>
                  </PeriodCategoryFieldsContainer>
                </PeriodCategoryContainer>
              </FieldsContainer>
              <SessionContainer>
                <SessionIconContainer>
                  <MdAssignmentInd size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="credentials" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <CredentialsContainer>
                  <CredentialsTitle>
                    <FormattedMessage id="appointment_configuration" />
                  </CredentialsTitle>
                  <CredentialsFieldsContainer>
                    <DatePickerWithLabel
                      name="appointment_configuration_start"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "start" })}
                      labelWidth="80px"
                      popperPlacement="bottom-start"
                      validateEnd={() =>
                        validationBlur("appointment_configuration_end")
                      }
                      required
                    />
                    <DatePickerWithLabel
                      name="appointment_configuration_end"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "end" })}
                      test={formRef?.current?.getFieldValue(
                        "appointment_configuration_start"
                      )}
                      labelWidth="80px"
                      popperPlacement="bottom-end"
                      required
                    />
                  </CredentialsFieldsContainer>
                  <CredentialsFieldsContainer>
                    <SelectWithLabel
                      name="appointment_configuration_opening"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "opening" })}
                      placeholder={intl.formatMessage({ id: "opening" })}
                      validateEnd={() =>
                        validationBlur("appointment_configuration_closure")
                      }
                      options={timeOptions5m}
                      required
                    />
                    <SelectWithLabel
                      name="appointment_configuration_closure"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "closure" })}
                      placeholder={intl.formatMessage({ id: "closure" })}
                      options={timeOptions5m}
                      required
                    />
                    <SelectWithLabel
                      name="interval_between_appointments"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({
                        id: "interval_between_appointments",
                      })}
                      placeholder={intl.formatMessage({
                        id: "interval_between_appointments",
                      })}
                      rootClass="interval"
                      options={intervalTimes}
                      disabled={data.answeringTimeInterval}
                      required
                    />
                  </CredentialsFieldsContainer>
                  <CredentialsFieldsContainer>
                    <SelectWithLabel
                      name="appointment_configuration_first_day_opening"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "first_day_opening" })}
                      placeholder={intl.formatMessage({ id: "first_day_opening" })}
                      validateEnd={() =>{
                        validationBlur("appointment_configuration_first_day_opening")
                      }}
                      options={timeOptions5m}
                      required
                    />
                    <SelectWithLabel
                      name="appointment_configuration_first_day_closure"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "first_day_closure" })}
                      placeholder={intl.formatMessage({ id: "first_day_closure" })}
                      validateEnd={() =>{
                        validationBlur("appointment_configuration_first_day_closure")
                      }}
                      options={timeOptions5m}
                      required
                    />
                  </CredentialsFieldsContainer>
                  <CredentialsTitle>
                    <FormattedMessage id="credential_metrics" />
                  </CredentialsTitle>
                  <Horizontal>
                    <InputWithHorizontalLabel
                      name="credential_metrics_free_quota"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "free_quota" })}
                      labelWidth="80px"
                      disabled={data.freeQuotasOnCredentials}
                      required
                    />
                    <InputWithHorizontalLabel
                      name="credential_metrics_considered_area"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "considered_area" })}
                      labelWidth="80px"
                      disabled={data.consideredArea}
                      required
                    />
                    {data.consideredArea && hasEditCredentialMetricsPermission &&
                    <EditMetricsButtonContainer
                      onClick={() =>
                        handleMetricsModalOpen()
                      }
                      disabled={!data.consideredArea}
                    >
                      <Badge
                        color="#002244"
                        text={intl.formatMessage({ id: "edit" })}
                        fontSize="12px"
                      />
                    </EditMetricsButtonContainer>}
                  </Horizontal>
                </CredentialsContainer>
              </FieldsContainer>
              <SessionContainer>
                <SessionIconContainer>
                  <MdImage size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="fair_logo" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <DocumentsContainer>
                  {logoSrc ? (
                    <DocumentVisualization>
                      <DocumentOpenText>
                        <DocumentOpenImage src={logoSrc} />
                      </DocumentOpenText>
                    </DocumentVisualization>
                  ) : null}
                </DocumentsContainer>
              </FieldsContainer>
            </Session>
          </SessionDivider>
          <Session>
            <SessionContainer>
              <SessionIconContainer>
                <MdDescription size="16px" color="#002244" />
              </SessionIconContainer>
              <SessionTitle>
                <FormattedMessage id="document_management" />
              </SessionTitle>
            </SessionContainer>
            <FileActionsContainer>
              <BadgeContainer
                onClick={() => handleOpenEventFileModal()}
              >
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "add" })}
                  fontSize="12px"
                />
              </BadgeContainer>
            </FileActionsContainer>
            <FieldsContainer>
              <TableContainer>
                <Table
                  data={eventFileList}
                  columns={eventFileColumns}
                  hasPagination={false}
                />
              </TableContainer>
              <PDFModal
                setIsOpen={setViewEventFileModalOpen}
                modalIsOpen={viewEventFileModalOpen}
                pdfLocation={viewEventFileLocation}
              />
            </FieldsContainer>
          </Session>
          <Session>
            <SessionContainer>
              <SessionIconContainer>
                <MdImage size="16px" color="#002244" />
              </SessionIconContainer>
              <SessionTitle>
                <FormattedMessage id="banner" />
              </SessionTitle>
            </SessionContainer>
            <FieldsContainer>
              <DocumentsContainer>
                <DocumentsUploadContainer>
                  <DocumentUpload>
                    <DocumentDragAndDropContainer>
                      {isBannerUnmodified ? (
                        <DocumentVisualization>
                          <DocumentAction>
                            <MdClose
                              color="#e00000"
                              size={20}
                              onClick={() => {
                                setRemoveBannerModalOpen(true);
                              }}
                            />
                          </DocumentAction>
                          <DocumentOpenText>
                            <DocumentOpenImage src={data.banner} />
                          </DocumentOpenText>
                        </DocumentVisualization>
                      ) : (
                        <>
                          <MdCloudUpload size={32} color="#525a5c" />
                          <DocumentDragAndDropText>
                            <Dropzone
                              name="banner"
                              accept="image/*"
                              multiple={false}
                              type="JPG/JPEG/PNG"
                              maxSize={10000000}
                            />
                          </DocumentDragAndDropText>
                        </>
                      )}
                    </DocumentDragAndDropContainer>
                  </DocumentUpload>
                </DocumentsUploadContainer>
              </DocumentsContainer>
            </FieldsContainer>
          </Session>
        </Form>
      </Container>
      <ConfirmationModal
        setIsOpen={setDateModalIsOpen}
        modalIsOpen={dateModalIsOpen}
        confirmationFunction={() => confirmDateModal()}
        description={intl.formatMessage({id: "scheduled_credentials_message"})}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
      <ConfirmationModal
        setIsOpen={setActivateModalIsOpen}
        modalIsOpen={activateModalIsOpen}
        confirmationFunction={() => changeEventStatus()}
        cancelationFunction={() => cancelActivation()}
        description={intl.formatMessage({ id: "activate_event_message" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
      <ConfirmationModal
        setIsOpen={setManageGuestsEnableModalIsOpen}
        modalIsOpen={manageGuestsEnableModalIsOpen}
        confirmationFunction={() => changeManageGuestsStatus()}
        cancelationFunction={() => cancelManageGuestsStatusChange()}
        description={intl.formatMessage({ id: "change_manage_guests_status" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
      <ConfirmationModal
        setIsOpen={setDeactivateModalIsOpen}
        modalIsOpen={deactivateModalIsOpen}
        confirmationFunction={() => changeEventStatus()}
        cancelationFunction={() => cancelActivation()}
        description={intl.formatMessage({ id: "deactivate_event_message" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
      <ConfirmationModal
        setIsOpen={setExhibitorsIntegrationModalIsOpen}
        modalIsOpen={exhibitorsIntegrationModalIsOpen}
        confirmationFunction={() => changeExhibitorsIntegrationStatus()}
        cancelationFunction={() => cancelExhibitorsIntegration()}
        description={intl.formatMessage({ id: "change_exhibitors_integration_status" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
      <ConfirmationModal
        setIsOpen={setVipMetricsConfirmationModalOpen}
        modalIsOpen={vipMetricsConfirmationModalOpen}
        confirmationFunction={() => confirmVipMetricsModal()}
        description={intl.formatMessage({ id: "vip_metrics_change_message" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
      <ConfirmationModal
        setIsOpen={setDeleteFileModalIsOpen}
        modalIsOpen={deleteFileModalIsOpen}
        confirmationFunction={() => handleDeleteEventFile()}
        description={intl.formatMessage({ id: "delete_file_message" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
      <ConfirmationModal
        setIsOpen={setRemoveBannerModalOpen}
        modalIsOpen={removeBannerModalOpen}
        confirmationFunction={() => handleRemoveLoadedBanner()}
        description={intl.formatMessage({id: "delete_file_message"})}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
      <EventFileModal
        setIsOpen={setEditEventFileModalIsOpen}
        modalIsOpen={editEventFileModalIsOpen}
        fileId={fileIdToEventFileModal}
        eventEditionCode={id}
        updateCallback={() => handleSearchForEventEditionFiles()}
      />
      <Modal
        modalIsOpen={isMetricsModalOpen}
        setIsOpen={setIsMetricsModalOpen}
        modalWidth={'800px'}
      >
        <Form
          ref={metricsFormRef}
          onSubmit={submitMetricsForm}  
          initialData={{
            credential_metrics_free_quota_modal: data.freeQuotasOnCredentials,
            credential_metrics_considered_area_modal: data.consideredArea,
          }}
        >
           <CredentialsTitle>
              <FormattedMessage id="credential_metrics" />
            </CredentialsTitle>
          <Horizontal>
            <InputWithHorizontalLabel
              name="credential_metrics_free_quota_modal"
              validationBlur={validationBlurMetrics}
              label={intl.formatMessage({ id: "free_quota" })}
              labelWidth="80px"
              labelOrientation="vertical"
              required
            />
            <InputWithHorizontalLabel
              name="credential_metrics_considered_area_modal"
              validationBlur={validationBlurMetrics}
              label={intl.formatMessage({ id: "considered_area" })}
              labelWidth="80px"
              labelOrientation="vertical"
              required
            />
          </Horizontal>
          <ModalSaveMetricsContainer
          >
            <Badge
              color="#002244"
              text={intl.formatMessage({ id: "save" })}
              fontSize="12px"
              loading={loading}
              onClick={()=> metricsFormRef.current.submitForm()}
            />
          </ModalSaveMetricsContainer>
        </Form>
      </Modal>
    </AnimatedMainContainer>
  );
};

export default Detail;
