import { useIntl } from "react-intl";
import ClickIcon from "../../../../../icons/Analytics/click";
import HeartIcon from "../../../../../icons/Analytics/heart";
import ViewIcon from "../../../../../icons/Analytics/view";
import Recommend from "../../../../../newUXAssets/icons/Recommend";
import { ACard, IconContainer } from "../../styles";

export function AnalyticsCard({ number, analyticType }) {
  const intl = useIntl();
  const cardCollection = new Map();
  cardCollection.set("impressions", {
    icon: (
      <IconContainer bg="#eff5ff">
        <HeartIcon />
      </IconContainer>
    ),
    label: "total_impressions",
  });
  cardCollection.set("views", {
    icon: (
      <IconContainer bg="#FFF4f1">
        <ViewIcon />
      </IconContainer>
    ),
    label: "total_views",
  });
  cardCollection.set("interations", {
    icon: (
      <IconContainer bg="#FFF7e1">
        <ClickIcon />
      </IconContainer>
    ),
    label: "total_interations",
  });
  cardCollection.set("recommendations", {
    icon: (
      <IconContainer bg="#FCF1FF">
        <Recommend fill="#bd11d9" stroke="none" />
      </IconContainer>
    ),
    label: "total_recommendations",
  });
  const type = cardCollection.get(analyticType);

  const label = intl.formatMessage({
    id: type.label,
  });

  return (
    <ACard>
      {type.icon}
      <p>{number}</p>
      <h3>{label}</h3>
    </ACard>
  );
}