import styled, { css } from "styled-components";

export const ConcludedContainer = styled.div`
  border-radius: 8px;
  padding: 4px 8px;
  background: #f0fdf4;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #16a34a;
  text-transform: capitalize;
  animation: fadeIn 500ms forwards;

  & > p {
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 19.07px;
    margin-bottom: 0px;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  ${(props) =>
    props.overlap &&
    css`
      position: absolute;
      top: 21px;
      right: 1rem;
      z-index: 1;
    `}
`;
