import React, { useEffect, useState } from "react";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import {
  Container,
  Title,
  TitleContainer,
  Subtitle,
  FormContainer,
  ActionButtonsContainer,
  PreviewButton,
  CancelButton,
  AddBanner,
  FormTitle,
  RemoveButton,
} from "./style";
import ReorderableTable from "../../../components/ReorderableTable";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  getBannerCouseList,
  getEventList,
  deleteBanner,
  adminGetAllBannerEdit,
  getPreviewCourseList,
} from "../../../redux/modules/informaAcademy/actions";
import { useHistory } from "react-router-dom";
import {
  removeBanner,
  setIsEdit,
  setPreviewBanner,
  setPreviewCourse,
} from "../../../redux/modules/homepageBanner/actions";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray, useForm } from "react-hook-form";
import ControlledInput from "../../../components/ControlledInputs/input";
import ControlledDatePicker from "../../../components/ControlledInputs/date-picker";
import { Error, FormRow } from "../../../components/ControlledInputs/styles";
import {
  ControlledDropzone,
  ControlledDropzoneForArray,
} from "../../../components/ControlledInputs/dropzone";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdError } from "react-icons/md";
import ControlledTextareaJodit from "../../../components/ControlledInputs/jodit-textarea";
import moment from "moment";

const schema = z.object({
  title: z.string().min(1, { message: "error.field_required" }).max(180, {
    message: "error.max_length-180",
  }),
  buttonUrl: z
    .string()
    .url({ message: "error.valid_url" })
    .optional()
    .or(z.literal("")),
  startDate: z.date(),
  endDate: z.date().nullish(),
  bannerImage: z.instanceof(File).refine((file) => file.size > 0, {
    message: "file cannot be empty",
  }),
  description: z
    .string()
    .refine(
      (value) => {
        const cleanedValue = value
          .replace(/<\/?[^>]+(>|$)/g, "")
          .replace(/\n/g, "")
          .trim();

        return cleanedValue.length > 0;
      },
      {
        message: "error.field_required",
      }
    )
    .refine(
      (value) => {
        const cleanedValue = value
          .replace(/<\/?[^>]+(>|$)/g, "")
          .replace(/\n/g, "")
          .trim();

        return cleanedValue.length <= 360;
      },
      {
        message: "error.max_length-360",
      }
    ),
});

const bannersSchema = z.object({
  banners: z.array(schema),
});

const NewHomepageBanner = () => {
  const intl = useIntl();
  const history = useHistory();
  const { id, homePageId } = useParams();
  const { event } = useSelector((state) => state.informaAcademy);
  const [eventName, setEventName] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const { allBanners, fetchCourse } = useSelector(
    (state) => state.informaAcademy
  );
  const [needCourseError, setNeedCourseError] = useState(null);
  const [courses, setCourses] = useState([]);

  const dispatch = useDispatch();

  const { previewBanner, previewCourses, isEdit } = useSelector(
    (state) => state.homepageBanner
  );

  const breadcrumbTitle = isEdit
    ? intl.formatMessage({
        id: "breadcrumb.edit-manage-homepage-banner",
      }) + ` | ${eventName}`
    : intl.formatMessage({
        id: "breadcrumb.create-manage-homepage-banner",
      }) + ` | ${eventName}`;

  const form = useForm({
    resolver: zodResolver(bannersSchema),
    defaultValues: isEdit
      ? {
          banners: allBanners && homePageId ? allBanners : previewBanner,
        }
      : {
          banners: [
            {
              title: "",
              buttonUrl: "",
              bannerImage: [],
              description: "",
            },
          ],
        },
  });

  const { control, handleSubmit, formState } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "banners",
  });

  useEffect(() => {
    if (!isLoaded) {
      dispatch(getPreviewCourseList({ eventId: id }));
      dispatch(getEventList());
      setIsLoaded(true);
    }
  }, [dispatch, isLoaded]);

  useEffect(() => {
    if (event) {
      setEventName(event.filter((event) => event.id === id)[0].name);
    }
  }, [event]);

  useEffect(() => {
    if (homePageId) {
      dispatch(adminGetAllBannerEdit({ eventId: id }));
    }
  }, [homePageId]);

  useEffect(() => {
    if (fetchCourse && !previewCourses.length) {
      return setCourses(fetchCourse);
    } else {
      if (!previewCourses) {
        return setCourses([]);
      }

      return setCourses(previewCourses);
    }
  }, [fetchCourse]);

  useEffect(() => {
    if (event) {
      setEventName(event.filter((event) => event.id === id)[0].name);
    }
  }, [event]);

  useEffect(() => {
    if (courses) {
      const normalizedCourses = courses.map((course, index) => ({
        ...course,
        courseOrder: course.courseOrder ?? 999 + index,
      }));

      if (JSON.stringify(courses) !== JSON.stringify(normalizedCourses)) {
        setCourses(normalizedCourses);
      }
    }
  }, [courses]);

  useEffect(() => {
    if (allBanners && homePageId) {
      allBanners.forEach((banner, index) => {
        if (index >= form.getValues("banners").length) {
          append({
            title: banner.title,
            buttonUrl: banner.buttonUrl,
            startDate: new Date(moment(banner.startDate)),
            endDate: banner.endDate ? new Date(moment(banner.endDate)) : null,
            bannerImage: banner.bannerImage,
            description: banner.description,
          });
        } else {
          form.setValue(`banners.${index}.title`, banner.title);
          form.setValue(`banners.${index}.buttonUrl`, banner.buttonUrl);
          form.setValue(
            `banners.${index}.startDate`,
            new Date(moment(banner.startDate))
          );
          form.setValue(
            `banners.${index}.endDate`,
            banner.endDate ? new Date(moment(banner.endDate)) : null
          );
          form.setValue(`banners.${index}.bannerImage`, banner.bannerImage);
          form.setValue(`banners.${index}.description`, banner.description);
        }
      });

      dispatch(setPreviewBanner(allBanners));
    }
  }, [allBanners, form, homePageId, append]);

  const handleDeleteBanner = (index) => {
    if (homePageId && allBanners[index]) {
      dispatch(deleteBanner({ bannerId: allBanners[index].id }));
      return false;
    }

    return dispatch(removeBanner(index));
  };

  const validateVisibleCourses = () => {
    const hasCourseVisible =
      courses && courses.filter((course) => course.visible === true);

    if (hasCourseVisible.length === 0) {
      setNeedCourseError(
        intl.formatMessage({
          id: "error.message_add_one_course_banner",
        })
      );

      return false;
    }

    setNeedCourseError(null);
    return true;
  };

  const onSubmit = async (data) => {
    if (!validateVisibleCourses()) return null;

    const previewBanner = allBanners
      ? data.banners.map((banners, index) => {
          return {
            ...banners,
            id: allBanners[index]?.id ?? 0,
          };
        })
      : data.banners.map((banners) => {
          return {
            ...banners,
          };
        });

    dispatch(setPreviewBanner(previewBanner));
    dispatch(setPreviewCourse(courses));
    navigateToPreview();
  };

  const navigateToPreview = () => {
    history.push(`/admin/informa-academy/manage-homepage-banner/${id}/preview`);
  };

  const redirectToEvents = () => {
    dispatch(setPreviewBanner([]));
    dispatch(setPreviewCourse([]));
    dispatch(setIsEdit(false));
    history.push("/admin/informa-academy/manage-homepage-banner/");
  };

  return (
    <AnimatedMainContainer breadcrumb={breadcrumbTitle}>
      <Container>
        <TitleContainer>
          <Title>
            <FormattedMessage id="carousel-banner" />
            <Subtitle>
              {intl.formatMessage({ id: "add_one_to_five_banners" })}
            </Subtitle>
          </Title>
        </TitleContainer>
        <FormContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((item, index) => {
              const startDateWatch = form.watch(`banners.${index}.startDate`);
              const endDateWatch = form.watch(`banners.${index}.endDate`);

              return (
                <div key={item.id}>
                  <FormTitle>
                    Banner {index + 1}
                    {fields.length > 1 && index !== 0 && (
                      <RemoveButton
                        type="button"
                        onClick={() => {
                          handleDeleteBanner(index);
                          remove(index);
                        }}
                      >
                        <FaRegTrashAlt /> Remover
                      </RemoveButton>
                    )}
                  </FormTitle>
                  <FormRow marginBottom="24px">
                    <ControlledInput
                      name={`banners.${index}.title`}
                      control={control}
                      formState={formState}
                      label="title"
                      required
                      counter
                      arrayName="banners"
                      max={180}
                    />
                    <ControlledInput
                      name={`banners.${index}.buttonUrl`}
                      control={control}
                      formState={formState}
                      label="button_url"
                      arrayName="banners"
                      required={false}
                    />
                  </FormRow>
                  <FormRow zIndex="20" marginBottom={"24px"}>
                    <ControlledDatePicker
                      name={`banners.${index}.startDate`}
                      control={control}
                      label="start"
                      required
                      errors={formState.errors}
                      arrayName="banners"
                      minDate={new Date()}
                      onChange={(date) => {
                        if (date > endDateWatch) {
                          form.setValue(`banners.${index}.endDate`, date);
                        }
                      }}
                    />
                    <ControlledDatePicker
                      name={`banners.${index}.endDate`}
                      control={control}
                      label="end"
                      errors={formState.errors}
                      arrayName="banners"
                      minDate={startDateWatch ? startDateWatch : new Date()}
                    />
                  </FormRow>
                  <FormRow marginBottom="24px">
                    <ControlledDropzoneForArray
                      name={`banners.${index}.bannerImage`}
                      errors={formState.errors}
                      control={control}
                      required
                      arrayName="banners"
                      setValue={form.setValue}
                      label="image"
                      description="course_cover_sub"
                      subtitle="banner_cover_sub_2"
                      formMulti={true}
                      index={index}
                      isEdit={isEdit}
                    />
                    <ControlledTextareaJodit
                      name={`banners.${index}.description`}
                      control={control}
                      formState={formState}
                      arrayName="banners"
                      label={"description"}
                      required={true}
                      sub={"description_banner"}
                      max={360}
                      height={248}
                      minHeight={248}
                      maxHeight={248}
                      buttonsType="normal"
                      splitColumn
                    />
                  </FormRow>
                </div>
              );
            })}

            {fields.length < 5 && (
              <AddBanner
                type="button"
                onClick={() =>
                  append({
                    title: "",
                    buttonUrl: "",
                    bannerImage: [],
                    description: "",
                  })
                }
              >
                <AddIcon />
                {intl.formatMessage({ id: "add_new_banner_button" })}
              </AddBanner>
            )}
            <ReorderableTable
              title={intl.formatMessage({ id: "sidebar.courses" })}
              data={courses}
              setCourses={setCourses}
            />

            {needCourseError && (
              <Error>
                <MdError color="#DC2626" size={14} />
                {needCourseError}
              </Error>
            )}

            <ActionButtonsContainer>
              <CancelButton onClick={redirectToEvents}>
                <FaRegTrashAlt />
                {intl.formatMessage({ id: "cancel" })}
              </CancelButton>
              <PreviewButton>
                <FaRegEye />
                {intl.formatMessage({ id: "preview" })}
              </PreviewButton>
            </ActionButtonsContainer>
          </form>
        </FormContainer>
      </Container>
    </AnimatedMainContainer>
  );
};

export default NewHomepageBanner;

function AddIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      fill="none"
      viewBox="0 0 18 19"
    >
      <g stroke="#024" strokeWidth="1.5" clipPath="url(#a)">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9.14H6m3 3v-6"
        ></path>
        <path d="M1.5 9.14a7.5 7.5 0 1 0 15 0 7.5 7.5 0 0 0-15 0Z"></path>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M18 .14v18H0v-18z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
