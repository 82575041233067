import { useIntl } from "react-intl";
import { SelectContainer } from "../AnalyticsTable/styles";
import { Select } from "./styles";

const { Option } = Select;

export default function SelectFilter({
  value,
  setValue,
  label,
  options,
  disabled = false,
  fit = false,
}) {
  const intl = useIntl();

  const handleChange = (value) => {
    setValue(value);
  };

  const labelIntl = intl.formatMessage({
    id: label,
  });
  const noOptions = intl.formatMessage({
    id: "noOptions",
  });

  return (
    <SelectContainer fit={fit}>
      <span>{labelIntl}:</span>

      <Select
        showSearch
        value={value}
        onChange={handleChange}
        defaultValue={""}
        disabled={disabled}
        optionFilterProp="children"
        notFoundContent={<div>{noOptions}</div>}
      >
        <Option value="">
          {intl.formatMessage({
            id: "all",
          })}
        </Option>
        {options.map((opt, i) => (
          <Option value={opt.value} key={i}>
            {opt.label}
          </Option>
        ))}
      </Select>
    </SelectContainer>
  );
}
