import { useEffect, useState } from "react";

const useZoom = () => {
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const detectScale = () => {
      const scaleValue = window.devicePixelRatio;
      setScale(scaleValue);
    };

    detectScale();

    window.addEventListener("resize", detectScale);

    return () => {
      window.removeEventListener("resize", detectScale);
    };
  }, []);

  return { scale };
};

export default useZoom;
