import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #fff;
`;

export const TableContainer = styled.div`
  width: 100%;
  background-color: #fff;
  margin-top: 32px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  gap: 2rem;
`;

export const Title = styled.h1`
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0%;
  vertical-align: middle;
  color: #002244;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin: 0;
`;

export const FieldsContainer = styled.div`
  margin-top: 16px;
`;

export const Horizontal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > div {
    display: flex;
    flex: 1 0 100px;
    margin-left: 16px;
    @media (max-width: 768px) {
      margin-left: 0px;
      flex: 1 1 auto;
      width: 100%;
    }
  }

  & > div:first-child {
    margin-left: 0px;
  }
`;

export const FilterActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  flex-wrap: wrap;

  & > div:first-child {
    margin-left: 0px;
  }

  @media (max-width: 500px) {
    width: 100%;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 16px;
    @media (max-width: 500px) {
      margin-left: 0px;
    }
  }
`;

export const SubmitContainer = styled.div`
  width: 100%;
  max-width: 150px;

  @media (max-width: 500px) {
    margin-left: 0px;
    max-width: none;
    width: 100%;
  }
`;

export const TableTitleContainer = styled.div`
  padding: 16px 0px 16px 24px;
`;

export const TableTitle = styled.p``;

export const CardContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(329px, 328px));
  place-content: center;
  gap: 24px;
`;

export const Card = styled.div`
  border: 1px solid #ebebf0;

  width: 329px;
  height: 190px;
  border-radius: 8px;
  border-width: 1px;
  padding-top: 24px;
  padding-right: 16px;
  padding-bottom: 24px;
  padding-left: 16px;
  gap: 24px;

  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
  }
`;

export const CardHeader = styled.header`
  margin-bottom: 24px;
`;

export const CodeCard = styled.p`
  background-color: #33d27e1a;
  color: #33d27e;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: center;
  vertical-align: middle;

  width: fit-content;

  margin: 0;

  border-radius: 8px;
  padding-top: 4px;
  padding-right: 8px;
  padding-bottom: 4px;
  padding-left: 8px;

  margin-bottom: 4px;
`;

export const NameCard = styled.p`
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0%;
  vertical-align: middle;
  margin: 0;
  color: #3a3a3c;

  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const YearCard = styled(NameCard)`
  font-weight: 400;
  color: #6c6c6c;
`;

export const Divider = styled.div`
  height: 1px;
  background: #ebebf0;
  margin: 24px 0;
`;

export const FooterLink = styled(Link)`
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0%;
  vertical-align: middle;
  text-decoration-style: solid;
  text-decoration-thickness: 0%;
  text-decoration-skip-ink: auto;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #11a7d9;
  text-transform: capitalize;

  &:hover {
    text-decoration: underline;
    color: #11a7d9;
  }
`;

export const FooterCreatePage = styled(FooterLink)`
  color: #002244;

  &:hover {
    text-decoration: underline;
    color: #002244;
  }
`;
