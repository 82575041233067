import {
  ADMIN_GET_ALL_BANNERS,
  ADMIN_GET_ALL_BANNERS_SUCCESS,
  CHANGE_VISIBILITY_COURSE,
  CREATE_BANNERS,
  CREATE_BANNERS_SUCCESS,
  DELETE_BANNER,
  DELETE_BANNER_SUCCESS,
  EDIT_BANNER,
  EDIT_BANNER_SUCCESS,
  FILTER_EVENT_LIST_BY_NAME,
  FILTER_EVENT_LIST_BY_NAME_SUCCESS,
  FILTER_EVENT_LIST_BY_YEAR,
  FILTER_EVENT_LIST_BY_YEAR_SUCCESS,
  GET_BANNER_COURSE_LIST,
  GET_BANNER_COURSE_LIST_SUCCESS,
  GET_EVENT_LIST,
  GET_EVENT_LIST_SUCCESS,
  GET_PREVIEW_COURSE_LIST,
  GET_PREVIEW_COURSE_LIST_SUCCESS,
  REORDER_BANNER_COURSE_LIST,
  SET_BANNER,
  SET_BANNER_SUCCESS,
  SET_COURSES,
  SET_COURSES_SUCCESS,
} from "./types";

const courseInitialState = {
  id: 99999999,
  name: "",
  coverImage: "",
  description: "",
  workload: "99",
  category: "",
  lastUpdatedDate: "2099-09-30T12:46:12.360217-03:00",
  lessons: [
    {
      lessonId: 9999999,
      title: "qweqwe",
      url: "",
      completed: false,
    },
  ],
  courseStatus: undefined,
};

const INITIAL_STATE = {
  event: null,
  bannerCourse: null,
  banner: null,
  allBanners: null,
  courses: [],
  onGoingCourses: [],
  course: courseInitialState,
  lesson: null,
  fetchCourse: null,
  isLoading: false,
  isLoadingCourse: false,
  isLoadingLesson: false,
  error: null,
  nextLessonId: "",
  adminCourse: null,
  adminCourseError: false,
  adminLessons: [],
  adminLessonsError: false,
  latestCourses: [],
  latestCoursesError: null,
  latestCoursesLoading: false,
  allowAccess: true,
  coursesErrorLoading: false,
  coursesLoaded: false,
  courseLoaded: false,
};

const informaAcademy = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_EVENT_LIST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case GET_EVENT_LIST_SUCCESS:
      return {
        ...state,
        event: action.payload,
        isLoading: false,
        error: null,
      };

    case FILTER_EVENT_LIST_BY_NAME:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case FILTER_EVENT_LIST_BY_NAME_SUCCESS:
      return {
        ...state,
        event: action.payload,
        isLoading: false,
        error: null,
      };

    case FILTER_EVENT_LIST_BY_YEAR:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case FILTER_EVENT_LIST_BY_YEAR_SUCCESS:
      return {
        ...state,
        event: action.payload,
        isLoading: false,
        error: null,
      };

    case GET_BANNER_COURSE_LIST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case GET_BANNER_COURSE_LIST_SUCCESS:
      return {
        ...state,
        bannerCourse: action.payload,
        isLoading: false,
        error: null,
      };

    case REORDER_BANNER_COURSE_LIST:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case CREATE_BANNERS:
      return {
        ...state,
        isLoading: true,
        error: true,
      };

    case CREATE_BANNERS_SUCCESS:
      return {
        ...state,
        bannerCreated: action.payload,
        isLoading: false,
        error: true,
      };

    case SET_BANNER:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case SET_BANNER_SUCCESS:
      return {
        ...state,
        banner: action.payload,
        isLoading: false,
        error: null,
      };

    case "SET_BANNER_FAILURE":
      return {
        ...state,
        banner: null,
        isLoading: false,
        error: null,
      };

    case EDIT_BANNER:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case EDIT_BANNER_SUCCESS:
      return {
        ...state,
        bannerCreated: action.payload,
        isLoading: false,
        error: null,
      };

    case DELETE_BANNER:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case DELETE_BANNER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case SET_COURSES:
      return {
        ...state,
        courses: [],
        coursesErrorLoading: false,
      };

    case SET_COURSES_SUCCESS:
      return {
        ...state,
        courses: action.payload.courses,
        onGoingCourses: [action.payload.ongoingCourse],
        coursesLoaded: true,
        coursesErrorLoading: false,
      };

    case "SET_COURSES_FAILURE":
      return {
        ...state,
        courses: [],
        onGoingCourses: [],
        coursesLoaded: true,
        coursesErrorLoading: true,
      };

    case CHANGE_VISIBILITY_COURSE:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case GET_PREVIEW_COURSE_LIST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case GET_PREVIEW_COURSE_LIST_SUCCESS:
      return {
        ...state,
        fetchCourse: action.payload,
        isLoading: false,
        error: null,
      };

    case "GET_INFORMA_ACADEMY_EVENT_BANNER":
      return { ...state, isLoading: true, error: null };

    // ACADEMY COURSES AND LESSONS
    // Academy Course
    case "GET_INFORMA_ACADEMY_COURSE_INFO":
      return {
        ...state,
        isLoadingCourse: true,
        error: null,
        courseLoaded: false,
      };

    case "GET_COURSE_INFO_SUCCESS":
      return {
        ...state,
        isLoadingCourse: false,
        course: action.payload,
        courseLoaded: true,
      };
    case "GET_COURSE_INFO_FAILURE":
      return {
        ...state,
        erro: true,
        isLoadingCourse: false,
        courseLoaded: true,
      };

    case "GET_INFORMA_ACADEMY_LESSON_INFO":
      return { ...state, isLoadingLesson: true, error: null };
    case "GET_LESSON_INFO_SUCCESS":
      return {
        ...state,
        isLoadingLesson: false,
        lesson: action.payload,
      };
    case "GET_LESSON_INFO_FAILURE":
      return { ...state, isLoadingLesson: false, error: action.payload };

    case "START_COURSE":
    case "COMPLETE_COURSE":
    case "RESTART_COURSE":
    case "MARK_LESSON_COMPLETED":
      return { ...state, isLoading: false, error: null };

    case "MARK_LESSON_COMPLETED_SUCCESS":
      return {
        ...state,
        course: {
          ...state.course,
          lessons: state.course.lessons.map((lesson) => {
            return lesson.lessonId === +action.payload
              ? { ...lesson, completed: true }
              : lesson;
          }),
        },
      };

    case "START_COURSE_SUCCESS":
      return {
        ...state,
        course: {
          ...state.course,
          courseStatus: "IN_PROGRESS",
        },
      };

    case "COMPLETE_COURSE_SUCCESS":
      return {
        ...state,
        course: {
          ...state.course,
          courseStatus: "FINISHED",
        },
      };

    case "RESTART_COURSE_SUCCESS":
      return {
        ...state,
        course: {
          ...state.course,
          courseStatus: "NOT_STARTED",
          lessons: state.course.lessons.map((lesson) => ({
            ...lesson,
            completed: false,
          })),
        },
      };

    case "RESET_ADMIN_COURSE":
      return {
        ...state,
        adminCourse: null,
        adminCourseError: false,
      };

    case "GET_ADMIN_COURSE_INFO":
      return {
        ...state,
        adminCourse: null,
        isLoading: true,
        adminCourseError: false,
      };
    case "GET_ADMIN_COURSE_INFO_SUCCESS":
      return {
        ...state,
        isLoading: false,
        adminCourse: action.payload,
      };
    case "GET_ADMIN_COURSE_INFO_FAILURE":
      return {
        ...state,
        adminCourse: null,
        isLoading: false,
        adminCourseError: action.payload,
      };

    case "GET_ADMIN_LESSONS_INFO":
      return { ...state, isLoading: true, adminLessonsError: false };
    case "GET_ADMIN_LESSONS_INFO_SUCCESS":
      return {
        ...state,
        isLoading: false,
        adminLessons: action.payload,
      };
    case "GET_ADMIN_LESSONS_INFO_FAILURE":
      return {
        ...state,
        isLoading: false,
        adminLessonsError: action.payload,
        adminLessons: [],
      };

    case "GET_ADMIN_LASTEST_COURSES":
      return {
        ...state,
        latestCoursesLoading: true,
        latestCoursesError: false,
      };
    case "GET_ADMIN_LASTEST_COURSES_SUCCESS":
      return {
        ...state,
        latestCoursesLoading: false,
        latestCourses: action.payload,
      };
    case "GET_ADMIN_LASTEST_COURSES_FAILURE":
      return { ...state, isLoading: false, error: true };

    case "COURSE_IMPRESSION":
    case "COURSE_IMPRESSION_SUCCESS":
    case "LESSON_INTERACTION":
    case "LESSON_INTERACTION_SUCCESS":
    case "LESSON_LIKE":
    case "LESSON_UNLIKE":
      return { ...state };

    case "LESSON_LIKE_SUCCESS":
      return {
        ...state,
        lesson: {
          ...state.lesson,
          recommendation: true,
        },
      };
    case "LESSON_LIKE_FAILURE":
      return {
        ...state,
      };
    case "LESSON_UNLIKE_SUCCESS":
      return {
        ...state,
        lesson: {
          ...state.lesson,
          recommendation: false,
        },
      };
    case ADMIN_GET_ALL_BANNERS:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case ADMIN_GET_ALL_BANNERS_SUCCESS:
      return {
        ...state,
        allBanners: action.payload,
        isLoading: true,
        error: null,
      };

    case "ALLOW_ACCESS":
      return {
        ...state,
        allowAccess: true,
      };

    case "DONT_ALLOW_ACCESS":
      return {
        ...state,
        allowAccess: false,
      };

    default:
      return state;
  }
};

export default informaAcademy;
