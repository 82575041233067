import * as React from "react";

const ForwardIcon = (props) => {
  const { size = 16, color = "#FFF" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14 12.807v1.333H2v-1.333M10 2.14l3.333 3.333L10 8.806"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13.333 5.473C6.667 5.473 2 6.473 2 10.806"
      ></path>
    </svg>
  );
};

export default ForwardIcon;
