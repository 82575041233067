export const Bold = `
<svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12" fill="none">
<path d="M5.20879 11.5002C7.53232 11.5002 8.97322 10.2931 8.97322 8.38452C8.97322 6.98135 7.92461 5.89502 6.48372 5.7894V5.65361C7.60022 5.48765 8.45269 4.4843 8.45269 3.33008C8.45269 1.64778 7.19285 0.614258 5.08054 0.614258H0.320312V11.5002H5.20879ZM2.59858 2.35691H4.49966C5.58599 2.35691 6.21213 2.87744 6.21213 3.745C6.21213 4.62009 5.54072 5.12554 4.31106 5.12554H2.59858V2.35691ZM2.59858 9.75752V6.6645H4.5751C5.91792 6.6645 6.64968 7.19258 6.64968 8.18838C6.64968 9.20681 5.94055 9.75752 4.62791 9.75752H2.59858Z" fill="#585F74"/>
</svg>
`;

export const Italic = `
<svg xmlns="http://www.w3.org/2000/svg" width="5" height="13" viewBox="0 0 5 13" fill="none">
<path d="M0.429688 12.5003H1.72725L3.45481 4.36797H2.15725L0.429688 12.5003ZM3.30393 2.79883C3.80183 2.79883 4.2092 2.39146 4.2092 1.89355C4.2092 1.39565 3.80183 0.988281 3.30393 0.988281C2.80603 0.988281 2.39866 1.39565 2.39866 1.89355C2.39866 2.39146 2.80603 2.79883 3.30393 2.79883Z" fill="#585F74"/>
</svg>
`;

export const Underline = `
<svg xmlns="http://www.w3.org/2000/svg" width="11" height="15" viewBox="0 0 11 15" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.17 0.614258H2.52791V7.66785C2.52791 9.31243 3.60669 10.5044 5.49268 10.5044C7.37866 10.5044 8.45745 9.31243 8.45745 7.66785V0.614258H9.81536V7.75837C9.81536 10.0819 8.19341 11.7567 5.49268 11.7567C2.79195 11.7567 1.17 10.0819 1.17 7.75837V0.614258ZM0 14.6002H11V13.4002H0V14.6002Z" fill="#585F74"/>
</svg>
`;

export const Strikethrough = `
<svg xmlns="http://www.w3.org/2000/svg" width="11" height="13" viewBox="0 0 11 13" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.56563 2.23438C4.06563 2.23438 3.07344 2.98438 3.07344 4.125C3.07344 4.73171 3.36494 5.1613 4.05191 5.5H1.90358C1.71703 5.11992 1.62813 4.68012 1.62813 4.17188C1.62813 2.25781 3.22969 0.960938 5.6125 0.960938C7.79219 0.960938 9.41719 2.25781 9.53438 4.07812H8.12031C7.925 2.92188 6.97188 2.23438 5.56563 2.23438ZM5.4875 12.7656C3.09688 12.7656 1.44844 11.5 1.3 9.55469H2.72188C2.89375 10.7344 4.01875 11.4844 5.62031 11.4844C7.13594 11.4844 8.25313 10.6641 8.25313 9.54688C8.25313 9.13755 8.12555 8.79201 7.85891 8.5H9.59125C9.6638 8.76599 9.69844 9.05664 9.69844 9.375C9.69844 11.4453 8.06563 12.7656 5.4875 12.7656ZM0 7.59999H11V6.39999H0V7.59999Z" fill="#585F74"/>
</svg>
`;

export const Link = `
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
<path d="M5.99985 6.99985L7.49985 8.49985C8.2809 9.2809 9.7188 9.2809 10.4998 8.49985L13.4998 5.49985C14.2809 4.7188 14.2809 3.2809 13.4998 2.49985L12.9998 1.99985C12.2188 1.2188 10.7809 1.2188 9.99985 1.99985L7.99985 3.99985M6.99985 9.99984L4.99985 11.9998C4.2188 12.7809 2.78089 12.7809 1.99985 11.9998L1.49985 11.4998C0.718797 10.7188 0.718804 9.28089 1.49985 8.49985L4.49986 5.49985C5.2809 4.7188 6.71881 4.7188 7.49986 5.49984L8.99985 6.99985" stroke="#585F74" stroke-width="1.2"/>
</svg>
`;

export const OrderedList = `
<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
<path d="M2.5 13V13.5H3.30902L2.94721 12.7764L2.5 13ZM2.5 11L2.94721 11.2236L3.30902 10.5H2.5V11ZM2 12L1.55279 11.7764L1.44098 12L1.55279 12.2236L2 12ZM1.5 1H2V0.5H1.5V1ZM1 8L0.552786 7.77639L0.190983 8.5H1V8ZM2 6L2.44721 6.22361L2.80902 5.5H2V6ZM5 2.5H14V1.5H5V2.5ZM5 7.5H14V6.5H5V7.5ZM5 12.5H14V11.5H5V12.5ZM0 13.5H2.5V12.5H0V13.5ZM2.5 10.5H0V11.5H2.5V10.5ZM2.94721 12.7764L2.44721 11.7764L1.55279 12.2236L2.05279 13.2236L2.94721 12.7764ZM2.44721 12.2236L2.94721 11.2236L2.05279 10.7764L1.55279 11.7764L2.44721 12.2236ZM0 1.5H1.5V0.5H0V1.5ZM0 3.5H1.5V2.5H0V3.5ZM1.5 3.5H3V2.5H1.5V3.5ZM1 1V3H2V1H1ZM3 7.5H1V8.5H3V7.5ZM1.44721 8.22361L2.44721 6.22361L1.55279 5.77639L0.552786 7.77639L1.44721 8.22361ZM2 5.5H0V6.5H2V5.5Z" fill="#585F74"/>
</svg>
`;

export const List = `
<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
<path d="M4.99997 1.99997H14M4.99997 12H14M4.99997 6.99997H14M2.1015 12C2.1015 12.3322 1.83218 12.6016 1.49997 12.6016C1.16775 12.6016 0.898438 12.3322 0.898438 12C0.898438 11.6678 1.16775 11.3985 1.49997 11.3985C1.83218 11.3985 2.1015 11.6678 2.1015 12ZM2.1015 1.99997C2.1015 2.33218 1.83218 2.6015 1.49997 2.6015C1.16775 2.6015 0.898438 2.33218 0.898438 1.99997C0.898438 1.66775 1.16775 1.39844 1.49997 1.39844C1.83218 1.39844 2.1015 1.66775 2.1015 1.99997ZM2.1015 6.99997C2.1015 7.33218 1.83218 7.6015 1.49997 7.6015C1.16775 7.6015 0.898438 7.33218 0.898438 6.99997C0.898438 6.66775 1.16775 6.39844 1.49997 6.39844C1.83218 6.39844 2.1015 6.66775 2.1015 6.99997Z" stroke="#585F74" stroke-width="1.2"/>
</svg>
`;

export const Image = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="#585F74"
  >
    <path
      d="M17 10C17 9.58577 16.6642 9.25 16.25 9.25C15.8358 9.25 15.5 9.58577 15.5 10H17ZM9.5 4C9.91423 4 10.25 3.66421 10.25 3.25C10.25 2.83579 9.91423 2.5 9.5 2.5V4ZM15.125 16H3.875V17.5H15.125V16ZM3.5 15.625V4.375H2V15.625H3.5ZM15.5 10V15.625H17V10H15.5ZM3.875 4H9.5V2.5H3.875V4ZM3.875 16C3.66789 16 3.5 15.8321 3.5 15.625H2C2 16.6605 2.83946 17.5 3.875 17.5V16ZM15.125 17.5C16.1605 17.5 17 16.6605 17 15.625H15.5C15.5 15.8321 15.3321 16 15.125 16V17.5ZM3.5 4.375C3.5 4.16789 3.66789 4 3.875 4V2.5C2.83947 2.5 2 3.33946 2 4.375H3.5Z"
      fill="#585F74"
    />
    <path
      d="M2.5 14.5835L6.95546 10.4993C7.26621 10.2144 7.74079 10.2065 8.06088 10.4809L13.3333 15.0002"
      stroke="#585F74"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.666 12.9166L13.655 10.9277C13.9482 10.6344 14.4124 10.6014 14.7442 10.8503L17.4993 12.9166"
      stroke="#585F74"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 5H17.5"
      stroke="#585F74"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 2.5V7.5"
      stroke="#585F74"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
`;

export const Table = `
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
<path d="M2 3.99219V6.99219H6V3.99219H2ZM2 7.99219V10.9922H6V7.99219H2ZM2 11.9922V14.9922H6V11.9922H2ZM7 14.9922H11V11.9922H7V14.9922ZM12 14.9922H16V11.9922H12V14.9922ZM16 10.9922V7.99219H12V10.9922H16ZM16 6.99219V3.99219H12V6.99219H16ZM17.5 14.9922C17.5 15.39 17.342 15.7715 17.0607 16.0528C16.7794 16.3342 16.3978 16.4922 16 16.4922H2C1.60218 16.4922 1.22064 16.3342 0.93934 16.0528C0.658035 15.7715 0.5 15.39 0.5 14.9922V1.99219C0.722 1.12919 1.568 0.492188 2.5 0.492188H15.5C16.432 0.492188 17.278 1.12919 17.5 1.99219V14.9922ZM11 10.9922V7.99219H7V10.9922H11ZM11 6.99219V3.99219H7V6.99219H11Z" fill="#585F74"/>
</svg>
`;

export const Video = `
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
<path d="M18.6792 0.021748C19.2792 0.021748 19.2692 -0.00825197 19.2692 0.571748V13.4117C19.2692 14.0017 19.2792 13.9717 18.6792 13.9717H1.28923C0.689229 13.9717 0.699229 14.0017 0.699229 13.4117V0.571748C0.699229 -0.00825197 0.689229 0.021748 1.29923 0.021748H18.6792ZM15.7692 11.9917V1.99175H4.19923V11.9917H15.7692ZM1.99923 0.991748V1.99175H2.99923V0.991748H1.99923ZM1.99923 2.99175V3.99175H2.99923V2.99175H1.99923ZM1.99923 4.99175V5.99175H2.99923V4.99175H1.99923ZM1.99923 6.99175V7.99175H2.99923V6.99175H1.99923ZM1.99923 8.99175V9.99175H2.99923V8.99175H1.99923ZM1.99923 10.9917V11.9917H2.99923V10.9917H1.99923ZM16.9992 0.991748V1.99175H17.9992V0.991748H16.9992ZM16.9992 2.99175V3.99175H17.9992V2.99175H16.9992ZM16.9992 4.99175V5.99175H17.9992V4.99175H16.9992ZM16.9992 6.99175V7.99175H17.9992V6.99175H16.9992ZM16.9992 8.99175V9.99175H17.9992V8.99175H16.9992ZM16.9992 10.9917V11.9917H17.9992V10.9917H16.9992ZM7.49923 4.16875C7.49912 4.09916 7.51716 4.03075 7.55158 3.97027C7.586 3.90979 7.6356 3.85933 7.69548 3.82389C7.75537 3.78844 7.82346 3.76923 7.89304 3.76815C7.96262 3.76708 8.03128 3.78417 8.09223 3.81775L13.2252 6.64175C13.2877 6.67634 13.3399 6.72705 13.3762 6.7886C13.4124 6.85015 13.4316 6.9203 13.4316 6.99175C13.4316 7.0632 13.4124 7.13335 13.3762 7.1949C13.3399 7.25645 13.2877 7.30716 13.2252 7.34175L8.09223 10.1657C8.03135 10.1993 7.96279 10.2164 7.89329 10.2153C7.8238 10.2143 7.75577 10.1952 7.69591 10.1599C7.63606 10.1245 7.58644 10.0742 7.55195 10.0139C7.51746 9.95354 7.49929 9.88525 7.49923 9.81575V4.16875Z" fill="#585F74"/>
</svg>
`;

export const Paragraph = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M15.4486 18.2992C16.0006 18.2992 16.4486 17.8512 16.4486 17.2992V7.70117H17.2726C17.8246 7.70117 18.2726 7.25317 18.2726 6.70117C18.2726 6.14917 17.8246 5.70117 17.2726 5.70117H9.36956C7.36056 5.70117 5.72656 7.33517 5.72656 9.34417C5.72656 11.3532 7.36056 12.9872 9.36956 12.9872H10.9996V17.2992C10.9996 17.8512 11.4476 18.2992 11.9996 18.2992C12.5516 18.2992 12.9996 17.8512 12.9996 17.2992V7.70117H14.4486V17.3002C14.4486 17.8522 14.8966 18.2992 15.4486 18.2992ZM10.9996 10.9872H9.36956C8.46356 10.9872 7.72656 10.2502 7.72656 9.34417C7.72656 8.43817 8.46356 7.70117 9.36956 7.70117H10.9996V10.9872Z" fill="#585F74"/>
</svg>
`;

export const FontSize = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M12.0282 7.22607H5.82422C5.27122 7.22607 4.82422 7.59597 4.82422 8.05173V9.02188C4.82422 9.47764 5.27122 9.84753 5.82422 9.84753C6.37722 9.84753 6.82422 9.47764 6.82422 9.02188V8.87739H7.92722V15.2936H7.73722C7.18422 15.2936 6.73722 15.6635 6.73722 16.1192C6.73722 16.575 7.18422 16.9449 7.73722 16.9449H10.1162C10.6692 16.9449 11.1162 16.575 11.1162 16.1192C11.1162 15.6635 10.6692 15.2936 10.1162 15.2936H9.92722V8.87739H11.0292V9.02188C11.0292 9.47764 11.4762 9.84753 12.0292 9.84753C12.5822 9.84753 13.0292 9.47764 13.0292 9.02188V8.05173C13.0282 7.59597 12.5812 7.22607 12.0282 7.22607Z" fill="#585F74"/>
<path d="M17.522 14.4703L17.449 14.5223V9.6196L17.522 9.67162C17.942 9.96885 18.574 9.93005 18.932 9.5841C19.292 9.23897 19.244 8.71716 18.827 8.41992L17.109 7.1996C16.733 6.93292 16.177 6.93374 15.803 7.20043L14.093 8.41992C13.675 8.71798 13.628 9.2398 13.989 9.58492C14.187 9.774 14.466 9.8706 14.746 9.8706C14.978 9.8706 15.211 9.80455 15.399 9.66996L15.448 9.63529V14.5067L15.4 14.472C14.985 14.1747 14.352 14.2119 13.99 14.557C13.629 14.9021 13.676 15.4231 14.094 15.722L15.804 16.9415C15.992 17.0753 16.224 17.1421 16.457 17.1421C16.689 17.1421 16.922 17.0753 17.109 16.9423L18.827 15.7228C19.245 15.4256 19.293 14.9038 18.932 14.5587C18.574 14.2127 17.942 14.1747 17.522 14.4703Z" fill="#585F74"/>
</svg>`;
