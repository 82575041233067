import React, { useCallback, useMemo, useState } from "react";

import {
  LHeader,
  LContainer,
  LInfoContainer,
  LSidebarContainer,
  Info,
  H1,
  H2,
  P,
  Text,
  DownloadCard,
  DownloadCardContainer,
  DownloadCardInfo,
  SidebarCard,
  SidebarCardImg,
  SidebarContent,
  Animation,
  AnimationContainer,
  Link,
  FalseLink,
} from "./styles";
import { Breadcrumb, Divider } from "antd";
import Home from "../../../newUXAssets/icons/Home";
import { FaChevronRight, FaArrowRight } from "react-icons/fa";
import Button from "../components/Button";
import BackButton from "../components/BackButton";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getInformaAcademyCourseInfo,
  getInformaAcademyLessonInfo,
  lessonInteraction,
  lessonLike,
  lessonUnlike,
  markLessonCompleted,
  setCourses,
  startCourse,
} from "../../../redux/modules/informaAcademy/actions";
import { Container } from "../Course/styles";

import CourseProgression from "../components/CourseProgression";
import InformaAcademyVideo, { getVideoId } from "../components/Videos";
import File from "../../../newUXAssets/icons/InformaAcademyFile";
import DownloadIcon from "../../../newUXAssets/icons/InformaAcademyDownload";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FormattedMessage, useIntl } from "react-intl";
import Recommend from "../../../newUXAssets/icons/Recommend";
import { FaEye } from "react-icons/fa";
import { LessonLoaderSkelleton } from "./skeleton";
import useNotification from "../../../hooks/newUXNotification";

export default function InformaAcademyLesson() {
  const { courseId, lessonId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { course, lesson, error, courses, coursesLoaded, courseLoaded } =
    useSelector((state) => state.informaAcademy);
  const notification = useNotification();
  const { event } = useSelector((state) => state.initialSettings);
  const [hasStartedCourse, setHasStartedCourse] = useState(false);

  const completeLesson = useCallback(() => {
    dispatch(
      markLessonCompleted({
        lessonId,
        courseId,
      })
    );
  }, [courseId, dispatch, lessonId]);

  useEffect(() => {
    dispatch(
      getInformaAcademyCourseInfo({
        courseId,
      })
    );
  }, [courseId, dispatch]);

  useEffect(() => {
    if (!course || hasStartedCourse) return;

    if (courseLoaded && course.courseStatus === "NOT_STARTED") {
      setHasStartedCourse(true);
      dispatch(startCourse({ courseId }));
    }
  }, [course, courseId, courseLoaded, dispatch, hasStartedCourse]);

  useEffect(() => {
    if (!courseId && !lessonId) return;

    dispatch(
      getInformaAcademyLessonInfo({
        lessonId,
        courseId,
      })
    );
  }, [courseId, dispatch, lessonId]);

  useEffect(() => {
    if (coursesLoaded) return;
    dispatch(setCourses({}));
  }, [dispatch, coursesLoaded]);

  useEffect(() => {
    if (isNaN(event?.value)) return;
    if (
      coursesLoaded &&
      !courses.some((course) => course.courseId === +courseId)
    ) {
      notification.displayInfo(
        intl.formatMessage({ id: "error.course_not_allowed" }),
        "error.course_not_allowed"
      );
      history.push("/checklist");
    }
  }, [
    courseId,
    courses,
    coursesLoaded,
    event?.value,
    history,
    intl,
    notification,
  ]);

  useEffect(() => {
    if (error) {
      history.push("/not-found");
    }

    if (isNaN(event?.value)) {
      history.push("/checklist");
    }
  }, [error, event?.value, history]);

  if (!course || !lesson) {
    return (
      <LessonLoaderSkelleton courseId={courseId} coursename={course?.name} />
    );
  }

  const lessonIndex = course.lessons.findIndex(
    (i) => i.lessonId === lesson.lessonId
  );

  return (
    <Container>
      <LessonHeader
        courseId={courseId}
        courseTitle={course.name}
        completeLesson={completeLesson}
        courseStatus={course.courseStatus}
        lessons={course.lessons}
        lessonId={lessonId}
        recommendedLesson={lesson?.recommendation}
      />
      <LContainer>
        <LessonInfo
          key={lessonId}
          lesson={lesson}
          lessonId={lessonId}
          lessonIndex={lessonIndex}
          lessons={course.lessons}
          courseId={courseId}
          completeLesson={completeLesson}
          courseStatus={course.courseStatus}
        />
        <LessonSidebar
          lessons={course.lessons}
          courseId={courseId}
          currentLesson={lessonId}
        />
      </LContainer>
    </Container>
  );
}

function LessonHeader({
  courseTitle,
  courseId,
  completeLesson,
  courseStatus,
  lessons,
  lessonId,
  recommendedLesson,
}) {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const isAllLessonsCompleted = lessons.every((l) => l.completed === true);

  const recommendLessonString = intl.formatMessage({
    id: "recommendLesson",
  });
  const recommendedLessonString = intl.formatMessage({
    id: "recommendedLesson",
  });
  const finishLessonString =
    isAllLessonsCompleted && courseStatus === "IN_PROGRESS"
      ? intl.formatMessage({
          id: "finishCourse",
        })
      : intl.formatMessage({
          id: "finishLesson",
        });

  const handleRecommend = () => {
    if (recommendedLesson)
      return dispatch(
        lessonUnlike({
          courseId,
          lessonId,
        })
      );

    return dispatch(
      lessonLike({
        courseId,
        lessonId,
      })
    );
  };

  const handleConclude = () => {
    const mappedLessons = lessons.map((lesson) => {
      return lesson.lessonId === +lessonId
        ? { ...lesson, completed: true }
        : lesson;
    });
    const nextLesson = getNextLessonId({
      lessons: mappedLessons,
    });

    completeLesson();

    if (nextLesson)
      return history.push(
        `/informa-academy/course/${courseId}/lesson/${nextLesson}`
      );

    if (isAllLessonsCompleted && courseStatus === "IN_PROGRESS")
      return history.push(`/informa-academy/course/${courseId}/complete`);
  };

  return (
    <LHeader>
      <LessonBreadCrumb courseId={courseId} courseName={courseTitle} />

      <div className="buttons">
        <Button
          label={
            recommendedLesson ? recommendedLessonString : recommendLessonString
          }
          Icon={
            recommendedLesson ? (
              <IoIosCheckmarkCircle size={18} color="#FFF" />
            ) : (
              <Recommend size="16" stroke="none" fill="#093348" />
            )
          }
          variant={
            recommendedLesson ? "academyRecommended" : "academyRecommend"
          }
          size={"academy"}
          iconEnd={true}
          onClick={handleRecommend}
        />
        <Button
          label={finishLessonString}
          variant={"academy"}
          size={"academy"}
          iconEnd={true}
          Icon={<FaArrowRight />}
          onClick={handleConclude}
        />
      </div>
    </LHeader>
  );
}

const LessonInfo = React.memo(
  ({
    lesson,
    lessonIndex,
    lessons,
    lessonId,
    courseId,
    completeLesson,
    courseStatus,
  }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const isAllLessonsCompleted = lessons.every((l) => l.completed === true);

    const lastLesson = useMemo(() => {
      const lessonsToComplete = lessons.filter((l) => l.completed === false);

      return (
        lessonsToComplete.length === 1 &&
        +lessonId === lessonsToComplete[0]?.lessonId
      );
    }, [lessonId, lessons]);

    const moveToNextLesson = useCallback(() => {
      const lessons1 = lessons.map((lesson) => {
        return lesson.lessonId === +lessonId
          ? { ...lesson, completed: true }
          : lesson;
      });
      const nextLesson = getNextLessonId({
        lessons: lessons1,
      });

      if (nextLesson)
        return history.push(
          `/informa-academy/course/${courseId}/lesson/${nextLesson}`
        );

      if (isAllLessonsCompleted && courseStatus === "IN_PROGRESS")
        return history.push(`/informa-academy/${courseId}/complete`);
    }, [
      lessons,
      lessonId,
      history,
      courseId,
      isAllLessonsCompleted,
      courseStatus,
    ]);

    const interaction = useCallback(() => {
      dispatch(
        lessonInteraction({
          courseId,
          lessonId,
        })
      );
    }, [dispatch, courseId, lessonId]);

    return (
      <AnimationContainer>
        <Animation />
        <LInfoContainer appear>
          <InformaAcademyVideo
            url={lesson.url}
            interaction={interaction}
            completeLesson={completeLesson}
            moveToNextLesson={moveToNextLesson}
            lastLesson={lastLesson}
          />
          <Info>
            <LessonInfoTitle
              title={lesson.lessonTitle}
              instructor={lesson.lessonInstructor}
              index={lessonIndex + 1}
            />
            <LessonInfoDescription description={lesson.lessonDescription} />
            <LessonInfoDownloadFiles files={lesson.lessonFiles} />
          </Info>
        </LInfoContainer>
      </AnimationContainer>
    );
  },
  (prevProps, nextProps) => {
    // Custom comparison function to determine if re-render is needed
    return (
      prevProps.lessonId === nextProps.lessonId &&
      prevProps.lesson === nextProps.lesson &&
      prevProps.lessonIndex === nextProps.lessonIndex &&
      prevProps.courseStatus === nextProps.courseStatus &&
      prevProps.coverImage === nextProps.coverImage &&
      prevProps.lessons === nextProps.lessons
    );
  }
);

function LessonInfoTitle({ title, instructor, index }) {
  return (
    <div>
      <H1>{`${index}. ${title}`}</H1>
      <H2>{instructor}</H2>
    </div>
  );
}

function LessonInfoDescription({ description }) {
  const intl = useIntl();

  const aboutLesson = intl.formatMessage({
    id: "aboutLesson",
  });

  return (
    <div>
      <P>{aboutLesson}</P>
      <Text dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
}

function LessonInfoDownloadFiles({ files }) {
  const intl = useIntl();
  const text = intl.formatMessage({
    id: "downloadableFiles",
  });

  if (files.length === 0) return null;

  return (
    <div>
      <P>{text}</P>
      <DownloadCardContainer>
        {files.map((file, index) => (
          <DownloadFileCard key={index} file={file} />
        ))}
      </DownloadCardContainer>
    </div>
  );
}

function DownloadFileCard({ file }) {
  const { fileUrl, fileSize, fileName } = file;

  const over1mb = fileSize > 1024;
  const size = over1mb
    ? `${(fileSize / 1024 / 1024).toFixed(2)}mb`
    : `${fileSize}kb`;
  return (
    <DownloadCard
      href={fileUrl}
      download={true}
      target="_blank"
      title={fileName}
    >
      <File />
      <DownloadCardInfo>
        <p>{fileName}</p>
        <span>{size}</span>
      </DownloadCardInfo>
      <DownloadIcon />
    </DownloadCard>
  );
}

function LessonSidebar({ lessons, courseId, currentLesson }) {
  const total = lessons.length;

  const concluded = lessons.filter((l) => l.completed === true).length;
  const lastIndex = total - 1;

  return (
    <LSidebarContainer appear>
      <CourseProgression
        concludedLessons={concluded}
        totalLessons={total}
        fullsize={true}
      />
      <section>
        {lessons.map((lesson, index) => (
          <LessonSidebarCard
            key={lesson.lessonId}
            index={index}
            lastLesson={index === lastIndex}
            lessonImageUrl={lesson.urlVideo}
            title={lesson.title}
            lessonId={lesson.lessonId}
            courseId={courseId}
            concluded={lesson.completed}
            currentLesson={currentLesson}
          />
        ))}
      </section>
    </LSidebarContainer>
  );
}

function LessonSidebarCard({
  lastLesson,
  index,
  title,
  lessonImageUrl,
  lessonId,
  courseId,
  concluded,
  currentLesson,
}) {
  const history = useHistory();
  const [url, setUrl] = useState("");

  useEffect(() => {
    const youtubeRegex =
      /(youtube\.com\/(watch\?v=|playlist\?list=|embed\/|v\/)?|youtu\.be\/)[a-zA-Z0-9_-]+/;
    const vimeoRegex =
      /(vimeo\.com\/(channels\/[a-zA-Z0-9_-]+\/|groups\/[a-zA-Z0-9_-]+\/videos\/)?)[0-9]+/;

    if (youtubeRegex.test(lessonImageUrl)) {
      const videoId = getVideoId("youtube", lessonImageUrl);
      return setUrl(`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`);
    }

    if (vimeoRegex.test(lessonImageUrl)) {
      const videoId = getVideoId("vimeo", lessonImageUrl);
      return setUrl(`https://vumbnail.com/${videoId}.jpg`);
    }
  }, [lessonImageUrl]);

  const handleRedirect = () => {
    history.push(`/informa-academy/course/${courseId}/lesson/${lessonId}`);
  };

  return (
    <div>
      <SidebarCard onClick={handleRedirect}>
        <SidebarContent>
          <SidebarCardImg src={url} alt={title} />
          <p>
            {index + 1}. {title}
          </p>
        </SidebarContent>

        {lessonId === +currentLesson && (
          <FaEye size={22} color={concluded ? "#018326" : "#11a7d9"} />
        )}

        {concluded && lessonId !== +currentLesson && (
          <IoIosCheckmarkCircle size={24} color="#018326" />
        )}
      </SidebarCard>
      {!lastLesson && <Divider />}
    </div>
  );
}

export function LessonSidebarCardPreview({
  inView,
  order,
  title,
  lessonImageUrl,
  concluded,
}) {
  const [url, setUrl] = useState("");

  useEffect(() => {
    const youtubeRegex =
      /(youtube\.com\/(watch\?v=|playlist\?list=|embed\/|v\/)?|youtu\.be\/)[a-zA-Z0-9_-]+/;
    const vimeoRegex =
      /(vimeo\.com\/(channels\/[a-zA-Z0-9_-]+\/|groups\/[a-zA-Z0-9_-]+\/videos\/)?)[0-9]+/;

    if (youtubeRegex.test(lessonImageUrl)) {
      const videoId = getVideoId("youtube", lessonImageUrl);
      return setUrl(`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`);
    }

    if (vimeoRegex.test(lessonImageUrl)) {
      const videoId = getVideoId("vimeo", lessonImageUrl);
      return setUrl(`https://vumbnail.com/${videoId}.jpg`);
    }
  }, [lessonImageUrl]);

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {!concluded && !inView && <FormattedMessage id={"default"} />}
      {!concluded && inView && <FormattedMessage id={"inView"} />}
      {concluded && !inView && <FormattedMessage id={"concluded"} />}
      {concluded && inView && <FormattedMessage id={"inView_concluded"} />}
      <SidebarCard>
        <SidebarContent>
          <SidebarCardImg src={url} alt={title} />
          <p>
            {order}. {title}
          </p>
        </SidebarContent>

        {inView && (
          <FaEye size={20} color={concluded ? "#018326" : "#11a7d9"} />
        )}

        {concluded && !inView && (
          <IoIosCheckmarkCircle size={20} color="#018326" />
        )}
      </SidebarCard>
      <Divider />
    </div>
  );
}

const getNextLessonId = ({ lessons }) => {
  const lastCompletedLessonIndex = lessons.findLastIndex(
    (l) => l.completed === true
  );

  if (lastCompletedLessonIndex === -1) {
    return lessons[0].lessonId;
  }

  if (lastCompletedLessonIndex + 1 === lessons.length) {
    const index = lessons.findIndex((l) => l.completed === false);
    return lessons[index]?.lessonId;
  }

  return lessons[lastCompletedLessonIndex + 1].lessonId;
};

export function LessonBreadCrumb({ courseId, courseName }) {
  const history = useHistory();
  const intl = useIntl();
  return (
    <div className="bread">
      <BackButton href={`/informa-academy/course/${courseId}`} />
      <Breadcrumb separator={<FaChevronRight color="#11A7D9" />}>
        <Breadcrumb.Item>
          <Link href="/" className="center">
            <Home />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link onClick={() => history.push("/informa-academy")}>
            Informa Academy
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link
            onClick={() => history.push(`/informa-academy/course/${courseId}`)}
          >
            {courseName}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <FalseLink>
            {intl.formatMessage({
              id: "playCourse",
            })}
          </FalseLink>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
}
