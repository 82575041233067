import styled, { css } from "styled-components";

export const ManualCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 64px;
  margin-bottom: 24px;
`;

export const ManualCardContainer = styled.div`
  width: 200px;
  height: 100px;
  border-radius: 4px;
  /* padding: 24px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;

  background-color: ${(props) =>
    props.label === "total" ? "#56BFE2" : "#4A627A"};

  & p {
    color: #fff;
    /* font-weight: 600; */
    margin-bottom: 0px;
    text-transform: uppercase;

    &.label {
      font-size: 15px;
      line-height: 20.43px;
    }

    &.value {
      font-size: 30px;
      line-height: 40.85px;
    }
  }
`;

export const ContainerHeader = styled.div`
  padding-bottom: 20px;
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;

  & > h2 {
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    color: #002244;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;

  ${(props) =>
    props.end &&
    css`
      width: fit-content;
      margin-left: auto;
    `}
`;
