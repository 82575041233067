import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import { useIntl } from "react-intl";
import Steps from "../../../components/Steps";
import { useToast } from "../../../hooks/toast";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../service";
import { convertCourseToFormData, convertLessonToFormData } from "../new";
import { Container } from "../styles";
import CourseForm from "../components/course";
import Lesson from "../components/Lesson";
import { ButtonContainer } from "../new/styles";
import Button from "../components/button";
import Restart from "../../../newUXAssets/icons/Restart";
import Save from "../../../icons/Save";
import {
  getAdminCourseInfo,
  getAdminLessonsInfo,
} from "../../../redux/modules/informaAcademy/actions";
import { LoadingSpinnerLogo } from "../../../components/LoadingOverlay/styles";
import LogoCircle from "../../../assets/images/Informa_Logo_Circle.png";
import { LoaderContainer } from "./styles";

export default function EditCourse() {
  const { courseId } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [course, setCourse] = useState(null);
  const [lessons, setLessons] = useState([]);
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { token } = useSelector((state) => state.auth);
  const {
    adminLessonsError,
    adminCourseError,
    adminLessons,
    adminCourse,
    isLoading,
  } = useSelector((state) => state.informaAcademy);
  const [lessonFetchErro, setLessonFetchErro] = useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    dispatch(getAdminCourseInfo({ courseId }));
    dispatch(getAdminLessonsInfo({ courseId }));
  }, [courseId, dispatch]);

  useEffect(() => {
    if (adminCourse) setCourse(adminCourse);
    if (adminLessons) setLessons(adminLessons);
  }, [adminCourse, adminLessons]);

  useEffect(() => {
    if (adminCourseError) {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "admin_course_error",
        }),
      });
    }
    if (adminLessonsError) {
      setLessonFetchErro(true);
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "admin_lessons_error",
        }),
      });
    }
    if (adminCourseError) {
      history.push("/admin/informa-academy");
    }
  }, [addToast, adminCourseError, adminLessonsError, history, intl]);

  const refatchLessonData = useCallback(async () => {
    try {
      addToast({
        type: "info",
        title: intl.formatMessage({
          id: "admin_lessons.refetch",
        }),
      });
      const response = await api.get(
        `/informa-academy/lesson/course/${courseId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { data: lessons } = response;
      const parsedLessons = lessons.lesson.map((lesson) => {
        const L = {
          ...lesson,
          files: lesson.file,
        };
        delete L.file;
        return L;
      });

      setLessons((prev) => [...prev, ...parsedLessons]);
      setLessonFetchErro(false);

      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "admin_lessons_succcess.refetch",
        }),
      });
    } catch (error) {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "admin_lessons_error.refetch",
        }),
      });
    }
  }, [addToast, courseId, intl, token]);

  if (isLoading || !course)
    return (
      <AnimatedMainContainer
        breadcrumb={intl.formatMessage({
          id: "edit_course",
        })}
      >
        <LoaderContainer>
          <LoadingSpinnerLogo src={LogoCircle} />
        </LoaderContainer>
      </AnimatedMainContainer>
    );

  const postLessons = async ({ courseId, lessons }) => {
    try {
      await Promise.all(
        lessons.map((lesson) =>
          api.post(
            "informa-academy/lesson",
            convertLessonToFormData(lesson, courseId),
            config
          )
        )
      );
    } catch (err) {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error.lessons_create",
        }),
      });
    }
  };

  const putLessons = async ({ lessons }) => {
    try {
      await Promise.all(
        lessons.map(async (lesson) => {
          return await api.put(
            `/informa-academy/lesson/${lesson.id}`,
            convertLessonToFormData(lesson, courseId),
            config
          );
        })
      );

      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "success.lessons_updated",
        }),
      });
    } catch (err) {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error.lessons_update",
        }),
      });
    }
  };

  const editLessons = async () => {
    if (lessons.length === 0)
      return addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error.lessons_empty",
        }),
      });

    const lessonsWithoutId = lessons.filter((lesson) => !lesson.id);
    const lessonsWithId = lessons.filter((lesson) => lesson.id);

    if (lessonsWithoutId.length > 0) {
      await postLessons({
        courseId,
        lessons: lessonsWithoutId,
      });
    }

    await putLessons({
      lessons: lessonsWithId,
    });
  };

  const editCourse = async () => {
    try {
      const formDataCourse = convertCourseToFormData(course);
      await api.put(
        `/informa-academy/course/${courseId}`,
        formDataCourse,
        config
      );

      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "success.course_updated",
        }),
      });

      return history.push("/admin/informa-academy");
    } catch (err) {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error.course_update",
        }),
      });

      return "error";
    }
  };

  const handleSubmit = async () => {
    if (lessons.length < 1) {
      return addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error.lessons_create_min",
        }),
      });
    }
    await editLessons();
    await editCourse();
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({
        id: "edit_course",
      })}
    >
      <Steps
        currentStep={currentStep}
        steps={["course_information", "course_lessons"]}
      />
      <Container key={courseId}>
        <CourseForm
          defaultValue={course}
          setCourse={setCourse}
          step={currentStep}
          goToLessons={() => setCurrentStep(1)}
        />
        {currentStep === 1 && (
          <Lesson
            lessons={lessons}
            setLessons={setLessons}
            hasError={lessonFetchErro}
            refecthData={refatchLessonData}
          />
        )}

        {currentStep === 1 && (
          <ButtonContainer>
            <Button
              label={"goback"}
              variant={"secondary"}
              Icon={<Restart stroke={"#002244"} />}
              onClick={() => setCurrentStep(0)}
            />

            <Button label={"save"} Icon={<Save />} onClick={handleSubmit} />
          </ButtonContainer>
        )}
      </Container>
    </AnimatedMainContainer>
  );
}
