import { Link } from "react-router-dom";
import styled from "styled-components";

export const PageTitleContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  margin: 0 24px 24px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
`;

export const PageTitle = styled.p`
  margin-bottom: 0px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0%;
  vertical-align: middle;
  color: #000000d9;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const EditButton = styled.button`
  width: fit-content;
  padding: 4px 1rem;
  background: #e2e8f0;
  color: #000;
  font-size: 0.875rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 5px;
  text-transform: uppercase;

  &:hover {
    opacity: 0.7;
    color: #000;
  }
`;

export const PublishButton = styled(Link)`
  width: fit-content;
  padding: 4px 1rem;
  background: #002244;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 5px;
  text-transform: uppercase;

  &:hover {
    opacity: 0.7;
    color: #fff;
  }
`;

export const Container = styled.div`
  /* width: 100%;
  padding: 1rem 5rem;
  display: flex;
  justify-content: center; */
  background-color: #fff;
`;

export const HomepageContainer = styled.div`
  max-width: 1142px;
  margin: 0 auto;
  display: grid;
  gap: 24px;
`;

export const InProgressSection = styled.section`
  width: 100%;
  background: linear-gradient(93.65deg, #002244 1.29%, #051a6c 130.28%);
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InProgressContent = styled.div`
  font-family: "Poppins";
  color: #fff;
`;

export const InProgressText = styled.p`
  font-size: 1.25rem;
  margin: 0;
`;

export const InProgressTitle = styled.p`
  font-size: 1.625rem;
  font-weight: 700;
  margin: 0;
`;

export const InProgressLesson = styled.p`
  font-size: 1rem;
  margin: 0;
`;

export const InProgressButton = styled(Link)`
  width: fit-content;
  padding: 1rem 1.5rem;
  background: #11a7d9;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 5px;
  text-transform: uppercase;

  &:hover {
    opacity: 0.7;
    color: #fff;
  }
`;
