import { Controller, useWatch } from "react-hook-form";
import { Description, Error, FormItem, InputLabel, Required } from "./styles";
import { MdError } from "react-icons/md";
import Select, { components } from "react-select";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";

export default function ControlledSelect({
  name,
  control,
  defaultValue,
  type = "text",
  disabled = false,
  onChange,
  min,
  max,
  placeholder,
  step,
  label,
  required,
  description,
  errors,
  closeMenuOnSelect = true,
  ...props
}) {
  const intl = useIntl();
  const [error, setError] = useState(false);
  const selectWatch = useWatch({ control, name });

  useEffect(() => {
    if (selectWatch?.length > 0) {
      return setError(false);
    }

    if (errors?.[name]) {
      return setError(true);
    }

    return setError(false);
  }, [errors, selectWatch, name]);

  const errorMessage = intl.formatMessage({ id: "error.field_required" });

  const labelIntl = intl.formatMessage({
    id: label,
  });

  const isRequired = required && <Required>*</Required>;
  const hasDesc = description && (
    <Description>
      (
      {intl.formatMessage({
        id: description,
      })}
      )
    </Description>
  );

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="">
          {" "}
          {intl.formatMessage({
            id: "noOptions",
          })}
        </span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => {
        return (
          <FormItem>
            <InputLabel htmlFor={name}>
              {labelIntl}
              {isRequired} {hasDesc}
            </InputLabel>

            <Select
              {...props}
              {...field}
              components={{ NoOptionsMessage }}
              value={field.value}
              defaultValue={defaultValue}
              onChange={(selectedOption) => {
                field.onChange(selectedOption);
                if (onChange) onChange(selectedOption);
              }}
              isDisabled={disabled}
              closeMenuOnSelect={closeMenuOnSelect}
              classNamePrefix="react-select"
              menuPortalTarget={document.body}
              placeholder={placeholder}
              styles={{
                placeholder: (base) => ({
                  ...base,
                  fontSize: "14px",
                  color: "#002244",
                  fontWeight: 400,
                  paddingLeft: "16px",
                }),
                singleValue: (base) => ({
                  ...base,
                  fontSize: "14px",
                  color: "#002244",
                  fontWeight: 400,
                  paddingLeft: "16px",
                }),
                valueContainer: (base) => ({
                  ...base,
                  paddingTop: "8px",
                  paddingBottom: "8px",
                }),
                menuList: (base) => ({
                  ...base,
                  zIndex: "9999999999999",
                }),
                menuPortal: (base) => ({
                  ...base,
                  zIndex: "9999999999999",
                }),
                control: (base) => ({
                  ...base,
                  borderColor: errors?.[name] ? "#e00000" : "#dadada",
                  ":hover": {
                    borderColor: errors?.[name] ? "#e00000" : "#dadada",
                  },
                }),
              }}
            />
            {errors?.[name] && (
              <Error>
                <MdError color="#DC2626" size={14} />
                {errorMessage}
              </Error>
            )}
          </FormItem>
        );
      }}
    />
  );
}
