const pageInfo = {
  sort: {
    sorted: false,
    unsorted: true,
    empty: true,
  },
  offset: 0,
  pageNumber: 0,
  pageSize: 10,
  paged: true,
  unpaged: false,
};

const coursesTotalizers = {
  actions: {
    VIEWS: 0,
    IMPRESSION: 0,
    INTERACTION: 0,
    RECOMMENDATION: 0,
  },
  users: {
    byCategory: {
      SERVICE_PROVIDER: 0,
      STAFF: 0,
      EXHIBITOR: 0,
    },
    byLanguage: {
      pt: 0,
      en: 0,
    },
  },
};

const bannersTotalizers = {
  actions: {
    VIEWS: 0,
    IMPRESSION: 0,
    INTERACTION: 0,
  },
  banners: {
    byCategory: {
      SERVICE_PROVIDER: 0,
      STAFF: 0,
      EXHIBITOR: 0,
    },
    byLanguage: {
      pt: 0,
      en: 0,
    },
  },
};

const INITIAL_STATE = {
  coursesTotalizers,
  coursesTotalizersError: false,
  courseAnalyticsError: false,
  isLoading: true,
  isLoadingcourseAnalytics: true,
  courseAnalytics: [],
  courseAnalyticsPageInfo: pageInfo,
  courseAnalyticsTotal: 0,

  bannerAnalytics: [],
  bannersTotalizers,
  bannersTotalizersError: false,
  bannerAnalyticsError: false,
  isLoadingBanners: true,
  isLoadingBannerUsers: true,
  bannerAnalyticsPageInfo: pageInfo,
  bannerAnalyticsTotal: 0,

  impressionError: null,
  viewError: null,
  interactionError: null,

  courses: [],
  lessons: [],
  events: [],
  banners: [],
  years: [],

  eventsInfo: [],

  courseError: false,
  eventsError: false,
  bannersError: false,
};

const analytics = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_COURSES_TOTALIZERS":
      return { ...state, isLoading: true };
    case "GET_COURSE_ANALYTICS":
      return { ...state, isLoadingcourseAnalytics: true };

    case "GET_COURSES_TOTALIZERS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        coursesTotalizers: action.payload.params,
      };
    case "GET_COURSES_TOTALIZERS_FAIL":
      return {
        ...state,
        isLoading: false,
        coursesTotalizersError: true,
        coursesTotalizers,
      };

    case "GET_COURSE_ANALYTICS_SUCCESS":
      return {
        ...state,
        courseAnalytics: payload.courseAnalytics,
        courseAnalyticsPageInfo: payload.pageInfo,
        courseAnalyticsTotal: payload.total,
        isLoadingcourseAnalytics: false,
      };
    case "GET_COURSE_ANALYTICS_FAIL":
      return {
        ...state,
        courseAnalytics: [],
        courseAnalyticsPageInfo: pageInfo,
        courseAnalyticsTotal: 0,
        courseAnalyticsError: true,
        isLoadingcourseAnalytics: false,
      };

    case "GET_BANNERS_TOTALIZERS":
      return { ...state, isLoadingBanner: true };
    case "GET_BANNER_ANALYTICS":
      return { ...state, isLoadingBannerUsers: true };

    case "GET_BANNERS_TOTALIZERS_SUCCESS":
      return {
        ...state,
        isLoadingBanners: false,
        bannersTotalizers: action.payload.params,
      };
    case "GET_BANNERS_TOTALIZERS_FAIL":
      return {
        ...state,
        isLoadingBanners: false,
        bannersTotalizersError: true,
        bannersTotalizers,
      };

    case "GET_BANNER_ANALYTICS_SUCCESS":
      return {
        ...state,
        bannerAnalytics: payload.bannerAnalytics,
        bannerAnalyticsPageInfo: payload.pageInfo,
        bannerAnalyticsTotal: payload.total,
        isLoadingBannerUsers: false,
      };
    case "GET_BANNER_ANALYTICS_FAIL":
      return {
        ...state,
        bannerAnalytics: [],
        bannerAnalyticsPageInfo: pageInfo,
        bannerAnalyticsTotal: 0,
        bannerAnalyticsError: true,
        isLoadingBannerUsers: false,
      };

    case "BANNER_IMPRESSION":
    case "BANNER_INTERACTION":
    case "BANNER_VIEW":
    case "BANNER_IMPRESSION_SUCCESS":
    case "BANNER_INTERACTION_SUCCESS":
    case "BANNER_VIEW_SUCCESS":
      return { ...state };

    case "BANNER_IMPRESSION_FAILURE":
      return {
        ...state,
        impressionError: action.payload,
      };
    case "BANNER_INTERACTION_FAILURE":
      return {
        ...state,
        interactionError: action.payload,
      };
    case "BANNER_VIEW_FAILURE":
      return {
        ...state,
        viewError: action.payload,
      };

    case "GET_EVENT_LIST_ANALYTICS":
      return { ...state, eventsError: false, courses: [], lessons: [] };
    case "GET_EVENT_LIST_ANALYTICS_SUCCESS":
      return {
        ...state,
        eventsError: false,
        events: action.payload.events,
        years: action.payload.years,
        eventsInfo: action.payload.eventsInfo,
      };
    case "GET_EVENT_LIST_ANALYTICS_FAILURE":
      return {
        ...state,
        eventsError: true,
        events: [],
        years: [],
        eventsInfo: [],
      };

    case "GET_COURSES_ANALYTICS":
      return { ...state, courseError: false };
    case "GET_COURSES_ANALYTICS_SUCCESS":
      return {
        ...state,
        courseError: false,
        courses: action.payload.courses,
        lessons: action.payload.lessons,
      };
    case "GET_COURSES_ANALYTICS_FAILURE":
      return {
        ...state,
        courseError: true,
        courses: [],
        lessons: [],
      };

    case "GET_BANNERS_LIST":
      return { ...state, bannersError: false };
    case "GET_BANNERS_LIST_SUCCESS":
      return {
        ...state,
        bannersError: false,
        banners: action.payload.banners,
      };
    case "GET_BANNERS_LIST_FAILURE":
      return {
        ...state,
        bannersError: true,
        banners: [],
      };

    default:
      return state;
  }
};

export default analytics;
