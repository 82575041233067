import { Controller, useWatch } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Não se esqueça de importar o estilo
import { FormItem, InputLabel, Required, Error } from "./styles";
import { MdError } from "react-icons/md";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import moment from 'moment';

export default function ControlledDatePicker({
  name,
  control,
  label,
  required,
  description,
  placeholder,
  errors,
  arrayName,
  onChange,
  ...props
}) {
  const [error, setError] = useState(false);
  const intl = useIntl();
  const locale = intl.locale;
  const isRequired = required && <Required>*</Required>;
  const errorMessage = intl.formatMessage({
    id:
      errors?.[name]?.ref?.name === name
        ? "end_date_error"
        : "error.field_required",
  });

  const dateWatch = useWatch({ control, name });

  useEffect(() => {
    if (dateWatch) {
      return setError(false);
    }

    if (arrayName) {
      const [_, index, field] = name.split(".");
      const hasError = errors?.[arrayName]?.[index]?.[field];
      return setError(!!hasError);
    }

    if (errors?.[name]) {
      return setError(true);
    }

    return setError(false);
  }, [errors, dateWatch, name]);

  const labelIntl = intl.formatMessage({
    id: label,
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormItem error={error}>
            <InputLabel htmlFor={name}>
              {labelIntl}
              {isRequired}
              {description && <span>({description})</span>}
            </InputLabel>
            <DatePicker
              {...props}
              locale={locale}
              selected={field.value ? new Date(moment(field.value)) : null}
              onChange={(date) => {
                field.onChange(date);
                if (onChange) onChange(date);
              }}
              placeholderText={placeholder}
              dateFormat="dd/MM/yyyy"
              className={`datepicker-input ${errorMessage ? "error" : ""}`}
            />

            <ErrorMessage
              errors={errors}
              name={name}
              arrayName={arrayName}
              error={error}
            />
          </FormItem>
        );
      }}
    />
  );
}

function ErrorMessage({ errors, name, arrayName, error }) {
  const intl = useIntl();

  if (!error) return null;

  const getMessage = () => {
    if (!errors) return null;

    let message;

    if (arrayName) {
      const [_, index, field] = name.split(".");
      message = errors[arrayName]?.[index]?.[field]?.message;
    } else {
      message = errors[name]?.message;
    }

    if (!message) return null;

    switch (true) {
      case message.includes("end_date_error"): {
        return intl.formatMessage({ id: "end_date_error" });
      }
      default: {
        return intl.formatMessage({ id: "error.field_required" });
      }
    }
  };

  const errorMessage = getMessage();
  if (!errorMessage) return null;

  return (
    <Error>
      <MdError color="#DC2626" size={14} />
      {errorMessage}
    </Error>
  );
}

export function plusOneDay(date) {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + 1);

  return newDate;
}
