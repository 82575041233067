import { useEffect, useRef, useState } from "react";
import {
  NextLessonContainer,
  PlayButton,
  Thumbnail,
  VideoContainer,
  YoutubePlayer,
} from "../style";
import { Progress } from "antd";
import Button from "../../Button";
import { FormattedMessage } from "react-intl";

export const YouTubeVideo = ({
  videoId,
  completeLesson,
  moveToNextLesson,
  interaction,
  lastLesson,
}) => {
  const [playerLoaded, setPlayerLoaded] = useState(false);
  const [nextLesson, setNextLesson] = useState(false);
  const [progress, setProgress] = useState(0);
  const intervalRef = useRef(null);
  const playerRef = useRef(null);
  const hasCompletedRef = useRef(false);

  useEffect(() => {
    if (!nextLesson) {
      return;
    }

    completeLesson();

    if (lastLesson) return;

    intervalRef.current = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 5) {
          clearInterval(intervalRef.current);
          moveToNextLesson();
          return prev;
        }
        return prev + 1;
      });
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, [nextLesson, moveToNextLesson, completeLesson, lastLesson]);

  useEffect(() => {
    if (!playerLoaded) return;

    const exitFullScreen = () => {
      if (document.fullscreenElement || document.webkitFullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    };

    const initializePlayer = () => {
      playerRef.current = new window.YT.Player("youtube-player", {
        videoId,
        events: {
          onReady: (event) => {
            event.target.playVideo();
          },
          onStateChange: (event) => {
            if (!(event.data === 0 && !hasCompletedRef.current)) {
              return;
            }

            hasCompletedRef.current = true;
            exitFullScreen();
            setNextLesson(true);
          },
        },
        playerVars: {
          autoplay: 1,
          rel: 0,
        },
      });
    };

    if (window.YT?.Player) {
      initializePlayer();
    } else {
      window.onYouTubeIframeAPIReady = initializePlayer;
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }
      hasCompletedRef.current = false;
    };
  }, [playerLoaded, videoId]);

  const cancelInterval = () => {
    if (!intervalRef.current) return;

    clearInterval(intervalRef.current);
    intervalRef.current = null;
    setNextLesson(false);
    setProgress(0);
  };

  return (
    <VideoContainer>
      {!playerLoaded && (
        <Thumbnail
          youtube={videoId}
          videoId={videoId}
          videoType={"youtube"}
          onClick={() => {
            setPlayerLoaded(true);
            interaction();
          }}
        >
          <PlayButton>▶</PlayButton>
        </Thumbnail>
      )}
      {playerLoaded && <YoutubePlayer id="youtube-player" />}
      {!lastLesson && nextLesson && (
        <Thumbnail
          youtube={videoId}
          videoId={videoId}
          videoType={"youtube"}
          nextLesson
        >
          <NextLessonContainer>
            <p>
              <FormattedMessage id="moving_to_next_lesson" />
            </p>
            <Progress
              percent={progress * 20}
              format={(percent) => {
                const mapping = {
                  0: 5,
                  20: 4,
                  40: 3,
                  60: 2,
                  80: 1,
                  100: 0,
                };

                return mapping[percent] ?? 5;
              }}
              type="circle"
              className="progress"
            />
            <Button
              label={"cancel"}
              onClick={cancelInterval}
              variant={"academy"}
              size={"academy"}
            />
          </NextLessonContainer>
        </Thumbnail>
      )}
    </VideoContainer>
  );
};
