import produce from "immer";

const INITIAL_STATE = {
  allEvent: [],
  events: [],
  isLoading: false,
  error: null,
};

const event = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_ALL_EVENT": {
        const { allEvent } = action.payload;

        draft.allEvent = allEvent;

        localStorage.setItem(
          "InformaMarket:allEvent",
          JSON.stringify(allEvent)
        );

        break;
      }

      case "CLEAR_ALL_EVENT": {
        draft.allEvent = [];

        localStorage.setItem("InformaMarket:allEvent", JSON.stringify([]));

        break;
      }

      case "GET_ALL_EVENTS_INFO":
      case "GET_ALL_EVENTS":
        return { ...state, isLoading: true, error: null };

      case "GET_EVENTS_INFO_SUCCESS":
      case "GET_EVENTS_SUCCESS":
        return {
          ...state,
          isLoading: false,
          events: action.payload,
        };

      case "GET_EVENTS_INFO_FAILURE":
      case "GET_EVENTS_FAILURE":
        return {
          ...state,
          isLoading: false,
          error: action.payload,
          events: [],
        };

      default: {
        return draft;
      }
    }
  });
};

export default event;
