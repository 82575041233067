import React, { useEffect, useMemo } from "react";
import { Controller, useWatch } from "react-hook-form";
import JoditEditor from "jodit-react";
import {
  Description,
  Error,
  FormItem,
  InputLabel,
  InputLengthCounter,
  Required,
} from "./styles";
import { MdError } from "react-icons/md";
import { useIntl } from "react-intl";

import {
  Bold,
  Image,
  Italic,
  Link,
  List,
  OrderedList,
  Strikethrough,
  Table,
  Underline,
  Video,
  FontSize,
  Paragraph,
} from "./jodit-icons";
import { useWindowSize } from "../../hooks/dimensions";
import sanitizeHtml from "sanitize-html";

export default function ControlledTextareaJodit({
  name,
  customConfig,
  control,
  onChange,
  max = Infinity,
  placeholder,
  label,
  required,
  description,
  formState,
  minHeight,
  sub,
  height,
  maxHeight,
  buttonsType = "article",
  arrayName,
  width,
  split,
  splitColumn,
  ...props
}) {
  const [windowWidth] = useWindowSize();
  const intl = useIntl();
  const watch = useWatch({ control, name });

  const errorMessage =
    formState?.errors?.[name]?.message ||
    formState.errors?.[arrayName]?.[name.split(".")[1]]?.[name.split(".")[2]]
      ?.message;

  const labelIntl = intl.formatMessage({
    id: label,
  });

  const isRequired = required && <Required>*</Required>;
  const hasDesc = description && (
    <Description>
      (
      {intl.formatMessage({
        id: description,
      })}
      )
    </Description>
  );
  const hasSub = sub && (
    <Description subtitle>{intl.formatMessage({ id: sub })}</Description>
  );

  const config = useMemo(() => {
    return {
      placeholder: intl.formatMessage({
        id: "type_a_text",
        defaultMessage: "Start typing...",
      }),
      readonly: false,
      toolbar: true,
      minHeight,
      maxHeight,
      toolbarSticky: true,
      toolbarAdaptive: false,
      height: height ?? "auto",
      limitChars: max,
      language: intl.locale === "en" ? "en" : "pt_br",
      buttons: buttonsType === "article" ? buttonsArticle : buttons,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      paste: {
        processHTML: true,
        processText: true,
        defaultActionOnPaste: "insert_clear_html",
      },
    };
  }, [buttonsType, height, intl, max, maxHeight, minHeight]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormItem
          width={props.width}
          error={
            errorMessage ||
            sanitizeHtml(watch, { allowedTags: [] }).replace(/&nbsp;/g, "")
              .length > max
          }
          hidePlaceholder={Boolean(watch)}
          maxWidth={windowWidth}
          split={split}
          splitColumn={splitColumn}
        >
          <InputLabel htmlFor={name} style={{ marginBottom: "0" }}>
            {labelIntl}
            {isRequired} {hasDesc}
          </InputLabel>
          {hasSub}
          <JoditEditor
            {...field}
            id={name}
            className="maxWidth"
            config={config}
            onChange={(e) => {
              field.onChange(e);
            }}
          />
          {arrayName ? (
            <GetArrayErrorMessage
              errors={formState.errors}
              arrayName={arrayName}
              name={name}
              overflowed={
                sanitizeHtml(watch, { allowedTags: [] }).replace(/&nbsp;/g, "")
                  .length > max
              }
              max={max}
            />
          ) : (
            <ErrorMessage
              errorMessage={errorMessage}
              overflowed={
                sanitizeHtml(watch, { allowedTags: [] }).replace(/&nbsp;/g, "")
                  .length > max
              }
              max={max}
            />
          )}
          {max && (
            <InputLengthCounter
              overflowed={
                sanitizeHtml(watch, { allowedTags: [] }).replace(/&nbsp;/g, "")
                  .length > max
              }
            >
              <span className="current">
                {watch
                  ? sanitizeHtml(watch, { allowedTags: [] }).replace(
                      /&nbsp;/g,
                      ""
                    ).length
                  : 0}
              </span>{" "}
              / {max} {intl.formatMessage({ id: "characters" })}
            </InputLengthCounter>
          )}
        </FormItem>
      )}
    />
  );
}

function ErrorMessage({ errorMessage, overflowed, max }) {
  const intl = useIntl();

  if (overflowed) {
    return (
      <Error>
        <MdError color="#DC2626" size={14} />
        {intl.formatMessage(
          {
            id: "error.max_length",
          },
          {
            length: max,
          }
        )}
      </Error>
    );
  }

  if (!errorMessage) return null;

  const getErrorMessage = () => {
    if (!errorMessage) return;
    switch (true) {
      case errorMessage.includes("error.max_length"):
      case errorMessage.includes("error.min_length"): {
        const splited = errorMessage.split("-");
        const id = splited[0];
        const length = splited[1];
        return intl.formatMessage(
          {
            id,
          },
          {
            length,
          }
        );
      }
      case errorMessage.includes("invalid_workload"): {
        return intl.formatMessage({
          id: "invalid_workload",
        });
      }
      case errorMessage.includes("informa_academy_invalid_lesson_video_link"): {
        return intl.formatMessage({
          id: "informa_academy_invalid_lesson_video_link",
        });
      }
      default: {
        return intl.formatMessage({
          id: "error.field_required",
        });
      }
    }
  };

  return (
    <Error>
      <MdError color="#DC2626" size={14} />
      {getErrorMessage(errorMessage)}
    </Error>
  );
}

function GetArrayErrorMessage({ errors, arrayName, name, overflowed, max }) {
  const intl = useIntl();

  let errorMessage = null;

  if (overflowed) {
    return (
      <Error>
        <MdError color="#DC2626" size={14} />
        {intl.formatMessage(
          {
            id: "error.max_length",
          },
          {
            length: max,
          }
        )}
      </Error>
    );
  }

  if (arrayName) {
    const [, index, field] = name.split(".");
    const message = errors[arrayName]?.[index]?.[field]?.message;

    if (message?.includes("error.field_required")) {
      errorMessage = intl.formatMessage({
        id: "error.field_required",
      });
    }

    if (message?.includes("error.valid_url")) {
      errorMessage = intl.formatMessage({
        id: "error.valid_url",
      });
    }
  }

  if (!errorMessage) return null;

  return (
    <Error>
      <MdError color="#DC2626" size={14} />
      {errorMessage}
    </Error>
  );
}

const buttons = [
  {
    name: "paragraph",
    icon: Paragraph,
  },
  "|",
  {
    name: "fontsize",
    icon: FontSize,
  },
  "|",
  {
    name: "bold",
    icon: Bold,
  },
  {
    name: "italic",
    icon: Italic,
  },
  {
    name: "underline",
    icon: Underline,
  },
  {
    name: "strikethrough",
    icon: Strikethrough,
  },
  {
    name: "link",
    icon: Link,
  },
  {
    name: "ol",
    icon: OrderedList,
  },
  {
    name: "ul",
    icon: List,
  },
  "|",
];

const buttonsArticle = [
  ...buttons,
  {
    name: "image",
    icon: Image,
  },
  "|",
  {
    name: "table",
    icon: Table,
  },
  "|",
  {
    name: "video",
    icon: Video,
  },
  "|",
];
