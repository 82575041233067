import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  TopicCardContainer,
  TopicCard,
  TopicCardHeader,
  TopicCardTitle,
  TopicCardCount,
  TopicCardList,
  TopicCardItem,
  TopicCardLink,
  Button,
  TopicCardText,
} from "../../styles";
import { ButtonContainer } from "../style";

const DEFAULT_CARDS_NUMBER = 9;

const EventManualTopicList = ({ topics = [] }) => {
  const [show, setShow] = useState(DEFAULT_CARDS_NUMBER);
  const intl = useIntl();

  const handleShowMore = () => {
    if (topics.length > show) {
      setShow((prev) => prev + 9);
    }
  };

  const handleShowLess = () => {
    setShow(DEFAULT_CARDS_NUMBER);
  };

  return (
    <div>
      <TopicCardContainer>
        {topics.slice(0, show).map((topic, index) => (
          <TopicCard key={`chapter-${index}`}>
            <TopicCardHeader $column={false}>
              <TopicCardTitle>{topic.chapterTitle}</TopicCardTitle>
              <TopicCardCount>
                {topic.articleCount}{" "}
                {intl.formatMessage({
                  id: "exhibitors_manual_article_count",
                })}
                {topic.articleCount > 1 ? "s" : ""}
              </TopicCardCount>
            </TopicCardHeader>

            <TopicCardList>
              {topic.articles
                .filter((_, index) => index < 5)
                .map((article, index) => {
                  return (
                    <TopicCardItem
                      key={`article-${index}`}
                      to={`/event-manual/chapter/${topic.chapterId}/article/${article.id}`}
                    >
                      <ArticleIcon />
                      <TopicCardText>{article.title}</TopicCardText>
                    </TopicCardItem>
                  );
                })}
            </TopicCardList>

            <TopicCardLink to={`/event-manual/chapter/${topic.chapterId}`}>
              {intl.formatMessage({
                id: "exhibitors_manual_view_all",
              })}
              <ArrowRight />
            </TopicCardLink>
          </TopicCard>
        ))}
      </TopicCardContainer>

      <ButtonContainer>
        {topics.length > show && (
          <Button onClick={handleShowMore}>
            <FormattedMessage id="exhibitors_manual_view_more" />
          </Button>
        )}
        {topics.length > 6 && show > topics.length && (
          <Button onClick={handleShowLess}>
            <FormattedMessage id="exhibitors_manual_view_less" />
          </Button>
        )}
      </ButtonContainer>
    </div>
  );
};

export default EventManualTopicList;

export function ArticleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="15"
      fill="none"
      viewBox="0 0 13 15"
    >
      <g clipPath="url(#clip0_397_8643)">
        <g clipPath="url(#clip1_397_8643)">
          <g
            stroke="#585F74"
            strokeLinejoin="round"
            strokeWidth="0.863"
            clipPath="url(#clip2_397_8643)"
          >
            <path
              strokeLinecap="round"
              d="M3.152 13.718a1.44 1.44 0 0 1-1.438-1.438V.776h6.47l3.595 3.595v7.909a1.437 1.437 0 0 1-1.438 1.438z"
            />
            <path d="M7.466.776V5.09h4.313" />
            <path
              strokeLinecap="round"
              d="M4.59 7.966h4.313M4.59 10.842h4.313"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_397_8643">
          <path fill="#fff" d="M.706.345h12.082v13.804H.706z" />
        </clipPath>
        <clipPath id="clip1_397_8643">
          <path fill="#fff" d="M.706.345h12.082v13.804H.706z" />
        </clipPath>
        <clipPath id="clip2_397_8643">
          <path fill="#fff" d="M.707.345h12.078v13.804H.707z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ArrowRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="17"
      fill="none"
      viewBox="0 0 15 17"
    >
      <path
        stroke="#11A7D9"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.955 8.5h-10.5M9.455 4.5l3.5 4-3.5 4"
      ></path>
    </svg>
  );
}
