import React from "react";

import { Container, Image } from "./styles";

import Logo from "../../../newUXAssets/images/InformaLogo.png";
import Menu from "./Menu";

const Sidebar = ({ informaAcademyCourse }) => {
  return (
    <Container informaAcademyCourse={informaAcademyCourse}>
      <Image src={Logo} />
      <Menu />
    </Container>
  );
};

export default Sidebar;
