import styled from "styled-components";

export const CourseProgressionContainer = styled.div`
  padding: 8px;
  background: #fbfbfd;
  display: flex;
  flex-direction: column;
  gap: 4px;


  & .ant-progress-text {
    font-weight: 600;
    line-height: 19.07px;
  }
`;

export const CourseProgressionHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 27px;

  & > span {
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 19.07px;
    color: #3a3a3c;
  }
`;

export const ProgressionContainer = styled.div`
  & .ant-progress-text {
    color: #3a3a3c;
    margin: 0;
    width: fit-content;
    margin-top: 2px;
  }

  & .ant-progress {
    display: flex;
    gap: 6px;
  }

  & .ant-progress-outer {
    padding-right: 0;
    margin: 0;
  }

  & .ant-progress-inner {
    width: ${(props) => (props.fullsize === true ? "100%" : "238px")};
  }
`;
