import * as React from "react";

const ViewIcon = (props) => {
  const { size = 18 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#FF8F6B"
        stroke="#FF8F6B"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9.703 13.5c4.142 0 7.5-4.5 7.5-4.5s-3.358-4.5-7.5-4.5-7.5 4.5-7.5 4.5 3.358 4.5 7.5 4.5Z"
      ></path>
      <path
        fill="#FF8F6B"
        stroke="#FFF4F0"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9.703 10.875a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
      ></path>
    </svg>
  );
};

export default ViewIcon;
