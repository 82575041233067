import React, { useCallback, useEffect } from "react";
import BannerCaroussel from "./carousel";
import {
  Container,
  ContentContainer,
  InProgressButton,
  InProgressContainer,
  InProgressContent,
  InProgressLesson,
  InProgressSection,
  InProgressText,
  InProgressTitle,
} from "./style";
import CourseList from "./courses";
import { FaPlayCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  setBanner,
  setCourses,
} from "../../redux/modules/informaAcademy/actions";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import {
  bannerImpression,
  bannerInteraction,
  bannerViews,
} from "../../redux/modules/analytics/actions";
import useNotification from "../../hooks/newUXNotification";

const PreviewHomepageBanner = () => {
  const dispatch = useDispatch();
  const { event } = useSelector((state) => state.initialSettings);
  const eventId = event.value;
  const notification = useNotification();
  const intl = useIntl();
  const history = useHistory();

  const { banner, courses, allowAccess, onGoingCourses } = useSelector(
    (state) => state.informaAcademy
  );

  useEffect(() => {
    dispatch(setBanner({}));
    dispatch(setCourses({}));
  }, [dispatch, eventId]);

  useEffect(() => {
    if (!allowAccess) {
      notification.displayInfo(
        intl.formatMessage({ id: "error.homepage_not_allowed" }),
        "error.homepage_not_allowed"
      );

      history.push("/checklist");
    }

    if (isNaN(event?.value)) {
      history.push("/checklist");
    }

    return;
  }, [allowAccess, event?.value, history, intl, notification]);

  const handleImpression = useCallback(
    ({ bannerId }) => {
      dispatch(bannerImpression({ bannerId }));
    },
    [dispatch]
  );

  const handleInteraction = useCallback(
    ({ bannerId }) => {
      dispatch(bannerInteraction({ bannerId }));
    },
    [dispatch]
  );

  const handleViews = useCallback(
    ({ bannerId }) => {
      dispatch(bannerViews({ bannerId }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (banner && banner.length > 0) {
      banner.map(({ id }) => {
        return handleImpression({ bannerId: id });
      });
    }
  }, [banner, handleImpression]);

  if (!banner || !courses) return null;

  return (
    <Container>
      {banner && (
        <ContentContainer>
          <BannerCaroussel
            banners={banner}
            handleInteraction={handleInteraction}
            handleViews={handleViews}
          />
        </ContentContainer>
      )}

      {OngoingCoursesInfo({
        coursesArray: courses,
        ongoingArray: onGoingCourses,
      }).map((course) => (
        <ContentContainer key={course.lessonUrl}>
          <InProgressContainer>
            <InProgressTitle>
              <FormattedMessage id="homepage.onGoing" />
            </InProgressTitle>
            <InProgressSection>
              <InProgressContent>
                <InProgressText>
                  <FormattedMessage id="homepage.course" />
                </InProgressText>
                <InProgressTitle>{course.courseTitle}</InProgressTitle>
                <InProgressLesson>
                  <FormattedMessage id="homepage.lesson" />{" "}
                  {course.currentLessonNumber}: {course.currentLessonTitle}
                </InProgressLesson>
              </InProgressContent>
              <InProgressButton
                to={`informa-academy/course/${course.courseId}/lesson/${course.lessonId}`}
              >
                <FaPlayCircle />
                <FormattedMessage id="homepage.continue" />
              </InProgressButton>
            </InProgressSection>
          </InProgressContainer>
        </ContentContainer>
      ))}
      {courses.length > 0 && (
        <ContentContainer>
          <CourseList courses={courses} />
        </ContentContainer>
      )}
    </Container>
  );
};

export default PreviewHomepageBanner;

function OngoingCoursesInfo({ ongoingArray, coursesArray }) {
  return ongoingArray.map((ongoing) => {
    const matchedCourse = coursesArray.find(
      (course) => course.courseTitle === ongoing.courseTitle
    );

    if (matchedCourse) {
      const matchedLesson = matchedCourse.lessons.find(
        (lesson) => lesson.urlVideo === ongoing.lessonUrl
      );

      return {
        ...ongoing,
        courseId: matchedCourse.courseId,
        lessonId: matchedLesson?.lessonId,
      };
    }

    return ongoing;
  });
}
