export function getAllEvent() {
  return {
    type: "GET_ALL_EVENT",
  };
}

export function setAllEventSuccess(allEvent) {
  return {
    type: "SET_ALL_EVENT",
    payload: {
      allEvent: allEvent.event,
    },
  };
}

export function clearAllEvent() {
  return {
    type: "CLEAR_ALL_EVENT",
    payload: {},
  };
}

export function getEventsInfo() {
  return {
    type: "GET_ALL_EVENTS_INFO",
  };
}

export function getEventsInfoSuccess(data) {
  return {
    type: "GET_EVENTS_INFO_SUCCESS",
    payload: data,
  };
}

export function getEventsInfoFailure(error) {
  return {
    type: "GET_EVENTS_INFO_FAILURE",
    payload: error,
  };
}

export function getEvents() {
  return {
    type: "GET_ALL_EVENTS",
  };
}

export function getEventsSuccess(data) {
  return {
    type: "GET_EVENTS_SUCCESS",
    payload: data,
  };
}

export function getEventsFailure(error) {
  return {
    type: "GET_EVENTS_FAILURE",
    payload: error,
  };
}