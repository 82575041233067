import React from "react";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import { FormattedMessage, useIntl } from "react-intl";
import { Container, Title, TitleContainer, WrapperContainer } from "../style";
import Button from "../../InformaAcademy/components/button";
import Empty from "../../InformaAcademy/components/Empty";
import { BiPlus } from "react-icons/bi";
import SearchInput from "../../InformaAcademy/components/SearchInput";

const ManualHeader = ({ total = 0, setSearch, disableSearch = false }) => {
  return (
    <TitleContainer>
      <Title>
        <FormattedMessage id={"manual"} /> ({total})
      </Title>
      <SearchInput setSearch={setSearch} disabled={disableSearch} />
    </TitleContainer>
  );
};

const ManualError = () => {
  const intl = useIntl();
  return (
    <AnimatedMainContainer
      breadcrumb={`${intl.formatMessage({
        id: "manual",
      })}`}
    >
      <WrapperContainer>
        <Button label={"Novo Manual"} Icon={<BiPlus size={20} />} disabled />
        <Container>
          <ManualHeader total={0} disableSearch />
          <Empty intlId="admin_manual_error" highlightText="db_error" />
        </Container>
      </WrapperContainer>
    </AnimatedMainContainer>
  );
};

export default ManualError;
