import * as React from "react";

const BackIcon = (props) => {
  const { size = "16", stroke = "#002244" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4.333 3.167 2 5.167 4.333 7.5"
      ></path>
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M2 5.167h7.665c2.294 0 4.242 1.873 4.332 4.167.094 2.423-1.908 4.5-4.332 4.5H3.999"
      ></path>
    </svg>
  );
};

export default BackIcon;
