import styled, { css } from "styled-components";

export const TableOrderContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const ColumnName = styled.span`
  font-size: 0.75rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const ArrowButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  border-radius: 11px;
  padding: 2px;
  background: #f2f2f5;
  border: none;
  outline: none;
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.sidebar &&
    css`
      flex-direction: column;
      width: 377px;

      & div {
        cursor: unset;
      }
    `}

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;
