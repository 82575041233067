import React, { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import { Container } from "../style";
import NavigationHeader from "../components/container-header";
import {
  ButtonContainer,
  ContainerHeader,
  ManualCardContainer,
  ManualCardsContainer,
} from "./styles";
import { FormattedMessage } from "react-intl";
import Button from "../../InformaAcademy/components/button";
import { BiPlus } from "react-icons/bi";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsFileEarmarkText } from "react-icons/bs";
import Articles from "../components/article";
import { useDispatch, useSelector } from "react-redux";
import { getArticlesTotalCounter } from "../../../redux/modules/adminExhibitorManual/actions";

export default function ManageManual() {
  const { manualId } = useParams();

  const dispatch = useDispatch();
  const { articlesTotal, errorArticlesTotal, isLoading } = useSelector(
    (state) => state.adminManualExhibitor
  );

  useEffect(() => {
    dispatch(
      getArticlesTotalCounter({
        manualId,
      })
    );
  }, [dispatch, manualId]);

  return (
    <AnimatedMainContainer>
      <NavigationHeader breadcrumb={"manual_view"} goBack={"/admin/manual"} />
      <ManualCards
        total={articlesTotal}
        error={errorArticlesTotal}
        loading={isLoading}
      />
      <Container>
        <Header manualId={manualId} eventName={articlesTotal.eventName} />
        <Articles manualId={manualId} total={articlesTotal.totalArticles} />
      </Container>
    </AnimatedMainContainer>
  );
}

function ManualCards({ total, error, loading }) {
  const { totalArticles, totalPostedArticles, totalDraftArticles } = total;

  return (
    <ManualCardsContainer>
      <ManualCard label={"total"} value={totalArticles} />
      <ManualCard label={"published_2"} value={totalPostedArticles} />
      <ManualCard label={"drafts"} value={totalDraftArticles} />
    </ManualCardsContainer>
  );
}

function ManualCard({ label, value }) {
  return (
    <ManualCardContainer label={label}>
      <p className="label">{<FormattedMessage id={label} />}</p>
      <p className="value">{value}</p>
    </ManualCardContainer>
  );
}

function Header({ manualId, eventName }) {
  return (
    <ContainerHeader>
      <h2>{eventName}</h2>
      <ButtonContainer>
        <Button
          label={"manual_manage_FAQ"}
          variant={"secondary"}
          Icon={<AiOutlineQuestionCircle size={18} />}
          href={`/admin/manual/${manualId}/FAQ`}
          shadow
        />
        <Button
          label={"manual_manage_chapters"}
          variant={"secondary"}
          Icon={<BsFileEarmarkText size={18} />}
          href={`/admin/manual/${manualId}/chapters`}
          shadow
        />
        <Button
          label={"article_new"}
          variant={"primary"}
          Icon={<BiPlus size={20} />}
          href={`/admin/manual/${manualId}/newArticle`}
          shadow
        />
      </ButtonContainer>
    </ContainerHeader>
  );
}
