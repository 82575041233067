import * as React from "react";

const BookIconAdmin = (props) => {
  const { size = 38, fill = "#CCC" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fill}
      viewBox="0 0 36 38"
      {...props}
    >
      <path d="M6.824 29.579h26.47c.977 0 1.765-.788 1.765-1.765V1.932c0-.977-.788-1.765-1.765-1.765H6.824A5.885 5.885 0 0 0 .94 6.049v25.883a5.885 5.885 0 0 0 5.883 5.882h26.47c.977 0 1.765-.788 1.765-1.765s-.788-1.764-1.765-1.764H6.824a2.36 2.36 0 0 1-2.353-2.353 2.36 2.36 0 0 1 2.353-2.353"></path>
    </svg>
  );
};

export default BookIconAdmin;
