import React from "react";
import {
  CourseContainer,
  CourseTitle,
  CourseTitleContainer,
  LessonList,
  LessonListWrapper,
  OpenCourseLink,
} from "./style";

import { LessonCardPreview } from "../../../../newUXComponents/LessonCard";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CourseList = ({ courses }) => {
  const intl = useIntl();
  const history = useHistory();
  const adminView = history.location.pathname.includes("/admin/");

  return (
    <CourseContainer>
      {courses
        .filter((course) => course.visible === true)
        .map((course, index) => {
          return (
            <div key={index}>
              <CourseTitleContainer>
                <CourseTitle>{course.courseTitle}</CourseTitle>
                {!adminView && (
                  <OpenCourseLink href={course.url}>
                    {intl.formatMessage({
                      id: "preview_banner_button_open_course",
                    })}
                  </OpenCourseLink>
                )}
              </CourseTitleContainer>

              <LessonList>
                {course.lessons.slice(0, 5).map((lesson, index) => (
                  <LessonCardPreview
                    title={lesson.title}
                    order={index + 1}
                    icon={true}
                    imageUrl={lesson.url}
                  />
                ))}
              </LessonList>
            </div>
          );
        })}
    </CourseContainer>
  );
};

export default CourseList;
