import React from "react";
import { LoaderContainer } from "../../InformaAcademy/EditCourse/styles";
import { LoadingSpinnerLogo } from "../../../components/LoadingOverlay/styles";
import LogoCircle from "../../../assets/images/Informa_Logo_Circle.png";
import { LoaderComponent } from "../style";

const Loader = () => {
  return (
    <LoaderComponent>
      <LoaderContainer>
        <LoadingSpinnerLogo src={LogoCircle} />
      </LoaderContainer>
    </LoaderComponent>
  );
};

export default Loader;
