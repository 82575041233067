import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useState } from "react";

import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { FormRow } from "../../../../components/ControlledInputs/styles";
import ControlledInput from "../../../../components/ControlledInputs/input";
import ControlledSelect from "../../../../components/ControlledInputs/select";
import ControlledDatePicker, {
  plusOneDay,
} from "../../../../components/ControlledInputs/date-picker";

import { getEventsInfo } from "../../../../redux/modules/event/actions";
import Button from "../button";
import ForwardIcon from "../../../../icons/Forward";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Form } from "./styles";
import moment from "moment";
import ControlledTextareaJodit from "../../../../components/ControlledInputs/jodit-textarea";
import { resetAdminCourseState } from "../../../../redux/modules/informaAcademy/actions";
import { ControlledDropzoneSingle } from "../../../../components/ControlledInputs/single-dropzone";

const schema = z.object({
  id: z.coerce.number().optional(),
  name: z.string().min(1).max(150, {
    message: "error.max_length-150",
  }),
  category: z.string().min(1).max(50, {
    message: "error.max_length-50",
  }),
  responsible: z.string().min(1).max(50, {
    message: "error.max_length-50",
  }),
  workload: z
    .string()
    .min(1)
    .refine(
      (workload) => {
        const regEx = /^(\d{1,2})(?::\d{2})?$/;
        return regEx.test(workload);
      },
      {
        message: "invalid_workload",
      }
    ),
  language: z.object({
    value: z.string().min(1),
    label: z.string().min(1),
  }),
  accessStartDate: z.date(),
  accessEndDate: z.date().nullish(),
  setupEvents: z
    .array(
      z.object({
        value: z.number(),
        label: z.string().min(1),
      })
    )
    .refine((setupEvents) => setupEvents?.length > 0, {
      message: "setupEvents cannot be empty",
    }),
  coverImage: z.instanceof(File).refine((file) => file.size > 0, {
    message: "file cannot be empty",
  }),
  description: z
    .string()
    .refine(
      (value) => {
        const cleanedValue = value
          .replace(/<\/?[^>]+(>|$)/g, "")
          .replace(/\n/g, "")
          .trim();
        return cleanedValue.length > 0;
      },
      {
        message: "error.field_required",
      }
    )
    .refine(
      (value) => {
        const cleanedValue = value
          .replace(/<\/?[^>]+(>|$)/g, "")
          .replace(/\n/g, "")
          .trim();
        return cleanedValue.length <= 360;
      },
      {
        message: "error.max_length-360",
      }
    ),
});

const CourseForm = ({ defaultValue, setCourse, goToLessons, step }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const [languages] = useState([
    { value: "PORTUGUESE", label: intl.formatMessage({ id: "portuguese" }) },
    { value: "ENGLISH", label: intl.formatMessage({ id: "english" }) },
  ]);

  const { events, isLoading } = useSelector((state) => state.event);

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      category: "",
      responsible: "",
      workload: "",
      language: "",
      coverImage: null,
    },
  });

  useEffect(() => {
    dispatch(getEventsInfo());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetAdminCourseState());
      form.reset();
    };
  }, []);

  const { control, handleSubmit, formState, setValue } = form;

  useEffect(() => {
    if (!defaultValue) return;

    setValue("name", defaultValue.name);
    setValue("category", defaultValue.category);
    setValue("responsible", defaultValue.responsible);
    setValue("workload", defaultValue.workload);
    setValue("coverImage", defaultValue?.coverImage);
    setValue("language", {
      value: defaultValue.language,
      label: intl.formatMessage({ id: defaultValue.language.toLowerCase() }),
    });
    setValue("accessStartDate", new Date(moment(defaultValue.accessStartDate)));

    setValue(
      "setupEvents",
      typeof defaultValue.setupEvents[0] === "number"
        ? defaultValue.setupEvents.map((value) => ({
            value,
            label: events.find((event) => event.value === value)?.label ?? "",
          }))
        : defaultValue.setupEvents.map((event) => ({
            value: event.id,
            label: event.name,
          }))
    );
    setValue("description", defaultValue.description);

    if (defaultValue.accessEndDate)
      setValue("accessEndDate", new Date(moment(defaultValue.accessEndDate)));

    if (defaultValue.id) setValue("id", defaultValue.id);
  }, [defaultValue, events, intl, setValue]);

  const onSubmit = (data) => {
    const course = {
      ...data,
      accessStartDate: parseDate(data.accessStartDate),
      accessEndDate: data?.accessEndDate
        ? parseDate(data?.accessEndDate)
        : undefined,
      setupEvents: data.setupEvents.map((i) => i.value),
      language: data.language.value,
    };

    setCourse(course);
    goToLessons();
  };

  const startDateWatch = form.watch("accessStartDate");
  const endDateWatch = form.watch("accessEndDate");

  return (
    <Form onSubmit={handleSubmit(onSubmit)} step={step}>
      <FormRow marginBottom={"24px"}>
        <ControlledInput
          name={"name"}
          control={control}
          formState={formState}
          label={"course_name"}
          required={true}
          description={"course_name_sub"}
          max={150}
        />
        <ControlledInput
          name={"category"}
          control={control}
          formState={formState}
          label={"category"}
          required={true}
          description={"category_sub"}
          max={50}
        />
      </FormRow>

      <FormRow marginBottom={"24px"}>
        <ControlledInput
          name={"responsible"}
          control={control}
          formState={formState}
          label={"responsible"}
          required={true}
          description={"responsible_sub"}
          max={50}
        />

        <FormRow>
          <ControlledInput
            name={"workload"}
            control={control}
            formState={formState}
            label={"workload"}
            required={true}
            description={"course_workload_sub"}
          />

          <ControlledSelect
            name={"language"}
            placeholder={"Selecione"}
            control={control}
            label={"language"}
            required={true}
            options={languages}
            errors={formState.errors}
            defaultValue={formState.defaultValues.language}
          />
        </FormRow>
      </FormRow>

      <FormRow marginBottom={"24px"}>
        <ControlledDatePicker
          name="accessStartDate"
          control={control}
          label="accessStartDate"
          required={true}
          errors={formState.errors}
          minDate={new Date()}
          onChange={(date) => {
            if (date > endDateWatch) {
              form.setValue("accessEndDate", plusOneDay(date));
            }
          }}
        />
        <ControlledDatePicker
          name="accessEndDate"
          control={control}
          label="accessEndDate"
          errors={formState.errors}
          minDate={startDateWatch ? plusOneDay(startDateWatch) : new Date()}
        />
      </FormRow>

      <FormRow marginBottom={"24px"}>
        <ControlledSelect
          name={"setupEvents"}
          placeholder={"Selecione"}
          control={control}
          label={"events"}
          required={true}
          options={events}
          disabled={isLoading}
          isMulti
          closeMenuOnSelect={false}
          errors={formState.errors}
          defaultValue={formState.defaultValues.setupEvents}
        />
      </FormRow>

      <FormRow marginBottom={"24px"}>
        <ControlledDropzoneSingle
          required
          errors={formState.errors}
          control={control}
          name={"coverImage"}
          setValue={form.setValue}
          label={"course_cover"}
          description={"course_cover_sub"}
          subtitle={"course_cover_sub_2"}
          defaultName={defaultValue?.name}
        />
      </FormRow>

      <FormRow marginBottom={"24px"}>
        <ControlledTextareaJodit
          name={"description"}
          control={control}
          formState={formState}
          label={"description"}
          required={true}
          description={"description_sub"}
          sub={"description_sub_2"}
          max={360}
          minHeight={242}
          buttonsType="normal"
        />
      </FormRow>
      <FormRow buttons>
        <Button
          label={"cancel"}
          Icon={<IoIosCloseCircleOutline size={20} />}
          variant={"destructive"}
          type={"button"}
          onClick={() => {
            dispatch(resetAdminCourseState());
            form.reset();
            history.push("/admin/informa-academy");
          }}
        />
        <Button label={"next"} Icon={<ForwardIcon />} />
      </FormRow>
    </Form>
  );
};

export default CourseForm;

function parseDate(date) {
  const d = new Date(date);

  const year = d.getFullYear();
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const day = d.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}
