import { FormattedMessage, useIntl } from "react-intl";
import { useToast } from "../../../../hooks/toast";
import { useSelector } from "react-redux";
import { useCallback, useMemo, useState } from "react";
import api from "../../../../service";
import { TableContainer } from "../../style";
import DataTable from "react-data-table-component";
import Empty from "../../../InformaAcademy/components/Empty";
import {
  ButtonsContainer,
  customStyles,
} from "../../../InformaAcademy/components/CoursesTable/styles";
import Button from "../../../InformaAcademy/components/button";
import { Modal } from "antd";
import BackIcon from "../../../../icons/Back";
import { FaRegTrashAlt as TrashIcon } from "react-icons/fa";
import { AiOutlineEdit as EditIcon } from "react-icons/ai";
import {
  ModalButtonsWrapper,
  ModalSubtitle,
  ModalTitle,
} from "../../../InformaAcademy/styles";
import EditChapterForm from "./edit-chapter-form";
import { OrdernableRow, paginationOptions } from "../table-utils";

function ChapterTable({ chapters, setChapters, articlesCol = false }) {
  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);

  const reorderRows = useCallback(
    (index, direction) => {
      setChapters((prevData) => {
        const newData = [...prevData];
        const targetIndex = direction === "up" ? index - 1 : index + 1;

        [newData[index], newData[targetIndex]] = [
          newData[targetIndex],
          newData[index],
        ];

        const data = newData.map((item, idx) => ({
          ...item,
          order: idx + 1,
        }));

        return data;
      });
    },
    [setChapters]
  );

  const removeChapterFromDB = useCallback(
    async ({ row }) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        await api.delete(`/chapters/${row.id}`, config);
        addToast({
          type: "success",
          title: intl.formatMessage({
            id: "success.chapter_deleted",
          }),
        });
        const updatedChapters = chapters.filter((chapter) => chapter !== row);
        const mappedChapters = updatedChapters.map((chapter, i) => {
          chapter.orders = i + 1;
          return chapter;
        });
        setChapters(mappedChapters);
      } catch (error) {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error.chapter_delete",
          }),
        });
      }
    },
    [addToast, token, intl, chapters, setChapters]
  );

  const removeChapter = useCallback(
    async (row) => {
      if (row.id) {
        return removeChapterFromDB({ row });
      }

      const updatedChapters = chapters.filter((chapter) => chapter !== row);
      const mappedChapters = updatedChapters
        .map((chapter, i) => {
          chapter.order = i + 1;
          return chapter;
        })
        .sort((a, b) => a.order - b.order);
      setChapters(mappedChapters);

      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "success.chapter_deleted",
        }),
      });
    },
    [chapters, setChapters, addToast, intl, removeChapterFromDB]
  );

  const columns = useMemo(() => {
    return [
      {
        maxWidth: "130px",
        name: <FormattedMessage id={"lesson_order"} />,
        selector: (row) => row.order,
        cell: (row, index) => (
          <OrdernableRow
            index={index}
            row={row}
            reorderRows={reorderRows}
            rowData={chapters}
          />
        ),
      },
      {
        name: <FormattedMessage id={"manual_chapter"} />,
        selector: (row) => row.chapterTitle,
      },
      articlesCol
        ? {
            name: <FormattedMessage id={"articles"} />,
            selector: (row) => {
              return row?.totalArticles ?? 0;
            },
          }
        : {},
      {
        maxWidth: "292px",
        selector: (row) => (
          <ActionRow
            row={row}
            deleteChapter={removeChapter}
            chapters={chapters}
            setChapters={setChapters}
          />
        ),
      },
    ];
  }, [articlesCol, chapters, removeChapter, reorderRows, setChapters]);

  return (
    <TableContainer noMarginBottom>
      <DataTable
        columns={columns}
        noDataComponent={<Empty intlId="manual_chapters_empty" />}
        data={chapters}
        customStyles={customStyles}
        pagination
        persistTableHead
        paginationComponentOptions={paginationOptions({ locale: intl.locale })}
      />
    </TableContainer>
  );
}

export default ChapterTable;

function ActionRow({ chapters, setChapters, deleteChapter, row }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const intl = useIntl();

  const handleDelete = () => {
    deleteChapter(row);
    setModalOpen(false);
  };

  return (
    <ButtonsContainer>
      <Button
        label={"edit"}
        size={"sm"}
        variant={"secondary"}
        Icon={<EditIcon size={16} />}
        onClick={() => {
          setModalEditOpen(true);
        }}
      />
      <Button
        label={"delete"}
        size={"sm"}
        variant={"destructive"}
        Icon={<TrashIcon size={16} />}
        onClick={() => setModalOpen(true)}
      />
      <Modal
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        width={"592px"}
        footer={""}
        closable={false}
        className="modalChapter"
      >
        <ModalTitle>
          {intl.formatMessage(
            {
              id: "delete_chapter_title",
            },
            {
              chapter: `"${row.chapterTitle}"`,
            }
          )}
        </ModalTitle>
        <ModalSubtitle>
          {intl.formatMessage({
            id: "delete_chapter_sub",
          })}
        </ModalSubtitle>
        <ModalSubtitle bold>
          {intl.formatMessage({
            id: "irreversible_action",
          })}
        </ModalSubtitle>
        <ModalButtonsWrapper>
          <Button
            label={"goback"}
            variant={"secondary"}
            Icon={<BackIcon size={16} />}
            onClick={() => setModalOpen(false)}
          />
          <Button
            label={"delete"}
            variant={"destructive2"}
            Icon={<TrashIcon size={16} />}
            onClick={handleDelete}
          />
        </ModalButtonsWrapper>
      </Modal>
      <EditChapter
        modalOpen={modalEditOpen}
        chapters={chapters}
        setChapters={setChapters}
        resetModal={() => setModalEditOpen(false)}
        chapter={row}
      />
    </ButtonsContainer>
  );
}

function EditChapter({
  modalOpen,
  resetModal,
  setChapters,
  chapters,
  chapter,
}) {
  return (
    <Modal
      open={modalOpen}
      onCancel={resetModal}
      footer={""}
      closable={false}
      className="modalChapter"
      width={600}
    >
      <EditChapterForm
        defaultValue={chapter}
        chapters={chapters}
        setChapters={setChapters}
        resetModal={resetModal}
      />
    </Modal>
  );
}
