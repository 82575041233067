import AnimatedMainContainer from "../../../../components/AnimatedMainContainer";
import { Container } from "../../style";
import { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { FormattedMessage, useIntl } from "react-intl";
import { HeaderContainer } from "../chapter/styles";
import FAQForm from "./faq-form";
import FAQTable from "./faq-table";
import { useDispatch, useSelector } from "react-redux";
import { listFAQ } from "../../../../redux/modules/adminExhibitorManual/actions";
import Button from "../../../InformaAcademy/components/button";
import Save from "../../../../icons/Save";
import { ButtonContainer } from "../../newManual/styles";
import { useToast } from "../../../../hooks/toast";
import api from "../../../../service";

export default function ManageFAQ() {
  const intl = useIntl();
  const { manualId } = useParams();
  const { addToast } = useToast();
  const history = useHistory();
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [FAQarray, setFAQarray] = useState([]);
  const { FAQ } = useSelector((state) => state.adminManualExhibitor);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    dispatch(listFAQ({ manualId }));
  }, [dispatch, manualId]);

  useEffect(() => {
    if (FAQ) {
      setFAQarray(FAQ);
    }
  }, [FAQ]);

  const createOnDb = async ({ question, answer, order }) => {
    const body = JSON.stringify({ question, answer, orders: order });

    await api.post(`/manual/${manualId}/faq`, body, config);
  };

  const updateInDb = async ({ question, answer, order, faqId }) => {
    const body = JSON.stringify({ question, answer, orders: order });

    await api.put(`/manual/${manualId}/faq/${faqId}`, body, config);
  };

  const updateFAQInDB = async (FAQToUpdate) => {
    if (FAQToUpdate.length === 0) return;

    const updatePromises = FAQToUpdate.map((question) => {
      return updateInDb({
        order: question.order,
        question: question.question,
        answer: question.answer,
        faqId: question.id,
      });
    });
    return Promise.all(updatePromises);
  };

  const createFAQOnDB = async (FAQToCreate) => {
    if (FAQToCreate.length === 0) return;

    const createPromises = FAQToCreate.map((question) => {
      return createOnDb({
        order: question.order,
        question: question.question,
        answer: question.answer,
      });
    });
    return Promise.all(createPromises);
  };

  const handleSave = async () => {
    try {
      const FAQWithId = FAQarray.filter((i) => i.id);
      const FAQWithoutId = FAQarray.filter((i) => i.id === undefined);

      await updateFAQInDB(FAQWithId);
      await createFAQOnDB(FAQWithoutId);

      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "success",
        }),
        description: intl.formatMessage({
          id: "success.FAQ_update",
        }),
      });
      history.push(`/admin/manual/${manualId}`);
    } catch (error) {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error.FAQ_update",
        }),
      });
    }
  };

  return (
    <AnimatedMainContainer goBack={`/admin/manual/${manualId}`}>
      <Container>
        <Header />
        <FAQForm FAQ={FAQarray} setFAQ={setFAQarray} />
        <FAQTable
          key={FAQarray.length}
          FAQarray={FAQarray}
          setFAQarray={setFAQarray}
          manualId={manualId}
        />
        <ButtonContainer>
          <Button
            label={"save"}
            Icon={<Save />}
            onClick={handleSave}
            disabled={FAQarray.length === 0}
          />
        </ButtonContainer>
      </Container>
    </AnimatedMainContainer>
  );
}

function Header() {
  return (
    <HeaderContainer>
      <p>
        <FormattedMessage id={"manual_manage_FAQ"} />
      </p>
    </HeaderContainer>
  );
}
