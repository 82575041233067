import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const BannerWrapper = styled.div`
  background: white;
  position: relative;
  overflow: hidden;
  /* margin-bottom: 4rem; */

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 60%;
    height: 60%;
    pointer-events: none;
  }

  &::before {
    top: -20%;
    left: -20%;
    background: radial-gradient(
      circle at center,
      rgba(17, 167, 217, 0.2) 0%,
      rgba(17, 167, 217, 0.08) 15%,
      rgba(255, 255, 255, 0) 30%
    );
    filter: blur(50px);
    transform: scale(2.2);
  }

  &::after {
    bottom: -20%;
    right: -20%;
    background: radial-gradient(
      circle at center,
      rgba(17, 167, 217, 0.2) 0%,
      rgba(17, 167, 217, 0.08) 15%,
      rgba(255, 255, 255, 0) 30%
    );
    filter: blur(50px);
    transform: scale(2.2);
  }
`;

export const ManualContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`;

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* padding: 24px 0; */
`;

export const Logo = styled.img`
  width: 300px;
  cursor: pointer;
`;

export const HeaderContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BannerContainer = styled.div`
  text-align: center;
  padding: 60px 24px;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  height: fit-content;

  ${({ searchPage }) =>
    searchPage &&
    css`
      padding: 24px;
    `};
`;

export const EventName = styled.p`
  font-size: 18px;
  color: #1e293b;
  text-transform: uppercase;
`;

export const Title = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: 48px;
  color: #1e293b;
  line-height: 1.2;
  font-weight: 700;
`;

export const SecondTitle = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  color: #1e293b;
  font-weight: 600;
`;

export const Description = styled.p`
  font-size: 18px;
  color: #4a4a4a;
  line-height: 1.5;
  max-width: 640px;
  margin: 0 auto;
  font-weight: 500;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  position: relative;
`;

export const IconUser = styled.div`
  background: #38d395;
  width: 74.62px;
  height: 74.62px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -30px;
  left: 190px;
  z-index: 1;
  box-shadow: 0px 5.51px 22.05px 0px rgba(65, 91, 245, 0.22);

  svg {
    color: white;
    font-size: 33.73px;
  }
`;

export const IconShield = styled.div`
  background: #38d395;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  right: 60px;
  z-index: 1;
  box-shadow: 0px 5.51px 13px 1px #aafc3780;

  ${({ searchPage }) =>
    searchPage &&
    css`
      bottom: -15px;
    `};

  svg {
    color: white;
    font-size: 16px;
  }
`;

export const SearchContainer = styled.div`
  width: 540px;
  height: 51px;
  margin: 40px auto 0;
  position: relative;
  display: flex;
  align-items: stretch;
`;

export const CategorySelect = styled.div`
  position: relative;
  min-width: 180px;
  border-radius: 30px 0 0 30px;
  background: #f1f5f9;
  border: 2px solid #e5e5e5;
  border-right: none;

  &::after {
    content: "";
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #095f83;
    pointer-events: none;
  }
`;

export const SearchSelect = styled.select`
  height: 100%;
  width: 100%;
  /* padding: 16px 35px 16px 24px; */
  border: 0;
  background: #f1f5f9 !important;
  appearance: none;
  background: white;
  font-size: 16px;
  color: #4a4a4a;
  cursor: pointer;
  outline: none;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* padding-right: 15px; */
  max-width: 90%;

  &,
  option {
    font-family: "Inter";
    font-weight: 500;
    font-size: 13px;
    line-height: 22.4px;
    letter-spacing: 0%;
    text-align: center;
    color: #095f83 !important;
  }
`;

export const SearchInput = styled.input`
  flex: 1;
  padding: 16px 24px;
  border: 2px solid #e5e5e5;
  border-radius: 0 30px 30px 0;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
  margin-left: -2px;

  &:focus {
    /* border-color: #45d0a1; */
    /* box-shadow: 0 0 0 4px rgba(69, 208, 161, 0.1); */
  }

  &::placeholder {
    color: #9ca3af;
  }
`;

export const SearchButton = styled.button`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: #11a7d9;
  border: none;
  border-radius: 24px;
  padding: 8px 16px;
  color: white;
  font-weight: 500;
  cursor: pointer;

  width: 48px;
  height: 35px;
  border-radius: 24px;
  gap: 10px;
  padding-top: 6px;
  padding-right: 16px;
  padding-bottom: 6px;
  padding-left: 16px;
  display: flex;
  align-items: center;
`;

export const FloatingDot = styled.div`
  position: absolute;
  background: #38d395;
  border-radius: 50%;
  filter: blur(4px);
  opacity: 0.8;
  z-index: 0;

  &.top-right {
    width: 30px;
    height: 30px;
    top: 20px;
    right: 30px;
  }

  &.bottom-left {
    width: 40px;
    height: 40px;
    bottom: 100px;
    left: 100px;
  }
`;

export const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  background: #11a7d9;
  border: none;
  border-radius: 24px;
  padding: 8px 16px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  margin-left: 27px;
  margin-bottom: 14px;
  cursor: pointer;

  &:hover {
    background: #11a7d9;
    color: white;
  }

  svg {
    font-size: 24px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 4rem;
`;

export const Subtitle = styled(EventName)`
  font-size: 14px;
  letter-spacing: 0.3em;
`;

export const TopicTitle = styled.h2`
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 0;
`;

export const TopicCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
  gap: 35px;
  padding: 40px 0;
`;

export const TopicCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  border-radius: 16px;
  padding: 20px 30px;
  width: 400px;
  height: 350px;
  box-shadow: 1.51px 1.51px 15.1px 0px #a8a8a840;

  transition: all 0.3s ease;

  border: 1px solid #cbd5e1;
`;

export const SavedArticlesContainer = styled.div`
  display: grid;
  place-content: center;
  width: calc(376px * 3 + 16px * 2);
  grid-template-columns: repeat(auto-fill, minmax(376px, 376px));
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 26px;
`;

export const SavedArticlesCard = styled.div`
  height: 345px;
  border-radius: 16px;
  border-width: 2px;
  padding-top: 40px;
  padding-right: 20px;
  padding-bottom: 40px;
  padding-left: 20px;

  border: 2px solid var(--Stroke-default, #cbd5e1);

  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 4px 4px 10px 0px #80808040;
`;

export const SavedArticlesTitle = styled.p`
  font-family: "Poppins";
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0%;
  vertical-align: middle;
  color: #1e293b;
  margin-bottom: 0;
`;

export const SavedArticlesCategory = styled.span`
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  line-height: 22.4px;
  letter-spacing: 5%;
  vertical-align: middle;

  color: #585f74;
  margin-bottom: 0;
`;

export const SavedArticlesDescription = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
  height: 333px;

  color: #585f74;
  margin-bottom: 0;
`;

export const TopicCardHeader = styled.div`
  display: flex;
  align-items: ${({ $column }) => ($column ? "flex-start" : "center")};
  justify-content: ${({ $column }) => ($column ? "center" : "space-between")};
  flex-direction: ${({ $column }) => ($column ? "column" : "row")};
  gap: 8px;
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: ${({ $column }) => ($column ? "none" : "1px solid #e2e8f0")};

  svg {
    color: #38d395;
    font-size: 20px;
  }
`;

export const TopicCardTitle = styled.h3`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #1e293b;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

const generateRandomHSL = () => {
  const hue = Math.floor(Math.random() * 360);
  const saturation = Math.floor(Math.random() * 61) + 40;
  const lightness = Math.floor(Math.random() * 31) + 40;
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

export const IconCircle = styled.div`
  width: 24.16438102722168px;
  height: 24.16438102722168px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  background: ${generateRandomHSL()};
`;

export const TopicCardCount = styled.span`
  color: #585f74;
  font-size: 14px;
  font-weight: 400;
`;

export const TopicCardList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 20px;
`;

export const TopicCardItem = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #475569;
  transition: all 0.2s ease;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 19.33px;
  letter-spacing: 0%;
  vertical-align: middle;
  border-bottom: 1px solid transparent;
  width: fit-content;

  &:hover {
    transform: translateX(3px);
    border-color: #475569;
  }
`;

export const TopicCardText = styled.span`
  color: #475569;
  font-size: 14px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #475569;
  }
`;

export const TopicCardLink = styled(Link)`
  display: flex;
  align-items: center;
  background: none;
  outline: none;
  border: none;
  gap: 4px;
  color: #11a7d9;
  text-decoration: none;
  margin-top: 16px;
  transition: color 0.2s ease;

  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  line-height: 22.4px;
  letter-spacing: 0%;
  vertical-align: middle;

  cursor: pointer;

  &:hover {
    color: #0d86ae;
  }

  svg {
    font-size: 16px;
  }
`;

export const Button = styled.button`
  background: #11a7d9;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;

export const ArticleTitle = styled(TopicCardTitle)`
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  line-height: 1;
`;

export const ArticleSubtitle = styled(TopicCardCount)`
  font-size: 12px;
  text-transform: uppercase;
`;

export const ArticleDescription = styled.p`
  font-size: 14px;
  color: #585f74;
  font-weight: 400;
`;

export const ArticleButton = styled(Link)`
  width: fit-content;
  background: #11a7d9;
  display: flex;
  align-items: center;
  gap: 4px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 8px;
  margin-left: auto;

  transition: all 0.3s ease;

  &:hover {
    background: rgba(17, 167, 217, 0.79);
    color: white;
  }
`;

export const ImportantInfoContainer = styled.div`
  display: flex;
  /* grid-template-columns: 1fr 1fr; */
  gap: 24px;
  width: 100%;
  max-width: 1200px;
  padding: 0 24px;
  margin-top: 40px;

  /* &.full-width {
    grid-template-columns: 1fr;
  } */
`;

export const InfoOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: all 0.3s ease-in-out;

  width: 100%;
  ${({ $isActive }) =>
    $isActive &&
    css`
      width: 50%;
    `};
`;

export const InfoOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background: ${({ $isActive }) => ($isActive ? "#1d9c59" : "white")};
  border: ${({ $isActive }) =>
    $isActive ? "1px solid #1d9c59" : "1px solid #E5E5E5"};
  min-height: 70px;
  border-width: 1px;
  justify-content: space-between;
  padding-top: 18.94px;
  padding-right: 25.63px;
  padding-bottom: 18.94px;
  padding-left: 25.63px;
`;

export const InfoOptionTitle = styled.h3`
  color: ${({ $isActive }) => ($isActive ? "white" : "#1E293B")};
  margin: 0;
  word-wrap: break-word;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0%;
  vertical-align: middle;
  max-width: 470px;
`;

export const InfoOptionIcon = styled.div`
  display: flex;
  align-items: center;
  color: ${({ $isActive }) => ($isActive ? "white" : "#1E293B")};

  svg {
    font-size: 20px;
  }
`;

export const WrapperInfo = styled.div`
  overflow: hidden;
  width: 0%;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  p {
    opacity: 0;
    transition: all 0.3s 0.3s ease-in-out;
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      width: 50%;
      opacity: 1;

      p {
        opacity: 1;
      }
    `};
`;

export const InfoDetailsContainer = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border: 1.11px solid #cbd5e1;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`;

export const InfoDetailsText = styled.p`
  font-size: 14px;
  color: #1e293b;
  line-height: 1.6;
  margin: 0;

  font-family: "Inter";
  font-weight: 400;
  font-size: 18px;
  line-height: 28.8px;
  letter-spacing: 0%;
  vertical-align: middle;
`;

export const Container = styled.div`
  background: #fff;
  width: 100%;
`;
