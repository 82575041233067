import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { HeaderContainer } from "../../newManual/styles";
import ForwardIcon from "../../../../icons/Forward";
import { FaRegTrashAlt as TrashIcon } from "react-icons/fa";
import { Modal } from "antd";
import {
  ModalButtonsWrapper,
  ModalSubtitle,
  ModalTitle,
} from "../../../InformaAcademy/styles";
import BackIcon from "../../../../icons/Back";
import Button from "../../../InformaAcademy/components/button";
import Save from "../../../../icons/Save";
import { ArticleButtonContainer } from "./styles";

export function FormHeader({
  step,
  saveAsDraft,
  postArticle,
  defaultValue,
  handleDelete,
  articleTitle,
}) {
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  return (
    <HeaderContainer>
      <h2>
        {!defaultValue && (
          <FormattedMessage
            id={step === 0 ? "article_new" : "article_new_preview"}
          />
        )}
        {defaultValue && (
          <FormattedMessage
            id={step === 0 ? "article_edit" : "article_edit_preview"}
          />
        )}
      </h2>
      {defaultValue && step === 0 && (
        <Button
          label={"delete"}
          Icon={<TrashIcon />}
          variant={"tertiaryDestructive"}
          type={"button"}
          onClick={() => setOpen(true)}
          border
        />
      )}
      {step === 1 && (
        <ArticleButtonContainer>
          <Button
            label={"article_save_draft"}
            Icon={<Save stroke={"#002244"} />}
            variant={"secondary"}
            type={"button"}
            onClick={saveAsDraft}
          />
          <Button
            label={"article_publish"}
            Icon={<ForwardIcon />}
            type={"button"}
            onClick={postArticle}
          />
        </ArticleButtonContainer>
      )}

      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        width={"592px"}
        footer={""}
        closable={false}
        className="modalLesson"
      >
        <ModalTitle>
          {intl.formatMessage(
            {
              id: "delete_article_title",
            },
            {
              article: `"${articleTitle}"`,
            }
          )}
        </ModalTitle>
        <ModalSubtitle>
          {intl.formatMessage({
            id: "delete_article_sub",
          })}
        </ModalSubtitle>
        <ModalSubtitle bold>
          {intl.formatMessage({
            id: "irreversible_action",
          })}
        </ModalSubtitle>
        <ModalButtonsWrapper>
          <Button
            label={"goback"}
            variant={"secondary"}
            Icon={<BackIcon size={16} />}
            onClick={() => setOpen(false)}
          />
          <Button
            label={"delete"}
            variant={"destructive2"}
            Icon={<TrashIcon size={16} />}
            onClick={handleDelete}
          />
        </ModalButtonsWrapper>
      </Modal>
    </HeaderContainer>
  );
}
