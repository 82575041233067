import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import Button from "./components/button";
import {
  Container,
  TitleContainer,
  Title,
  WrapperContainer,
  CourseCardContainer,
  CourseCardsContainer,
  Loader,
  ImageContainer,
  CourseImageCard,
  SkeletonWrapper,
  FailedToLoad,
} from "./styles";
import { BiPlus } from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import Empty from "./components/Empty";
import CoursesTable from "./components/CoursesTable";
import { useCallback, useEffect, useState } from "react";
import SearchInput from "./components/SearchInput";
import api from "../../service";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../../hooks/toast";
import { getAdminLatestCourses } from "../../redux/modules/informaAcademy/actions";
import { LoadingSpinnerLogo } from "../../components/LoadingOverlay/styles";
import LogoCircle from "../../assets/images/Informa_Logo_Circle.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SkeletonDiv } from "../../newUXPages/InformaAcademy/Lesson/styles";

const DEFAULT_PAGE = 1;
const DEFAULT_ROWS_PER_PAGE = 10;

function InformaAcademy() {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const { token } = useSelector((state) => state.auth);
  const [totalRows, setTotalRows] = useState(0);
  const { addToast } = useToast();
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const fetchData = useCallback(
    async (page, size, sortField, sortOrder) => {
      try {
        setLoading(true);
        const normalParams = {
          page: page - 1,
          per_page: size,
          sortBy: sortField,
          sortDirection: sortOrder,
        };

        const options = {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
          params: normalParams,
        };

        const { data: response } = await api.get(
          "/informa-academy/courses",
          options
        );

        const { courses } = response;
        setCourses(courses.content);
        setTotalRows(courses.totalElements);
      } catch (error) {
        if (error.response.data.code !== 404) {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: `api.error.${error.response.data.code}`,
            }),
          });
        }
        return setCourses([]);
      } finally {
        setLoading(false);
      }
    },
    [addToast, intl, token]
  );

  const fetchSearchData = useCallback(
    async (page, size, search, sortField, sortOrder) => {
      try {
        setLoading(true);
        const params = {
          query: search,
          page: page - 1,
          per_page: size,
          sortBy: sortField,
          sortDirection: sortOrder,
        };

        const options = {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
          params,
        };

        const { data: response } = await api.get(
          "/informa-academy/search",
          options
        );

        const { courses } = response;
        setCourses(courses.content);
        setTotalRows(courses.totalElements);
      } catch (error) {
        if (error.response.data.code !== 404) {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: `api.error.${error.response.data.code}`,
            }),
          });
        }
        return setCourses([]);
      } finally {
        setLoading(false);
      }
    },
    [addToast, intl, token]
  );

  useEffect(() => {
    if (search.length >= 3) {
      fetchSearchData(currentPage, rowsPerPage, search, sortBy, sortDirection);
    }
    if (search.length === 0) {
      fetchData(currentPage, rowsPerPage, sortBy, sortDirection);
    }
  }, [
    search,
    currentPage,
    rowsPerPage,
    sortBy,
    sortDirection,
    fetchSearchData,
    fetchData,
  ]);

  return (
    <AnimatedMainContainer
      breadcrumb={`Informa Academy | ${intl.formatMessage({
        id: "informa_academy_courses",
      })}`}
    >
      <WrapperContainer>
        <LastestCourses />

        <Container>
          <CoursesHeader setSearch={setSearch} coursesNumber={totalRows} />
          <CoursesTable
            setCourses={setCourses}
            loading={loading}
            data={courses}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalRows={totalRows}
            setTotalRows={setTotalRows}
            setSortBy={setSortBy}
            setSortDirection={setSortDirection}
            refecthOnDelete={fetchData}
          />
        </Container>
      </WrapperContainer>
    </AnimatedMainContainer>
  );
}

export default InformaAcademy;

function CoursesHeader({ setSearch, coursesNumber }) {
  return (
    <TitleContainer border={"noBorder"}>
      <Title>
        <FormattedMessage id={"informa_academy_courses"} /> ({coursesNumber})
      </Title>
      <SearchInput setSearch={setSearch} />
    </TitleContainer>
  );
}

function LastestCourses() {
  const intl = useIntl();
  const { latestCourses, latestCoursesLoading, latestCoursesError } =
    useSelector((state) => state.informaAcademy);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminLatestCourses());
  }, [dispatch]);

  return (
    <Container>
      <TitleContainer>
        <Title>
          <FormattedMessage id={"informa_academy_recently_modified"} /> (
          {latestCourses.length})
        </Title>
        <Button
          label={intl.formatMessage({
            id: "informa_academy_new_course",
          })}
          Icon={<BiPlus size={20} />}
          href={"/admin/informa-academy/courses/new"}
        />
      </TitleContainer>
      {!latestCoursesError &&
        !latestCoursesLoading &&
        latestCourses.length === 0 && <Empty />}
      {latestCoursesLoading && (
        <Loader>
          <LoadingSpinnerLogo src={LogoCircle} />
        </Loader>
      )}
      {!latestCoursesError &&
        !latestCoursesLoading &&
        latestCourses.length > 0 && (
          <CourseCardsContainer>
            {latestCourses.map((course) => (
              <CourseCard
                category={course.category}
                courseTitle={course.courseTitle}
                coverImage={course.coverImage}
                id={course.courseId}
                key={course.courseId}
              />
            ))}
          </CourseCardsContainer>
        )}
      {latestCoursesError && (
        <Empty
          highlightText="db_error"
          intlId="informa_academy_latest_courses_error_text"
        />
      )}
    </Container>
  );
}

function CourseCard({ coverImage, courseTitle, category, id }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const history = useHistory();

  const handleRedirect = () => {
    history.push(`/admin/informa-academy/courses/${id}`);
  };
  return (
    <CourseCardContainer onClick={handleRedirect}>
      <ImageContainer>
        {loading && (
          <SkeletonWrapper>
            <SkeletonDiv type="courseImg" />
          </SkeletonWrapper>
        )}
        {error && (
          <FailedToLoad className="failed" isLoading={loading}>
            <BrokenImage />
            <p>
              <FormattedMessage id={"load_fail"} />
            </p>
          </FailedToLoad>
        )}
        <CourseImageCard
          error={error}
          isLoading={loading}
          loading="lazy"
          src={coverImage}
          alt={coverImage}
          onLoad={() => {
            setLoading(false);
          }}
          onError={() => {
            setLoading(false);
            setError(true);
          }}
        />
      </ImageContainer>
      <div>
        <p title={courseTitle}>{courseTitle}</p>
        <span title={category}>{category}</span>
      </div>
    </CourseCardContainer>
  );
}

const BrokenImage = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="currentColor"
    stroke="currentColor"
    strokeWidth="0"
    viewBox="0 0 256 256"
  >
    <path
      stroke="none"
      d="M224 56v32l-48 16-16 40-23.35 9.34-39-39a8 8 0 0 0-11.32 0L32 168.69V56a8 8 0 0 1 8-8h176a8 8 0 0 1 8 8"
      opacity="0.2"
    ></path>
    <path
      stroke="none"
      d="M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h64a8 8 0 0 0 7.59-5.47l14.83-44.48L163 151.43a8.07 8.07 0 0 0 4.46-4.46l14.62-36.55 44.48-14.83A8 8 0 0 0 232 88V56a16 16 0 0 0-16-16M112.41 157.47 98.23 200H40v-28l52-52 30.42 30.42-5.42 2.15a8 8 0 0 0-4.59 4.9M216 82.23l-42.53 14.18a8 8 0 0 0-4.9 4.62l-14.72 36.82-15.27 6.15-35.27-35.27a16 16 0 0 0-22.62 0L40 149.37V56h176Zm12.68 33a8 8 0 0 0-7.21-1.1l-23.8 7.94a8 8 0 0 0-4.9 4.61l-14.31 35.77-35.77 14.31a8 8 0 0 0-4.61 4.9l-7.94 23.8a8 8 0 0 0 7.59 10.54H216a16 16 0 0 0 16-16v-78.27a8 8 0 0 0-3.32-6.49ZM216 200h-67.17l3.25-9.75 35.51-14.2a8.07 8.07 0 0 0 4.46-4.46l14.2-35.51 9.75-3.25Z"
    ></path>
  </svg>
);
