import styled, { keyframes } from "styled-components";

export const appear = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const ContentContainer = styled.div`
  padding: 24px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  background-color: #f7f9fa;
  max-width: 1440px;
  margin: 0 auto;

  & > img {
    width: 696px;
    height: 467px;
    animation: ${appear} 500ms;
  }

  & > div {
    color: #000;
    max-width: 613px;
    width: 100%;

    & h1,
    h2,
    h3,
    p {
      margin: 0;
    }

    & h1 {
      font-weight: 700;
      font-size: 40px;
      line-height: 60px;
    }

    & h2,
    h3 {
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
    }

    & h3 {
      font-weight: 600;
    }

    & p {
      margin-top: 8px;
      margin-bottom: 32px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }

    @media only screen and (max-width: 1300px) {
      order: 999;
    }
  }
`;
