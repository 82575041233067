import React, { useEffect } from "react";
import {
  CategoryLabel,
  MostAccessedTag,
  ResultCard,
  ResultDescription,
  ResultTitle,
  SearchHeader,
  SearchResultsContainer,
  SearchTitle,
} from "./style";
import { useDispatch, useSelector } from "react-redux";
import { ArticleListWrapper } from "../ArticleList/styles";
import ManualSidebar from "../sidebar";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  getManualTopicList,
  searchManualTopicList,
} from "../../../redux/modules/eventManual/action";
import EventManualHeader from "../EventManualHomepage/Header";
import { FormattedMessage } from "react-intl";
import { Container } from "../styles";
import ScrollTopButton from "../../../newUXComponents/ScrollTopButton";
import sanitizeHtml from "sanitize-html";

const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

export default function EventManualSearch() {
  const { searchResult, topics, loadedSearch } = useSelector(
    (state) => state.eventManual
  );
  const dispatch = useDispatch();
  const query = useQueryParams();
  const queryParams = query.get("query");
  const chapterParams = query.get("chapter");

  useEffect(() => {
    dispatch(getManualTopicList());
  }, [dispatch]);

  useEffect(() => {
    if (!loadedSearch && topics.length > 0 && queryParams) {
      const chapterId = topics.find(
        (topic) => topic.chapterTitle === chapterParams
      )?.chapterId;

      dispatch(
        searchManualTopicList({
          userSearch: queryParams,
          category: chapterId ?? "",
        })
      );
    }
  }, [chapterParams, dispatch, loadedSearch, queryParams, topics]);

  function cleanHtml(html) {
    return sanitizeHtml(html, {
      allowedTags: ["br"],
    }).replace(/<br\s*\/?>/g, "\n");
  }

  function highlightSearchTermWithSmartContext(
    text,
    searchTerm,
    contextSize = 230
  ) {
    if (!searchTerm || !text) return text;

    const lowerText = text.toLowerCase();
    const lowerSearchTerm = searchTerm.toLowerCase();
    const index = lowerText.indexOf(lowerSearchTerm);

    if (index === -1) return text; // Term not found

    // Find paragraph boundaries or use contextSize
    const paragraphBefore = text.lastIndexOf("\n\n", index);
    const paragraphAfter = text.indexOf("\n\n", index + searchTerm.length);

    // Use paragraph boundaries if they exist and are within a reasonable range
    // Otherwise fall back to contextSize
    const startIndex =
      paragraphBefore !== -1 && index - paragraphBefore < contextSize * 2
        ? paragraphBefore + 2
        : Math.max(0, index - contextSize);

    const endIndex =
      paragraphAfter !== -1 && paragraphAfter - index < contextSize * 2
        ? paragraphAfter
        : Math.min(text.length, index + searchTerm.length + contextSize);

    let snippet = text.substring(startIndex, endIndex);

    // Highlight all occurrences of the search term as whole words in the snippet
    // Using word boundary markers (\b) to match only whole words
    const regex = new RegExp(
      "\\b" + searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") + "\\b",
      "gi"
    );

    return snippet.replace(regex, "<b>$&</b>");
  }

  return (
    <Container>
      <EventManualHeader
        topics={topics}
        defaultInput={queryParams ?? ""}
        defaultChapter={chapterParams ?? "all"}
      />

      <ArticleListWrapper>
        <ManualSidebar topics={topics} />
        <SearchResultsContainer>
          <SearchHeader>
            <SearchTitle>
              <FormattedMessage id={"homepage.searchResult"} /> "{queryParams}"
            </SearchTitle>
          </SearchHeader>

          {searchResult.length === 0 && loadedSearch && (
            <div>
              <FormattedMessage id={"homepage.NoMaches"} />
            </div>
          )}
          {searchResult && (
            <div>
              {searchResult.map((result, index) => (
                <ResultCard key={index}>
                  <CategoryLabel>
                    {result.chapterTitle}
                    {result.mostAccessed && (
                      <MostAccessedTag>
                        <FormattedMessage id="exhibitor_manual_mostViewed" />
                      </MostAccessedTag>
                    )}
                  </CategoryLabel>
                  <ResultTitle
                    to={`/event-manual/chapter/${result.chapterId}/article/${result.articleId}`}
                  >
                    {result.articleTitle}
                  </ResultTitle>
                  <ResultDescription
                    dangerouslySetInnerHTML={{
                      __html: highlightSearchTermWithSmartContext(
                        cleanHtml(result.articleDescription),
                        queryParams
                      ),
                    }}
                  />
                </ResultCard>
              ))}
            </div>
          )}
        </SearchResultsContainer>
      </ArticleListWrapper>

      <ScrollTopButton />
    </Container>
  );
}
