import React, { useState } from "react";
import {
  LessonCardContainer,
  LessonTitleContainer,
  LessonHeaderWrapper,
  LessonNumber,
  LessonTitle,
  Circle,
  ImageCover,
} from "./style";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Concluded from "../Concluded";
import InformaMarketsLogoWhite from "../../newUXAssets/images/Informa_Markets_Logo_White.png";
import { useIntl } from "react-intl";

export const LessonCard = ({
  lesson,
  index,
  courseId,
  lessonId,
  courseStatus,
}) => {
  const [flag, setFlag] = useState(!lesson?.url);
  const intl = useIntl();
  const history = useHistory();

  const redirectToLesson = () => {

    history.push(`/informa-academy/course/${courseId}/lesson/${lessonId}`);
  };

  const lessonString = intl.formatMessage({
    id: "lesson",
  });

  return (
    <LessonCardContainer onClick={redirectToLesson}>
      {lesson?.url && lesson.completed && <Concluded overlap />}

      {flag && (
        <>
          <LessonTitleContainer>
            <LessonHeaderWrapper>
              <LessonNumber>{`${lessonString} ${index + 1}`}</LessonNumber>
              {lesson.completed && <Concluded />}
            </LessonHeaderWrapper>
            <LessonTitle>{lesson.title}</LessonTitle>
          </LessonTitleContainer>
          <Circle />
          <img
            src={InformaMarketsLogoWhite}
            alt="Informa Markets Logo"
            className="informaLogo"
          />
        </>
      )}
      {!flag && (
        <ImageCover
          src={lesson?.url}
          loading="lazy"
          alt={`Lesson Cover Image - ${lesson.title}`}
          onError={() => setFlag(true)}
        />
      )}
    </LessonCardContainer>
  );
};

export const LessonCardPreview = ({ title, order, imageUrl }) => {
  const [flag, setFlag] = useState(!imageUrl);
  const intl = useIntl();
  const lessonString = intl.formatMessage({
    id: "lesson",
  });

  return (
    <LessonCardContainer noHover>
      {flag && (
        <>
          <LessonTitleContainer>
            <LessonHeaderWrapper>
              <LessonNumber>{`${lessonString} ${order}`}</LessonNumber>
            </LessonHeaderWrapper>
            <LessonTitle>{title}</LessonTitle>
          </LessonTitleContainer>
          <Circle />
          <img
            src={InformaMarketsLogoWhite}
            alt="Informa Markets Logo"
            className="informaLogo"
          />
        </>
      )}
      {!flag && (
        <ImageCover
          src={imageUrl}
          alt={`Lesson Cover Image - ${title}`}
          onError={() => setFlag(true)}
        />
      )}
    </LessonCardContainer>
  );
};
