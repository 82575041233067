import { Modal } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Title, TitleContainer } from "../../styles";
import Button from "../button";
import { BiPlus } from "react-icons/bi";
import LessonTable from "./lesson-table";
import LessonForm from "./lesson-form";
import { IoReload } from "react-icons/io5";

const Lesson = ({
  lessons,
  setLessons,
  hasError = false,
  refecthData = () => {},
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [updateCounter, setUpdateCounter] = useState(0);

  const resetModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <TitleContainer>
        <Title>
          <FormattedMessage id={"added_lessons"} /> ({lessons.length})
        </Title>
        <div>
          {hasError && (
            <Button
              label={"refetch_lessons"}
              Icon={<IoReload size={20} />}
              onClick={refecthData}
              variant={"secondary"}
            />
          )}
          <Button
            label={"add_lesson"}
            Icon={<BiPlus size={20} />}
            onClick={() => {
              setModalOpen(true);
            }}
          />
        </div>
      </TitleContainer>

      <AddLesson
        modalOpen={modalOpen}
        resetModal={resetModal}
        setLessons={setLessons}
        lessons={lessons}
        setUpdateCounter={setUpdateCounter}
      />

      <LessonTable
        lessons={lessons}
        loading={false}
        setLessons={setLessons}
        updateCounter={updateCounter}
        setUpdateCounter={setUpdateCounter}
      />
    </div>
  );
};

export default Lesson;

export function AddLesson({
  modalOpen,
  resetModal,
  setLessons,
  lessons,
  setUpdateCounter,
}) {
  return (
    <Modal
      open={modalOpen}
      onCancel={resetModal}
      width={"1192px"}
      footer={""}
      closable={false}
      className="modalLesson"
      centered={true}
    >
      <LessonForm
        key={Math.random()}
        lessons={lessons}
        setLessons={setLessons}
        resetModal={resetModal}
        setUpdateCounter={setUpdateCounter}
      />
    </Modal>
  );
}
