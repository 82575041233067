import React, { useState, useEffect } from "react";
import {
  Arrow,
  BackgroundDecoration,
  BannerButton,
  BannerContainer,
  BannerDescription,
  BannerImage,
  BannerSlide,
  BannerTitle,
  BannerWrapper,
  CarouselDots,
  ContentColumn,
  Dot,
  ImageColumn,
  NavigationButton,
} from "./style";
import SvgBannerImageBg from "./svg-banner-image-bg";
import useZoom from "../../../hooks/zoom";
import { FormattedMessage } from "react-intl";

const BannerCaroussel = ({ banners, handleInteraction, handleViews }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [translate, setTranslate] = useState(0);
  const [actionTriggered, setActionTriggered] = useState(false);
  const { scale } = useZoom();

  useEffect(() => {
    if (banners?.length > 1) {
      const interval = setInterval(() => {
        nextSlide();
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [currentSlide, banners?.length]);

  useEffect(() => {
    if (currentSlide === banners.length - 1 && !actionTriggered) {
      banners.map((banner) => {
        return handleViews({ bannerId: banner.id });
      });
      setActionTriggered(true);
    }
  }, [actionTriggered, banners, currentSlide, handleViews]);

  const nextSlide = () => {
    const next = currentSlide === banners.length - 1 ? 0 : currentSlide + 1;

    setCurrentSlide(next);
    setTranslate(-next * 100);
  };

  const prevSlide = () => {
    const prev = currentSlide === 0 ? banners.length - 1 : currentSlide - 1;
    setCurrentSlide(prev);
    setTranslate(-prev * 100);
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
    setTranslate(-index * 100);
  };

  if (!banners || banners.length === 0) return null;

  return (
    <BannerContainer>
      {banners.length > 1 && (
        <>
          <NavigationButton direction="prev" onClick={prevSlide}>
            <Arrow direction="prev" />
          </NavigationButton>
          <NavigationButton direction="next" onClick={nextSlide}>
            <Arrow direction="next" />
          </NavigationButton>
        </>
      )}
      <BannerWrapper translate={translate}>
        {banners.map((banner, index) => (
          <BannerSlide key={index} index={index}>
            <ContentColumn>
              <BannerTitle>{banner.title}</BannerTitle>
              <BannerDescription
                scale={scale}
                dangerouslySetInnerHTML={{ __html: banner.description }}
              />
              {banner.buttonUrl && (
                <BannerButton
                  href={banner.buttonUrl}
                  onClick={
                    () =>
                      handleInteraction({ bannerId: banners[currentSlide]?.id })
                    // handleImpression({ bannerId: banners[currentSlide]?.id })
                  }
                  target="_blank"
                >
                  <FormattedMessage id="homepage.details" />
                </BannerButton>
              )}
            </ContentColumn>
            <ImageColumn>
              <BannerImage src={banner.bannerImage.url} alt={banner.title} />
            </ImageColumn>
          </BannerSlide>
        ))}
      </BannerWrapper>
      {banners.length > 1 && (
        <CarouselDots>
          {banners.map((_, index) => (
            <Dot
              key={index}
              active={currentSlide === index}
              onClick={() => goToSlide(index)}
            />
          ))}
        </CarouselDots>
      )}
    </BannerContainer>
  );
};

export default BannerCaroussel;
