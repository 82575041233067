import { useIntl } from "react-intl";
import BackArrow from "../../../../newUXAssets/icons/BackArrow";
import React from "react";
import Button from "../Button";

export default function BackButton({ href }) {
  const intl = useIntl();

  return (
    <Button
      variant={"backButton"}
      size={"backButton"}
      href={href}
      Icon={<BackArrow size={18} />}
      label={intl.formatMessage({ id: "goback" })}
    />
  );
}
