import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  ACardsContainer,
  AContainer,
  AMainContainer,
  AsideContainer,
  ATableContainer,
  DataHeader,
} from "../styles";
import { AnalyticsCard } from "../components/AnalyticsCard";
import AnalyticsCourseDataTable from "../components/AnalyticsTable/analytics-course-table";
import { ProfileChart } from "../components/ProfileChart";
import { LanguagesData } from "../components/LanguageData";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { Selectors } from "../components/AnalyticsTable/styles";
import SelectFilter from "../components/SelectFilter";
import { DatePicker } from "antd";
import moment from "moment";
import Button from "../../../../newUXPages/InformaAcademy/components/Button";
import { FiDownload } from "react-icons/fi";
import {
  getCourseAnalytics,
  getCoursesByEventId,
} from "../../../../redux/modules/analytics/actions";
import api from "../../../../service";
import generateHeaders from "../../../../utils/generateHeaders";
import { useToast } from "../../../../hooks/toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const { RangePicker } = DatePicker;

const DEFAULT_PAGE = 1;
const DEFAULT_ROWS_PER_PAGE = 10;

export default function CoursesAnalytics({
  coursesTotalizers,
  events = [],
  years = [],
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [date, setDate] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedLesson, setSelectedLesson] = useState("");
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [totalRows, setTotalRows] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const { courses, lessons, courseAnalytics, courseAnalyticsPageInfo } =
    useSelector((state) => state.analytics);

  const users = intl.formatMessage({
    id: "users",
  });

  useEffect(() => {
    if (selectedEvent) {
      dispatch(
        getCoursesByEventId({
          eventId: selectedEvent,
        })
      );
    }
    setSelectedCourse("");
    setSelectedLesson("");
  }, [dispatch, selectedEvent]);

  useEffect(() => {
    if (courseAnalyticsPageInfo) {
      setTotalRows(courseAnalyticsPageInfo.totalElements);
    }
  }, [courseAnalyticsPageInfo]);

  const filters = useMemo(
    () => ({
      idCourse: selectedCourse,
      idEvent: selectedEvent,
      idLesson: selectedLesson,
      year: selectedYear,
      starterDate: date && date?.length > 0 ? date[0] : "",
      finishingDate: date && date?.length > 0 ? date[1] : "",
      sortBy,
      sortDirection,
    }),
    [
      date,
      selectedCourse,
      selectedEvent,
      selectedLesson,
      selectedYear,
      sortBy,
      sortDirection,
    ]
  );

  const handleSearch = useCallback(() => {
    const params = {
      ...filters,
      page: currentPage - 1,
      perPage: rowsPerPage,
    };

    return dispatch(getCourseAnalytics(params));
  }, [currentPage, dispatch, filters, rowsPerPage]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const totalUsers =
    coursesTotalizers.users?.byLanguage?.pt ??
    +coursesTotalizers.users?.byLanguage?.en ??
    0;

  return (
    <AContainer>
      <AMainContainer>
        <ACardsContainer>
          <AnalyticsCard
            number={coursesTotalizers?.actions?.IMPRESSION ?? 0}
            analyticType={"impressions"}
          />
          <AnalyticsCard
            number={coursesTotalizers?.actions?.VIEWS ?? 0}
            analyticType={"views"}
          />
          <AnalyticsCard
            number={coursesTotalizers?.actions?.INTERACTION ?? 0}
            analyticType={"interations"}
          />
          <AnalyticsCard
            number={coursesTotalizers?.actions?.RECOMMENDATION ?? 0}
            analyticType={"recommendations"}
          />
        </ACardsContainer>

        <ATableContainer>
          <DataHeader>
            <h2>
              {users} ({totalUsers})
            </h2>
            <AnalyticsDownloadButton
              selectedLesson={selectedLesson}
              params={filters}
              isEmpty={courseAnalytics.length === 0}
            />
          </DataHeader>

          <Selectors>
            <SelectFilter
              label={"year"}
              options={years}
              setValue={setSelectedYear}
              value={selectedYear}
            />
            <SelectFilter
              label={"event"}
              options={events.filter((event) => event.year === selectedYear)}
              setValue={setSelectedEvent}
              value={selectedEvent}
            />
            <SelectFilter
              disabled={!selectedEvent || courses.length === 0}
              label={"sidebar.courses"}
              options={courses}
              setValue={setSelectedCourse}
              value={selectedCourse}
            />
            <SelectFilter
              disabled={!selectedCourse}
              label={"lesson"}
              options={
                lessons.find((lesson) => lesson.courseId === selectedCourse)
                  ?.lessons ?? []
              }
              setValue={setSelectedLesson}
              value={selectedLesson}
            />
            <RangePicker
              format="DD/MM/YYYY"
              onChange={(d) => setDate(d?.map((i) => i.format("YYYY-MM-DD")))}
              style={{ width: 240 }}
            />
          </Selectors>

          <AnalyticsCourseDataTable
            courseAnalytics={courseAnalytics}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalRows={totalRows}
            setSortBy={setSortBy}
            setSortDirection={setSortDirection}
            selectedLesson={Boolean(selectedLesson)}
          />
        </ATableContainer>
      </AMainContainer>

      <AsideContainer>
        <ProfileChart categoryData={coursesTotalizers.users.byCategory} />
        <LanguagesData languageData={coursesTotalizers.users.byLanguage} />
      </AsideContainer>
    </AContainer>
  );
}

function AnalyticsDownloadButton({ isEmpty, selectedLesson, params }) {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const { token } = useSelector((state) => state.auth);
  const { addToast } = useToast();

  const download = intl.formatMessage({
    id: "download",
  });

  const parseToCVS = useCallback(
    ({ courseAnalyticsData }) => {
      if (courseAnalyticsData.length === 0) return;

      const timestamp = moment().format("YYYY-MM-DD_HH-mm-ss");
      const cleanedUsers = courseAnalyticsData.map(
        ({ language, profile, email, name, recommendation }) => {
          const defaultObj = {
            name,
            email,
            language: intl.formatMessage({ id: language }),
            profile: intl.formatMessage({ id: profile }),
          };

          return selectedLesson
            ? {
                ...defaultObj,
                recommendation: intl.formatMessage({ id: recommendation }),
              }
            : defaultObj;
        }
      );

      const columns = [
        intl.formatMessage({ id: "enterprise" }),
        intl.formatMessage({ id: "email" }),
        intl.formatMessage({ id: "language" }),
        intl.formatMessage({ id: "profile" }),
      ];

      const cols = selectedLesson
        ? [[...columns, intl.formatMessage({ id: "recommendation" })]]
        : [[...columns]];

      const worksheet = XLSX.utils.json_to_sheet(cleanedUsers);
      XLSX.utils.sheet_add_aoa(worksheet, cols, { origin: "A1" });

      const csvOutput = XLSX.utils.sheet_to_csv(worksheet);
      const csvBlobData = new Blob([String.fromCharCode(0xfeff), csvOutput], {
        type: "text/csv;charset=utf-8",
      });
      FileSaver.saveAs(csvBlobData, `Analytics-Courses-${timestamp}.csv`);
    },
    [intl, selectedLesson]
  );

  const handleDownload = useCallback(async () => {
    try {
      setLoading(true);
      const headers = generateHeaders(token);
      const options = {
        ...headers,
        params,
      };
      const { data } = await api.get(
        "/academy/analytics/courses/listall",
        options
      );
      const { analyticsCourseListResponse: courseAnalyticsData } = data;
      parseToCVS({ courseAnalyticsData });
      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "analytics.download_success",
        }),
      });
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      console.log(error, error.message, "Error to download dat - COURSES");
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "analytics.download_failed",
        }),
      });
    }
  }, [token, params, parseToCVS, addToast, intl]);

  return (
    <Button
      Icon={loading ? <AiOutlineLoading3Quarters /> : <FiDownload size={18} />}
      loading={loading}
      label={download}
      variant={"secondary"}
      onClick={handleDownload}
      disabled={isEmpty || loading}
    />
  );
}
