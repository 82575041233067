const pageInfo = {
  sort: {
    sorted: false,
    unsorted: true,
    empty: true,
  },
  offset: 0,
  pageNumber: 0,
  pageSize: 10,
  paged: true,
  unpaged: false,
};

const articlesTotal = {
  totalArticles: 0,
  totalPostedArticles: 0,
  totalDraftArticles: 0,
};

const INITIAL_STATE = {
  eventName: "",
  manuals: [],
  articles: [],
  chapters: [],
  total: 0,
  articlesTotal,
  error: null,
  errorArticles: null,
  errorArticlesTotal: null,
  isLoading: false,
  pageInfo,
  article: null,
  errorUpdateManual: null,
  FAQ: [],
};

const exhibitorManual = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case "LIST_MANUALS":
    case "LIST_ARTICLES":
    case "GET_ARTICLES_TOTAL":
    case "LIST_CHAPTERS":
    case "LIST_FAQ":
    case "GET_ARTICLE":
      return { ...state, isLoading: true };

    case "UPDATE_MANUAL_STATUS":
      return { ...state };

    case "LIST_MANUALS_FAILURE":
    case "LIST_CHAPTERS_FAILURE":
    case "GET_ARTICLE_FAILURE":
      return { ...state, isLoading: false, error: payload };
    case "UPDATE_MANUAL_STATUS_FAILURE":
      return { ...state, isLoading: false, errorUpdateManual: payload };
    case "LIST_ARTICLES_FAILURE":
      return { ...state, isLoading: false, errorArticles: payload };
    case "GET_ARTICLES_TOTAL_FAILURE":
      return { ...state, isLoading: false, errorArticlesTotal: payload };

    case "LIST_MANUALS_SUCCESS":
      return {
        ...state,
        manuals: mapEventRepeats(payload.manuals),
        total: payload.total,
        pageInfo: payload.pageInfo,
        isLoading: false,
        error: null,
      };

    case "UPDATE_MANUAL_STATUS_SUCCESS": {
      return {
        ...state,
        manuals: mapEventRepeats(state.manuals).map((manual) => {
          return manual.id === payload.manualId
            ? { ...manual, status: payload.newStatus }
            : manual;
        }),
        errorUpdateManual: null,
      };
    }

    case "LIST_ARTICLES_SUCCESS":
      return {
        ...state,
        articles: payload.articles,
        pageInfo: payload.pageInfo,
        isLoading: false,
        errorArticles: null,
      };

    case "GET_ARTICLES_TOTAL_SUCCESS":
      return {
        ...state,
        articlesTotal: payload.total,
        isLoading: false,
        errorArticlesTotal: null,
      };

    case "LIST_CHAPTERS_SUCCESS":
      return {
        ...state,
        chapters: payload.chapters,
        eventName: payload.eventName,
        isLoading: false,
        error: null,
      };

    case "GET_ARTICLE_SUCCESS":
      return {
        ...state,
        article: payload.article,
        isLoading: false,
        error: null,
      };

    case "LIST_FAQ_SUCCESS":
      return {
        ...state,
        FAQ: payload.FAQ,
        isLoading: false,
        error: null,
      };

    case "LIST_FAQ_FAILURE":
      return {
        ...state,
        FAQ: [],
        isLoading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default exhibitorManual;

function mapEventRepeats(manuals) {
  const eventNameCount = new Map();

  manuals.forEach((manual) => {
    const count = eventNameCount.get(manual.eventName) || 0;
    eventNameCount.set(manual.eventName, count + 1);
  });

  return manuals.map((manual) => ({
    ...manual,
    repeat: eventNameCount.get(manual.eventName) > 1,
  }));
}
