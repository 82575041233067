import { all, takeLatest, call, put, select } from "redux-saga/effects";

import {
  getEventsFailure,
  getEventsInfoFailure,
  getEventsInfoSuccess,
  getEventsSuccess,
  setAllEventSuccess,
} from "./actions";
import {
  getAllEventsService,
  getAllEventsWithVersionService,
  getEventService,
} from "../../../service/event.service";

function* getEvent() {
  try {
    const response = yield call(getEventService);

    if (response.status === 200 && response.data.output.length) {
      yield put(
        setAllEventSuccess({
          event: response.data.output,
        })
      );
    }
  } catch (error) {
    console.warn("error", error); //Falta configurar msg de erro
  }
}

function* getAllEventsInfo() {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);
    const response = yield call(getAllEventsService, {
      token,
    });

    const { data, status } = response;

    if (status !== 200) throw new Error("Failed to load events");
    const { setupEventOutputList } = data;

    const mappedEvents = setupEventOutputList
      .map((event) => ({
        label: event.eventSetupName,
        value: event.id,
      }))
      .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));

    yield put(getEventsInfoSuccess(mappedEvents));
  } catch (error) {
    yield put(getEventsInfoFailure(error.response?.data?.code || true));
  }
}

function* getEvents() {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);
    const response = yield call(getAllEventsWithVersionService, {
      token,
    });

    const { data, status } = response;

    if (status !== 200) throw new Error("Failed to load events");

    const { output } = data;

    const mappedEvents = output
      .map((event) => ({
        label: event.eventEditionName,
        value: event.idNumber,
      }))
      .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));

    yield put(getEventsSuccess(mappedEvents));
  } catch (error) {
    yield put(getEventsFailure(error.response?.data?.code || true));
  }
}

export default all([
  takeLatest("GET_ALL_EVENT", getEvent),
  takeLatest("GET_ALL_EVENTS", getEvents),
  takeLatest("GET_ALL_EVENTS_INFO", getAllEventsInfo),
]);
