import React, { useRef } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useWatch } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import {
  Description,
  Error,
  FormItem,
  InputLabel,
  Required,
  ButtonFileContainer,
  SelectedFilesContainer,
  SelectedFile,
} from "./styles";
import { useToast } from "../../hooks/toast";
import { useIntl } from "react-intl";
import { MdError } from "react-icons/md";
import Button from "../../pages/InformaAcademy/components/button";
import { IoMdClose } from "react-icons/io";
import axios from "axios";

const MARLABS_DEFAULT =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1HakUW8f2Uf03gxKrnuYvMs-2L_yQKCQspw&s";

export const ControlledInputFiles = ({
  name,
  control,
  setValue,
  errors,
  required,
  description,
  defaultValue,
  subtitle,
  label,
  maxFiles = 5,
  ...rest
}) => {
  const [files, setFiles] = useState([]);
  const intl = useIntl();
  const [error, setError] = useState(false);
  const watch = useWatch({ control, name });
  const { addToast } = useToast();
  const processedFilesRef = useRef(new Set());

  const parseFiles = useCallback(() => {
    const val = Array.isArray(watch) && watch.length > 0;

    if (val) {
      if (typeof watch[0] === "object" && typeof watch[0]?.type === "string") {
        const newFiles = watch.filter((file) => {
          const fileId = `${file.name}-${file.size}-${file.lastModified}`;
          if (processedFilesRef.current.has(fileId)) {
            return false;
          }
          processedFilesRef.current.add(fileId);
          return true;
        });

        if (newFiles.length > 0) {
          setFiles((prev) => {
            // Create a map to deduplicate files
            const uniqueFilesMap = new Map();

            // Add existing files to the map
            prev.forEach((file) => {
              const fileId = `${file.name}-${file.size}-${
                file.lastModified || Date.now()
              }`;
              uniqueFilesMap.set(fileId, file);
            });

            // Add new files to the map (will overwrite any duplicates)
            newFiles.forEach((file) => {
              const fileId = `${file.name}-${file.size}-${
                file.lastModified || Date.now()
              }`;
              uniqueFilesMap.set(fileId, file);
            });

            // Convert map values back to array
            const uniqueFiles = Array.from(uniqueFilesMap.values());

            // Update form value with the unique files
            setValue(name, uniqueFiles);

            return uniqueFiles;
          });
        }
        return;
      }

      if (typeof watch[0] === "object" && typeof watch[0]?.url === "string") {
        const fetchAndSetFiles = async () => {
          const unprocessedFiles = watch.filter((item) => {
            const fileId = item.url;
            if (processedFilesRef.current.has(fileId)) {
              return false;
            }
            processedFilesRef.current.add(fileId);
            return true;
          });

          const fetchPromises = unprocessedFiles.map(async (item) => {
            try {
              const response = await axios.get(
                process.env.NODE_ENV === `development`
                  ? MARLABS_DEFAULT
                  : item.url,
                {
                  responseType: "blob",
                }
              );

              return new File([response.data], item.name, {
                type: response.data.type,
              });
            } catch (error) {
              addToast({
                type: "error",
                title: intl.formatMessage({
                  id: error.message,
                }),
                description: `File: ${item.name}`,
              });
              return null;
            }
          });

          const fetchedFiles = (await Promise.all(fetchPromises)).filter(
            Boolean
          );

          if (fetchedFiles.length > 0) {
            setFiles((prev) => {
              // Same deduplication logic for fetched files
              const uniqueFilesMap = new Map();

              // Add existing files to the map
              prev.forEach((file) => {
                const fileId = `${file.name}-${file.size}-${
                  file.lastModified || Date.now()
                }`;
                uniqueFilesMap.set(fileId, file);
              });

              // Add new fetched files to the map
              fetchedFiles.forEach((file) => {
                const fileId = `${file.name}-${file.size}-${
                  file.lastModified || Date.now()
                }`;
                uniqueFilesMap.set(fileId, file);
              });

              const uniqueFiles = Array.from(uniqueFilesMap.values());

              // Update form value with the unique files
              setValue(name, uniqueFiles);

              return uniqueFiles;
            });
          }
        };

        fetchAndSetFiles();
      }
    }
  }, [watch, setValue, name, addToast, intl]); // Added setValue and name back to dependencies

  useEffect(() => {
    if (files.length > 0) {
      setValue(name, files);
    }
  }, [files, name, setValue]);

  useEffect(() => {
    const val = Array.isArray(watch) && watch.length > 0;

    if (watch === undefined) {
      setFiles([]);
      setValue(name, []);
      processedFilesRef.current.clear();
      return;
    }

    if (val) {
      parseFiles();
      setError(false);
      return;
    }

    if (errors?.[name]) {
      setError(true);
      return;
    }

    setError(false);
  }, [errors, watch, name, parseFiles, setValue]);

  useEffect(() => {
    const current = processedFilesRef.current;
    return () => {
      current.clear();

      files.forEach((file) => {
        if (file.fileURL) {
          URL.revokeObjectURL(file.fileURL);
        }
      });
    };
  }, []);

  const labelIntl = intl.formatMessage({
    id: label,
  });

  const errorMessage = intl.formatMessage({ id: "error.field_required" });
  const isRequired = required && <Required>*</Required>;
  const hasDesc = description && (
    <Description>({intl.formatMessage({ id: description })})</Description>
  );
  const hasDesc2 = subtitle && (
    <Description subtitle>{intl.formatMessage({ id: subtitle })}</Description>
  );

  const handleReset = () => {
    setFiles([]);
    setValue(name, undefined);
  };

  const onDrop = useCallback(
    (droppedFiles) => {
      setFiles((prev) => {
        const existingFilesMap = new Map();
        prev.forEach((file) => {
          const fileId = `${file.name}-${file.size}-${
            file.lastModified || Date.now()
          }`;
          existingFilesMap.set(fileId, true);
        });

        const newUniqueFiles = droppedFiles.filter((file) => {
          const fileId = `${file.name}-${file.size}-${
            file.lastModified || Date.now()
          }`;
          return !existingFilesMap.has(fileId);
        });

        if (newUniqueFiles.length === 0) {
          return prev;
        }

        const updatedFiles = [...prev, ...newUniqueFiles];

        setValue(name, updatedFiles);

        newUniqueFiles.forEach((file) => {
          const fileId = `${file.name}-${file.size}-${
            file.lastModified || Date.now()
          }`;
          processedFilesRef.current.add(fileId);
        });

        return updatedFiles;
      });
    },
    [setValue, name]
  );

  const removeItem = (file) => {
    const updatedItems = files.filter((item) => item !== file);

    setFiles(updatedItems);
    setValue(name, updatedItems);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { onChange } }) => (
        <FormItem>
          <InputLabel subtitle={subtitle}>
            {labelIntl}
            {isRequired} {hasDesc}
          </InputLabel>
          {hasDesc2}
          <Dropzone
            reset={handleReset}
            onDrop={onDrop}
            error={error}
            files={files}
            removeItem={removeItem}
            onChange={(e) => {
              setFiles((prev) => {
                const arr = [...prev, ...e];
                setValue(name, arr);
                return arr;
              });
            }}
            maxFiles={maxFiles - files.length}
            {...rest}
          />
          {error && (
            <Error>
              <MdError color="#DC2626" size={14} />
              {errorMessage}
            </Error>
          )}
        </FormItem>
      )}
    />
  );
};

const Dropzone = ({
  maxFiles,
  reset,
  files,
  onChange,
  onDrop,
  accept = ACCEPT,
  maxFileSize,
  error,
  removeItem,
  maxSize = 8 * 1024 * 1024, // 8mb
  ...rest
}) => {
  const { addToast } = useToast();
  const intl = useIntl();

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: [".jpg", ".jpeg", ".png", ".pdf"],
    maxFiles,
    maxSize,
    noClick: true,
    noKeyboard: true,
    onDropRejected: (e) => {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error",
        }),
        description: intl.formatMessage({
          id: `${e[0].errors[0].code}`,
        }),
      });
    },
    ...rest,
  });

  return (
    <div>
      <ButtonFileContainer>
        <Button
          files
          variant={"secondary"}
          type="button"
          onClick={open}
          label={"select_files"}
          disabled={files.length > 4}
        />
        {files.length === 0 && (
          <span>{intl.formatMessage({ id: "no_files_selected" })}</span>
        )}
      </ButtonFileContainer>

      <div {...getRootProps()} style={{ display: "none" }}>
        <input {...getInputProps()} />
      </div>

      <SelectedFilesContainer>
        {files.map((file, index) => {
          const fileDetail = getFileDetails(file);

          return (
            <SelectedFile key={fileDetail.fileName + index}>
              <p>{fileDetail.fileName}</p>
              <button
                type="button"
                onClick={() => {
                  removeItem(file);
                }}
              >
                <IoMdClose size={12} />
              </button>
            </SelectedFile>
          );
        })}
      </SelectedFilesContainer>
    </div>
  );
};

const ACCEPT = {
  "image/png": [".png"],
  "image/jpeg": [".jpg", ".jpeg"],
};

const getFileDetails = (file) => {
  if (file) {
    const fileURL = URL.createObjectURL(file);
    const fileName = file.name;
    const fileSize = file.size;
    const fileSizeInMB = (fileSize / (1024 * 1024)).toFixed(2);

    return {
      fileURL,
      fileName,
      fileSize: fileSizeInMB,
    };
  }
  return null;
};
