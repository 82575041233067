import React from 'react';
import { Container } from './styles';

const Tip = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  );
}

export default Tip;