import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  listArticlesFailure,
  listArticlesSuccess,
  getArticlesTotalCounterFailure,
  getArticlesTotalCounterSuccess,
  listManualsFailure,
  listManualsSuccess,
  listChaptersFailure,
  listChaptersSuccess,
  getArticleSuccess,
  getArticleFailure,
  updateManualStatusFailure,
  updateManualStatusSuccess,
  listFAQSuccess,
  listFAQFailure,
} from "./actions";
import {
  listArticlesService,
  getArticlesTotalCounterService,
  listManualsService,
  listChaptersService,
  getArticleService,
  updateManualStatusService,
  listFAQService,
} from "../../../service/adminManual.service";

function* listManuals({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);
    const { query, page, size, sortBy, sortDirection } = payload;
    const response = yield call(listManualsService, {
      token,
      query,
      page,
      size,
      sortBy,
      sortDirection,
    });

    if (response.status !== 200) throw new Error("Failed to list Manuals");

    const { data } = response;

    yield put(
      listManualsSuccess({
        manuals: data.manuals.content,
        total: data.manuals.totalElements,
        pageInfo: {
          totalElements: data.manuals.totalElements,
          size: data.manuals.size,
          pageNumber: data.manuals.pageable.pageNumber,
        },
      })
    );
  } catch (error) {
    yield put(listManualsFailure(error));
  }
}

function* updateManualStatus({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);
    const { manualId, status } = payload;
    const response = yield call(updateManualStatusService, {
      token,
      manualId,
      status,
    });

    if (response.status !== 200) throw new Error("Failed to updateManual");

    yield put(
      updateManualStatusSuccess({
        manualId,
        newStatus: status,
      })
    );
  } catch (error) {
    yield put(updateManualStatusFailure(error));
  }
}

function* listArticles({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);
    const { query, page, size, manualId, sortBy, sortDirection } = payload;
    const response = yield call(listArticlesService, {
      token,
      manualId,
      query,
      page,
      size,
      sortBy,
      sortDirection,
    });

    if (response.status !== 200) throw new Error("Failed to list Articles");

    const { data } = response;

    yield put(
      listArticlesSuccess({
        articles: data.articles.content,
        pageInfo: {
          totalElements: data.articles.totalElements,
          size: data.articles.size,
          pageNumber: data.articles.pageable.pageNumber,
        },
      })
    );
  } catch (error) {
    yield put(listArticlesFailure(error.message));
  }
}

function* getArticlesTotalCounter({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);
    const { manualId } = payload;
    const response = yield call(getArticlesTotalCounterService, {
      token,
      manualId,
    });

    if (response.status !== 200)
      throw new Error("Failed to get Articles Totals");

    const { data } = response;

    yield put(getArticlesTotalCounterSuccess({ total: data.articleCountDTO }));
  } catch (error) {
    yield put(getArticlesTotalCounterFailure(error));
  }
}

function* listChapters({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);
    const { manualId } = payload;
    const response = yield call(listChaptersService, {
      token,
      manualId,
    });

    if (response.status !== 200) throw new Error("Failed to list Chapters");

    const { data } = response;

    yield put(
      listChaptersSuccess({
        chapters: data.result,
        eventName: data.eventName,
      })
    );
  } catch (error) {
    yield put(listChaptersFailure(error));
  }
}

function* getArticle({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);
    const { articleId } = payload;
    const response = yield call(getArticleService, {
      token,
      articleId,
    });

    if (response.status !== 200) throw new Error("Failed to get article info");

    const { data } = response;

    yield put(
      getArticleSuccess({
        article: data.articleAdmViewDetailsDTO,
      })
    );
  } catch (error) {
    yield put(getArticleFailure(error));
  }
}

function* listFAQ({ payload }) {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);
    const { manualId } = payload;
    const response = yield call(listFAQService, {
      token,
      manualId,
    });

    if (response.status !== 200 && response.status !== 204)
      throw new Error("Failed to list Chapters");

    const { data } = response;

    if (response.status === 200) {
      return yield put(
        listFAQSuccess({
          FAQ: data
            .map(({ id, question, answer, orders }) => ({
              id,
              question,
              order: orders,
              answer,
            }))
            .sort((a, b) => a.order - b.order),
        })
      );
    }

    return yield put(
      listFAQSuccess({
        FAQ: [],
      })
    );
  } catch (error) {
    yield put(listFAQFailure(error));
  }
}

export default all([
  takeLatest("LIST_MANUALS", listManuals),
  takeLatest("UPDATE_MANUAL_STATUS", updateManualStatus),
  takeLatest("LIST_ARTICLES", listArticles),
  takeLatest("GET_ARTICLES_TOTAL", getArticlesTotalCounter),
  takeLatest("LIST_CHAPTERS", listChapters),
  takeLatest("GET_ARTICLE", getArticle),
  takeLatest("LIST_FAQ", listFAQ),
]);
