import React, { useEffect, useState } from "react";
import {
  ArticleSection,
  ArticleItemList,
  ArticleListContainer,
  ChapterTitle,
  IconContainer,
  ArticleListWrapper,
} from "./styles";
import { AiFillPrinter } from "react-icons/ai";
import { VscArrowRight } from "react-icons/vsc";
import {
  getManualTopicList,
  printChapter,
} from "../../../redux/modules/eventManual/action";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import EventManualHeader from "../EventManualHomepage/Header";
import ManualSidebar from "../sidebar";
import { Container } from "../styles";
import GeneratePdf from "../GeneratePDF";
import useNotification from "../../../hooks/newUXNotification";
import { useIntl } from "react-intl";

const ArticleList = () => {
  const [isPrinting, setIsPrinting] = useState(false);
  const { chapterData, topics, loadedTopics } = useSelector(
    (state) => state.eventManual
  );
  const { chapterId } = useParams();
  const dispatch = useDispatch();
  const notification = useNotification();
  const intl = useIntl();

  useEffect(() => {
    if (chapterId) {
      dispatch(printChapter({ chapterId }));
    }
  }, [chapterId, dispatch]);

  useEffect(() => {
    if (!loadedTopics) {
      dispatch(getManualTopicList());
    }
  }, [dispatch, loadedTopics]);

  if (!chapterData) return null;

  const handleGeneratePdf = async (chapter) => {
    if (isPrinting) return;
    setIsPrinting(true);
    try {
      await GeneratePdf({
        data: chapter,
        successMessage: intl.formatMessage({ id: "generating_pdf_success" }),
        waitingMessage: intl.formatMessage({ id: "generating_pdf" }),
      });
    } catch (error) {
      notification.displayInfo(
        intl.formatMessage({ id: "error.PDF_cannot_be_created" }),
        "error.PDF_cannot_be_created"
      );
    } finally {
      setIsPrinting(false);
    }
  };

  return (
    <Container>
      <EventManualHeader topics={topics} />

      <ArticleListWrapper>
        <ManualSidebar topics={topics} />
        <ArticleListContainer>
          <ChapterTitle>
            {chapterData[0].chapterName}{" "}
            <IconContainer onClick={() => handleGeneratePdf(chapterData)}>
              <AiFillPrinter />
            </IconContainer>
          </ChapterTitle>
          <ArticleSection>
            {chapterData.map((chapter, index) => (
              <ArticleItemList
                to={`/event-manual/chapter/${chapterId}/article/${chapter.articleId}`}
                key={`articleList-${index}`}
              >
                {chapter.articleTitle} <VscArrowRight />
              </ArticleItemList>
            ))}
          </ArticleSection>
        </ArticleListContainer>
      </ArticleListWrapper>
    </Container>
  );
};

export default ArticleList;
