import { useEffect, useState } from "react";
import {
  CourseProgressionContainer,
  CourseProgressionHeader,
  ProgressionContainer,
} from "./styles";
import { Progress } from "antd";

import { FormattedMessage } from "react-intl";
import Concluded from "../../../../newUXComponents/Concluded";

export default function CourseProgression({
  concludedLessons,
  totalLessons,
  fullsize = false,
  showLabel = true,
  skelleton = false,
}) {
  const [progression, setProgression] = useState(
    (concludedLessons / totalLessons) * 100
  );

  useEffect(() => {
    setProgression((concludedLessons / totalLessons) * 100);
  }, [concludedLessons, totalLessons]);

  const strokeColor = progression === 100 ? "#22c55e" : "#002244";

  return (
    <CourseProgressionContainer>
      <CourseProgressionHeader>
        <span>
          {concludedLessons}/{skelleton ? "??" : totalLessons}{" "}
          <FormattedMessage id={totalLessons > 1 ? "lessons" : "lesson"} />
        </span>
        {showLabel && progression === 100 && <Concluded labelId="finished" />}
      </CourseProgressionHeader>

      <ProgressionContainer fullsize={fullsize}>
        <Progress
          percent={progression}
          format={(percent) => `${percent.toFixed(0)}%`}
          strokeColor={strokeColor}
          trailColor="#DDe5e9"
        />
      </ProgressionContainer>
    </CourseProgressionContainer>
  );
}
