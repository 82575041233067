import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { z } from "zod";
import { useToast } from "../../../hooks/toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { getEvents } from "../../../redux/modules/event/actions";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import api from "../../../service";
import { ButtonContainer, HeaderContainer, Text } from "./styles";
import { FormRow } from "../../../components/ControlledInputs/styles";
import ControlledSelect from "../../../components/ControlledInputs/select";
import ControlledCheckbox from "../../../components/ControlledInputs/checkbox";
import Button from "../../InformaAcademy/components/button";
import Copy from "./Copy";
import ControlledInput from "../../../components/ControlledInputs/input";
import BackIcon from "../../../icons/Back";
import { Divider } from "antd";

const schema = z
  .object({
    language: z.object({
      value: z.string().min(1),
      label: z.string().min(1),
    }),
    fromEvent: z.object({
      value: z.coerce.number().min(1),
      label: z.string().min(1),
    }),
    toEvent: z.object({
      value: z.coerce.number().min(1),
      label: z.string().min(1),
    }),
    replace: z.boolean().optional(),
    fromReplace: z.string().optional(),
    toReplace: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.replace) {
        return data.fromReplace?.trim();
      }
      return true;
    },
    {
      message: "required",
      path: ["fromReplace"],
    }
  )
  .refine(
    (data) => {
      if (data.replace) {
        return data.toReplace?.trim();
      }
      // if(data.fromReplace.trim())
      return true;
    },
    {
      message: "required",
      path: ["toReplace"],
    }
  );

export default function DuplicateManualForm({ closeModal }) {
  const intl = useIntl();
  const [languages] = useState([
    { value: "PORTUGUESE", label: intl.formatMessage({ id: "portuguese" }) },
    { value: "ENGLISH", label: intl.formatMessage({ id: "english" }) },
  ]);
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { events, isLoading } = useSelector((state) => state.event);
  const { addToast } = useToast();
  const history = useHistory();

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      language: undefined,
      fromEvent: undefined,
      toEvent: undefined,
    },
  });
  const { control, handleSubmit, formState } = form;

  useEffect(() => {
    dispatch(getEvents());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getEvents());
  }, [dispatch]);

  const handleReset = () => {
    closeModal();
  };

  const onSubmit = async (data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        sourceEventId: data.fromEvent.value,
        targetEventId: data.toEvent.value,
        language: data.language.value,
      },
    };

    try {
      if (data.replace) {
        const replace = {
          [data.fromReplace]: data.toReplace,
        };
        const body = JSON.stringify(replace);
        await api.post("/manual/duplicate", body, config);
      }

      if (!data.replace) {
        await api.post("/manual/duplicate", {}, config);
      }

      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "success",
        }),
        description: intl.formatMessage({
          id: "success.manual_duplicate",
        }),
      });

      handleReset();
      history.push("/admin/manual");
    } catch (error) {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error.manual_duplicate",
        }),
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HeaderContainer modal>
        <h2 className="bold">
          <FormattedMessage id={"manual_existing_duplicte"} />
        </h2>
      </HeaderContainer>

      <Text>
        <FormattedMessage id={"duplicate_from_event"} />
      </Text>

      <FormRow marginBottom={"16px"}>
        <ControlledSelect
          name={"fromEvent"}
          placeholder={"Selecione"}
          control={control}
          label={"events"}
          required={true}
          options={events}
          errors={formState.errors}
          disabled={isLoading}
        />
      </FormRow>

      <FormRow marginBottom={"16px"}>
        <ControlledSelect
          name={"language"}
          placeholder={"Selecione"}
          control={control}
          label={"language"}
          required={true}
          options={languages}
          errors={formState.errors}
        />
      </FormRow>

      <FormRow marginBottom={"16px"}>
        <ControlledCheckbox
          control={control}
          name={"replace"}
          formState={formState}
          label={"duplicate_replace"}
        />
      </FormRow>

      <FormRow marginBottom={"16px"}>
        <ControlledInput
          control={control}
          name={"fromReplace"}
          formState={formState}
          label={"from"}
          placeholder={intl.formatMessage(
            {
              id: "e.g.",
            },
            {
              content: "2024",
            }
          )}
          disabled={!form.watch("replace")}
        />
        <ControlledInput
          control={control}
          name={"toReplace"}
          formState={formState}
          label={"to"}
          placeholder={intl.formatMessage(
            {
              id: "e.g.",
            },
            {
              content: "2025",
            }
          )}
          disabled={!form.watch("replace")}
        />
      </FormRow>
      <Divider
        style={{
          marginTop: 16,
          marginBottom: 16,
          borderColor: "#CBD5E1",
        }}
      />

      <Text>
        <FormattedMessage id={"duplicate_to_event"} />
      </Text>

      <FormRow marginBottom={"16px"}>
        <ControlledSelect
          name={"toEvent"}
          placeholder={"Selecione"}
          control={control}
          label={"events"}
          required={true}
          options={events}
          errors={formState.errors}
          disabled={isLoading}
        />
      </FormRow>

      <ButtonContainer>
        <Button
          label={"goback"}
          variant={"secondary"}
          Icon={<BackIcon size={16} />}
          type={"button"}
          onClick={handleReset}
        />
        <Button
          label={"duplicate"}
          Icon={<Copy stroke={"#FFF"} />}
          // onClick={handleSubmit(onSubmit)}
        />
      </ButtonContainer>
    </form>
  );
}
