import styled, { css } from "styled-components";

export const LessonCardContainer = styled.div`
  min-width: 252px;
  max-width: 252px;
  height: 361px;
  background-image: url(${(props) => props.imageUrl});
  background-color: #f7f9fa;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease;
  position: relative;
  overflow: hidden;

  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }

  ${(props) =>
    props.noHover &&
    css`
      cursor: unset;

      &:hover {
        transform: scale(1);
      }
    `}

  & > img.informaLogo {
    position: absolute;
    bottom: 0px;
    left: 14.46px;
  }
`;

export const ImageCover = styled.img`
  position: absolute;
  width: 252px;
  height: 361px;
  top: 0;
  left: 0;
  object-fit: cover;
`;

export const Circle = styled.div`
  position: absolute;
  top: 211px;
  left: -62px;
  background: #ae8afd;
  width: 258px;
  height: 258px;
  border-radius: 999px;
`;

export const LessonHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LessonTitleContainer = styled.div`
  padding: 1rem;
`;

export const LessonTitle = styled.p`
  font-size: 22px;
  line-height: 26px;
  padding-top: 0.75rem;
  color: #3a3a3c;
`;

export const LessonNumber = styled.span`
  width: fit-content;
  font-size: 1.125rem;
  color: #6c6c6c;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  border-bottom: 3px solid #c7c9d9;
`;

export const DoneLesson = styled.span`
  width: fit-content;
  font-size: 0.875rem;
  font-weight: 600;
  color: #16a34a;
`;

export const LessonIcon = styled.div`
  width: 60px;
  height: 60px;
  background-color: #d7b4ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
