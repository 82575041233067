import styled from "styled-components";

export const TableContainer = styled.section`
  margin-top: 2rem;
`;

export const Container = styled.div`
  width: 100%;
  background-color: #fff;

  .rdt_Table {
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    overflow: hidden;
  }

  .rdt_Pagination {
    border: 0;
  }
`;

export const TableTitle = styled.h3`
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0%;
  vertical-align: middle;

  color: #002244;
`;

export const Divider = styled.div`
  height: 1px;
  background: #0000001f;
  margin: 16px 0;
`;

export const TableOrderContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const ColumnName = styled.span`
  font-size: 0.75rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const ArrowButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  border-radius: 11px;
  padding: 2px;
  background: #f2f2f5;
  border: none;
  outline: none;
  cursor: pointer;

  &:disabled{
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const RemoveButton = styled.button`
  width: 120px;
  height: 26px;
  border-radius: 5px;
  padding-top: 4px;
  padding-right: 16px;
  padding-bottom: 4px;
  padding-left: 16px;
  gap: 8px;
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 12px;
  line-height: 25.14px;
  letter-spacing: 0%;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #e2e8f0;
  border: none;
  outline: none;
  text-transform: uppercase;

  color: #002244;
  cursor: pointer;
`;

export const AddButton = styled(RemoveButton)`
  background-color: #002244;
  color: #ffffff;

  &:hover {
    color: #ffffff;
  }
`;
