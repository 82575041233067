import React from "react";

import {
  Container,
  Description,
  Header,
  HeaderContainer,
  HeaderImageSkeleton,
  HeaderWrapper,
  Info,
  InfoContainer,
  Lessons,
  LessonsContainer,
  LessonsHeader,
  Title,
} from "../Course/styles";

import useZoom from "../../../hooks/zoom";
import { BackButton } from "../components/Button";
import Skeleton from "../../../newUXComponents/Skeleton";
import { SkeletonContainer } from "../../../newUXComponents/Skeleton/styles";
import Category from "../../../newUXAssets/icons/Category";
import { useIntl } from "react-intl";
import { BsClock } from "react-icons/bs";
import Calendar from "../../../newUXAssets/icons/Calendar";
import CourseProgression from "../components/CourseProgression";

export function CourseLoaderSkelleton({ courseId, coursename }) {
  const { scale } = useZoom();
  const intl = useIntl();

  const categoryString = intl.formatMessage({
    id: "category",
  });
  const workloadString = intl.formatMessage({
    id: "workload",
  });
  const updatedAtString = intl.formatMessage({
    id: "updatedAt",
  });
  // const hoursString = intl.formatMessage({
  //   id: "hours",
  // });

  const color = "#095F83";
  const size = 15;
  return (
    <Container>
      <HeaderWrapper>
        <Header>
          <section>
            <BackButton href={"/informa-academy"} />

            <HeaderContainer scale={scale}>
              <Skeleton
                height={scale >= 1.5 ? "47.25px" : "63px"}
                width={"70%"}
              />

              <SkeletonContainer margin="10px 0 24px 0">
                <Skeleton height={"25.59px"} width={"75%"} mb="5px" />
                <Skeleton height={"25.59px"} width={"75%"} mb="5px" />
                <Skeleton height={"25.59px"} width={"75%"} mb="5px" />
                <Skeleton height={"25.59px"} width={"45%"} mb="5px" />
              </SkeletonContainer>
            </HeaderContainer>

            <SkeletonContainer margin="0 0 24px 0">
              <InfoContainer>
                <Info>
                  {<Category width={size} height={size} color={color} />}
                  <p>{categoryString}</p>
                  <Skeleton height={"25.59px"} width={"80%"} mt="5px" />
                </Info>
                <Info>
                  {<BsClock width={size} height={size} color={color} />}
                  <p>{workloadString}</p>
                  <Skeleton height={"25.59px"} width={"80%"} mt="5px" />
                </Info>
                <Info>
                  {<Calendar width={size} height={size} color={color} />}
                  <p>{updatedAtString}</p>
                  <Skeleton height={"25.59px"} width={"80%"} mt="5px" />
                </Info>
              </InfoContainer>
            </SkeletonContainer>

            <Skeleton height={"40px"} width={"157.844px"} />
          </section>

          <HeaderImageSkeleton>
            <Skeleton height={"100%"} width={"100%"} />
          </HeaderImageSkeleton>
        </Header>
      </HeaderWrapper>

      <LessonsContainer>
        <LessonsHeader>
          <Skeleton height={"40px"} width={"97.844px"} />

          <CourseProgression concludedLessons={0} totalLessons={99} />
        </LessonsHeader>

        <Lessons>
          {Array.from({ length: 10 }).map((_, i) => (
            <Skeleton key={i} height={"361px"} width={"252px"} />
          ))}
        </Lessons>
      </LessonsContainer>
    </Container>
  );
}
