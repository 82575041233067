import styled from "styled-components";

export const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 450px;
  overflow: hidden;
  background-color: #f7f9fa;
  border-radius: 11px;
`;

export const BannerWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease;
  transform: translateX(${(props) => props.translate}%);
`;

export const BannerSlide = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: ${(props) => props.index * 100}%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

export const ContentColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 5rem;
`;

export const ImageColumn = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 3rem;
`;

export const BannerTitle = styled.h2`
  font-family: "Poppins";
  font-size: 2.25rem;
  line-height: 2.75rem;
  font-weight: bold;
  color: #000;
  margin: 0;
`;

export const BannerDescription = styled.p`
  font-size: 1rem;
  font-weight: normal;
  color: #000;
  margin: 0;
  padding-bottom: 1rem;
`;

export const BannerButton = styled.a`
  font-family: "Open Sans";
  display: inline-block;
  padding: 12px 24px;
  background-color: #11a7d9;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: opacity 0.3s;
  font-weight: normal;
  width: fit-content;

  &:hover {
    background-color: #11a7d9;
    color: white;
  }
`;

export const BannerImage = styled.img`
  max-height: 100%;
  max-width: min(550px, calc(100vw - 60px));
  width: 100%;
  object-fit: contain;
  border-radius: 24px;
`;

export const BackgroundDecoration = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: -1;
`;

export const NavigationButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(199, 201, 217, 0.2);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 3;

  &:hover {
    background-color: rgba(199, 201, 217, 0.2);
  }

  ${(props) => (props.direction === "prev" ? "left: 20px;" : "right: 20px;")}
`;

export const Arrow = styled.span`
  border: solid #000;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  transform: ${(props) =>
    props.direction === "prev" ? "rotate(135deg)" : "rotate(-45deg)"};
`;

export const CarouselDots = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  z-index: 3;
`;

export const Dot = styled.button`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? "#33D27E" : "#C7C9D9")};
  border: none;
  cursor: pointer;
`;
