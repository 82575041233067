import { FormattedMessage } from "react-intl";
import {
  ColumnName,
  TableOrderContainer,
} from "../../../components/ReorderableTable/style";

import {
  ArrowButton,
  ButtonContainer,
} from "../../InformaAcademy/components/Lesson/styles";

import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";

export const paginationOptions = ({ locale }) => {
  return {
    rowsPerPageText: <FormattedMessage id={"rows_per_page"} />,
    rangeSeparatorText: locale === "en" ? "of" : "de",
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: <FormattedMessage id={"all"} />,
  };
};

export function OrdernableRow({ row, index, reorderRows, rowData }) {
  return (
    <TableOrderContainer>
      <ColumnName>{row.order}</ColumnName>
      <ButtonContainer>
        <ArrowButton
          onClick={() => reorderRows(index, "up")}
          disabled={index === 0}
        >
          <IoChevronUpOutline />
        </ArrowButton>
        <ArrowButton
          onClick={() => reorderRows(index, "down")}
          disabled={index === rowData.length - 1}
        >
          <IoChevronDownOutline />
        </ArrowButton>
      </ButtonContainer>
    </TableOrderContainer>
  );
}
