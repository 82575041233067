import React, { useCallback, useEffect, useState } from "react";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import { useIntl } from "react-intl";
import BannerCaroussel from "./carousel";
import {
  ButtonContainer,
  Container,
  EditButton,
  HomepageContainer,
  PageTitle,
  PageTitleContainer,
  PublishButton,
} from "./style";
import { AiOutlineEdit as EditIcon } from "react-icons/ai";
import { RiShareForward2Fill } from "react-icons/ri";
import CourseList from "./courses";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsEdit } from "../../../redux/modules/homepageBanner/actions";
import {
  createBanners,
  editBanner,
  getEventList,
  setBanner,
  setCourses,
} from "../../../redux/modules/informaAcademy/actions";
import { useToast } from "../../../hooks/toast";
import Button from "../../InformaAcademy/components/button";
import api from "../../../service";

const PreviewHomepageBanner = () => {
  const intl = useIntl();
  const { id, homePageId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToast();
  const { event } = useSelector((state) => state.informaAcademy);
  const [eventName, setEventName] = useState(null);
  const { previewBanner, previewCourses, isEdit } = useSelector(
    (state) => state.homepageBanner
  );
  const { bannerCreated, banner, courses } = useSelector(
    (state) => state.informaAcademy
  );
  const { token } = useSelector((state) => state.auth);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const breadcrumbTitle = homePageId
    ? intl.formatMessage({
        id: "breadcrumb.preview-manage-homepage-banner",
      }) + `${eventName}`
    : intl.formatMessage({
        id: "breadcrumb.preview-create-manage-homepage-banner",
      }) + `${eventName}`;

  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    dispatch(getEventList());
  }, [dispatch]);

  useEffect(() => {
    if (event) {
      setEventName(event.filter((event) => event.id === id)[0].name);
    }
  }, [event, id]);

  useEffect(() => {
    dispatch(setBanner({ eventId: id }));
    dispatch(setCourses({ eventId: id }));
    // if (homePageId && !isEdit) {
    // }
  }, [dispatch, id]);

  useEffect(() => {
    if (!banner) setPageData([]);
    setPageData(
      banner?.map((banner) => ({
        title: banner.title,
        buttonUrl: banner.buttonUrl,
        startDate: banner.startDate,
        endDate: banner.endDate,
        bannerImage: banner.bannerImage,
        description: banner.description,
      }))
    );
  }, [banner]);

  const redirectToEdit = () => {
    dispatch(setIsEdit(true));

    if (homePageId) {
      return history.push(
        `/admin/informa-academy/manage-homepage-banner/${id}/${homePageId}/create`
      );
    }

    return history.push(
      `/admin/informa-academy/manage-homepage-banner/${id}/create`
    );
  };

  const createHomePage = async ({ body }) => {
    try {
      await api.post("academy/banners", body, config);
      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "success",
        }),
        description: intl.formatMessage({
          id: "success.homepage_created",
        }),
      });
      history.push(`/admin/informa-academy/manage-homepage-banner/`);
    } catch (error) {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error",
        }),
        description: intl.formatMessage({
          id: "error.homepage_created",
        }),
      });
    }
  };

  const updateHomePage = async ({ body, homePageId }) => {
    try {
      await api.put(`academy/banners/homepage/${homePageId}`, body, config);
      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "success",
        }),
        description: intl.formatMessage({
          id: "success.homepage_updated",
        }),
      });
      history.push(`/admin/informa-academy/manage-homepage-banner/`);
    } catch (error) {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error",
        }),
        description: intl.formatMessage({
          id: "error.homepage_updated",
        }),
      });
    }
  };

  const publishBanner = async () => {
    const homepage = event.find((event) => event.id === id);
    const body = convertToFormData(
      previewBanner,
      previewCourses,
      id,
      homepage?.homePageId
    );

    if (homepage?.homePageId) {
      return await updateHomePage({ body, homePageId: homepage.homePageId });
    }

    return await createHomePage({ body });
  };

  if (!courses) return null;

  return (
    <AnimatedMainContainer
      goBack={`/admin/informa-academy/manage-homepage-banner`}
    >
      <Container>
        <PageTitleContainer>
          <PageTitle>{breadcrumbTitle}</PageTitle>
          <ButtonContainer>
            <Button
              label={"edit"}
              size={"sm"}
              variant={"secondary"}
              Icon={<EditIcon size={16} />}
              onClick={redirectToEdit}
            />
            {!homePageId && (
              <PublishButton onClick={publishBanner}>
                <RiShareForward2Fill />
                {intl.formatMessage({ id: "preview_banner_button_publish" })}
              </PublishButton>
            )}
          </ButtonContainer>
        </PageTitleContainer>

        <HomepageContainer>
          <BannerCaroussel banners={pageData} />

          {previewCourses.length > 0 ? (
            <CourseList courses={previewCourses} />
          ) : (
            <CourseList courses={courses} />
          )}
        </HomepageContainer>
      </Container>
    </AnimatedMainContainer>
  );
};

export default PreviewHomepageBanner;

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toISOString();
}

function convertToFormData(banners, courses, eventId, homePageId) {
  const formData = new FormData();

  if (homePageId) formData.append("homePageId", homePageId);

  formData.append("eventId", eventId);

  banners.forEach((banner, index) => {
    homePageId && formData.append(`banners[${index}].id`, banner.id);
    formData.append(`banners[${index}].title`, banner.title);
    formData.append(`banners[${index}].buttonUrl`, banner.buttonUrl);
    formData.append(
      `banners[${index}].startDate`,
      formatDate(banner.startDate).replace("Z", "")
    );
    formData.append(
      `banners[${index}].endDate`,
      banner.endDate ? formatDate(banner.endDate).replace("Z", "") : ""
    );
    formData.append(`banners[${index}].description`, banner.description);
    formData.append(`banners[${index}].image`, banner.bannerImage);
  });

  courses.forEach((course, index) => {
    formData.append(`courses[${index}].courseId`, course.courseId);
    formData.append(`courses[${index}].courseOrder`, course.courseOrder);
    formData.append(`courses[${index}].visible`, course.visible);
  });

  return formData;
}
