import DataTable from "react-data-table-component";

import { BiChevronUp } from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import Empty from "../Empty";
import {
  customStyles,
  Container,
  ButtonsContainer,
} from "../CoursesTable/styles";
import { useCallback, useMemo, useState } from "react";
import {
  ArrowButton,
  ButtonContainer,
  Center,
  ColumnName,
  TableOrderContainer,
} from "./styles";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import Button from "../button";
import { FaEye, FaRegTrashAlt as TrashIcon } from "react-icons/fa";
import { AiOutlineEdit as EditIcon } from "react-icons/ai";
import { ModalButtonsWrapper, ModalSubtitle, ModalTitle } from "../../styles";
import { Modal, Tabs } from "antd";
import BackIcon from "../../../../icons/Back";
import LessonForm from "./lesson-form";
import api from "../../../../service";
import { useSelector } from "react-redux";
import { useToast } from "../../../../hooks/toast";
import { ThumbnailPreview } from "../../../../newUXPages/InformaAcademy/components/Videos";
import { LessonSidebarCardPreview } from "../../../../newUXPages/InformaAcademy/Lesson";
import { paginationOptions } from "../../../Manual/components/table-utils";
import { LessonCardPreview } from "../../../../newUXComponents/LessonCard";

function LessonTable({ lessons, setLessons, updateCounter, setUpdateCounter }) {
  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);

  const reorderRows = useCallback(
    (index, direction) => {
      setLessons((prevData) => {
        const newData = [...prevData];
        const targetIndex = direction === "up" ? index - 1 : index + 1;

        [newData[index], newData[targetIndex]] = [
          newData[targetIndex],
          newData[index],
        ];

        const data = newData.map((item, idx) => ({
          ...item,
          orders: idx + 1,
        }));

        return data;
      });
    },
    [setLessons]
  );

  const removeLessonFromDB = useCallback(
    async ({ row }) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        await api.delete(`/informa-academy/lesson/${row.id}`, config);
        addToast({
          type: "success",
          title: intl.formatMessage({
            id: "success.lesson_deleted",
          }),
        });
        const updatedLessons = lessons.filter((lesson) => lesson !== row);
        const mappedLessons = updatedLessons.map((lesson, i) => {
          lesson.orders = i + 1;
          return lesson;
        });
        setLessons(mappedLessons);
      } catch (error) {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error.lesson_delete",
          }),
        });
      }
    },
    [addToast, token, intl, lessons, setLessons]
  );

  const removeLesson = useCallback(
    async (row) => {
      if (row.id) {
        return removeLessonFromDB({ row });
      }

      const updatedLessons = lessons.filter((lesson) => lesson !== row);
      const mappedLessons = updatedLessons.map((lesson, i) => {
        lesson.orders = i + 1;
        return lesson;
      });
      setLessons(mappedLessons);

      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "success.lesson_deleted",
        }),
      });
    },
    [lessons, setLessons, addToast, intl, removeLessonFromDB]
  );

  const columns = useMemo(() => {
    return [
      {
        maxWidth: "130px",
        name: <FormattedMessage id={"lesson_order"} />,
        selector: (row) => row.orders,
        cell: (row, index) => (
          <OrdernableRow
            index={index}
            row={row}
            reorderRows={reorderRows}
            rowData={lessons}
          />
        ),
        sortable: true,
      },
      {
        name: <FormattedMessage id={"title"} />,
        selector: (row) => row.title,
        sortable: true,
      },
      {
        name: <FormattedMessage id={"instructor"} />,
        selector: (row) => row.instructor,
        sortable: true,
      },
      {
        // width: "300px",
        width: intl.locale === "en" ? "410px" : "480px",
        selector: (row) => (
          <ActionRow
            row={row}
            deleteLesson={removeLesson}
            lessons={lessons}
            setLessons={setLessons}
            setUpdateCounter={setUpdateCounter}
          />
        ),
      },
    ];
  }, [
    intl.locale,
    lessons,
    removeLesson,
    reorderRows,
    setLessons,
    setUpdateCounter,
  ]);

  return (
    <Container mt>
      <DataTable
        key={`lessons-${updateCounter}`}
        columns={columns}
        noDataComponent={<Empty intlId="informa_academy_empty_lessons" />}
        data={lessons}
        customStyles={customStyles}
        sortIcon={<BiChevronUp style={{ display: "flex" }} />}
        pagination
        highlightOnHover
        persistTableHead
        paginationComponentOptions={paginationOptions({ locale: intl.locale })}
      />
    </Container>
  );
}

export default LessonTable;

function OrdernableRow({ row, index, reorderRows, rowData }) {
  return (
    <TableOrderContainer>
      <ColumnName>{row.orders}</ColumnName>
      <ButtonContainer>
        <ArrowButton
          onClick={() => reorderRows(index, "up")}
          disabled={index === 0}
        >
          <IoChevronUpOutline />
        </ArrowButton>
        <ArrowButton
          onClick={() => reorderRows(index, "down")}
          disabled={index === rowData.length - 1}
        >
          <IoChevronDownOutline />
        </ArrowButton>
      </ButtonContainer>
    </TableOrderContainer>
  );
}

function ActionRow({
  lessons,
  setLessons,
  deleteLesson,
  row,
  setUpdateCounter,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [preview, setpreview] = useState(false);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const intl = useIntl();

  const handleDelete = () => {
    deleteLesson(row);
    setModalOpen(false);
  };

  return (
    <ButtonsContainer>
      <Button
        label={"preview"}
        size={"sm"}
        variant={"secondary"}
        Icon={<FaEye size={16} />}
        onClick={() => {
          setpreview(true);
        }}
      />
      <Button
        label={"edit"}
        size={"sm"}
        variant={"secondary"}
        Icon={<EditIcon size={16} />}
        onClick={() => {
          setModalEditOpen(true);
        }}
      />
      <Button
        label={"delete"}
        size={"sm"}
        variant={"destructive"}
        Icon={<TrashIcon size={16} />}
        onClick={() => setModalOpen(true)}
      />
      <Modal
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        width={"592px"}
        footer={""}
        closable={false}
        className="modalLesson"
      >
        <ModalTitle>
          {intl.formatMessage(
            {
              id: "delete_lesson_title",
            },
            {
              lesson: `"${row.title}"`,
            }
          )}
        </ModalTitle>
        <ModalSubtitle>
          {intl.formatMessage({
            id: "delete_lesson_sub",
          })}
        </ModalSubtitle>
        <ModalSubtitle bold>
          {intl.formatMessage({
            id: "irreversible_action",
          })}
        </ModalSubtitle>
        <ModalButtonsWrapper>
          <Button
            label={"goback"}
            variant={"secondary"}
            Icon={<BackIcon size={16} />}
            onClick={() => setModalOpen(false)}
          />
          <Button
            label={"delete"}
            variant={"destructive2"}
            Icon={<TrashIcon size={16} />}
            onClick={handleDelete}
          />
        </ModalButtonsWrapper>
      </Modal>
      <EditLesson
        modalOpen={modalEditOpen}
        lessons={lessons}
        setLessons={setLessons}
        resetModal={() => setModalEditOpen(false)}
        lesson={row}
        setUpdateCounter={setUpdateCounter}
      />

      <PreviewModal
        coverImage={row.coverImage}
        orders={row.orders}
        preview={preview}
        setpreview={setpreview}
        title={row.title}
        url={row.url}
      />
    </ButtonsContainer>
  );
}

function EditLesson({
  modalOpen,
  resetModal,
  setLessons,
  lessons,
  lesson,
  setUpdateCounter,
}) {
  return (
    <Modal
      open={modalOpen}
      onCancel={resetModal}
      width={"1192px"}
      footer={""}
      closable={false}
      className="modalLesson"
      centered={true}
    >
      <LessonForm
        defaultValue={lesson}
        lessons={lessons}
        setLessons={setLessons}
        resetModal={resetModal}
        setUpdateCounter={setUpdateCounter}
      />
    </Modal>
  );
}

function PreviewModal({ preview, setpreview, url, orders, title, coverImage }) {
  const image = getFileDetails(coverImage);

  const items = [
    {
      key: "1",
      label: <FormattedMessage id={"lesson_card"} />,
      children: (
        <Center>
          <LessonCardPreview
            order={orders}
            title={title}
            imageUrl={image?.fileURL}
          />
        </Center>
      ),
    },
    {
      key: "2",
      label: <FormattedMessage id={"lesson_thumbnail"} />,
      children: (
        <Center>
          <ThumbnailPreview url={url} />
        </Center>
      ),
    },
    {
      key: "3",
      label: <FormattedMessage id={"lesson_sidebar_card"} />,
      children: (
        <Center sidebar>
          <LessonSidebarCardPreview
            title={title}
            lessonImageUrl={url}
            order={orders}
          />
          <LessonSidebarCardPreview
            title={title}
            lessonImageUrl={url}
            order={orders}
            inView
          />
          <LessonSidebarCardPreview
            title={title}
            lessonImageUrl={url}
            order={orders}
            concluded
          />
          <LessonSidebarCardPreview
            title={title}
            lessonImageUrl={url}
            order={orders}
            concluded
            inView
          />
        </Center>
      ),
    },
  ];

  return (
    <Modal
      open={preview}
      width={"448px"}
      onCancel={() => setpreview(false)}
      footer={""}
      closable={false}
      className="modalLesson"
    >
      <Tabs defaultActiveKey="1" items={items} centered />
    </Modal>
  );
}

const getFileDetails = (file) => {
  if (typeof file === "object" && typeof file?.url === "string")
    return {
      fileURL: file?.url,
    };

  if (typeof file === "object" && typeof file?.type === "string") {
    const fileURL = URL.createObjectURL(file);

    return {
      fileURL,
    };
  }

  return {
    fileURL: "",
  };
};
