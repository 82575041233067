import styled from "styled-components";

export const CourseContainer = styled.div`
  width: 100%;
  padding: 1.25rem;
  padding-right: 0;
  background-color: #fff;
  border-radius: 8px;
  display: grid;
  gap: 25px;
`;

export const CourseTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
`;

export const CourseTitle = styled.h2`
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 40px;
  letter-spacing: 0%;
  color: #444444;
  margin-bottom: 0;
`;

export const OpenCourseLink = styled.a`
  font-size: 0.875rem;
  color: #007bff;
  margin-left: auto;
  display: block;
  margin-top: 0.625rem;
  text-decoration: underline;
`;

export const LessonListWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 3rem;
`;

export const LessonList = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  overflow: hidden;
  max-width: 1142px;

  /* scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  } */
`;

export const LessonCard = styled.div`
  flex: 0 0 calc((100% - 3rem) / 4.5);
  min-width: calc((100% - 3rem) / 4.5);
  background-color: #f7f9fa;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.02);
  }
`;

export const LessonHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LessonTitleContainer = styled.div`
  padding: 1rem;
`;

export const LessonTitle = styled.p`
  font-size: 1.5rem;
  padding-top: 0.75rem;
`;

export const LessonNumber = styled.span`
  width: fit-content;
  font-size: 1.125rem;
  color: #777;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  border-bottom: 3px solid #c7c9d9;
`;

export const DoneLesson = styled.span`
  width: fit-content;
  font-size: 0.875rem;
  font-weight: 600;
  color: #16a34a;
`;

export const LessonIcon = styled.div`
  width: 60px;
  height: 60px;
  background-color: #d7b4ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
