import { FormattedMessage, useIntl } from "react-intl";
import { useToast } from "../../../../hooks/toast";
import { useSelector } from "react-redux";
import { useCallback, useMemo, useState } from "react";
import api from "../../../../service";
import { TableContainer } from "../../style";
import DataTable from "react-data-table-component";
import Empty from "../../../InformaAcademy/components/Empty";
import {
  ButtonsContainer,
  customStyles,
} from "../../../InformaAcademy/components/CoursesTable/styles";
import Button from "../../../InformaAcademy/components/button";
import { Modal } from "antd";
import BackIcon from "../../../../icons/Back";
import { FaRegTrashAlt as TrashIcon } from "react-icons/fa";
import { AiOutlineEdit as EditIcon } from "react-icons/ai";
import {
  ModalButtonsWrapper,
  ModalSubtitle,
  ModalTitle,
} from "../../../InformaAcademy/styles";
import { TruncatedText } from "./styles";
import { OrdernableRow, paginationOptions } from "../table-utils";
import EditFAQForm from "./edit-faq-form";

export default function FAQTable({ FAQarray, setFAQarray, manualId }) {
  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);

  const reorderRows = useCallback(
    (index, direction) => {
      setFAQarray((prevData) => {
        const newData = [...prevData];
        const targetIndex = direction === "up" ? index - 1 : index + 1;

        [newData[index], newData[targetIndex]] = [
          newData[targetIndex],
          newData[index],
        ];

        return newData.map((item, idx) => ({
          ...item,
          order: idx + 1,
        }));
      });
    },
    [setFAQarray]
  );

  const removeChapterFromDB = useCallback(
    async ({ row }) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        await api.delete(`/manual/${manualId}/faq/${row.id}`, config);
        const updatedFAQ = FAQarray.filter((FAQ) => FAQ !== row);
        const mappedFAQ = updatedFAQ.map((FAQ, i) => {
          FAQ.order = i + 1;
          return FAQ;
        });
        setFAQarray(mappedFAQ);
        addToast({
          type: "success",
          title: intl.formatMessage({
            id: "success.FAQ_deleted",
          }),
        });
      } catch (error) {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error.FAQ_delete",
          }),
        });
      }
    },
    [addToast, token, intl, FAQarray, setFAQarray, manualId]
  );

  const removeChapter = useCallback(
    async (row) => {
      if (row.id) {
        return removeChapterFromDB({ row });
      }

      const updatedFAQ = FAQarray.filter((FAQ) => FAQ !== row);
      const mappedFAQ = updatedFAQ
        .map((FAQ, i) => {
          FAQ.order = i + 1;
          return FAQ;
        })
        .sort((a, b) => a.order - b.order);
      setFAQarray(mappedFAQ);

      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "success.FAQ_deleted",
        }),
      });
    },
    [FAQarray, setFAQarray, addToast, intl, removeChapterFromDB]
  );

  const columns = useMemo(() => {
    return [
      {
        maxWidth: "130px",
        name: <FormattedMessage id={"lesson_order"} />,
        selector: (row) => row.order,
        cell: (row, index) => (
          <OrdernableRow
            index={index}
            row={row}
            reorderRows={reorderRows}
            rowData={FAQarray}
          />
        ),
      },
      {
        name: <FormattedMessage id={"question"} />,
        selector: (row) => (
          <TruncatedText maxWidth={"430px"} title={row.question}>
            {row.question}
          </TruncatedText>
        ),
      },
      {
        name: <FormattedMessage id={"answer"} />,
        selector: (row) => (
          <TruncatedText maxWidth={"430px"} title={row.answer}>
            {row.answer}
          </TruncatedText>
        ),
      },
      {
        maxWidth: "292px",
        selector: (row) => (
          <ActionRow
            row={row}
            deleteChapter={removeChapter}
            FAQarray={FAQarray}
            setFAQarray={setFAQarray}
            manualId={manualId}
          />
        ),
      },
    ];
  }, [FAQarray, manualId, removeChapter, reorderRows, setFAQarray]);

  return (
    <TableContainer>
      <DataTable
        columns={columns}
        noDataComponent={<Empty intlId="manual_chapters_empty" />}
        data={FAQarray}
        customStyles={customStyles}
        pagination
        persistTableHead
        paginationComponentOptions={paginationOptions({ locale: intl.locale })}
      />
    </TableContainer>
  );
}
function ActionRow({ FAQarray, setFAQarray, deleteChapter, row, manualId }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const intl = useIntl();

  const handleDelete = () => {
    deleteChapter(row);
    setModalOpen(false);
  };

  return (
    <ButtonsContainer>
      <Button
        label={"edit"}
        size={"sm"}
        variant={"secondary"}
        Icon={<EditIcon size={16} />}
        onClick={() => {
          setModalEditOpen(true);
        }}
      />
      <Button
        label={"delete"}
        size={"sm"}
        variant={"destructive"}
        Icon={<TrashIcon size={16} />}
        onClick={() => setModalOpen(true)}
      />
      <Modal
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        width={"592px"}
        footer={""}
        closable={false}
        className="modalChapter"
      >
        <ModalTitle>
          {intl.formatMessage(
            {
              id: "delete_FAQ_question",
            },
            {
              question: `"${row.question}"`,
            }
          )}
        </ModalTitle>
        <ModalSubtitle bold>
          {intl.formatMessage({
            id: "irreversible_action",
          })}
        </ModalSubtitle>
        <ModalButtonsWrapper>
          <Button
            label={"goback"}
            variant={"secondary"}
            Icon={<BackIcon size={16} />}
            onClick={() => setModalOpen(false)}
          />
          <Button
            label={"delete"}
            variant={"destructive2"}
            Icon={<TrashIcon size={16} />}
            onClick={handleDelete}
          />
        </ModalButtonsWrapper>
      </Modal>
      <EditChapter
        modalOpen={modalEditOpen}
        FAQarray={FAQarray}
        setFAQarray={setFAQarray}
        resetModal={() => setModalEditOpen(false)}
        FAQ={row}
        manualId={manualId}
      />
    </ButtonsContainer>
  );
}

function EditChapter({
  modalOpen,
  resetModal,
  setFAQarray,
  FAQarray,
  FAQ,
  manualId,
}) {
  return (
    <Modal
      open={modalOpen}
      onCancel={resetModal}
      footer={""}
      closable={false}
      className="modalChapter"
      width={600}
    >
      <EditFAQForm
        FAQ={FAQarray}
        defaultValue={FAQ}
        manualId={manualId}
        resetModal={resetModal}
        setFAQ={setFAQarray}
      />
    </Modal>
  );
}
