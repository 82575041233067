import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  & > div {
    width: 100%;
  }

  /* margin-bottom: 16px; */
  & + div {
    margin-top: 24px;
  }

  input {
    font-size: 14px;
  }

  ${(props) =>
    props.homepageStyle &&
    css`
      .react-select__control {
        border: 1px solid #ebebf0;
        border-radius: 10px;
        width: 150px;
        margin-bottom: 0px !important;
      }

      .react-select__indicator-separator {
        display: none;
      }
    `}
`;

export const Error = styled.div`
  margin: 5px 0px !important;
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: flex-start;
  color: #e00000;
  text-align: left;
  svg {
    margin-right: 4px;
    /* margin-left: 16px; */
    min-width: 32px;
  }
  span {
    background: #c53030;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }

  ${(props) =>
    props.labelWidth &&
    props.labelOrientation === "horizontal" &&
    css`
      padding-left: ${props.labelWidth};
      @media (max-width: 500px) {
        padding-left: 0px;
      }
    `}
`;

export const InputContainer = styled.div`
  background: transparent;
  border-radius: 5px;
  padding: 8px 16px;
  color: #dadada;
  display: flex;
  align-items: center;
`;

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  ${(props) =>
    props.homepageStyle &&
    css`
      margin-bottom: 0px;
    `}

  ${(props) =>
    props.labelOrientation === "horizontal" &&
    css`
      flex-direction: column;
      display: flex;
      align-items: center;
      flex: 1 1 100%;
      & > div {
        width: 100%;
      }
      @media (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
        & > div {
          width: 100%;
        }
      }
    `}
`;

export const Label = styled.span`
  font-size: 12px;
  font-weight: 600;
  width: 100px;
`;

export const LabelContainer = styled.div`
  margin-right: 8px;
  margin-bottom: 8px;

  ${(props) =>
    props.labelWidth &&
    css`
      width: ${props.labelWidth};
      @media (max-width: 500px) {
        width: auto;
      }
    `}

  ${(props) =>
    props.homepageStyle &&
    css`
      margin-right: 0px;
      margin-bottom: 0px;
    `}
`;

export const InputRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  ${(props) =>
    props.labelOrientation === "horizontal" &&
    css`
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    `}

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const AsteriskText = styled.span`
  color: #c53030;
`;
