import * as React from "react";

const Home = (props) => {
  const { size = "16", color = "#11A7D9" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.665 15.167v-8L8 1.833 1.332 7.167v8h4v-6h5.333v6z"
      />{" "}
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8 15.167v-3.334"
      />
    </svg>
  );
};

export default Home;
