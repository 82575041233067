import React from "react";
import { RouteBreadcrumbs } from "../../../components/AnimatedMainContainer/styles";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GoBack, Header, HeaderContainer } from "../style";
import { BsChevronLeft } from "react-icons/bs";
import { FormattedMessage } from "react-intl";

export default function NavigationHeader({ goBack, breadcrumb }) {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  return (
    <HeaderContainer>
      {goBack && (
        <Header>
          <GoBack onClick={() => history.push(goBack)}>
            <BsChevronLeft />
            <p>
              <FormattedMessage id={"goback"} />
            </p>
          </GoBack>
        </Header>
      )}
      {breadcrumb && (
        <Header>
          <RouteBreadcrumbs>
            {<FormattedMessage id={breadcrumb} />}
          </RouteBreadcrumbs>
          {user?.name}
        </Header>
      )}
    </HeaderContainer>
  );
}
