import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getArticle } from "../../../../redux/modules/adminExhibitorManual/actions";
import AnimatedMainContainer from "../../../../components/AnimatedMainContainer";
import ArticleForm from "./article-form";
import Loader from "../loader";

export default function EditArticle() {
  const { articleId } = useParams();
  const dispatch = useDispatch();
  const { article } = useSelector((state) => state.adminManualExhibitor);

  useEffect(() => {
    dispatch(getArticle({ articleId }));
  }, [dispatch, articleId]);

  if (!article) return <Loader />;

  return (
    <AnimatedMainContainer>
      <ArticleForm defaultValue={article} />
    </AnimatedMainContainer>
  );
}
