import { useHistory } from "react-router-dom";
import { ButtonContainer } from "./styles";
import { useIntl } from "react-intl";

export default function Button({
  label,
  Icon,
  variant,
  size,
  href,
  onClick,
  ...rest
}) {
  const history = useHistory();
  const intl = useIntl();

  const handleClick = () => {
    if (href) return history.push(href);

    if (onClick) return onClick();

    return;
  };

  return (
    <ButtonContainer
      variant={variant}
      size={size}
      onClick={handleClick}
      {...rest}
    >
      {Icon}
      {intl.formatMessage({ id: label })}
    </ButtonContainer>
  );
}
