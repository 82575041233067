import { useState, useEffect, useRef } from "react";
import {
  NextLessonContainer,
  PlayButton,
  Thumbnail,
  VideoContainer,
} from "../style";
import { FormattedMessage } from "react-intl";
import { Progress } from "antd";
import Button from "../../Button";

export const VimeoVideo = ({
  videoId,
  moveToNextLesson,
  completeLesson,
  interaction,
  lastLesson,
}) => {
  const [playerLoaded, setPlayerLoaded] = useState(false);
  const [nextLesson, setNextLesson] = useState(false);
  const [progress, setProgress] = useState(0);
  const intervalRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    if (!nextLesson) {
      return;
    }

    completeLesson();

    if (lastLesson) return;

    intervalRef.current = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 5) {
          clearInterval(intervalRef.current);
          moveToNextLesson();
          return prev;
        }
        return prev + 1;
      });
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, [nextLesson, moveToNextLesson, completeLesson, lastLesson]);

  useEffect(() => {
    if (!playerLoaded) return;

    const initializePlayer = () => {
      const iframe = document.getElementById("vimeo-player");
      playerRef.current = new window.Vimeo.Player(iframe);
      playerRef.current.on("ended", () => {
        setNextLesson(true);
      });
      playerRef.current.play();
    };

    initializePlayer();

    return () => {
      if (playerRef.current) {
        playerRef.current.unload();
      }
    };
  }, [playerLoaded]);

  const cancelInterval = () => {
    if (!intervalRef.current) return;

    clearInterval(intervalRef.current);
    intervalRef.current = null;
    setNextLesson(false);
    setProgress(0);
  };

  return (
    <VideoContainer>
      {!playerLoaded && (
        <Thumbnail
          vimeo={videoId}
          videoId={videoId}
          videoType={"vimeo"}
          onClick={() => {
            setPlayerLoaded(true);
            interaction();
          }}
        >
          <PlayButton>▶</PlayButton>
        </Thumbnail>
      )}
      {playerLoaded && (
        <iframe
          id="vimeo-player"
          src={`https://player.vimeo.com/video/${videoId}`}
          width="100%"
          height="100%"
          frameBorder="0"
          allow="autoplay; fullscreen"
          title="informa-academy-video-lesson"
        />
      )}
      {!lastLesson && nextLesson && (
        <Thumbnail
          vimeo={videoId}
          videoId={videoId}
          videoType={"youtube"}
          nextLesson
        >
          <NextLessonContainer>
            <p>
              <FormattedMessage id="moving_to_next_lesson" />
            </p>
            <Progress
              percent={progress * 20}
              format={(percent) => {
                const mapping = {
                  0: 5,
                  20: 4,
                  40: 3,
                  60: 2,
                  80: 1,
                  100: 0,
                };

                return mapping[percent] ?? 5;
              }}
              type="circle"
              className="progress"
            />
            <Button
              label={"cancel"}
              onClick={cancelInterval}
              variant={"academy"}
              size={"academy"}
            />
          </NextLessonContainer>
        </Thumbnail>
      )}
    </VideoContainer>
  );
};
