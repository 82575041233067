import React from "react";
import { YouTubeVideo } from "./YoutubeVideo";
import { VimeoVideo } from "./VimeoVideo";
import { PlayButton, Thumbnail, VideoContainer } from "./style";

const InformaAcademyVideo = React.memo(
  ({ url, completeLesson, interaction, moveToNextLesson, lastLesson }) => {
    const youtubeRegex =
      /(youtube\.com\/(watch\?v=|playlist\?list=|embed\/|v\/)?|youtu\.be\/)[a-zA-Z0-9_-]+/;
    const vimeoRegex =
      /(vimeo\.com\/(channels\/[a-zA-Z0-9_-]+\/|groups\/[a-zA-Z0-9_-]+\/videos\/)?)[0-9]+/;

    if (youtubeRegex.test(url)) {
      const videoId = getVideoId("youtube", url);
      return (
        <YouTubeVideo
          completeLesson={completeLesson}
          videoId={videoId}
          interaction={interaction}
          moveToNextLesson={moveToNextLesson}
          lastLesson={lastLesson}
        />
      );
    }

    if (vimeoRegex.test(url)) {
      const videoId = getVideoId("vimeo", url);
      return (
        <VimeoVideo
          completeLesson={completeLesson}
          videoId={videoId}
          interaction={interaction}
          moveToNextLesson={moveToNextLesson}
          lastLesson={lastLesson}
        />
      );
    }

    return null;
  },
  (prevProps, nextProps) => {
    return (
      prevProps.url === nextProps.url &&
      prevProps.coverImage === nextProps.coverImage
    );
  }
);
export default InformaAcademyVideo;

export function getVideoId(type, url) {
  function getYouTubeId(url) {
    const match = url.match(
      /(?:v=|\/v\/|\/embed\/|youtu\.be\/|\/shorts\/)([a-zA-Z0-9_-]{11})/
    );
    return match ? match[1] : null;
  }

  function getVimeoId(url) {
    const match = url.match(
      /vimeo\.com\/(?:channels\/[a-zA-Z0-9_-]+\/|groups\/[a-zA-Z0-9_-]+\/videos\/|video\/)?(\d+)/
    );
    return match ? match[1] : null;
  }

  switch (type) {
    case "youtube":
      return getYouTubeId(url);
    case "vimeo":
      return getVimeoId(url);
    default:
      return null;
  }
}

export function ThumbnailPreview({ url }) {
  const youtubeRegex =
    /(youtube\.com\/(watch\?v=|playlist\?list=|embed\/|v\/)?|youtu\.be\/)[a-zA-Z0-9_-]+/;
  const vimeoRegex =
    /(vimeo\.com\/(channels\/[a-zA-Z0-9_-]+\/|groups\/[a-zA-Z0-9_-]+\/videos\/)?)[0-9]+/;

  if (youtubeRegex.test(url)) {
    const videoId = getVideoId("youtube", url);
    return (
      <VideoContainer preview>
        <Thumbnail youtube={videoId} videoId={videoId} normalCursor>
          <PlayButton preview>▶</PlayButton>
        </Thumbnail>
      </VideoContainer>
    );
  }

  if (vimeoRegex.test(url)) {
    const videoId = getVideoId("vimeo", url);
    return (
      <VideoContainer preview>
        <Thumbnail vimeo={videoId} videoId={videoId} normalCursor>
          <PlayButton preview>▶</PlayButton>
        </Thumbnail>
      </VideoContainer>
    );
  }

  return null;
}
