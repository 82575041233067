import styled, { css, keyframes } from "styled-components";

export const WrapperContainer = styled.div`
  display: grid;
  gap: 24px;

  & > button {
    margin-left: auto;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #fff;
`;

export const Message = styled.p`
  text-align: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 21px;
  padding-left: 8px;
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
  line-height: 22px;
  color: #002244;
`;

export const TableContainer = styled.div`
  margin: 24px 0px;

  & .rdt_Table {
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    overflow: hidden;
  }

  & .rdt_Pagination {
    border-color: transparent;
  }

  ${(props) =>
    props.noMarginBottom &&
    css`
      margin-bottom: 0 !important;
    `}
`;

export const TableButtonContainer = styled.div`
  ${(props) =>
    props.center &&
    css`
      flex: 1;
      justify-content: center;
    `}
  display: flex;
  gap: 16px;
`;

export const HeaderContainer = styled.div`
  margin-bottom: 16px;
  display: grid;
  gap: 4px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GoBack = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #002244;
  line-height: 22px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  transition: all 300ms;

  &:hover {
    border-color: #002244;
    & > svg {
      transform: translateX(-2px);
    }
  }
  & > p {
    margin: 0;
  }
  & > svg {
    transition: all 300ms;
  }
`;

export const ArticleHeaderContainer = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ArticleHeaderTitle = styled.p`
  color: #585f74;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 0;
`;

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const LoaderComponent = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: stretch;
  background-color: #dadada;
  padding: 88px 16px 88px 16px;
  animation: ${appear} 300ms;
`;
