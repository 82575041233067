import React, { useEffect, useState } from "react";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import { FormattedMessage, useIntl } from "react-intl";
import { Container } from "../style";
import { ButtonContainer, HeaderContainer } from "./styles";
import Button from "../../InformaAcademy/components/button";
import Copy from "./Copy";
import ChapterTable from "../components/chapter/chapters-table";
import { useDispatch, useSelector } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import ChapterForm from "../components/chapter/chapter-form";
import { z } from "zod";
import { getEvents } from "../../../redux/modules/event/actions";
import { FormRow } from "../../../components/ControlledInputs/styles";
import ControlledSelect from "../../../components/ControlledInputs/select";
import Save from "../../../icons/Save";
import api from "../../../service";
import { useToast } from "../../../hooks/toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Modal } from "antd";
import DuplicateManualForm from "./duplicate-manual.form";

const schema = z.object({
  eventId: z.object({
    value: z.coerce.number().min(1),
    label: z.string().min(1),
  }),
  language: z.object({
    value: z.string().min(1),
    label: z.string().min(1),
  }),
});

export default function NewManual() {
  const intl = useIntl();
  const { addToast } = useToast();
  const history = useHistory();

  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { events, isLoading } = useSelector((state) => state.event);

  const [languages] = useState([
    { value: "PORTUGUESE", label: intl.formatMessage({ id: "portuguese" }) },
    { value: "ENGLISH", label: intl.formatMessage({ id: "english" }) },
  ]);
  const [chapters, setChapters] = useState([]);

  const form = useForm({
    resolver: zodResolver(schema),
  });
  const { control, handleSubmit, formState } = form;

  useEffect(() => {
    dispatch(getEvents());
  }, [dispatch]);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const onSubmit = async (data) => {
    const newManual = {
      eventId: data.eventId.value,
      language: data.language.value,
      chapters,
    };
    const body = JSON.stringify(newManual);

    try {
      await api.post("/manual", body, config);

      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "success",
        }),
        description: intl.formatMessage({
          id: "success.manual_create",
        }),
      });

      history.push("/admin/manual");
    } catch (error) {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error.manual_create",
        }),
        description:
          error.response.status === 409
            ? intl.formatMessage({
                id: "error.manual_create_same_event",
              })
            : "",
      });
    }
  };

  return (
    <AnimatedMainContainer goBack={"/admin/manual"}>
      <Container>
        <Header />

        <FormRow>
          <ControlledSelect
            name={"eventId"}
            placeholder={"Selecione"}
            control={control}
            label={"events"}
            required={true}
            options={events}
            errors={formState.errors}
            disabled={isLoading}
          />
          <ControlledSelect
            name={"language"}
            placeholder={"Selecione"}
            control={control}
            label={"language"}
            required={true}
            options={languages}
            errors={formState.errors}
          />
        </FormRow>

        <ChapterForm chapters={chapters} setChapters={setChapters} />
        <ChapterTable
          key={chapters.length}
          chapters={chapters}
          setChapters={setChapters}
        />
        <ButtonContainer margin="16px 0px 0px auto">
          <Button
            label={"save"}
            Icon={<Save />}
            onClick={handleSubmit(onSubmit)}
            disabled={chapters.length === 0}
          />
        </ButtonContainer>
      </Container>
    </AnimatedMainContainer>
  );
}

function Header() {
  const [open, setOpen] = useState(false);
  return (
    <HeaderContainer>
      <h2>
        <FormattedMessage id={"manual_new"} />
      </h2>
      <Button
        label={"manual_existing_duplicte"}
        Icon={<Copy />}
        variant={"tertiary"}
        border
        onClick={() => setOpen(true)}
      />
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        centered={true}
        closable={false}
        width={600}
        footer={""}
        className="modalLesson"
        destroyOnClose
      >
        <DuplicateManualForm closeModal={() => setOpen(false)} />
      </Modal>
    </HeaderContainer>
  );
}
