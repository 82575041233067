import styled, { css } from "styled-components";

export const Container = styled.aside`
  position: absolute;
  width: 280px;
  height: 94vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  transition: all 11300ms;

  ${({ informaAcademyCourse }) =>
    informaAcademyCourse &&
    css`
      left: -280px;
      /* width: 0px;
      transform: translateX(-280px); */
    `}
`;

export const Image = styled.img`
  margin: 0;
  height: 80px;
`;
