import React, { useRef, useEffect } from "react";
import ReactSelect, { components } from "react-select";
import { useField } from "@unform/core";

import { MdError } from "react-icons/md";
import {
  Error,
  InputContainer,
  Container,
  RootContainer,
  Label,
  InputRow,
  LabelContainer,
  AsteriskText,
} from "./styles";

const Control = ({ children, ...props }) => {
  const { Icon } = props.selectProps;

  return (
    <components.Control {...props}>
      {Icon && (
        <InputContainer>
          <Icon size={24} />
        </InputContainer>
      )}
      {children}
    </components.Control>
  );
};

const SelectWithLabel = ({
  name,
  icon: Icon,
  validationBlur,
  disabled,
  label,
  className,
  clearable,
  labelOrientation = "vertical",
  closeMenuOnSelect = true,
  labelWidth,
  validateEnd,
  rootClass,
  required,
  homepageStyle,
  ...rest
}) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        }
        if (!ref.state.value) {
          return "";
        }
        return ref.state.value.value;
      },
      setValue: (ref, value) => {
        ref.select.setValue(value);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const handleBlur = () => {
    if (validationBlur) {
      validationBlur(fieldName, selectRef.current?.value);
    }
    if (validateEnd) {
      validateEnd();
    }
  };

  return (
    <RootContainer
      labelOrientation={labelOrientation}
      className={rootClass}
      homepageStyle={homepageStyle}
    >
      <InputRow labelOrientation={labelOrientation}>
        <LabelContainer homepageStyle={homepageStyle} labelWidth={labelWidth}>
          <Label>{label}</Label>
          {required && <AsteriskText>*</AsteriskText>}
        </LabelContainer>

        <Container className={className} homepageStyle={homepageStyle}>
          <ReactSelect
            defaultValue={defaultValue}
            ref={selectRef}
            Icon={Icon}
            isDisabled={disabled}
            closeMenuOnSelect={closeMenuOnSelect}
            classNamePrefix="react-select"
            components={{ Control }}
            isClearable={clearable}
            onBlur={handleBlur}
            menuPortalTarget={document.body}
            styles={{
              placeholder: (base) => ({
                ...base,
                fontSize: "14px",
                color: "#002244",
                fontWeight: 400,
                paddingLeft: Icon ? "0px" : "16px",
              }),
              singleValue: (base) => ({
                ...base,
                fontSize: "14px",
                color: "#002244",
                fontWeight: 400,
                paddingLeft: Icon ? "0px" : "16px",
              }),
              valueContainer: (base) => ({
                ...base,
                paddingTop: "8px",
                paddingBottom: "8px",
              }),
              menuList: (base) => ({
                ...base,
                zIndex: "9999999999999",
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: "9999999999999",
              }),
              control: (base) => ({
                ...base,
                borderColor: error ? "#e00000" : "#dadada",
                ":hover": {
                  borderColor: error ? "#e00000" : "#dadada",
                  ":hover": {
                    borderColor: error ? "#e00000" : "#dadada",
                  },
                },
              }),
            }}
            {...rest}
          />
        </Container>
      </InputRow>
      {error && (
        <Error
          title={error}
          labelWidth={labelWidth}
          labelOrientation={labelOrientation}
        >
          <MdError color="#e00000" size={20} />
          {error}
        </Error>
      )}
    </RootContainer>
  );
};

export default SelectWithLabel;
