import { all, takeLatest, call, put, select } from "redux-saga/effects";
import {
  getCoursesTotalizersSuccess,
  getCoursesTotalizersFail,
  getCourseAnalyticsSuccess,
  getCourseAnalyticsFail,
  getBannersTotalizersSuccess,
  getBannersTotalizersFail,
  getBannerAnalyticsSuccess,
  getBannerAnalyticsFail,
  bannerInteractionSuccess,
  bannerInteractionFailure,
  bannerViewsFailure,
  bannerViewsSuccess,
  bannerImpressionSuccess,
  bannerImpressionFailure,
  getEventListSuccess,
  getEventListFailure,
  getCoursesFailureByEventId,
  getCoursesSuccessByEventId,
  getBannersSuccessByEventId,
  getBannersFailureByEventId,
} from "./actions";
import {
  getAnalyticsCourseUsersService,
  getAnalyticsCourseTotalizersService,
  getAnalyticsBannerTotalizersService,
  getAnalyticsBannerUsersService,
  bannerAnalyticsAction,
} from "../../../service/analytics.service";
import {
  getInformaAcademyBannerService,
  getInformaAcademyCourseListService,
  getInformaAcademyEventService,
} from "../../../service/informaAcademy.service";

function* getCourseAnalyticsTotalizers() {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);
    const response = yield call(getAnalyticsCourseTotalizersService, {
      token,
    });

    const { status, data } = response;

    if (status !== 200)
      throw new Error("Failed to get course analytics totalizers");

    yield put(getCoursesTotalizersSuccess(data));
  } catch (error) {
    yield put(getCoursesTotalizersFail(error));
  }
}

function* getCourseAnalyticsUsers({ payload }) {
  const selectToken = (state) => state.auth.token;
  const {
    idCourse,
    idEvent,
    idLesson,
    year,
    starterDate,
    finishingDate,
    page,
    perPage,
    sortBy,
    sortDirection,
  } = payload;

  try {
    const token = yield select(selectToken);
    const response = yield call(getAnalyticsCourseUsersService, {
      idCourse,
      idEvent,
      idLesson,
      year,
      starterDate,
      finishingDate,
      page,
      perPage,
      sortBy,
      sortDirection,
      token,
    });

    const { status, data } = response;

    if (status !== 200) throw new Error("Failed to get course analytics users");

    yield put(
      getCourseAnalyticsSuccess({
        courseAnalytics: data.analyticsCourseListResponse,
        total: data.paginationInfo.totalElements,
        pageInfo: data.paginationInfo,
      })
    );
  } catch (error) {
    yield put(getCourseAnalyticsFail(error));
  }
}

function* getBannerAnalyticsTotalizers() {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);
    const response = yield call(getAnalyticsBannerTotalizersService, {
      token,
    });

    const { status, data } = response;

    if (status !== 200)
      throw new Error("Failed to get course analytics totalizers");

    yield put(getBannersTotalizersSuccess(data));
  } catch (error) {
    yield put(getBannersTotalizersFail(error));
  }
}

function* getBannerAnalyticsUsers({ payload }) {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);
    const {
      idEvent,
      idBanner,
      year,
      starterDate,
      finishingDate,
      page,
      perPage,
      sortBy,
      sortDirection,
    } = payload;
    const response = yield call(getAnalyticsBannerUsersService, {
      token,
      idEvent,
      idBanner,
      year,
      starterDate,
      finishingDate,
      page,
      perPage,
      sortBy,
      sortDirection,
    });

    const { status, data } = response;

    if (status !== 200) throw new Error("Failed to get course analytics users");

    yield put(
      getBannerAnalyticsSuccess({
        bannerAnalytics: data.analyticsBannerOutputLists,
        total: data.paginationInfo.totalElements,
        pageInfo: data.paginationInfo,
      })
    );
  } catch (error) {
    yield put(getBannerAnalyticsFail(error));
  }
}

function* postBannerImpression({ payload }) {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);
    const { bannerId } = payload;
    yield call(bannerAnalyticsAction, {
      token,
      bannerId,
      action: "IMPRESSION",
    });

    yield put(bannerImpressionSuccess());
  } catch (error) {
    yield put(bannerImpressionFailure(error.message));
  }
}

function* postBannerViews({ payload }) {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);
    const { bannerId } = payload;
    yield call(bannerAnalyticsAction, {
      token,
      bannerId,
      action: "VIEWS",
    });

    yield put(bannerViewsSuccess());
  } catch (error) {
    yield put(bannerViewsFailure(error));
  }
}

function* postBannerInteraction({ payload }) {
  const selectToken = (state) => state.auth.token;
  try {
    const token = yield select(selectToken);
    const { bannerId } = payload;
    yield call(bannerAnalyticsAction, {
      token,
      bannerId,
      action: "INTERACTION",
    });

    yield put(bannerInteractionSuccess());
  } catch (error) {
    yield put(bannerInteractionFailure(error));
  }
}

function* getEventList() {
  const selectedToken = (state) => state.auth.token;

  try {
    const token = yield select(selectedToken);

    const response = yield call(getInformaAcademyEventService, {
      token,
    });

    if (response.status !== 200)
      throw new Error("Failed to get course analytics users");
    const { data } = response;
    const { events } = data;
    const mappedEvents = events.map((event) => {
      return {
        value: Number(event.id),
        label: event.name,
        year: event.year,
      };
    });

    const mappedYears = events.map((event) => {
      return {
        value: event.year,
        label: event.year,
      };
    });

    const years = [...new Set(mappedYears.map((item) => item.value))].map(
      (value) => {
        return { value, label: value };
      }
    );

    const eventsInfo = events.map(
      ({ eventEditionCode, homePageId, id, name, year }) => ({
        eventEditionCode,
        homePageId,
        id,
        name,
        year,
      })
    );

    yield put(
      getEventListSuccess({
        events: mappedEvents,
        eventsInfo,
        years,
      })
    );
  } catch (error) {
    yield put(getEventListFailure(error));
  }
}

function* getCoursesByEventId({ payload }) {
  const selectToken = (state) => state.auth.token;

  try {
    const { eventId } = payload;
    const token = yield select(selectToken);

    if (!eventId) return;

    const params = {
      eventId,
      token,
    };

    const response = yield call(getInformaAcademyCourseListService, params);

    const { courses } = response.data;
    const mappedCourses = courses.map((course) => {
      return {
        value: Number(course.courseId),
        label: course.courseTitle,
      };
    });
    const lessons = courses.map((course) => {
      return {
        courseId: Number(course.courseId),
        lessons: course.lessons.map((lesson) => ({
          value: Number(lesson.lessonId),
          label: lesson.title,
        })),
      };
    });

    yield put(getCoursesSuccessByEventId({ courses: mappedCourses, lessons }));
  } catch (error) {
    yield put(getCoursesFailureByEventId(error));
  }
}

function* getBannersByEventId({ payload }) {
  const selectToken = (state) => state.auth.token;

  try {
    const { eventId } = payload;
    const token = yield select(selectToken);

    if (!eventId) return;

    const params = {
      eventId,
      token,
    };

    const response = yield call(getInformaAcademyBannerService, params);

    const { banners } = response.data;

    const mappedBanners = banners.map((banner) => {
      return {
        value: Number(banner.id),
        label: banner.title,
      };
    });

    yield put(getBannersSuccessByEventId({ banners: mappedBanners }));
  } catch (error) {
    yield put(getBannersFailureByEventId(error));
  }
}

export default all([
  takeLatest("GET_COURSES_TOTALIZERS", getCourseAnalyticsTotalizers),
  takeLatest("GET_COURSE_ANALYTICS", getCourseAnalyticsUsers),

  takeLatest("GET_BANNERS_TOTALIZERS", getBannerAnalyticsTotalizers),
  takeLatest("GET_BANNER_ANALYTICS", getBannerAnalyticsUsers),

  takeLatest("BANNER_INTERACTION", postBannerInteraction),
  takeLatest("BANNER_VIEWS", postBannerViews),
  takeLatest("BANNER_IMPRESSION", postBannerImpression),

  takeLatest("GET_EVENT_LIST_ANALYTICS", getEventList),
  takeLatest("GET_COURSES_ANALYTICS", getCoursesByEventId),
  takeLatest("GET_BANNERS_LIST", getBannersByEventId),
]);
