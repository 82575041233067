import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  resetSearchManualTopicLis,
  searchManualTopicList,
} from "../../../../redux/modules/eventManual/action";
import Header from "../../../../newUXComponents/Layout/Header";
import {
  BackButton,
  BannerContainer,
  BannerWrapper,
  CategorySelect,
  Description,
  EventName,
  FloatingDot,
  HeaderContainer,
  IconShield,
  IconUser,
  IconWrapper,
  Logo,
  SearchButton,
  SearchContainer,
  SearchInput,
  SearchSelect,
  SecondTitle,
  Title,
} from "../../styles";
import { FaUsers } from "react-icons/fa";
import { IoShieldCheckmarkSharp, IoArrowBack } from "react-icons/io5";
import LogoImg from "../../../../newUXAssets/images/InformaLogo.png";
import useNotification from "../../../../hooks/newUXNotification";

const EventManualHeader = ({ topics = [], defaultInput, defaultChapter }) => {
  const [userSearch, setUserSearch] = useState(defaultInput);
  const [category, setCategory] = useState(defaultChapter);
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const notification = useNotification();
  const { event } = useSelector((state) => state.initialSettings);
  const { adminToken, user, serviceProviderLoggingAsAnExhibitor } = useSelector(
    (state) => state.auth
  );
  const searchPage = history.location.pathname.includes("/event-manual/search");

  const nameEvent = useMemo(() => {
    if (event?.label) {
      return event?.label;
    }
    return intl.formatMessage({ id: "event_edition" });
  }, [event, intl]);

  const handleSearch = (userInput) => {
    setUserSearch(userInput);
  };

  const keyToSearch = (event) => {
    if (event.key === "Enter") {
      getSearchResults();
    }
  };

  const getSearchResults = () => {
    if (userSearch?.length === 0 || typeof userSearch === 'undefined') {
      return notification.displayInfo(
        intl.formatMessage({
          id: "exhibitors_manual_emptySearch",
        })
      );
    }

    const chapterId = topics.find(
      (topic) => topic.chapterTitle === category
    )?.chapterId;

    const url = chapterId
      ? `/event-manual/search?query=${userSearch}&chapter=${category}`
      : `/event-manual/search?query=${userSearch}`;

    dispatch(
      searchManualTopicList({
        userSearch,
        category: chapterId ?? "",
      })
    );

    history.push(url);
  };

  const isExhibitor = useCallback(
    () => !adminToken && user?.role?.type === "EXHIBITOR",
    [adminToken, user?.role?.type]
  );

  const isServiceProvider = useCallback(
    () =>
      !adminToken &&
      (serviceProviderLoggingAsAnExhibitor ||
        user?.role?.type === "SERVICE_PROVIDER"),
    [adminToken, serviceProviderLoggingAsAnExhibitor, user?.role?.type]
  );

  const isExhibitorOrServiceProvider = () => {
    return isExhibitor() || isServiceProvider();
  };

  return (
    <BannerWrapper>
      <HeaderContainer>
        <Logo
          onClick={() => {
            if (isExhibitorOrServiceProvider()) history.push("/checklist");
            else history.push("/dashboard");
          }}
          src={LogoImg}
        />
        <Header isTransparent={true} />
      </HeaderContainer>

      <BannerContainer searchPage={searchPage}>
        <FloatingDot className="top-right" />
        <FloatingDot className="bottom-left" />

        {!searchPage && (
          <IconWrapper>
            <IconUser>
              <FaUsers />
            </IconUser>
          </IconWrapper>
        )}

        {searchPage && (
          <SecondTitle>
            <FormattedMessage id="exhibitor_manual_search" />
          </SecondTitle>
        )}
        {!searchPage && (
          <>
            <EventName>{nameEvent}</EventName>
            <Title>
              {intl.formatMessage({ id: "exhibitors_manual_title" })}
            </Title>
            <Description>
              {intl.formatMessage({ id: "exhibitors_manual_description" })}
            </Description>
          </>
        )}

        <SearchContainer>
          <CategorySelect>
            <SearchSelect
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="all">
                {intl.formatMessage({
                  id: "exhibitors_manual_view_all_categories",
                })}
              </option>
              {topics.map((topic, index) => (
                <option key={`option-${index}`}>{topic.chapterTitle}</option>
              ))}
            </SearchSelect>
          </CategorySelect>

          <SearchInput
            type="text"
            placeholder={intl.formatMessage({
              id: "exhibitors_manual_search_placeholder",
            })}
            value={userSearch}
            onChange={(e) => handleSearch(e.target.value)}
            onKeyDown={(e) => keyToSearch(e)}
          />
          <SearchButton onClick={getSearchResults}>
            <SearchIcon />
          </SearchButton>
        </SearchContainer>

        <IconWrapper>
          <IconShield searchPage={searchPage}>
            <IoShieldCheckmarkSharp />
          </IconShield>
        </IconWrapper>
      </BannerContainer>

      <BackButton to="/checklist">
        <IoArrowBack /> <FormattedMessage id="homepage.portal" />
      </BackButton>
    </BannerWrapper>
  );
};

export default EventManualHeader;

function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        stroke="#fff"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7 13.167A5.667 5.667 0 1 0 7 1.833a5.667 5.667 0 0 0 0 11.334Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.886 5.281A2.66 2.66 0 0 0 7 4.5c-.736 0-1.403.298-1.886.781M11.074 11.574l2.828 2.828"
      />
    </svg>
  );
}
