import { useHistory } from "react-router-dom";

import { useIntl } from "react-intl";
import { ButtonContainer } from "./styles";
import BackArrow from "../../../../newUXAssets/icons/BackArrow";

export default function Button({
  label,
  Icon,
  variant,
  size,
  href,
  onClick,
  ...rest
}) {
  const history = useHistory();
  const intl = useIntl();

  const handleClick = () => {
    if (href) return history.push(href);

    if (onClick) return onClick();

    return;
  };

  return (
    <ButtonContainer
      variant={variant}
      size={size}
      onClick={handleClick}
      {...rest}
    >
      {Icon}
      {intl.formatMessage({ id: label })}
    </ButtonContainer>
  );
}

export function BackButton({ href }) {
  const handleGoBack = () => {
    history.push(href);
  };

  const history = useHistory();
  const intl = useIntl();

  return (
    <Button
      variant={"backButton"}
      size={"backButton"}
      onClick={handleGoBack}
      Icon={<BackArrow size={18} />}
      label={intl.formatMessage({ id: "goback" })}
    />
  );
}
