import React, { useEffect, useRef, useState, useCallback } from "react";
import { useField } from "@unform/core";
import { MdError } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Error,
  RootContainer,
  Label,
  InputRow,
  LabelContainer,
  AsteriskText,
  Tooltip,
} from "./styles";
import InfoTooltip from "../InfoTooltip";
import { useWindowSize } from "../../hooks/dimensions";

const DatePickerWithHorizontalLabel = ({
  name,
  containerStyle = {},
  label,
  labelWidth,
  className,
  maxWidth,
  labelOrientation,
  required,
  tooltip,
  validationBlur,
  disabled,
  ...rest
}) => {
  const [width] = useWindowSize();
  const datePickerRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [showPortalVisualization, setShowPortalVisualization] = useState(false);

  const { fieldName, defaultValue, error, registerField, setValue } =
    useField(name);
  const [date, setDate] = useState(defaultValue || null);

  // Handle window resize for portal visualization
  useEffect(() => {
    if (width <= 500) {
      setShowPortalVisualization(true);
    } else {
      setShowPortalVisualization(false);
    }
  }, [width]);

  // Register the field with Unform
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datePickerRef,
      getValue: (ref) => ref.current?.props.selected || null,
      setValue: (ref, value) => {
        setDate(value ? new Date(value) : null);
      },
      clearValue: (ref) => {
        setDate(null);
      },
    });
  }, [fieldName, registerField]);

  // Update the date when defaultValue changes
  useEffect(() => {
    setDate(defaultValue ? new Date(defaultValue) : null);
  }, [defaultValue]);

  // Handle input blur
  const handleInputBlur = useCallback(() => {
    setIsFilled(!!date);
    if (validationBlur) {
      validationBlur(fieldName);
    }
  }, [date, fieldName, validationBlur]);

  // Handle date change
  const handleDateChange = (value) => {
    setDate(value);
    setValue && setValue(fieldName, value); // Update the form value
  };

  // Handle blur
  const handleBlur = () => {
    setIsFocused(false);
    handleInputBlur();
  };

  return (
    <RootContainer className={className} maxWidth={maxWidth}>
      <InputRow labelOrientation={labelOrientation}>
        <LabelContainer labelWidth={labelWidth}>
          <Label labelWidth={labelWidth} labelOrientation={labelOrientation}>
            {label}
            {required && <AsteriskText>*</AsteriskText>}
          </Label>
          {tooltip && (
            <Tooltip>
              <InfoTooltip {...tooltip} />
            </Tooltip>
          )}
        </LabelContainer>
        <Container
          style={containerStyle}
          isFocused={isFocused}
          isFilled={isFilled}
          isErrored={!!error}
          disabled={disabled}
        >
          <DatePicker
            ref={datePickerRef}
            selected={date}
            onChange={handleDateChange}
            onBlur={handleBlur}
            onFocus={() => setIsFocused(true)}
            disabled={disabled}
            dateFormat="dd/MM/yyyy"
            withPortal={showPortalVisualization}
            popperProps={{ positionFixed: true }}
            {...rest}
          />
        </Container>
      </InputRow>

      {error && (
        <Error title={error}>
          <MdError color="#e00000" size={20} />
          {error}
        </Error>
      )}
    </RootContainer>
  );
};

export default DatePickerWithHorizontalLabel;
