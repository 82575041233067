import { Controller } from "react-hook-form";
import {
  CheckboxContainer,
  Error,
  FormItem,
  InputLabel,
  Required,
} from "./styles";
import { MdError } from "react-icons/md";
import { useIntl } from "react-intl";
import { Checkbox } from "antd";

export default function ControlledCheckbox({
  name,
  control,
  defaultValue,
  label,
  required,
  formState,
}) {
  const intl = useIntl();

  const errorMessage = formState?.errors?.[name]?.message;

  const labelIntl = intl.formatMessage({
    id: label,
  });

  const isRequired = required && <Required>*</Required>;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormItem>
          <CheckboxContainer>
            <Checkbox {...field} />
            <InputLabel htmlFor={name} checkbox>
              {labelIntl}
              {isRequired}
            </InputLabel>
          </CheckboxContainer>
          <ErrorMessage errorMessage={errorMessage} />
        </FormItem>
      )}
    />
  );
}

function ErrorMessage({ errorMessage }) {
  const intl = useIntl();

  if (!errorMessage) return null;

  const getErrorMessage = () => {
    if (!errorMessage) return;
    return intl.formatMessage({
      id: "error.field_required",
    });
  };

  return (
    <Error>
      <MdError color="#DC2626" size={14} />
      {getErrorMessage(errorMessage)}
    </Error>
  );
}
