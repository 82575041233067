import styled, { css } from "styled-components";

export const Container = styled.div`
  height: 72px;
  background-color: #fff;
  top: 0;
  left: 0;
  border-bottom: 1px solid black;
  position: fixed;
  width: calc(100vw - 80px);
  display: flex;
  left: 80px;
  flex-wrap: wrap;
  z-index: 98;
  ${(props) =>
    !props.sidebarIsVisible &&
    css`
      width: 100vw;
      left: 0px;
    `}
`;



export const Navigation = styled.div`
  display: flex;
  align-self: center;
  flex: 1;
  justify-content: space-between;

  ${(props) =>
    props.sidebarIsVisible &&
    css`
      padding-left: 80px;
    `}
`;

export const NavigationText = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

export const NavigationContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const Avatar = styled.img`
  width: 24px;
  border-radius: 50%;
`;

export const UserContainer = styled.div`
  display: flex;
  margin: 0px 24px;

  svg {
    margin-left: 16px;
  }
`;

export const UserNameText = styled.span`
  margin-left: 8px;
`;

export const NotificationContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  svg {
    cursor: pointer;
  }
`;

export const NotificationLabel = styled.span`
  position: absolute;
  top: 10px;
  right: -5px;
  background-color: #cc0000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`;

export const NotificationDropdownContainer = styled.div`
  position: fixed;
  background-color: #fff;
  max-height: 258px;
  width: 320px;
  right: 40px;
  top: 72px;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top: 0px;
  @media (max-width: 540px) {
    right: 0px;
    width: calc(100% - 80px);
    left: 80px;
    ${(props) =>
      !props.sidebarIsVisible &&
      css`
        width: 100%;
        left: 0px;
      `}
  }
`;

export const LanguageContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;

export const SelectedLanguageContainer = styled.div`
  cursor: pointer;
`;

export const SelectedLanguage = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50%;
  margin-left: 16px;
`;

export const LanguageDropdownContainer = styled.div`
  background-color: #fff;
  height: 100px;
  width: 200px;
  right: 40px;
  top: 72px;
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  @media (max-width: 540px) {
    right: 0px;
    width: calc(100% - 80px);
    left: 80px;
    ${(props) =>
      !props.sidebarIsVisible &&
      css`
        width: 100%;
        left: 0px;
      `}
  }
`;

export const Flag = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50%;
  margin: 0px 8px;
`;

export const FlagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px 0;
  border-radius: 16px;

  &:hover {
    background-color: #002244;
    color: #cccccc;
  }
`;

export const BellContainer = styled.div`
  cursor: pointer;
`;

export const FlagText = styled.span`
  max-width: 200px;
  min-width: 100px;
`;

export const NotificationTitle = styled.h3`
  font-size: 14px;
  font-weight: 600;
  margin-top: 16px;
`;

export const NotificationDescription = styled.p`
  font-size: 10px;
  font-weight: normal;
  margin-bottom: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const NotificationItemContainer = styled.div`
  &:hover {
    border-left: 4px solid #11a7d9;
    background-color: #f4fbfd;
  }
  cursor: pointer;
  padding: 0 8px;
  border-left: 4px solid ${({ read }) => (read ? "#ffffff" : "#cc0000")};
`;

export const HideSidebarContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  cursor: pointer;
  justify-content: center;
`;
