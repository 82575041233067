import { useIntl } from "react-intl";
import {
  Label,
  Language,
  LanguagesHeader,
  LegendContainer,
} from "../../styles";
import { IoMdPerson } from "react-icons/io";

import BrazilFlag from "../../../../../newUXAssets/brazil- 1.png";
import USAFlag from "../../../../../newUXAssets/usa 1.png";

export function LanguagesData({ languageData }) {
  const intl = useIntl();
  const label = intl.formatMessage({
    id: "languages",
  });

  return (
    <div>
      <LanguagesHeader>
        <Label>{label}</Label>
        <IoMdPerson size={16} />
      </LanguagesHeader>
      <LegendContainer>
        <LanguageItem value={languageData?.pt ?? 0} type={"PTBR"} />
        <LanguageItem value={languageData?.en ?? 0} type={"ENUS"} />
      </LegendContainer>
    </div>
  );
}

function LanguageItem({ type, value }) {
  const intl = useIntl();

  const languageType = new Map();
  languageType.set("PTBR", {
    src: BrazilFlag,
    code: "PT BR",
    label: "portuguese",
  });
  languageType.set("ENUS", {
    src: USAFlag,
    code: "EN US",
    label: "english",
  });

  const language = languageType.get(type);
  const label = intl.formatMessage({
    id: language.label,
  });

  return (
    <Language>
      <img src={language.src} alt={label} />
      <p>
        {label} - {language.code}
      </p>
      <span>{value}</span>
    </Language>
  );
}
