import React, { useEffect, useContext, useCallback } from "react";
import { Redirect, Switch, useHistory } from "react-router-dom";
import SignIn from "../../pages/SignIn";
import ForgotPassword from "../../pages/ForgotPassword";
import {
  logoutRequest,
  preLogoutRequest,
} from "../../redux/modules/auth/actions";

import { alertReset } from "../../redux/modules/alerts/actions";
import { useToast } from "../../hooks/toast";

import { DialogContext } from "../../hooks/dialog/context";

import { useDispatch, useSelector } from "react-redux";

import NewUXRoute from "./NewUXRoute";
import { useIntl } from "react-intl";

import api from "../../service";

import ExhibitorOpenID from "../../pages/OpenID/Exhibitor";
import ServiceProviderOpenID from "../../pages/OpenID/ServiceProvider";
import StaffOpenID from "../../pages/OpenID/Staff";

import Contact from "../../newUXPages/Contact";
import Faq from "../../newUXPages/Faq";
import MyAccount from "../../newUXPages/MyAccount";
import FormAccountServiceProvider from "../../newUXPages/MyAccount/FormAccountServiceProvider";
import FormAccountExhibitor from "../../newUXPages/MyAccount/FormAccountExhibitor";
import TermsOfUse from "../../newUXPages/MyAccount/TermsOfUse";

import SignInForm from "../../pages/SignIn/Form";

import WelcomeModal from "../../newUXComponents/Modal/WelcomeModal";

import Logout from "../../pages/Logout";

import AuthSSO from "../../pages/AuthSSO";
import { useLoading } from "../../hooks/loading";

import useNotification from "../../hooks/newUXNotification";

import AuthSSOError from "../../pages/AuthSSOError";

import NotFound from "../../newUXPages/NotFound";

import Checklist from "../../newUXPages/Checklist";
import Ecommerce from "../../newUXPages/Ecommerce";
import Associate from "../../newUXPages/Credentials/Associate";
import Register from "../../newUXPages/Credentials/Register";
import Schedule from "../../newUXPages/Credentials/Schedule";
import NewEmployee from "../../newUXPages/Credentials/Register/NewEmployee";
import EditEmployee from "../../newUXPages/Credentials/Register/EditEmployee";
import TransferCredential from "../../newUXPages/Credentials/TransferCredential";
import ViewHistory from "../../newUXPages/Credentials/TransferCredential/ViewHistory";
import AssociateCompany from "../../newUXPages/AssociateCompany";
import CreateAssociateCompany from "../../newUXPages/AssociateCompany/CreateAssociateCompany";
import Contract from "../../newUXPages/Contract";
import Document from "../../newUXPages/Document";
import ImpersonateUser from "../../newUXPages/ImpersonateUser";
import EventManual from "../../newUXPages/EventManual";
import InformaAcademyCourse from "../../newUXPages/InformaAcademy/Course";
import InformaAcademyLesson from "../../newUXPages/InformaAcademy/Lesson";
import PreviewHomepageBanner from "../../newUXPages/HomepageBanner";
import CompleteCourse from "../../newUXPages/InformaAcademy/components/CompleteCourse";
import ArticleList from "../../newUXPages/EventManual/ArticleList";
import Article from "../../newUXPages/EventManual/ArticleList/Article";
import EventManualSearch from "../../newUXPages/EventManual/searchResult";

const Routes = () => {
  const dispatch = useDispatch();
  const notification = useNotification();

  const message = useSelector((state) => state.alerts);
  const { user, token, accountCreated, executeLogout } = useSelector(
    (state) => state.auth
  );

  const { addToast } = useToast();
  const intl = useIntl();
  const history = useHistory();
  const { debouncing, isAccountLoading, handleAccountLoading, handleLoading } =
    useLoading();

  const { createDialog } = useContext(DialogContext);

  const handleModalWelcome = useCallback(() => {
    createDialog({
      id: "modal-welcome",
      Component: WelcomeModal,
      props: {},
    });
  }, [createDialog]);

  useEffect(() => {
    if (message.isDialog) {
      addToast({
        type: message.messageType,
        title: intl.formatMessage({
          id: message.title,
        }),
        description: intl.formatMessage({
          id: message.message,
        }),
      });
      dispatch(alertReset());
    }
  }, [message, addToast, intl, dispatch]);

  useEffect(() => {
    if (executeLogout) {
      dispatch(
        logoutRequest({
          token,
          callbackLoading: handleLoading,
          tokenExpired: true,
          user: user,
        })
      );
    }
  }, [dispatch, executeLogout, handleLoading, token, user]);

  useEffect(() => {
    if (!user && history.location.pathname === "/") {
      const script = document.createElement("script");

      script.src = process.env.REACT_APP_WEBCHAT;
      script.defer = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [user]);

  const UNAUTHORIZED = 401;
  useEffect(() => {
    api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === UNAUTHORIZED) {
          if (isAccountLoading && debouncing) {
            handleAccountLoading(false);
            return Promise.reject(error);
          }
          dispatch(preLogoutRequest());
        }

        return Promise.reject(error);
      }
    );
  }, [
    user,
    history.location.pathname,
    dispatch,
    handleLoading,
    executeLogout,
    token,
    isAccountLoading,
    debouncing,
    handleAccountLoading,
  ]);

  useEffect(() => {
    if (user && user?.role?.type === "SERVICE_PROVIDER") {
      if (!accountCreated) {
        if (history.location.pathname !== "/logout") {
          handleModalWelcome();
        }
      }
    }

    return history.listen((location) => {
      if (
        location.pathname !== "/user/update-user-service-provider" &&
        location.pathname !== "/" &&
        location.pathname !== "/logout"
      ) {
        if (user) {
          if (user?.role?.type === "SERVICE_PROVIDER") {
            if (!accountCreated) {
              notification.displayInfo(
                intl.formatMessage({
                  id: "complete_your_data",
                }),
                "complete_your_data"
              );

              history.push("/user/update-user-service-provider");
            }
          }
        }
      }
    });
  }, [user, accountCreated, history, addToast, intl, dispatch, token]);

  return (
    <Switch>
      <NewUXRoute path="/" exact component={SignIn} />
      <NewUXRoute path="/signin-form" exact component={SignInForm} />
      <NewUXRoute path="/logout" component={Logout} isPrivate />
      <NewUXRoute path="/forgot-password" component={ForgotPassword} />
      <NewUXRoute
        path="/auth/exhibitor-openid-signin"
        exact
        component={ExhibitorOpenID}
      />
      <NewUXRoute
        path="/auth/3rdparth-openid-signin"
        exact
        component={ServiceProviderOpenID}
      />
      <NewUXRoute
        path="/auth/staff-openid-signin"
        exact
        component={StaffOpenID}
      />
      <NewUXRoute path="/auth/informa-sso-signin" exact component={AuthSSO} />
      <NewUXRoute
        path="/auth/informa-sso-error"
        exact
        component={AuthSSOError}
      />

      <NewUXRoute
        path="/user/view"
        code="PROFILE_INFORMATION"
        component={MyAccount}
        isPrivate
      />
      <NewUXRoute
        path="/user/terms-of-use"
        code="ACCESS_TERMS_OF_USE"
        component={TermsOfUse}
        isPrivate
      />
      <NewUXRoute
        path="/user/update-user-service-provider"
        component={FormAccountServiceProvider}
        code="PROFILE"
        exact
        isPrivate
      />
      <NewUXRoute
        path="/user/update-user-exhibitor"
        code="EDIT_EXHIBITOR_PROFILE_INFORMATION"
        component={FormAccountExhibitor}
        exact
        isPrivate
      />

      <NewUXRoute
        path="/contract"
        code="CONTRACT"
        component={Contract}
        isPrivate
      />
      <NewUXRoute path="/faq" code="FAQ" component={Faq} isPrivate />
      <NewUXRoute
        path="/contact"
        code="CONTACT"
        component={Contact}
        isPrivate
      />
      <NewUXRoute
        path="/checklist"
        code="SHOW_CHECKLIST"
        component={Checklist}
        isPrivate
      />

      <NewUXRoute
        path="/informa-academy"
        code="EXHIBITOR_ACADEMY"
        component={PreviewHomepageBanner}
        exact
        isPrivate
      />

      <NewUXRoute
        path="/informa-academy/course/:courseId"
        code="EXHIBITOR_ACADEMY"
        exact
        component={InformaAcademyCourse}
        isPrivate
      />

      <NewUXRoute
        path="/informa-academy/course/:courseId/complete"
        code="EXHIBITOR_ACADEMY"
        exact
        component={CompleteCourse}
        isPrivate
      />
      <NewUXRoute
        path="/informa-academy/course/:courseId/lesson/:lessonId"
        code="EXHIBITOR_ACADEMY"
        exact
        component={InformaAcademyLesson}
        isPrivate
      />
      <NewUXRoute path="/dashboard" component={NotFound} isPrivate />
      <NewUXRoute
        path="/ecommerce"
        code="ECOMMERCE"
        component={Ecommerce}
        isPrivate
      />

      <NewUXRoute
        path="/credentials/associate"
        component={Associate}
        code="MANAGE_CREDENTIALS"
        isPrivate
      />
      <NewUXRoute
        path="/credentials/register"
        code="MANAGE_ASSOCIATE_COLLABORATOR"
        component={Register}
        isPrivate
        exact
      />
      <NewUXRoute
        path="/credentials/register/new"
        code="MANAGE_ASSOCIATE_COLLABORATOR"
        component={NewEmployee}
        isPrivate
        exact
      />
      <NewUXRoute
        path="/credentials/register/edit"
        code="MANAGE_ASSOCIATE_COLLABORATOR"
        component={EditEmployee}
        isPrivate
        exact
      />
      <NewUXRoute
        path="/credentials/schedule"
        component={Schedule}
        code="MANAGE_CREDENTIALS"
        isPrivate
      />
      <NewUXRoute
        path="/credentials/transfer"
        code="TRANSFER_CREDENTIALS"
        component={TransferCredential}
        isPrivate
      />
      <NewUXRoute
        path="/credentials/transfer-history"
        component={ViewHistory}
        code="TRANSFER_CREDENTIALS"
        isPrivate
      />
      <NewUXRoute
        path="/associate-company/view"
        code="SERVICE_PROVIDERS"
        component={AssociateCompany}
        isPrivate
      />
      <NewUXRoute
        path="/associate-company/new"
        code="SERVICE_PROVIDERS"
        component={CreateAssociateCompany}
        isPrivate
      />
      <NewUXRoute
        path="/document"
        code="DOWNLOAD_DOCUMENTS"
        component={Document}
        isPrivate
      />

      <NewUXRoute
        path="/accounts-users"
        code="ADMIN"
        component={ImpersonateUser}
        isPrivate
      />

      <NewUXRoute
        path="/event-manual"
        code="ACCESS_EXHIBITOR_MANUAL"
        component={EventManual}
        exact
        isPrivate
      />

      <NewUXRoute
        path="/event-manual/chapter/:chapterId"
        code="ACCESS_EXHIBITOR_MANUAL"
        component={ArticleList}
        exact
        isPrivate
      />

      <NewUXRoute
        path="/event-manual/chapter/:chapterId/article/:articleId"
        code="ACCESS_EXHIBITOR_MANUAL"
        component={Article}
        exact
        isPrivate
      />
      <NewUXRoute
        path="/event-manual/search"
        code="ACCESS_EXHIBITOR_MANUAL"
        component={EventManualSearch}
        exact
        isPrivate
      />

      <NewUXRoute
        path="/not-found"
        code="NOT_FOUND"
        isPrivate
        component={NotFound}
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
