import api from ".";
import generateHeaders from "../utils/generateHeaders";

export const updateManualStatusService = async ({
  token,
  manualId,
  status,
}) => {
  const headers = generateHeaders(token);

  return await api.patch(`/manual/${manualId}?status=${status}`, headers);
};

export const listManualsService = async ({
  token,
  query,
  page,
  size,
  sortBy = "id",
  sortDirection = "ASC",
}) => {
  const headers = generateHeaders(token);

  const params = {
    title: query,
    page,
    size,
    sortBy,
    sortDirection,
  };
  const options = {
    ...headers,
    params,
  };

  return await api.get("/manual/search", options);
};

export const listArticlesService = async ({
  token,
  manualId,
  query,
  page,
  size,
  sortBy = "id",
  sortDirection = "ASC",
}) => {
  const headers = generateHeaders(token);
  const options = {
    ...headers,
    params: {
      title: query,
      page,
      size,
      sortBy,
      sortDirection,
    },
  };

  return await api.get(`/articles/manual/${manualId}/search`, options);
};

export const getArticlesTotalCounterService = async ({ token, manualId }) => {
  const options = generateHeaders(token);

  return await api.get(`/articles/manual/${manualId}/totalCount`, options);
};

export const listChaptersService = async ({ token, manualId }) => {
  const options = generateHeaders(token);

  return await api.get(`/chapters/manual/${manualId}`, options);
};

export const getArticleService = async ({ token, articleId }) => {
  const options = generateHeaders(token);

  return await api.get(`/articles/${articleId}`, options);
};

export const listFAQService = async ({ token, manualId }) => {
  const options = generateHeaders(token);

  return await api.get(`/manual/${manualId}/faq`, options);
};
