const SET_PREVIEW_BANNER = "@homepageBanner/SET_PREVIEW_BANNER";
const SET_PREVIEW_COURSE = "@homepageBanner/SET_PREVIEW_COURSE";
const REMOVE_BANNER = "@homepageBanner/REMOVE_BANNER";
const SET_IS_EDIT = "@homepageBanner/SET_IS_EDIT";

const INITIAL_STATE = {
  previewBanner: [],
  previewCourses: [],
  isEdit: false,
};

const homepageBanner = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PREVIEW_BANNER:
      return {
        ...state,
        previewBanner: action.payload,
      };

    case SET_PREVIEW_COURSE:
      return {
        ...state,
        previewCourses: action.payload,
      };

    case REMOVE_BANNER:
      return {
        ...state,
        previewBanner: state.previewBanner.filter(
          (_, i) => i !== action.payload
        ),
      };

    case SET_IS_EDIT:
      return {
        ...state,
        isEdit: action.payload,
      };

    default:
      return state;
  }
};

export default homepageBanner;
