import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "../../../InformaAcademy/components/button";
import ControlledInput from "../../../../components/ControlledInputs/input";
import { FormRow } from "../../../../components/ControlledInputs/styles";
import { HeaderContainer } from "./styles";
import Save from "../../../../icons/Save";
import BackIcon from "../../../../icons/Back";
import { useSelector } from "react-redux";
import api from "../../../../service";
import { useToast } from "../../../../hooks/toast";
import { reorderChapters } from "./chapter-form";

const schema = z.object({
  order: z.coerce.number().optional(),
  chapterTitle: z.string().min(1).max(150, {
    message: "error.max_length-150",
  }),
});

export default function EditChapterForm({
  chapters,
  setChapters,
  defaultValue,
  resetModal,
}) {
  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: defaultValue,
  });
  const { control, handleSubmit, formState, reset } = form;
  const intl = useIntl();
  const { token } = useSelector((state) => state.auth);
  const { addToast } = useToast();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleReset = () => {
    reset();
    resetModal();
  };

  const updateInDb = async ({ chapterTitle, order }) => {
    try {
      const body = JSON.stringify({ chapterTitle, order });
      await api.put(`/chapters/${defaultValue.id}`, body, config);

      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "success",
        }),
        description: intl.formatMessage({
          id: "success.chapter_update",
        }),
      });
    } catch (error) {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error.chapter_update",
        }),
      });
    }
  };

  const onSubmit = async ({ chapterTitle, order }) => {
    const updatedChapter = {
      chapterTitle,
      order: order ?? 99_999,
    };

    if (defaultValue) {
      const findIndex = chapters.findIndex(
        (chapter) => chapter.id === defaultValue.id
      );
      if (findIndex !== -1) chapters.splice(findIndex, 1);

      await updateInDb(updatedChapter);
    }

    if (defaultValue.id) {
      await updateInDb(updatedChapter);
    }

    const reorderedChapters = reorderChapters(chapters, updatedChapter);
    setChapters(reorderedChapters);
    handleReset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} key={Math.random()}>
      <HeaderContainer>
        <p>
          <FormattedMessage id={"manual_chapter_edit"} />
        </p>
      </HeaderContainer>
      <FormRow marginBottom={"24px"} manual>
        <ControlledInput
          name={"chapterTitle"}
          control={control}
          formState={formState}
          label={"manual_chapter_title"}
          required={true}
          max={150}
          description={"course_name_sub"}
        />
        <ControlledInput
          name={"order"}
          control={control}
          formState={formState}
          label={"lesson_order"}
          type="number"
          min={1}
          width={"200px"}
        />
      </FormRow>
      <FormRow buttons>
        <Button
          label={"goback"}
          variant={"secondary"}
          Icon={<BackIcon />}
          type={"button"}
          onClick={() => {
            handleReset();
          }}
        />
        <Button label={"save"} Icon={<Save />} />
      </FormRow>
    </form>
  );
}
