import { Controller, useWatch } from "react-hook-form";
import {
  Description,
  Error,
  FormItem,
  Textarea,
  InputLabel,
  Required,
  InputLengthCounter,
} from "./styles";
import { MdError } from "react-icons/md";
import { useIntl } from "react-intl";

export default function ControlledTextareaInput({
  name,
  control,
  defaultValue,
  type = "text",
  disabled = false,
  onChange,
  min,
  max,
  placeholder,
  step,
  label,
  required,
  description,
  formState,
  arrayName,
  ...props
}) {
  const intl = useIntl();

  const errorMessage = formState?.errors?.[name]?.message;

  const labelIntl = intl.formatMessage({
    id: label,
  });

   const watch = useWatch({ control, name });

  const isRequired = required && <Required>*</Required>;
  const hasDesc = description && (
    <Description>
      (
      {intl.formatMessage({
        id: description,
      })}
      )
    </Description>
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormItem width={props.width}>
          <InputLabel htmlFor={name}>
            {labelIntl}
            {isRequired} {hasDesc}
          </InputLabel>
          <Textarea
            {...field}
            {...props}
            id={name}
            type={type}
            disabled={disabled}
            min={min}
            max={max}
            step={step}
            placeholder={placeholder}
            error={errorMessage}
            onChange={(e) => {
              if (onChange) return onChange(e);

              if (max) {
                const newValue = e.target.value;
                const cleanValue = newValue.replace(/<[^>]*>/g, "");

                if (cleanValue.length > max) {
                  return;
                }
              }
              field.onChange(e);
            }}
          />
          {arrayName ? (
            <GetArrayErrorMessage
              errors={formState.errors}
              arrayName={arrayName}
              name={name}
            />
          ) : (
            <ErrorMessage errorMessage={errorMessage} />
          )}
          {max && (
            <InputLengthCounter>
              {watch ? watch.replace(/<[^>]*>/g, "").length : 0} / {max}{" "}
              {intl.formatMessage({ id: "characters" })}
            </InputLengthCounter>
          )}
        </FormItem>
      )}
    />
  );
}

function ErrorMessage({ errorMessage }) {
  const intl = useIntl();

  if (!errorMessage) return null;

  const getErrorMessage = () => {
    if (!errorMessage) return;
    switch (true) {
      case errorMessage.includes("error.max_length"):
      case errorMessage.includes("error.min_length"): {
        const splited = errorMessage.split("-");
        const id = splited[0];
        const length = splited[1];
        return intl.formatMessage(
          {
            id,
          },
          {
            length,
          }
        );
      }
      case errorMessage.includes("invalid_workload"): {
        return intl.formatMessage({
          id: "invalid_workload",
        });
      }
      case errorMessage.includes("informa_academy_invalid_lesson_video_link"): {
        return intl.formatMessage({
          id: "informa_academy_invalid_lesson_video_link",
        });
      }
      default: {
        return intl.formatMessage({
          id: "error.field_required",
        });
      }
    }
  };

  return (
    <Error>
      <MdError color="#DC2626" size={14} />
      {getErrorMessage(errorMessage)}
    </Error>
  );
}

function GetArrayErrorMessage({ errors, arrayName, name }) {
  const intl = useIntl();

  let errorMessage = null;

  if (arrayName) {
    const [_, index, field] = name.split(".");
    const message = errors[arrayName]?.[index]?.[field]?.message;

    if (message && message.includes("error.field_required")) {
      errorMessage = intl.formatMessage({
        id: "error.field_required",
      });
    }

    if (message && message.includes("error.valid_url")) {
      errorMessage = intl.formatMessage({
        id: "error.valid_url",
      });
    }
  }

  if (!errorMessage) return null;

  return (
    <Error>
      <MdError color="#DC2626" size={14} />
      {errorMessage}
    </Error>
  );
}
