import styled, { keyframes } from "styled-components";

const skeletonLoading = keyframes`
0% {
    background-position: 100% 50%;
}

100% {
    background-position: 0 50%;
}
`;

export const SkeletonDiv = styled.div`
  --gradient-from-color: rgba(0, 0, 0, 0.06);
  --gradient-to-color: rgba(0, 0, 0, 0.15);

  width: ${({ width }) => width ?? "100%"};
  border-radius: ${({ borderRadius }) => borderRadius ?? "6px"};
  height: ${({ height }) => height ?? "24px"};
  margin-bottom: ${({ mb }) => mb ?? "0"};
  margin-top: ${({ mt }) => mt ?? "0"};

  background: linear-gradient(
    90deg,
    var(--gradient-from-color) 25%,
    var(--gradient-to-color) 37%,
    var(--gradient-from-color) 63%
  );
  background-size: 400% 100%;
  animation-name: ${skeletonLoading};
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
`;

export const SkeletonContainer = styled.div`
  margin: ${({ margin }) => margin ?? "0"};
`;
