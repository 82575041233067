import * as React from "react";

const Analytics = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    fill="none"
    viewBox="0 0 18 19"
  >
    <path
      stroke="#002244"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M6.13 2.58A7.51 7.51 0 0 0 1.5 9.51a7.5 7.5 0 0 0 14.43 2.87"
    ></path>
    <path
      stroke="#002244"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M9 9.51h7.5A7.5 7.5 0 0 0 9 2.01z"
    ></path>
  </svg>
);

export default Analytics;
