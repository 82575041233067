import styled, { css } from "styled-components";

export const FormItem = styled.div`
  ${(props) =>
    props.width &&
    css`
      max-width: ${props.width};
    `}

  ${(props) =>
    props.maxWidth &&
    css`
      & .jodit-workplace {
        width: calc(${props.maxWidth}px - 148px) !important;
      }
    `}

    ${(props) =>
    props.split &&
    props.maxWidth &&
    css`
      & .jodit-workplace {
        width: calc(596px - 24px) !important;
      }
    `}

    ${(props) =>
    props.splitColumn &&
    props.maxWidth &&
    css`
      & .jodit-workplace {
        width: 100% !important;
      }
      max-width: 49.7% !important;
    `}

  & .react-datepicker-wrapper {
    width: 100%;

    & input {
      width: 100%;
      background: transparent;
      max-height: 48px;
      border-radius: 5px;
      border: 1px solid #dadada;
      padding: 16.8px 12px;
      width: 100%;
      color: rgba(0, 0, 0, 0.85);
      transition: all 300ms;
      ${(props) =>
        props.error &&
        css`
          border-color: #c53030;
        `}
    }
  }

  .jodit-placeholder {
    ${(props) =>
      props.hidePlaceholder &&
      css`
        display: none !important;
      `}
  }

  .jodit-container {
    ${(props) =>
      props.error &&
      css`
        border-color: #c53030;
      `}
  }

  .jodit-ui-group {
    height: 42px;
    align-items: center;
  }

  .jodit-toolbar-editor-collection_mode_horizontal {
    background-image: none;
  }

  & .jodit-toolbar__box {
    background-color: #fff;
  }

  .jodit-toolbar-editor-collection_mode_horizontal::after {
    height: 0px;
  }

  .jodit-toolbar-button_link {
    padding: 0 4px;
    display: block;
    border-left: 1px solid #e2e8f0;
    border-right: 1px solid #e2e8f0;
  }

  .jodit-toolbar-button {
    margin: 0;
  }

  & .jodit-toolbar-button__button,
  .jodit-toolbar-button.jodit-toolbar-button_size_middle.jodit-toolbar-button_variant_initial.jodit-toolbar-button_bold.jodit-ui-group__bold {
    max-width: 27px;
    min-width: 27px;
    height: 27px;
  }

  & .jodit-toolbar-button__icon > svg {
    /* min-width: 8px;
    height: 27px; */
    stroke-width: 1%;
    /* color: "#585F74";
    fill: "#585F74"; */
  }

  .jodit-icon {
    stroke: "#585F74";
    fill: none;
  }

  .jodit-toolbar-button_image,
  .jodit-toolbar-button_video,
  .jodit-toolbar-button_table {
    width: 48px;
  }

  .jodit-toolbar-button_image span,
  .jodit-toolbar-button_video span,
  .jodit-toolbar-button_table svg {
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
  }

  .jodit-toolbar-button_paragraph > button svg,
  .jodit-toolbar-button_fontsize > button svg {
    width: 24px;
    height: 24px;
  }

  & .jodit-toolbar-button_image svg {
    stroke-width: 4%;
    width: 20px;
    height: 20px;
  }

  & .jodit-toolbar-button_image svg > path {
    stroke: "#585F74";
    fill: "#585F74" !important;
  }

  & .jodit-toolbar-button_video svg {
    width: 20px;
    height: 20px;
  }

  .jodit-status-bar,
  .jodit-add-new-line {
    display: none;
  }

  .jodit-wysiwyg p {
    margin-bottom: 0;
  }

  .jodit-ui-separator {
    height: 42px;
    margin: 0px 2px;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Input = styled.input`
  background: transparent;
  max-height: 48px;
  border-radius: 5px;
  border: 1px solid #dadada;
  padding: 16.8px 12px;
  width: 100%;
  color: rgba(0, 0, 0, 0.85);
  transition: all 300ms;
  ${(props) =>
    props.error &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.disabled &&
    css`
      background-color: #e4e4e4;
      color: #a6a6a6d9;
      cursor: not-allowed;
    `}
`;

export const Textarea = styled.textarea`
  background: transparent;
  height: 82px;
  max-height: 164px;
  min-height: 82px;
  border-radius: 5px;
  border: 1px solid #dadada;
  padding: 16.8px 12px;
  width: 100%;
  color: rgba(0, 0, 0, 0.85);
  transition: all 300ms;
  ${(props) =>
    props.error &&
    css`
      border-color: #c53030;
    `}
`;

export const Required = styled.span`
  color: #dc2626;
`;

export const InputLabel = styled.label`
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
  font-weight: 600;
  line-height: 18.86px;
  margin-bottom: 8px;
  display: block;

  ${(props) =>
    props.subtitle &&
    css`
      margin-bottom: 0px;
    `}

  ${(props) =>
    props.checkbox &&
    css`
      margin-bottom: 0px;
      font-size: 14px;
      line-height: 22px;
      color: #1e293b;
    `}
`;

export const Description = styled.span`
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: 600;
  line-height: 18.86px;

  ${(props) =>
    props.subtitle &&
    css`
      font-weight: 400;
      line-height: 17px;
      display: block;
      color: #585f74;
      margin-bottom: 8px;
    `}
`;

export const Error = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #dc2626;
  font-weight: 600;
  font-size: 10px;
  line-height: 18.86px;
`;

export const FormRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "0px"};
  z-index: ${(props) => (props.zIndex ? props.zIndex : "unset")};
  position: relative;

  & > div {
    flex: 1;
  }

  ${(props) =>
    props.buttons &&
    css`
      justify-content: flex-end;
    `}

  ${(props) =>
    props.manual &&
    css`
      align-items: flex-start;

      button {
        margin-top: 26.86px;
        height: 48px;
      }
    `}
`;

export const InputContainer = styled.div`
  background: transparent;
  border-radius: 5px;
  padding: 8px 16px;
  color: #dadada;
  display: flex;
  align-items: center;
`;

export const DropzoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e7e4e4;
  height: 248px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  padding: 0px;
  transition: all 300ms;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  ${(props) =>
    props.isDragActive &&
    css`
      border-style: dashed;
      border-color: black;
      cursor: default;
      background: rgba(0, 0, 0, 0.2);
    `}

  ${(props) =>
    props.error &&
    css`
      border-color: #dc2626;
    `}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;
      background-color: #f2f2f2;
      cursor: default;
    `}
`;

export const PreviewImage = styled.img`
  width: 140px;
  height: 93px;
  object-fit: cover;
  aspect-ratio: 16/9;
  border-radius: 8px;
  border: 1px solid #ebebf0;
`;

export const PreviewImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e7e4e4;
  height: 248px;
  border-radius: 5px;
  width: 100%;
`;

export const PreviewImageContent = styled.div`
  display: flex;
  gap: 13px;
  padding: 24px;
  border-radius: 5px;
  border: 1px solid #dde5e9;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const PreviewImageContentRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PreviewImageName = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  color: #444444;
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
`;

export const PreviewImageSize = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  color: #6c6c6c;
`;

export const RemoveButtonPreview = styled.button`
  font-family: "Open Sans";
  background: none;
  border: none;
  color: #c53030;
  font-size: 16px;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
  align-self: flex-start;
  line-height: 21.79px;
`;

export const TextareaContainer = styled.div`
  background: transparent;
  border-radius: 5px;
  border: 1px solid #dadada;

  ${(props) =>
    props.height &&
    css`
      height: ${props.height}px;
    `}

  ${(props) =>
    props.error &&
    css`
      border: 1px solid #dc2626;
    `}

  & > .rdw-editor-wrapper {
    width: 100%;
    height: 100%;
    overflow-x: auto;
  }

  & .rdw-editor-toolbar {
    padding: 0 !important;
    margin: 0 !important;
    height: 42px;
    border-bottom: 1px solid rgba(134, 144, 160, 0.4) !important;
    align-items: center;

    & .rdw-link-modal {
      background: #fff;
    }

    & > div {
      margin-bottom: 0px;
    }

    .rdw-inline-wrapper {
      height: 27px;
      border-right: 1px solid #e2e8f0;
      gap: 4px;
      padding: 0px 8px 0px 8px;
    }
    .rdw-inline-wrapper > div,
    .rdw-link-wrapper > div:first-child,
    .rdw-list-wrapper > div {
      height: 27px;
      min-width: 27px;
      padding: 0px;
      margin: 0px;
    }

    & .rdw-block-wrapper {
      height: 100%;
      margin-right: 8px;
    }

    & .rdw-block-dropdown {
      height: 100%;
      width: ${(props) => (props.size === "sm" ? "131px" : `231px`)};
      margin: 0;
      border: 0;
      border-right: 1px solid #e2e8f0;

      & .rdw-dropdown-selectedtext {
        color: #585f74;
        font-family: "Inter";
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
      }

      & .rdw-dropdown-carettoopen {
        border: 0;
        height: 12px;
        top: 45%;
        right: 24px;

        &::before,
        &::after {
          position: absolute;
          display: block;
          content: "";
          border: 6px solid transparent;
        }

        &::before {
          top: 0;
          border-top-color: #585f74;
        }
        &::after {
          top: -2px;
          border-top-color: #fff;
        }
      }

      & .rdw-dropdown-carettoclose {
        border: 0;
        height: 12px;
        top: 25%;
        right: 24px;

        &::before,
        &::after {
          position: absolute;
          display: block;
          content: "";
          border: 6px solid transparent;
        }

        &::before {
          bottom: 0;
          border-bottom-color: #585f74;
        }
        &::after {
          bottom: -3px;
          border-bottom-color: #fff;
        }
      }
    }

    .rdw-link-wrapper > div:first-child {
      margin: 0px 8px 0px 8px;
      padding: 0px;
    }

    .rdw-list-wrapper > div:first-child {
      order: 1;
    }

    .rdw-fontsize-wrapper .rdw-fontsize-dropdown {
      width: 54px;
      border-color: #cbd5e1;
      border-radius: 4px;
    }

    .rdw-fontsize-dropdown .rdw-dropdown-selectedtext {
      font-family: "Inter";
      font-weight: 400;
      font-size: 12px;
      line-height: 21px;
      color: black;

      & .rdw-dropdown-carettoopen {
        border: 0;
        height: 8px;
        top: 40%;
        right: 12px;

        &::before,
        &::after {
          position: absolute;
          display: block;
          content: "";
          border: 4px solid transparent;
        }

        &::before {
          top: 0;
          border-top-color: #333;
        }
        &::after {
          top: -2px;
          border-top-color: #fff;
        }
      }

      & .rdw-dropdown-carettoclose {
        border: 0;
        height: 8px;
        top: 25%;
        right: 12px;

        &::before,
        &::after {
          position: absolute;
          display: block;
          content: "";
          border: 4px solid transparent;
        }

        &::before {
          bottom: 0;
          border-bottom-color: #333;
        }
        &::after {
          bottom: -3px;
          border-bottom-color: #fff;
        }
      }
    }
  }

  & .rdw-editor-main {
    padding: 12px;
    min-height: 200px;
    cursor: text;
  }

  & .public-DraftStyleDefault-block {
    margin: 0;

    ${(props) =>
      props.width &&
      css`
        width: calc(${props.width}px - 175px);
      `}

    ${(props) =>
      props.split &&
      props.width &&
      css`
        width: calc(${props.width / 2}px - 125px);
      `}
  }

  & .rdw-option-wrapper {
    border: none;
  }

  & .rdw-option-wrapper:hover {
    box-shadow: unset;
  }

  & .rdw-list-wrapper {
    gap: 4px;
    border-left: 1px solid #e2e8f0;
    position: relative;
    padding: 0px 8px 0px 8px;
    & > div {
      margin: 0;
    }

    &::after {
      content: "";
      position: absolute;
      top: -6px;
      right: 0;
      height: 42px;
      width: 1px;
      background: #e2e8f0;
    }
  }
`;

export const InputLengthCounter = styled.p`
  color: #6c6c6c !important;
  font-size: 12px !important;
  padding: 4px 0 !important;
  transition: all 300ms;
  font-weight: 400 !important;
  ${(props) =>
    props.overflowed &&
    css`
      .current {
        font-weight: 800 !important;
        color: #dc2626 !important;
      }
    `};
`;

export const Subtitle = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: #585f74;
  margin: 0;
  display: block;
  margin-bottom: 8px;
`;

export const DropLabel = styled.p`
  font-family: "Inter";
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  margin-bottom: 4px;
  margin-top: 8px;
  color: #3a3a3c;
`;

export const ClickLabel = styled.p`
  font-family: "Inter";
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #11a7d9;
  border-bottom: 1px solid #11a7d9;
`;

export const ButtonFileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & > span {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;
    color: #334155;
  }
`;

export const SelectedFile = styled.div`
  border-radius: 4px;
  padding: 4px 8px;
  color: #3a3a3c;
  background-color: #ebebf0;
  max-width: 155px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  & > p {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 18.86px;
    text-overflow: ellipsis;
    max-width: 120px;
    overflow: hidden;
    white-space: nowrap;
  }

  & > button {
    border: 0;
    cursor: pointer;
    width: 16px;
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: center;
    height: 16px;
  }
`;

export const SelectedFilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  // grid-template-columns: repeat(3, minmax(auto, 155px));
  gap: 8px;
  margin-top: 8px;
`;
