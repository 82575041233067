import DataTable from "react-data-table-component";
import Empty from "../Empty";
import { ButtonsContainer, Container, customStyles, Truncate } from "./styles";
import { FaRegTrashAlt as TrashIcon } from "react-icons/fa";
import { AiOutlineEdit as EditIcon } from "react-icons/ai";

import { BiChevronUp } from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "../button";
import { useSelector } from "react-redux";
import { useToast } from "../../../../hooks/toast";
import { useCallback, useMemo, useState } from "react";
import api from "../../../../service";
import { Modal } from "antd";
import { ModalButtonsWrapper, ModalSubtitle, ModalTitle } from "../../styles";
import BackIcon from "../../../../icons/Back";
import StatusBadge from "./status-badge";
import moment from "moment";

function CoursesTable({
  refecthOnDelete,
  setCourses,
  data,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  totalRows,
  setTotalRows,
  setSortBy,
  setSortDirection,
}) {
  const intl = useIntl();
  const paginationOptions = {
    rowsPerPageText: intl.formatMessage({ id: "rows_per_page" }),
    rangeSeparatorText: intl.formatMessage({ id: "of" }),
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: intl.formatMessage({ id: "all" }),
    disableNext: currentPage * rowsPerPage >= totalRows,
  };

  const removeCourse = useCallback(
    (row) => {
      const updatedCourses = data.filter((course) => course !== row);
      setCourses(updatedCourses);
      setTotalRows((prev) => {
        if (prev.length - 1 === 0 && currentPage - 1 !== 0) {
          refecthOnDelete(currentPage - 1);
        } else {
          refecthOnDelete(currentPage);
        }

        return prev - 1;
      });
    },
    [currentPage, data, refecthOnDelete, setCourses, setTotalRows]
  );

  const columns = useMemo(() => {
    return [
      {
        id: "id",
        name: "ID",
        selector: (row) => (
          <Truncate>{row.id.toString().padStart(4, "0")}</Truncate>
        ),
        sortable: true,
        width: "100px",
      },
      {
        id: "name",
        name: <Title title={"name"} />,
        selector: (row) => <Truncate title={row.name}>{row.name}</Truncate>,
        sortable: true,
        maxWidth: "100%",
        minWidth: "100px",
      },
      {
        id: "category",
        name: <Title title={"category"} />,
        selector: (row) => row.category,
        sortable: true,
        maxWidth: "199px",
      },
      {
        id: "accessEndDate",
        name: "Status",
        selector: (row) => {
          const initialDate = row.accessStartDate;
          const finalDate = row?.accessEndDate;
          const status = parseDateToStatus(initialDate, finalDate);

          return <StatusBadge label={status} />;
        },
        sortable: true,
        sortType: (rowA, rowB) => {
          const statusA = intl.formatMessage({
            id: parseDateToStatus(rowA.accessStartDate, rowA?.accessEndDate),
          });
          const statusB = intl.formatMessage({
            id: parseDateToStatus(rowB.accessStartDate, rowB?.accessEndDate),
          });

          return statusA > statusB ? 1 : -1;
        },
        maxWidth: "187px",
      },
      {
        maxWidth: "292px",
        selector: (row) => {
          return (
            <ActionRow
              row={row}
              removeCourse={removeCourse}
              refecthOnDelete={refecthOnDelete}
            />
          );
        },
      },
    ];
  }, [intl, refecthOnDelete, removeCourse]);

  return (
    <Container>
      <DataTable
        data={data}
        responsive
        noDataComponent={<Empty />}
        columns={columns}
        customStyles={customStyles}
        sortIcon={<BiChevronUp style={{ display: "flex" }} />}
        paginationComponentOptions={paginationOptions}
        persistTableHead
        pagination
        paginationServer
        paginationPerPage={rowsPerPage}
        onChangePage={(page) => setCurrentPage(page)}
        onChangeRowsPerPage={(size) => {
          setCurrentPage(1);
          setRowsPerPage(size);
        }}
        paginationTotalRows={totalRows}
        onSort={(column, sortDirection) => {
          setSortBy(column.id);
          setSortDirection(sortDirection);
        }}
      />
    </Container>
  );
}

export default CoursesTable;

function Title({ title }) {
  return <FormattedMessage id={title} />;
}

const parseDateToStatus = (startDate, finalDate) => {
  const startCourseDate = new Date(moment(startDate));
  const endCourseDate = finalDate ? new Date(moment(finalDate)) : undefined;
  const today = moment(new Date());

  if (endCourseDate === undefined) {
    return +startCourseDate > +today ? "waiting" : "published";
  }

  if (+startCourseDate > +today) return "waiting";

  if (+startCourseDate <= +today && typeof finalDate === "undefined")
    return "published";

  if (+endCourseDate < +today) return "expired";

  if (+startCourseDate <= +today && +endCourseDate >= +today)
    return "published";

  return "error";
};

function ActionRow({ row, removeCourse }) {
  const [modalOpen, setModalOpen] = useState(false);
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);
  const intl = useIntl();

  const handleDelete = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      await api.delete(`/informa-academy/course/${row.id}`, config);
      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "success.course_deleted",
        }),
      });
      setModalOpen(false);
      removeCourse(row);
    } catch (err) {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error.course_deleted",
        }),
      });
    }
  };

  return (
    <ButtonsContainer>
      <Button
        label={"edit"}
        size={"sm"}
        variant={"secondary"}
        Icon={<EditIcon size={16} />}
        href={`/admin/informa-academy/courses/${row.id}`}
      />
      <Button
        label={"delete"}
        size={"sm"}
        variant={"destructive"}
        Icon={<TrashIcon size={16} />}
        onClick={() => setModalOpen(true)}
      />
      <Modal
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        width={"592px"}
        footer={""}
        closable={false}
        className="modalLesson"
      >
        <ModalTitle>
          {intl.formatMessage(
            {
              id: "delete_course_title",
            },
            {
              course: `"${row.name}"`,
            }
          )}
        </ModalTitle>
        <ModalSubtitle>
          {intl.formatMessage({
            id: "delete_course_sub",
          })}
        </ModalSubtitle>
        <ModalSubtitle bold>
          {intl.formatMessage({
            id: "irreversible_action",
          })}
        </ModalSubtitle>
        <ModalButtonsWrapper>
          <Button
            label={"goback"}
            variant={"secondary"}
            Icon={<BackIcon size={16} />}
            onClick={() => setModalOpen(false)}
          />
          <Button
            label={"delete"}
            variant={"destructive2"}
            Icon={<TrashIcon size={16} />}
            onClick={handleDelete}
          />
        </ModalButtonsWrapper>
      </Modal>
    </ButtonsContainer>
  );
}
