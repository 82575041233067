import styled from "styled-components";

export const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 422px;
  overflow: hidden;
  background-color: #f7f9fa;
  border-radius: 11px;
`;

export const BannerWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease;
  transform: translateX(${(props) => props.translate}%);
`;

export const BannerSlide = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: ${(props) => props.index * 100}%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
`;

export const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 75px;
  padding-bottom: 35px;
  padding-top: 35px;
`;

export const BannerTitle = styled.h2`
  font-family: "Poppins";
  font-size: 2.25rem;
  line-height: 2.75rem;
  font-weight: bold;
  color: #000;
  margin: 0;
`;

export const BannerDescription = styled.div`
  max-height: 250px;
  margin: 8px 0 16px 0;
  word-break: break-all;
  position: relative;
  overflow: hidden;

  & > p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }
`;

export const BannerButton = styled.a`
  font-family: "Open Sans";

  background-color: #11a7d9;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: opacity 0.3s;
  font-weight: normal;
  width: fit-content;

  /* width: 132.06211853027344; */
  height: 33.82765579223633px;
  border-radius: 8.46px;
  padding-top: 6.77px;
  padding-right: 13.53px;
  padding-bottom: 6.77px;
  padding-left: 13.53px;
  gap: 6.77px;

  font-family: "Open Sans";
  font-weight: 700;
  font-size: 11.84px;
  line-height: 150%;
  letter-spacing: 0.2px;

  &:hover {
    opacity: 0.7;
    color: white;
  }
`;

export const ImageColumn = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  max-width: 55%;
  height: 422px;
  width: 100%;
`;

export const BannerImage = styled.img`
  object-fit: cover;
  align-self: center;
  width: 100%;

  aspect-ratio: 16/9;
  /* @media (max-width: 1235px) {
    height: 75%;
  } */
`;

export const BackgroundDecoration = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: -1;
`;

export const NavigationButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #c7c9d933;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 3;

  width: 30px;
  height: 30px;
  border-radius: 15px;

  &:hover {
    background-color: rgba(199, 201, 217, 0.5);
  }

  ${(props) => (props.direction === "prev" ? "left: 16px;" : "right: 16px;")}
`;

export const Arrow = styled.span`
  border: solid #000;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  transform: ${(props) =>
    props.direction === "prev" ? "rotate(135deg)" : "rotate(-45deg)"};
`;

export const CarouselDots = styled.div`
  position: absolute;
  bottom: 11px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 7.33px;
  z-index: 3;
`;

export const Dot = styled.button`
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? "#33D27E" : "#C7C9D9")};
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${(props) =>
      props.active ? "#33D27E" : "rgba(51, 210, 125, 0.5)"};
  }
`;
