import React from "react";
import AnimatedMainContainer from "../../../../components/AnimatedMainContainer";
import ArticleForm from "./article-form";

export default function NewArticle() {
  return (
    <AnimatedMainContainer>
      <ArticleForm />
    </AnimatedMainContainer>
  );
}
