const SET_PREVIEW_BANNER = "@homepageBanner/SET_PREVIEW_BANNER";
const SET_PREVIEW_COURSE = "@homepageBanner/SET_PREVIEW_COURSE";
const REMOVE_BANNER = "@homepageBanner/REMOVE_BANNER";
const SET_IS_EDIT = "@homepageBanner/SET_IS_EDIT";

export const setPreviewBanner = (banners) => ({
  type: SET_PREVIEW_BANNER,
  payload: banners,
});

export const setPreviewCourse = (courses) => ({
  type: SET_PREVIEW_COURSE,
  payload: courses,
});

export const removeBanner = (index) => ({
  type: REMOVE_BANNER,
  payload: index,
});

export const setIsEdit = (isEdit) => ({
  type: SET_IS_EDIT,
  payload: isEdit,
});
