import { useIntl } from "react-intl";
import {
  ChartContainer,
  ChartLegend,
  Label,
  LegendContainer,
} from "../../styles";
import { Doughnut } from "react-chartjs-2";

export function ProfileChart({ categoryData }) {
  const intl = useIntl();
  const totalUsers = Object.values(categoryData).reduce(
    (acc, curr) => acc + curr,
    0
  );

  const data = {
    labels: [
      intl.formatMessage({
        id: "exhibitor",
      }),
      intl.formatMessage({
        id: "service_provider",
      }),
      intl.formatMessage({
        id: "advertiser",
      }),
      intl.formatMessage({
        id: "sponsorWithoutM2",
      }),
    ],
    datasets: [
      {
        label: "Distribuição",
        data: [
          categoryData.EXHIBITOR,
          categoryData.SERVICE_PROVIDER,
          categoryData.ADVERTISER,
          categoryData.SPONSOR_WITHOUT_M2,
        ],
        backgroundColor: ["#002244", "#06719e", "#7fd0eb", "#33d27e"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label(tooltipItem) {
            const value = tooltipItem.raw;
            const total = tooltipItem.chart.data.datasets[0].data.reduce(
              (a, b) => a + (b ?? 0),
              0
            );
            const percentage = ((value / total) * 100).toFixed(2);
            return `${tooltipItem.label}: ${percentage}%`;
          },
        },
      },
    },
    cutout: "60%",
    maintainAspectRatio: false,
  };

  return (
    <div>
      <Label marginBottom={"20px"}>Perfil</Label>
      <ChartContainer>
        <div>
          <Doughnut
            data={data}
            options={options}
            width={"181.5px"}
            height={"181.5px"}
          />
        </div>
        <LegendContainer>
          <ChartLegendItem
            value={(categoryData.EXHIBITOR ?? 0) / totalUsers}
            type={"exhibitor"}
          />
          <ChartLegendItem
            value={(categoryData.SERVICE_PROVIDER ?? 0) / totalUsers}
            type={"serviceProvider"}
          />
          <ChartLegendItem
            value={(categoryData.ADVERTISER ?? 0) / totalUsers}
            type={"advertiser"}
          />
          <ChartLegendItem
            value={(categoryData.SPONSOR_WITHOUT_M2 ?? 0) / totalUsers}
            type={"sponsor"}
          />
        </LegendContainer>
      </ChartContainer>
    </div>
  );
}

function ChartLegendItem({ value, type }) {
  const intl = useIntl();
  const accountType = new Map();
  accountType.set("exhibitor", {
    variant: "exhibitor",
    label: "exhibitor",
  });
  accountType.set("serviceProvider", {
    variant: "serviceProvider",
    label: "checklist.provider",
  });
  accountType.set("advertiser", {
    variant: "advertiser",
    label: "digital_advertiser",
  });
  accountType.set("sponsor", {
    variant: "sponsor",
    label: "sponsor_without_square_meters",
  });

  const account = accountType.get(type);
  const label = intl.formatMessage({
    id: account.label,
  });
  return (
    <ChartLegend variant={account.variant}>
      <div />
      <p>{label}</p>
      <span>{isNaN(value) ? 0 : (value * 100).toFixed(2)}%</span>
    </ChartLegend>
  );
}
