import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ACardsContainer,
  AContainer,
  AMainContainer,
  AsideContainer,
  ATableContainer,
  DataHeader,
} from "../styles";
import { AnalyticsCard } from "../components/AnalyticsCard";
import AnalyticsBannerDataTable from "../components/AnalyticsTable/analytics-banner-table";
import { ProfileChart } from "../components/ProfileChart";
import { LanguagesData } from "../components/LanguageData";
import { DatePicker } from "antd";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { Selectors } from "../components/AnalyticsTable/styles";
import SelectFilter from "../components/SelectFilter";
import { useToast } from "../../../../hooks/toast";
import moment from "moment";
import generateHeaders from "../../../../utils/generateHeaders";
import api from "../../../../service";
import Button from "../../../../newUXPages/InformaAcademy/components/Button";
import { FiDownload } from "react-icons/fi";
import {
  getBannerAnalytics,
  getBannersByEventId,
} from "../../../../redux/modules/analytics/actions";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const { RangePicker } = DatePicker;

const DEFAULT_PAGE = 1;
const DEFAULT_ROWS_PER_PAGE = 10;

export default function BannerAnalytics({ bannersTotalizers, years, events }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [date, setDate] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedBanner, setSelectedBanner] = useState("");
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [totalRows, setTotalRows] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const { banners, bannerAnalytics, bannerAnalyticsPageInfo } = useSelector(
    (state) => state.analytics
  );

  useEffect(() => {
    if (selectedEvent) {
      dispatch(
        getBannersByEventId({
          eventId: selectedEvent,
        })
      );
    }
    setSelectedBanner("");
  }, [dispatch, selectedEvent]);

  useEffect(() => {
    if (bannerAnalyticsPageInfo) {
      setTotalRows(bannerAnalyticsPageInfo.totalElements);
    }
  }, [bannerAnalyticsPageInfo]);

  const filters = useMemo(
    () => ({
      idEvent: selectedEvent,
      idBanner: selectedBanner,
      year: selectedYear,
      starterDate: date && date?.length > 0 ? date[0] : "",
      finishingDate: date && date?.length > 0 ? date[1] : "",
      sortBy,
      sortDirection,
    }),
    [date, selectedBanner, selectedEvent, selectedYear, sortBy, sortDirection]
  );

  const handleSearch = useCallback(() => {
    const params = {
      ...filters,
      page: currentPage - 1,
      perPage: rowsPerPage,
    };

    return dispatch(getBannerAnalytics(params));
  }, [currentPage, dispatch, filters, rowsPerPage]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const users = intl.formatMessage({
    id: "users",
  });

  const totalUsers =
    bannersTotalizers.banners?.byLanguage?.pt ??
    0 + bannersTotalizers.banners?.byLanguage?.en ??
    0;

  return (
    <AContainer>
      <AMainContainer>
        <ACardsContainer>
          <AnalyticsCard
            number={bannersTotalizers?.actions?.IMPRESSION ?? 0}
            analyticType={"impressions"}
          />
          <AnalyticsCard
            number={bannersTotalizers?.actions?.VIEWS ?? 0}
            analyticType={"views"}
          />
          <AnalyticsCard
            number={bannersTotalizers?.actions?.INTERACTION ?? 0}
            analyticType={"interations"}
          />
        </ACardsContainer>
        <ATableContainer>
          <DataHeader>
            <h2>
              {users} ({totalUsers})
            </h2>
            <AnalyticsDownloadButton
              isEmpty={bannerAnalytics.lenght === 0}
              params={filters}
            />
          </DataHeader>

          <Selectors>
            <SelectFilter
              label={"year"}
              options={years}
              setValue={setSelectedYear}
              value={selectedYear}
            />
            <SelectFilter
              label={"event"}
              options={events.filter((event) => event.year === selectedYear)}
              setValue={setSelectedEvent}
              value={selectedEvent}
            />
            <SelectFilter
              disabled={!selectedEvent || banners.length === 0}
              label={"Banner"}
              options={banners}
              setValue={setSelectedBanner}
              value={selectedBanner}
            />
            <RangePicker
              format="DD/MM/YYYY"
              onChange={(d) => setDate(d?.map((i) => i.format("YYYY-MM-DD")))}
              style={{ width: 240 }}
            />
          </Selectors>
        </ATableContainer>

        <AnalyticsBannerDataTable
          bannerAnalytics={bannerAnalytics}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalRows={totalRows}
          setSortBy={setSortBy}
          setSortDirection={setSortDirection}
        />
      </AMainContainer>
      <AsideContainer>
        <ProfileChart categoryData={bannersTotalizers.banners.byCategory} />
        <LanguagesData languageData={bannersTotalizers.banners.byLanguage} />
      </AsideContainer>
    </AContainer>
  );
}

function AnalyticsDownloadButton({ isEmpty, params }) {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const { token } = useSelector((state) => state.auth);
  const { addToast } = useToast();

  const download = intl.formatMessage({
    id: "download",
  });

  const parseToCVS = useCallback(
    ({ bannerAnalyticsData }) => {
      if (bannerAnalyticsData.length === 0) return;

      const timestamp = moment().format("YYYY-MM-DD_HH-mm-ss");
      const cleanedUsers = bannerAnalyticsData.map(
        ({ language, profile, email, name }) => ({
          name,
          email,
          language: intl.formatMessage({ id: language }),
          profile: intl.formatMessage({ id: profile }),
        })
      );

      const cols = [
        [
          intl.formatMessage({ id: "enterprise" }),
          intl.formatMessage({ id: "email" }),
          intl.formatMessage({ id: "language" }),
          intl.formatMessage({ id: "profile" }),
        ],
      ];

      const worksheet = XLSX.utils.json_to_sheet(cleanedUsers);
      XLSX.utils.sheet_add_aoa(worksheet, cols, { origin: "A1" });

      const csvOutput = XLSX.utils.sheet_to_csv(worksheet);
      const csvBlobData = new Blob([String.fromCharCode(0xfeff), csvOutput], {
        type: "text/csv;charset=utf-8",
      });
      FileSaver.saveAs(csvBlobData, `Analytics-Banner-${timestamp}.csv`);
    },
    [intl]
  );

  const handleDownload = useCallback(async () => {
    try {
      setLoading(true);
      const headers = generateHeaders(token);
      const options = {
        ...headers,
        params,
      };
      const { data } = await api.get(
        "/academy/analytics/banners/listall",
        options
      );
      const { analyticsBannerOutputLists: bannerAnalyticsData } = data;
      parseToCVS({ bannerAnalyticsData });
      addToast({
        type: "success",
        title: intl.formatMessage({
          id: "analytics.download_success",
        }),
      });
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      console.log(error, error.message, "Error to download data - BANNERS");
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "analytics.download_failed",
        }),
      });
    }
  }, [token, params, parseToCVS, addToast, intl]);

  return (
    <Button
      Icon={loading ? <AiOutlineLoading3Quarters /> : <FiDownload size={18} />}
      loading={loading}
      label={download}
      variant={"secondary"}
      onClick={handleDownload}
      disabled={isEmpty || loading}
    />
  );
}
