export function listManuals({ query, page, size, sortBy, sortDirection }) {
  return {
    type: "LIST_MANUALS",
    payload: { query, page, size, sortBy, sortDirection },
  };
}

export function listManualsSuccess({ manuals, total, pageInfo }) {
  return {
    type: "LIST_MANUALS_SUCCESS",
    payload: { manuals, total, pageInfo },
  };
}

export function listManualsFailure(error) {
  return {
    type: "LIST_MANUALS_FAILURE",
    payload: error,
  };
}

export function updateManualStatus({ manualId, status }) {
  return {
    type: "UPDATE_MANUAL_STATUS",
    payload: { manualId, status },
  };
}

export function updateManualStatusSuccess({ manualId, newStatus }) {
  return {
    type: "UPDATE_MANUAL_STATUS_SUCCESS",
    payload: { manualId, newStatus },
  };
}

export function updateManualStatusFailure(error) {
  return {
    type: "UPDATE_MANUAL_STATUS_FAILURE",
    payload: error,
  };
}

export function listArticles({
  query,
  page,
  size,
  sortBy,
  sortDirection,
  manualId,
}) {
  return {
    type: "LIST_ARTICLES",
    payload: { query, page, size, sortBy, sortDirection, manualId },
  };
}

export function listArticlesSuccess({ articles, pageInfo }) {
  return {
    type: "LIST_ARTICLES_SUCCESS",
    payload: { articles, pageInfo },
  };
}

export function listArticlesFailure(error) {
  return {
    type: "LIST_ARTICLES_FAILURE",
    payload: error,
  };
}

export function getArticlesTotalCounter({ manualId }) {
  return {
    type: "GET_ARTICLES_TOTAL",
    payload: { manualId },
  };
}

export function getArticlesTotalCounterSuccess({ total }) {
  return {
    type: "GET_ARTICLES_TOTAL_SUCCESS",
    payload: { total },
  };
}

export function getArticlesTotalCounterFailure(error) {
  return {
    type: "GET_ARTICLES_TOTAL_FAILURE",
    payload: error,
  };
}

export function listChapters({ manualId }) {
  return {
    type: "LIST_CHAPTERS",
    payload: { manualId },
  };
}

export function listChaptersSuccess({ chapters, eventName }) {
  return {
    type: "LIST_CHAPTERS_SUCCESS",
    payload: { chapters, eventName },
  };
}

export function listChaptersFailure(error) {
  return {
    type: "LIST_CHAPTERS_FAILURE",
    payload: error,
  };
}

export function getArticle({ articleId }) {
  return {
    type: "GET_ARTICLE",
    payload: { articleId },
  };
}

export function getArticleSuccess({ article }) {
  return {
    type: "GET_ARTICLE_SUCCESS",
    payload: { article },
  };
}

export function getArticleFailure(error) {
  return {
    type: "GET_ARTICLE_FAILURE",
    payload: error,
  };
}

export function listFAQ({ manualId }) {
  return {
    type: "LIST_FAQ",
    payload: { manualId },
  };
}

export function listFAQSuccess({ FAQ }) {
  return {
    type: "LIST_FAQ_SUCCESS",
    payload: { FAQ },
  };
}

export function listFAQFailure(error) {
  return {
    type: "LIST_FAQ_FAILURE",
    payload: error,
  };
}
