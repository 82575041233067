import React, { useCallback, useEffect, useState } from "react";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { Container, Title, TitleContainer, WrapperContainer } from "./style";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "../InformaAcademy/components/button";
import { BiPlus } from "react-icons/bi";
import SearchInput from "../InformaAcademy/components/SearchInput";
import { useDispatch, useSelector } from "react-redux";
import { listManuals } from "../../redux/modules/adminExhibitorManual/actions";
import ManualTable from "./components/manual-table";
import { debounce } from "lodash";
import ManualError from "./components/manualError";

const DEFAULT_PAGE = 1;
const DEFAULT_ROWS_PER_PAGE = 10;

function ManualHeader({ total, setSearch }) {
  const [inputValue, setInputValue] = useState("");

  const debouncedSearch = debounce((value) => {
    setSearch(value);
  }, 300);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    debouncedSearch(value);
  };

  return (
    <TitleContainer>
      <Title>
        <FormattedMessage id="manual" /> ({total})
      </Title>
      <SearchInput value={inputValue} onChange={handleSearchChange} />
    </TitleContainer>
  );
}

export default function ExhibitorManual() {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [totalRows, setTotalRows] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const intl = useIntl();
  const dispatch = useDispatch();
  const { manuals, total, pageInfo, error } = useSelector(
    (state) => state.adminManualExhibitor
  );

  const handleSearch = useCallback((searchTerm) => {
    setSearch(searchTerm);
    setCurrentPage(DEFAULT_PAGE);
    setRowsPerPage(DEFAULT_ROWS_PER_PAGE);
    setSortBy("");
    setSortDirection("");
  }, []);

  useEffect(() => {
    dispatch(
      listManuals({
        page: currentPage - 1,
        query: search,
        size: rowsPerPage,
        sortBy,
        sortDirection,
      })
    );
  }, [currentPage, dispatch, rowsPerPage, search, sortBy, sortDirection]);

  useEffect(() => {
    if (pageInfo) {
      setTotalRows(pageInfo.totalElements);
    }
  }, [pageInfo]);

  if (error) return <ManualError />;

  return (
    <AnimatedMainContainer breadcrumb={intl.formatMessage({ id: "manual" })}>
      <WrapperContainer>
        <Button
          label="Novo Manual"
          Icon={<BiPlus size={20} />}
          shadow
          href="/admin/manual/new"
        />
        <Container>
          <ManualHeader total={total} setSearch={handleSearch} />
          <ManualTable
            manuals={manuals}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalRows={totalRows}
            setSortBy={setSortBy}
            setSortDirection={setSortDirection}
          />
        </Container>
      </WrapperContainer>
    </AnimatedMainContainer>
  );
}
