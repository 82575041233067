import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const ManualSidebarContainer = styled.div`
  width: 324px;
  padding: 24px 27px;
  display: flex;
  flex-direction: column;
  border-right: 2px solid #e2e8f0;

  height: 100%;
`;

export const ManualSidebarItem = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #1e293b;
  margin-top: 1rem;
  cursor: pointer;

  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
  letter-spacing: 0%;

  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}

  ${(props) =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}


  &:first-child {
    margin-top: 0;
  }

  &.active {
    font-weight: 600;
  }

  &:nth-child(2) {
    cursor: initial;
  }

  &:hover {
    color: #1e293b;
    text-decoration: none;
  }
`;

export const ManualSidebarText = styled(Link)`
  font-size: 14px;
  color: #1e293b;
  text-decoration: none;
  text-align: justify;

  font-family: "Inter";
  font-weight: 300;
  font-size: 14px;
  line-height: 22.4px;
  letter-spacing: 0%;

  &:hover {
    color: #1e293b;
    text-decoration: none;
  }

  ${(props) =>
    props.active &&
    css`
      font-weight: 600;
    `}
`;

export const SidebarContainer = styled.div`
  display: grid;
  gap: 12px;
  margin-top: 12px;
`;

export const ManualSidebarWrapper = styled.div`
  padding: 0;
`;

export const ManualSidebarSubitemWrapper = styled.div``;

export const SidebarItensWrapper = styled.div`
  padding-left: 1.5rem;
  display: grid;
  gap: 8px;
  padding-top: 20px;
`;

export const ManualSidebarSubitem = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 14px;
  color: #1e293b;
  margin-top: 1rem;
  cursor: pointer;
  border-radius: 8px;

  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
  letter-spacing: 0%;

  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}

  &:first-child {
    margin-top: 0;
  }

  &.active {
    background: #e2e8f0;
    color: #095f83;
    font-weight: 600;
  }

  &:hover {
    color: #1e293b;
    text-decoration: none;
  }
`;
