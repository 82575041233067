import React from "react";
import { FaCheck } from "react-icons/fa";
import {
  StepsContainer,
  StepsHeader,
  Step,
  StepLine,
  StepCircle,
} from "./styles";
import { useIntl } from "react-intl";

const Steps = ({ steps, currentStep }) => {
  const intl = useIntl();
  return (
    <StepsContainer>
      <StepsHeader>
        {steps.map((step, index) => (
          <>
            <Step key={index} className={index === currentStep ? "active" : ""}>
              <StepCircle
                className={
                  index === currentStep
                    ? "active"
                    : index < currentStep
                    ? "passed"
                    : ""
                }
              >
                {index < currentStep ? <FaCheck /> : index + 1}
              </StepCircle>
              {intl.formatMessage({ id: step })}
            </Step>
            {index < steps.length - 1 && <StepLine />}
          </>
        ))}
      </StepsHeader>
    </StepsContainer>
  );
};

export default Steps;
