import * as React from "react";

const Lock = (props) => {
  const { size = 16, color = "#991B1B" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13.333 7.833H2.667A.667.667 0 0 0 2 8.5v6c0 .368.298.666.667.666h10.666A.667.667 0 0 0 14 14.5v-6a.667.667 0 0 0-.667-.667Z"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4.667 7.833V5.166a3.333 3.333 0 0 1 6.666 0v2.667M8 10.5v2"
      ></path>
    </svg>
  );
};

export default Lock;
