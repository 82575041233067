import { Controller, useWatch } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { useCallback, useEffect, useState } from "react";
import {
  Description,
  DropzoneContainer,
  Error,
  FormItem,
  InputLabel,
  PreviewImage,
  PreviewImageContainer,
  PreviewImageContent,
  PreviewImageContentRow,
  PreviewImageName,
  PreviewImageSize,
  RemoveButtonPreview,
  Required,
  DropLabel,
  ClickLabel,
} from "./styles";
import FileIcon from "../../icons/File";
import { useToast } from "../../hooks/toast";
import { useIntl } from "react-intl";
import { MdError } from "react-icons/md";

export const MARLABS_DEFAULT =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1HakUW8f2Uf03gxKrnuYvMs-2L_yQKCQspw&s";

export const ControlledDropzoneSingle = ({
  name,
  control,
  multiple = false,
  setValue,
  errors,
  required,
  description,
  subtitle,
  label,
  index,
  defaultName = undefined,
  ...rest
}) => {
  const [imageDetails, setImageDetails] = useState(null);
  const intl = useIntl();
  const [error, setError] = useState(false);
  const watch = useWatch({ control, name });

  useEffect(() => {
    if (errors?.[name]) {
      setError(true);
      return;
    }

    if (watch === undefined) {
      setImageDetails(null);
      setError(false);
      return;
    }

    if (watch && !imageDetails) {
      setImageDetails(getFileDetails(watch, defaultName));
      setError(false);
      return;
    }

    setError(false);
  }, [errors, watch, name, imageDetails]);

  const labelIntl = intl.formatMessage({
    id: label,
  });

  const errorMessage = intl.formatMessage({ id: "error.field_required" });
  const isRequired = required && <Required>*</Required>;
  const hasDesc = description && (
    <Description>({intl.formatMessage({ id: description })})</Description>
  );
  const hasDesc2 = subtitle && (
    <Description subtitle>{intl.formatMessage({ id: subtitle })}</Description>
  );

  const handleReset = () => {
    setValue(name, null);
    setImageDetails(getFileDetails(null));
  };

  const onDrop = useCallback(
    (files) => {
      setValue(name, files[0]);
      setImageDetails(getFileDetails(files[0]));
    },
    [setValue, name]
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <FormItem>
          <InputLabel subtitle={subtitle}>
            {labelIntl}
            {isRequired} {hasDesc}
          </InputLabel>
          {hasDesc2}
          <Dropzone
            multiple={multiple}
            reset={handleReset}
            onDrop={onDrop}
            error={error}
            imageDetails={imageDetails}
            onChange={(e) => {
              setImageDetails(getFileDetails(e.target.files?.[0] ?? null));
              return onChange(
                multiple ? e.target.files : e.target.files?.[0] ?? null
              );
            }}
            {...rest}
          />
          {error && (
            <Error>
              <MdError color="#DC2626" size={14} />
              {errorMessage}
            </Error>
          )}
        </FormItem>
      )}
    />
  );
};

const Dropzone = ({
  multiple,
  reset,
  imageDetails,
  onChange,
  onDrop,
  accept = ACCEPT,
  maxFileSize,
  error,
  maxSize = 1 * 1024 * 1024, // 1mb
  ...rest
}) => {
  const { addToast } = useToast();
  const intl = useIntl();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple,
    onDrop,
    accept: [".jpg", ".jpeg", ".png"],
    maxFiles: 1,
    maxSize: 1 * 1024 * 1024,
    onDropRejected: (e) => {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error",
        }),
        description: intl.formatMessage({
          id: `${e[0].errors[0].code}`,
        }),
      });
    },
    ...rest,
  });


  return (
    <DropzoneContainer
      {...getRootProps()}
      isDragActive={isDragActive}
      error={error}
    >
      {!imageDetails && (
        <>
          <div>
            <FileIcon />
            <DropLabel>
              {intl.formatMessage({
                id: "drop_file_here",
              })}
            </DropLabel>
            <ClickLabel>
              {intl.formatMessage({
                id: "upload_from_computer",
              })}
            </ClickLabel>
          </div>
          <input
            {...getInputProps({ onChange })}
            accept="image/png, image/jpeg, image/jpg"
          />
        </>
      )}

      {imageDetails && (
        <PreviewImageContainer>
          <PreviewImageContent>
            <PreviewImage
              src={imageDetails.fileURL}
              alt="Preview"
              onError={() => {
                reset();
                addToast({
                  type: "error",
                  title: intl.formatMessage({
                    id: "lesson.errorToLoadImage",
                  }),
                });
              }}
            />
            <div>
              <PreviewImageContentRow title={imageDetails.fileName}>
                <PreviewImageName>{imageDetails.fileName}</PreviewImageName>
                <PreviewImageSize>{imageDetails.fileSize} MB</PreviewImageSize>
              </PreviewImageContentRow>
              <RemoveButtonPreview onClick={reset}>Remover</RemoveButtonPreview>
            </div>
          </PreviewImageContent>
        </PreviewImageContainer>
      )}
    </DropzoneContainer>
  );
};

const ACCEPT = {
  "image/png": [".png"],
  "image/jpeg": [".jpg", ".jpeg"],
};

const getFileDetails = (file, defaultName) => {
  if (!file) return null;

  const fileURL = URL.createObjectURL(file);
  const fileName = file.name;
  const fileSize = file.size;
  const fileSizeInMB = (fileSize / (1024 * 1024)).toFixed(2);

  return {
    fileURL,
    fileName: defaultName ? defaultName : fileName,
    fileSize: fileSizeInMB,
  };
};
