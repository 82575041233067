import api from "./index";

export const getInformaAcademyEventService = async (params) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${params.token}`,
    },
  };

  return await api.get(`/home-page`, options);
};

export const getInformaAcademyBannersService = async (params) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${params.token}`,
    },
  };
  return await api.get(`/academy/banners/event/${params.eventId}`, options);
};

export const getInformaAcademyFilterEventByNameService = async (params) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${params.token}`,
    },
  };

  return await api.get(`/home-page?name=${params.eventName}`, options);
};

export const getInformaAcademyFilterEventByYearService = async (params) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${params.token}`,
    },
  };

  return await api.get(`/home-page?year=${params.eventYear}`, options);
};
// /academy/* -> Expositor
// /informa-academy/* -> ADMIN

export const getInformaAcademyBannerService = async (params) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${params.token}`,
    },
  };

  return await api.get(`/academy/banners/event/${params.eventId}`, options);
};

export const getInformaAcademyCourseListService = async (params) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${params.token}`,
    },
  };

  return await api.get(
    `/academy/courses/catalog?eventId=${params.eventId}`,
    options
  );
};

export const getInformaAcademyPreviewCourseListService = async (params) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${params.token}`,
    },
  };

  return await api.get(
    `/academy/courses/preview?eventId=${params.eventId}`,
    options
  );
};

export const getInformaAcademyBannerCourseListService = async (params) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${params.token}`,
    },
  };

  return await api.get(
    `academy/courses/visible?eventId=${params.eventId}`,
    options
  );
};

export const reorderInformaAcademyBannerCourseListService = async (params) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${params.token}`,
    },
  };

  return await api.put(`academy/courses/order`, params.reorderCourse, options);
};

export const changeVisibilityInformaAcademyBannerCourseListService = async (
  params
) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${params.token}`,
    },
  };

  return await api.put(
    `academy/courses/${params.courseId}/visibility`,
    options
  );
};

export const createInformaAcademyBannerService = async (params) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${params.token}`,
    },
  };

  return await api.post(`academy/banners`, params.banners, options);
};

export const editInformaAcademyBannerService = async (params) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${params.token}`,
    },
  };

  return await api.put(
    `academy/banners/homepage/${params.homePageId}`,
    params.banners,
    options
  );
};

export const deleteInformaAcademyBannerService = async (params) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${params.token}`,
    },
  };

  return await api.delete(`academy/banners/${params.bannerId}`, options);
};

export const getInformaAcademyCourseService = async (params) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${params.token}`,
    },
  };

  return await api.get(
    `/academy/courses/coursedetails/${params.courseId}`,
    options
  );
};

export const getInformaAcademyLessonService = async (params) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${params.token}`,
    },
  };

  return await api.get(
    `/academy/course/${params.courseId}/lesson/${params.lessonId}`,
    options
  );
};

export const markLessonCompletedService = async ({
  token,
  courseId,
  lessonId,
}) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${token}`,
    },
  };

  return await api.put(
    `/academy/course/${courseId}/lesson/${lessonId}/completed`,
    options
  );
};

export const startCourseService = async ({ token, courseId }) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${token}`,
    },
  };

  return await api.post(`/academy/course/${courseId}/start`, options);
};

export const completeCourseService = async ({ token, courseId }) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${token}`,
    },
  };
  await api.post(
    `/academy/analytics/courses/${courseId}?action=VIEWS`,
    options
  );
  return await api.put(`/academy/course/${courseId}/complete`, options);
};

export const restartCourseService = async ({ token, courseId }) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${token}`,
    },
  };

  return await api.put(`/academy/course/${courseId}/restart`, options);
};

export const getAdminCourseInfoService = async ({ token, courseId }) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${token}`,
    },
  };

  return await api.get(`/informa-academy/course/${courseId}`, options);
};

export const getAdminLessonsInfoService = async ({ token, courseId }) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${token}`,
    },
  };

  return await api.get(`/informa-academy/lesson/course/${courseId}`, options);
};

export const getAdminLastestCoursesService = async ({ token }) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${token}`,
    },
  };

  return await api.get("academy/course/last10-updated-courses", options);
};

export const likeLessonService = async ({ token, courseId, lessonId }) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${token}`,
    },
  };

  return await api.post(
    `/academy/analytics/courses/${courseId}/lessons/${lessonId}?action=RECOMMENDATION`,
    options
  );
};

export const unlikeLessonService = async ({ token, courseId, lessonId }) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${token}`,
    },
  };

  return await api.delete(
    `/academy/analytics/courses/lessons/Recommendation?idCourse=${courseId}&idLesson=${lessonId}`,
    options
  );
};

export const interactionLessonService = async ({
  token,
  courseId,
  lessonId,
}) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${token}`,
    },
  };

  return await api.post(
    `/academy/analytics/courses/${courseId}/lessons/${lessonId}?action=INTERACTION`,
    options
  );
};

export const impressionCourseService = async ({ token, courseId }) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${token}`,
    },
  };
  return await api.post(
    `/academy/analytics/courses/${courseId}?action=IMPRESSION`,
    options
  );
};

export const getAdminAllBannerEditService = async ({ params }) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${params.token}`,
    },
  };

  return await api.get(`academy/banners/all/event/${params.eventId}`, options);
};
