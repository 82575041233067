import * as React from "react";

const ClickIcon = (props) => {
  const { size = 18, color = "#FFC327" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 19 19"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9.902 2v3"
      ></path>
      <path
        fill={color}
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m9.152 8.75 7.5 1.5-2.25 1.5 2.25 2.25-2.25 2.25-2.25-2.25-1.5 2.25z"
        clipRule="evenodd"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m15.207 4.196-2.121 2.122M4.598 14.803l2.121-2.121M2.402 9.5h3M4.598 4.196l2.121 2.122"
      ></path>
    </svg>
  );
};

export default ClickIcon;
