import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  .rdt_Table {
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    overflow: hidden;
  }

  .rdt_Pagination {
    border: 0;
  }
`;

export const SelectContainer = styled.div`
  border: 1px solid #ebebf0;
  border-radius: 10px;
  padding: 10px;
  width: fit-content;
  & .ant-select-selector {
    border: none !important;
    padding-right: 20px !important;
    padding-left: 4px !important;
  }

  & .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }

  & > span {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 19.07px !important;
    color: #727272 !important;
  }

  ${({ fit }) =>
    fit &&
    css`
      .ant-select-selection-item {
        width: fit-content !important;
      }
    `}
`;

export const Selectors = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;

  & .ant-picker {
    border-radius: 10px !important;
    border-color: #e2e8f0 !important;
  }

  .ant-picker-input > input {
    text-align: center !important;
  }

  .ant-picker-focused {
    box-shadow: none !important;
  }
  .ant-picker-range .ant-picker-active-bar {
    background: #002244 !important;
  }
`;
