import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: white;
`;

export const Content = styled.article`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 250px;
  background: #fcfcfc;
  height: 94vh;

  ${({ informaAcademyCourse }) =>
    informaAcademyCourse &&
    css`
      padding: 0px;
    `}
`;
