import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";

import { FormRow } from "../../../../components/ControlledInputs/styles";
import ControlledInput from "../../../../components/ControlledInputs/input";
import Button from "../button";
import Save from "../../../../icons/Save";
import BackIcon from "../../../../icons/Back";
import { ControlledInputFiles } from "../../../../components/ControlledInputs/input-files";
import { useSelector } from "react-redux";
import { convertLessonToFormData } from "../../new";
import api from "../../../../service";
import ControlledTextareaJodit from "../../../../components/ControlledInputs/jodit-textarea";
import { ControlledDropzoneSingle } from "../../../../components/ControlledInputs/single-dropzone";

const schema = z.object({
  id: z.coerce.number().optional(),
  orders: z.coerce.number().optional(),
  title: z.string().min(1).max(70, {
    message: "error.max_length-70",
  }),
  instructor: z.string().min(1).max(50, {
    message: "error.max_length-50",
  }),
  coverImage: z.union([z.instanceof(File), z.undefined(), z.null()]),
  // .refine((file) => file.size > 0, {
  //   message: "file cannot be empty",
  // })
  description: z
    .string()
    .refine(
      (value) => {
        const cleanedValue = value
          .replace(/<\/?[^>]+(>|$)/g, "")
          .replace(/\n/g, "")
          .trim();
        return cleanedValue.length > 0;
      },
      {
        message: "error.field_required",
      }
    )
    .refine(
      (value) => {
        const cleanedValue = value
          .replace(/<\/?[^>]+(>|$)/g, "")
          .replace(/\n/g, "")
          .trim();
        return cleanedValue.length <= 180;
      },
      {
        message: "error.max_length-180",
      }
    ),
  url: z.string().refine(
    (url) => {
      const regEx =
        /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/|v\/)|youtu\.be\/|vimeo\.com\/)([a-zA-Z0-9_-]+)\/?$/;

      return regEx.test(url);
    },
    {
      message: "informa_academy_invalid_lesson_video_link",
    }
  ),
  files: z.array(z.instanceof(File)).optional(),
});

const LessonForm = ({
  defaultValue,
  setLessons,
  lessons,
  resetModal,
  setUpdateCounter,
}) => {
  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: defaultValue ?? {
      title: "",
      orders: "",
      instructor: "",
      // coverImage: null,
      description: "",
      url: "",
    },
  });
  const { control, handleSubmit, formState, reset, setValue } = form;
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!defaultValue) return;
    setValue("title", defaultValue.title);
    setValue("orders", defaultValue.orders);
    setValue("instructor", defaultValue.instructor);
    setValue("url", defaultValue.url);
    setValue("description", defaultValue.description);
    setValue("files", defaultValue?.files ?? defaultValue.file);

    if (defaultValue.id) setValue("id", defaultValue.id);
  }, [defaultValue, setValue]);

  const handleReset = () => {
    reset();
    resetModal();
  };

  const updateLesson = useCallback(
    async ({ lesson }) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      if (!defaultValue?.course) return;
      await api.put(
        `/informa-academy/lesson/${lesson.id}`,
        convertLessonToFormData(lesson, defaultValue.course),
        config
      );
    },
    [defaultValue?.course, token]
  );

  const onSubmit = async (data) => {
    if (defaultValue) {
      const findIndex = lessons.findIndex(
        (lesson) => lesson.id === defaultValue.id
      );
      if (findIndex !== -1) lessons.splice(findIndex, 1);

      await updateLesson({ lesson: data });
    }

    const newLesson = {
      ...data,
      orders: data.orders ?? 99_999,
    };

    const reorderedLessons = addLesson(lessons, newLesson);
    setLessons(reorderedLessons);
    setUpdateCounter((prev) => prev + 1);
    return handleReset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} key={Math.random()}>
      <FormRow marginBottom={"24px"}>
        <ControlledInput
          name={"title"}
          control={control}
          formState={formState}
          label={"title"}
          required={true}
          description={"lesson_name_sub"}
          max={70}
        />
        <ControlledInput
          name={"orders"}
          control={control}
          formState={formState}
          label={"lesson_order"}
          // required={true}
          type="number"
          min={1}
        />
        <ControlledInput
          name={"instructor"}
          control={control}
          formState={formState}
          label={"instructor"}
          required={true}
          max={50}
        />
      </FormRow>

      <FormRow marginBottom={"24px"}>
        <ControlledDropzoneSingle
          errors={formState.errors}
          control={control}
          name={"coverImage"}
          setValue={form.setValue}
          label={"lesson_cover"}
          description={"lesson_cover_sub"}
          subtitle={"lesson_cover_sub_2"}
          defaultName={defaultValue?.title}
        />
        <ControlledTextareaJodit
          name={"description"}
          control={control}
          formState={formState}
          label={"description"}
          required={true}
          description={"description_lesson_sub"}
          sub={"lesson_sub_2"}
          max={180}
          height={248}
          minHeight={248}
          maxHeight={248}
          buttonsType="normal"
          split
        />
      </FormRow>

      <FormRow marginBottom={"24px"}>
        <ControlledInput
          name={"url"}
          control={control}
          formState={formState}
          label={"URL"}
          required={true}
          description={"youtube_or_vimeo"}
        />
        <ControlledInputFiles
          errors={formState.errors}
          control={control}
          name={"files"}
          setValue={form.setValue}
          label={"files"}
          description={"files_sub"}
        />
      </FormRow>

      <FormRow buttons>
        <Button
          label={"goback"}
          variant={"secondary"}
          Icon={<BackIcon />}
          type={"button"}
          onClick={() => {
            handleReset();
          }}
        />
        <Button label={"save"} Icon={<Save />} />
      </FormRow>
    </form>
  );
};

export default LessonForm;

function addLesson(lessons, newLesson) {
  const copiedLesson = {
    ...newLesson,
    orders: +newLesson.orders - 1,
  };
  lessons.push(copiedLesson);

  lessons.sort((a, b) => a.orders - b.orders);

  for (let i = 0; i < lessons.length; i++) {
    if (i + 1 !== lessons[i].orders) {
      lessons[i].orders = i + 1;
    }
  }

  return lessons;
}
