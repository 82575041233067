import styled, { css, keyframes } from "styled-components";

export const VideoContainer = styled.div`
  position: relative;
  max-width: 991px;
  aspect-ratio: 16/9;
  border-radius: 4px;
  overflow: hidden;

  ${(props) =>
    props.preview &&
    css`
      width: 400px;
      max-width: 400px;
    `}
`;

const showUp = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Thumbnail = styled.div`
  ${(props) =>
    props.coverImage &&
    css`
      background-image: url(${props.coverImage});
    `}

  ${(props) =>
    props.youtube &&
    css`
      background-image: url(https://img.youtube.com/vi/${props.youtube}/hqdefault.jpg);
    `}

    ${(props) =>
    props.vimeo &&
    css`
      background-image: url(https://vumbnail.com/${props.vimeo}.jpg);
    `}

  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: overlay;
  width: 100%;
  height: 100%;
  cursor: pointer;

  ${(props) =>
    props.normalCursor &&
    css`
      cursor: default;
    `}

  ${(props) =>
    props.nextLesson &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99999;
      animation: ${showUp} 500ms;
      cursor: default;
    `}
`;

export const NextLessonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & p {
    color: white;
    font-size: 18px;
    margin-bottom: 0;
  }

  & .progress {
    & .ant-progress-inner {
      width: 100px !important;
      height: 100px !important;
    }

    & .ant-progress-text {
      color: white;
    }
  }
`;

export const YoutubePlayer = styled.div`
  width: 100%;
  height: 100%;
`;

export const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #3a3c3c;
  color: #fff;
  border: none;
  width: 132.5px;
  height: 132.5px;
  font-size: 48px;
  cursor: pointer;
  border-radius: 999px;

  ${(props) =>
    props.preview &&
    css`
      cursor: default;
      width: 66px;
      height: 66px;
      font-size: 24px;
    `}
`;
