import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import EventManualHomepage from "./EventManualHomepage";
import {
  getManualFaq_Highlight,
  getManualTopicList,
} from "../../redux/modules/eventManual/action";
import EventManualHeader from "./EventManualHomepage/Header";

const EventManual = () => {
  const { event } = useSelector((state) => state.initialSettings);
  const { topics, loadedTopics, faqs, recentArticles, savedArticles } =
    useSelector((state) => state.eventManual);
  const [getTopics, setGetTopics] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getManualTopicList());
    dispatch(getManualFaq_Highlight());
  }, [dispatch]);

  useEffect(() => {
    if (loadedTopics && topics.length === 0) {
      history.push("/checklist");
    }
  }, [history, loadedTopics, topics.length]);

  useEffect(() => {
    if (getTopics) {
      dispatch(getManualTopicList({ eventId: event.value }));
      setGetTopics(false);
    }
  }, [dispatch, event.value, getTopics]);

  if (!loadedTopics && topics.length === 0) return null;

  return (
    <div>
      <EventManualHeader topics={topics} />
      <EventManualHomepage
        topics={topics}
        faqs={faqs}
        recentArticles={recentArticles}
        savedArticles={savedArticles}
      />
    </div>
  );
};

export default EventManual;
