import React from "react";
import {
  CourseContainer,
  CourseTitle,
  CourseTitleContainer,
  LessonList,
  LessonListWrapper,
  OpenCourseLink,
} from "./style";

import { LessonCard } from "../../../newUXComponents/LessonCard";
import { FormattedMessage } from "react-intl";

const CourseList = ({ courses }) => {
  return (
    <CourseContainer>
      {courses.map((course, index) => {
        return (
          <div key={index}>
            <CourseTitleContainer>
              <CourseTitle>{course.courseTitle}</CourseTitle>
              <OpenCourseLink to={`/informa-academy/course/${course.courseId}`}>
                <FormattedMessage id="homepage.open_course" />
              </OpenCourseLink>
            </CourseTitleContainer>
            <LessonListWrapper>
              <LessonList>
                {course.lessons.map((lesson, index) => (
                  <LessonCard
                    key={index}
                    lesson={lesson}
                    courseId={course.courseId}
                    lessonId={lesson.lessonId}
                    index={index}
                    icon={true}
                  />
                ))}
              </LessonList>
            </LessonListWrapper>
          </div>
        );
      })}
    </CourseContainer>
  );
};

export default CourseList;
