import React, { useState, useEffect } from "react";
import {
  Arrow,
  BackgroundDecoration,
  BannerButton,
  BannerContainer,
  BannerDescription,
  BannerImage,
  BannerSlide,
  BannerTitle,
  BannerWrapper,
  CarouselDots,
  ContentColumn,
  Dot,
  ImageColumn,
  NavigationButton,
} from "./style";
import SvgBannerImageBg from "./svg-banner-image-bg";
import useZoom from "../../../../hooks/zoom";
import { useIntl } from "react-intl";

const BannerCaroussel = ({ banners }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [translate, setTranslate] = useState(0);
  const { scale } = useZoom();
  const intl = useIntl();

  useEffect(() => {
    if (banners?.length > 1) {
      const interval = setInterval(() => {
        nextSlide();
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [currentSlide, banners?.length]);

  const nextSlide = () => {
    const next = currentSlide === banners.length - 1 ? 0 : currentSlide + 1;
    setCurrentSlide(next);
    setTranslate(-next * 100);
  };

  const prevSlide = () => {
    const prev = currentSlide === 0 ? banners.length - 1 : currentSlide - 1;
    setCurrentSlide(prev);
    setTranslate(-prev * 100);
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
    setTranslate(-index * 100);
  };

  const getFileDetails = (file) => {
    if (!file) return null;

    if (file.hasOwnProperty("url")) {
      return file.url;
    }

    const urlFile = URL.createObjectURL(file);

    return urlFile;
  };

  if (!banners || banners.length === 0) return null;

  return (
    <BannerContainer>
      {banners.length > 1 && (
        <>
          <NavigationButton direction="prev" onClick={prevSlide}>
            <ArrowIcon direction="prev" />
          </NavigationButton>
          <NavigationButton direction="next" onClick={nextSlide}>
            <ArrowIcon direction="next" />
          </NavigationButton>
        </>
      )}
      <BannerWrapper translate={translate}>
        {banners.map((banner, index) => (
          <BannerSlide key={index} index={index}>
            <ContentColumn>
              <BannerTitle>{banner.title}</BannerTitle>
              <BannerDescription
                scale={scale}
                dangerouslySetInnerHTML={{ __html: banner.description }}
              />
              {banner.buttonUrl && (
                <BannerButton target="_blank" href={banner.buttonUrl}>
                  {intl.formatMessage({
                    id: "preview_homepage_button_details",
                  })}
                </BannerButton>
              )}
            </ContentColumn>
            <ImageColumn>
              <BannerImage
                src={
                  banner.bannerImage.url
                    ? banner.bannerImage.url
                    : getFileDetails(banner.bannerImage)
                }
                alt={banner.title}
              />
            </ImageColumn>
          </BannerSlide>
        ))}
      </BannerWrapper>
      {banners.length > 1 && (
        <CarouselDots>
          {banners.map((_, index) => (
            <Dot
              key={index}
              active={currentSlide === index}
              onClick={() => goToSlide(index)}
            />
          ))}
        </CarouselDots>
      )}
    </BannerContainer>
  );
};

export default BannerCaroussel;

function ArrowIcon({ direction = "prev" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke="#444"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d={
          direction === "prev"
            ? "M1.75 7h10.5M5.25 3.5 1.75 7l3.5 3.5"
            : "M12.25 7H1.75M8.75 3.5l3.5 3.5-3.5 3.5"
        }
      />
    </svg>
  );
}
