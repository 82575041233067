import React, { useMemo, useState } from "react";
import { TableButtonContainer, TableContainer } from "../style";
import DataTable from "react-data-table-component";
import Empty from "../../InformaAcademy/components/Empty";
import { customStyles } from "../../InformaAcademy/components/CoursesTable/styles";
import { FormattedMessage, useIntl } from "react-intl";
import { BiChevronUp } from "react-icons/bi";
import StatusBadge from "./status-badge";
import Button from "../../InformaAcademy/components/button";
import { FiEye } from "react-icons/fi";
import Lock from "./Lock";
import CheckCircle from "../../../newUXAssets/icons/CheckCircle";
import { paginationOptions as options } from "./table-utils";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { updateManualStatus } from "../../../redux/modules/adminExhibitorManual/actions";
import {
  ModalButtonsWrapper,
  ModalTitle,
} from "../../InformaAcademy/styles";
import BackIcon from "../../../icons/Back";
import ForwardIcon from "../../../icons/Forward";

export default function ManualTable({
  manuals,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  totalRows,
  setSortBy,
  setSortDirection,
}) {
  const intl = useIntl();

  const paginationOptions = {
    ...options({
      locale: intl.locale,
    }),
    disableNext: currentPage * rowsPerPage >= totalRows,
  };

  const columns = useMemo(() => {
    return [
      {
        id: "event.eventEditionName",
        name: <FormattedMessage id={"event_edition"} />,
        selector: (row) => {
          const repeat = row.repeat;

          const span = repeat && row.language && (
            <FormattedMessage id={row.language} />
          );
          return (
            <span>
              {row.eventName} {span}
            </span>
          );
        },
        sortable: true,
      },
      {
        maxWidth: "270px",
        name: <FormattedMessage id={"status"} />,
        selector: (row) => <StatusBadge label={row.status} />,
      },
      {
        maxWidth: "331px",
        selector: (row) => <ActionRow id={row.id} status={row.status} />,
      },
    ];
  }, []);

  return (
    <TableContainer>
      <DataTable
        data={manuals}
        responsive
        noDataComponent={<Empty intlId="manuals_empty" />}
        columns={columns}
        customStyles={customStyles}
        sortIcon={<BiChevronUp style={{ display: "flex" }} />}
        paginationComponentOptions={paginationOptions}
        persistTableHead
        pagination
        paginationServer
        paginationPerPage={rowsPerPage}
        onChangePage={(page) => setCurrentPage(page)}
        onChangeRowsPerPage={(size) => {
          setCurrentPage(1);
          setRowsPerPage(size);
        }}
        paginationTotalRows={totalRows}
        onSort={(column, sortDirection) => {
          setSortBy(column.id);
          setSortDirection(sortDirection);
        }}
      />
    </TableContainer>
  );
}

function ActionRow({ id, status }) {
  const [open, setOpen] = useState(false);
  return (
    <TableButtonContainer>
      <Button
        label={"visualize"}
        variant={"secondary"}
        size={"sm2"}
        Icon={<FiEye size={13.33} />}
        href={`/admin/manual/${id}`}
      />
      <Button
        label={status === "PUBLIC" ? "archive" : "publish"}
        variant={status === "PUBLIC" ? "destructive" : "green"}
        size={"sm2"}
        Icon={
          status === "PUBLIC" ? <Lock /> : <CheckCircle color={"#166534"} />
        }
        onClick={() => setOpen(true)}
      />

      <UpdateManualStatusModal
        id={id}
        open={open}
        setOpen={setOpen}
        status={status}
      />
    </TableButtonContainer>
  );
}

function UpdateManualStatusModal({ open, setOpen, id, status }) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleChange = () => {
    dispatch(
      updateManualStatus({
        manualId: id,
        status: status === "PRIVATE" ? "PUBLIC" : "PRIVATE",
      })
    );
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      centered={true}
      closable={false}
      width={600}
      footer={""}
      className="modalLesson"
      destroyOnClose
    >
      <ModalTitle>
        {intl.formatMessage({
          id:
            status === "PRIVATE"
              ? "update.manual_status_to_public"
              : "update.manual_status_to_private",
        })}
      </ModalTitle>

      <ModalButtonsWrapper>
        <Button
          label={"goback"}
          variant={"secondary"}
          Icon={<BackIcon size={16} />}
          onClick={() => setOpen(false)}
        />
        <Button
          label={"confirm"}
          Icon={<ForwardIcon />}
          onClick={handleChange}
        />
      </ModalButtonsWrapper>
    </Modal>
  );
}
